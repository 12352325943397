import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WorkoutOverview from "./workoutOverview";
import WorkoutWarmupPdfs from "./workoutWarmups/workoutWarmupPdfs";
import WorkoutWarmupsList from "./workoutWarmups";
import WorkoutExerciseSwapVideos from "./workoutkExercises/workoutExerciseSwapVideos";
import WorkoutExersiseCategories from "./workoutkExercises";
import WorkoutExerciseVideos from "./workoutkExercises/workoutExerciseVideos";
import { usePermissions } from "../../../../functions/usePermissions";

function WorkoutDetails(props) {
  const [exerciseVideosShow, setExerciseVideosShow] = React.useState(false);
  const [exerciseSwapVideosShow, setExerciseSwapVideosShow] = React.useState(false);
  const [warmupPdfsShow, setWarmupPdfsShow] = React.useState(false);
  const [exerciseCategory, setExerciseCategory] = React.useState();
  const [exerciseCategoryVideo, setExerciseCategoryVideo] = React.useState();
  const [workoutWarmupData, setWorkoutWarmupData] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const { state } = useLocation();
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();

  useEffect(() => {
    props.setPageTitleAndSubTitle(state?.workout?.title, `Here is the details of ${state?.workout?.title} !`);

    const loader = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => {
      clearTimeout(loader);
    };
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li class="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li class="breadcrumb-item">
                <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate(-3)}>
                  Workout Categories
                </span>
              </li>
              <li class="breadcrumb-item">
                <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate(-2)}>
                  {state?.category}
                </span>
              </li>
              <li class="breadcrumb-item">
                <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate(-1)}>
                  {state?.subCategory}
                </span>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {state?.workout?.title}
              </li>
            </ol>
          </div>
          <div className="col-12">
            <div className="card">
              {loading ? (
                <div className="text-center py-5">
                  <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                </div>
              ) : (
                <div className="card-body" style={{ minHeight: "300px" }}>
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    {hasPermission("view-workout-overview") && (
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="pills-home-tab"
                          data-toggle="pill"
                          href="#WeekDayOveriew"
                          role="tab"
                          aria-controls="WeekDayOveriew"
                          aria-selected="true"
                        >
                          Overview
                        </a>
                      </li>
                    )}
                    {hasPermission("view-workout-warmups") && (
                      <li class="nav-item">
                        <a
                          className={!hasPermission("view-workout-overview") ? "nav-link active" : "nav-link"}
                          id="pills-profile-tab"
                          data-toggle="pill"
                          href="#WeekDayWarmUp"
                          role="tab"
                          aria-controls="WeekDayWarmUp"
                          aria-selected="false"
                        >
                          Warm-up
                        </a>
                      </li>
                    )}
                    {hasPermission("view-workout-exercises") && (
                      <li class="nav-item">
                        <a
                          className={
                            !hasPermission("view-workout-overview") && !hasPermission("view-workout-warmups") ? "nav-link active" : "nav-link"
                          }
                          id="pills-contact-tab"
                          data-toggle="pill"
                          href="#WeekDayLectures"
                          role="tab"
                          aria-controls="WeekDayLectures"
                          aria-selected="false"
                        >
                          Routine
                        </a>
                      </li>
                    )}
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    {hasPermission("view-workout-overview") && (
                      <div className="tab-pane fade show active" id="WeekDayOveriew" role="tabpanel" aria-labelledby="WeekDayOveriew-tab">
                        <WorkoutOverview setPageTitleAndSubTitle={props?.setPageTitleAndSubTitle} />
                      </div>
                    )}
                    {hasPermission("view-workout-warmups") && (
                      <div
                        className={!hasPermission("view-workout-overview") ? "tab-pane fade show active" : "tab-pane fade"}
                        id="WeekDayWarmUp"
                        role="tabpanel"
                        aria-labelledby="WeekDayWarmUp-tab"
                      >
                        {warmupPdfsShow ? (
                          <WorkoutWarmupPdfs workoutWarmupData={workoutWarmupData} setWarmupPdfsShow={setWarmupPdfsShow} />
                        ) : (
                          <WorkoutWarmupsList setWarmupPdfsShow={setWarmupPdfsShow} setWorkoutWarmupData={setWorkoutWarmupData} />
                        )}
                      </div>
                    )}
                    {hasPermission("view-workout-exercises") && (
                      <div
                        className={
                          !hasPermission("view-workout-overview") && !hasPermission("view-workout-warmups")
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id="WeekDayLectures"
                        role="tabpanel"
                        aria-labelledby="WeekDayLectures-tab"
                      >
                        {exerciseVideosShow ? (
                          <WorkoutExerciseVideos
                            setExerciseVideosShow={setExerciseVideosShow}
                            exerciseCategory={exerciseCategory}
                            setExerciseSwapVideosShow={setExerciseSwapVideosShow}
                            setExerciseCategoryVideo={setExerciseCategoryVideo}
                          />
                        ) : exerciseSwapVideosShow ? (
                          <WorkoutExerciseSwapVideos
                            exerciseCategoryVideo={exerciseCategoryVideo}
                            setExerciseVideosShow={setExerciseVideosShow}
                            setExerciseSwapVideosShow={setExerciseSwapVideosShow}
                          />
                        ) : (
                          <WorkoutExersiseCategories setExerciseVideosShow={setExerciseVideosShow} setExerciseCategory={setExerciseCategory} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkoutDetails;
