import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import InAppRevenueIcon from "../../../assets/icons/InAppRevenueIcon.svg";
import StripeRevenueIcon from "../../../assets/icons/StripeRevenueIcon.svg";
import TotalRevenueIcon from "../../../assets/icons/TotalRevenueIcon.svg";
import ReactApexChart from "react-apexcharts";

import {
  TwentyFourHoursArray,
  generateCustomDateDataArray,
  generateMonthlyDataArray,
  generateMonthlyXAxisArray,
  generateWeeklyDataArray,
  generateWeeklyXAxisArray,
  generateYearlyDataArray,
  generateYearlyXAxisArray,
  getRevenueSummaryStats,
  remove_session,
} from "../../../functions/general";
import { useNavigate } from "react-router-dom";

const RevenueSection = ({ dashboardStats, getLoggedInUser }) => {
  const navigate = useNavigate();

  const [stats, setStats] = useState(dashboardStats);
  const [duration, setDuration] = useState("yearly");
  const [loading, setLoading] = useState(true);

  const [showCustomDuration, setShowCustomDuration] = useState(false);

  useEffect(() => {
    if (duration === "yearly") {
      setStats(dashboardStats);
      setLoading(false)
    } else {
      getRevenueSummaryStats(duration).then((res) => {
        if ((res && res.status == 200) || res.status == 201) {
          setStats(res?.data);
          setLoading(false);
        } else if (res && res.status == 401) {
          remove_session();
          getLoggedInUser();
          navigate("/login");
        } else {
        }
        setLoading(false);
      });
    }
  }, [duration, dashboardStats]);

  const isValidDateFormat = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
  };

  const yearXaxisArray = generateYearlyXAxisArray();
  const monthlyXaxisArray = generateMonthlyXAxisArray();

  let RevenueSeries = [
    {
      name: "Stripe",
      data:
        (isValidDateFormat(duration) && generateCustomDateDataArray(stats?.androidRevenue)) ||
        (duration === "yearly" && generateYearlyDataArray(stats?.androidRevenue, yearXaxisArray)) ||
        (duration === "monthly" && generateMonthlyDataArray(stats?.androidRevenue, monthlyXaxisArray)) ||
        (duration === "weekly" && generateWeeklyDataArray(stats?.androidRevenue)),
    },
    {
      name: "In App iOS",
      data:
        (isValidDateFormat(duration) && generateCustomDateDataArray(stats?.appleRevenue)) ||
        (duration === "yearly" && generateYearlyDataArray(stats?.appleRevenue, yearXaxisArray)) ||
        (duration === "monthly" && generateMonthlyDataArray(stats?.appleRevenue, monthlyXaxisArray)) ||
        (duration === "weekly" && generateWeeklyDataArray(stats?.appleRevenue)),
    },
    // {
    //   name: "Web",
    //   data:
    //     (duration === "yearly" && generateYearlyDataArray(stats?.webRevenue, yearXaxisArray)) ||
    //     (duration === "monthly" && generateMonthlyDataArray(stats?.webRevenue, monthlyXaxisArray)) ||
    //     (duration === "weekly" && generateWeeklyDataArray(stats?.webRevenue)),
    // },
  ];

  let RevenueOptions = {
    chart: {
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: "category", // Use category type for months on the X-axis
      categories:
        (isValidDateFormat(duration) && TwentyFourHoursArray) ||
        (duration === "yearly" && generateYearlyXAxisArray()) ||
        (duration === "monthly" && generateMonthlyXAxisArray()) ||
        (duration === "weekly" && generateWeeklyXAxisArray()),
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value; // Format Y-axis labels as thousands (e.g., 220k)
        },
      },
    },
    // colors: ["#2F4CDD", "#B519EC", "#2BC155"],
    colors: ["#2F4CDD", "#B519EC"],
    tooltip: {
      followCursor: true, // Tooltip follows the cursor
      offsetX: 0, // Adjust horizontal offset
      offsetY: 0, // Adjust vertical offset
      y: {
        formatter: function (value) {
          return value?.toFixed(2);
        },
      },
    },
  };

  const totalStripeRevenue = stats?.androidRevenue?.reduce((sum, item) => sum + (item?.totalSum || 0), 0);
  const totalIosRevenue = stats?.appleRevenue?.reduce((sum, item) => sum + (item?.totalSum || 0), 0);

  return (
    <>
      <div className="card">
        <div className="card-header border-0 pb-0 d-sm-flex d-block">
          <div>
            <h4 className="card-title mb-1">Revenue</h4>
            <small className="mb-0">A detailed summary of revenue</small>
          </div>
          <span className="d-flex gap-2">
            {showCustomDuration && (
              <input
                className="form-control"
                value={duration}
                style={{ width: "250px", height: "67.5px", marginRight: "15px" }}
                type="date"
                max={dayjs().format("YYYY-MM-DD")}
                onChange={(e) => {
                  setLoading(true);
                  setDuration(dayjs(e.target.value).format("YYYY-MM-DD"));
                }}
              />
            )}
            <div className="dropdown custom-dropdown">
              <div className="btn btn-sm btn-primary light d-flex align-items-center svg-btn px-4" data-toggle="dropdown">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M22.4281 2.856H21.8681V1.428C21.8681 0.56 21.2801 0 20.4401 0C19.6001 0 19.0121 0.56 19.0121 1.428V2.856H9.71606V1.428C9.71606 0.56 9.15606 0 8.28806 0C7.42006 0 6.86006 0.56 6.86006 1.428V2.856H5.57206C2.85606 2.856 0.560059 5.152 0.560059 7.868V23.016C0.560059 25.732 2.85606 28.028 5.57206 28.028H22.4281C25.1441 28.028 27.4401 25.732 27.4401 23.016V7.868C27.4401 5.152 25.1441 2.856 22.4281 2.856ZM5.57206 5.712H22.4281C23.5761 5.712 24.5841 6.72 24.5841 7.868V9.856H3.41606V7.868C3.41606 6.72 4.42406 5.712 5.57206 5.712ZM22.4281 25.144H5.57206C4.42406 25.144 3.41606 24.136 3.41606 22.988V12.712H24.5561V22.988C24.5841 24.136 23.5761 25.144 22.4281 25.144Z"
                      fill="#2F4CDD"
                    ></path>
                  </g>
                </svg>
                <div className="text-left ml-3">
                  <span className="d-block fs-16">Select Duration</span>
                  <small className="d-block fs-14 text-capitalize">{showCustomDuration ? "Custom" : duration}</small>
                </div>
                <i className="fa fa-angle-down scale5 ml-3"></i>
              </div>
              <div className="dropdown-menu dropdown-menu-right">
                <span
                  onClick={() => {
                    if (duration !== "yearly") {
                      setLoading(true);
                      setDuration("yearly");
                      setShowCustomDuration(false);
                    }
                  }}
                  className={`dropdown-item ${duration === "yearly" && !showCustomDuration ? "disabled" : ""}`}
                >
                  Yearly
                </span>
                <span
                  onClick={() => {
                    if (duration !== "monthly") {
                      setLoading(true);
                      setDuration("monthly");
                      setShowCustomDuration(false);
                    }
                  }}
                  className={`dropdown-item ${duration === "monthly" && !showCustomDuration ? "disabled" : ""}`}
                >
                  Monthly
                </span>
                <span
                  onClick={() => {
                    if (duration !== "weekly") {
                      setLoading(true);
                      setDuration("weekly");
                      setShowCustomDuration(false);
                    }
                  }}
                  className={`dropdown-item ${duration === "weekly" && !showCustomDuration ? "disabled" : ""}`}
                >
                  Weekly
                </span>
                <span
                  onClick={() => {
                    setShowCustomDuration(true);
                  }}
                  className={`dropdown-item ${showCustomDuration ? "disabled" : ""}`}
                >
                  Custom
                </span>
              </div>
            </div>
          </span>
        </div>
        <div className="card-body revenue-chart px-3" style={{ position: "relative" }}>
          <div className="d-flex flex-wrap justify-content-end px-3 pull-right revenue-chart-bar mb-3" style={{ gap: "25px" }}>
            <div className="d-flex align-items-end me-4 mb-2">
              <img src={StripeRevenueIcon} className="me-2 mb-1 mr-3" alt="" />
              <div>
                <small className="text-dark fs-14">Stripe</small>
                <h3 className="font-w600 mb-0">
                  $<span className="counter">{(totalStripeRevenue && totalStripeRevenue.toFixed(2)) ?? 0}</span>
                </h3>
              </div>
            </div>
            <div className="d-flex align-items-end mb-2">
              <img src={InAppRevenueIcon} className="me-2 mb-1 mr-3" alt="" />
              <div>
                <small className="text-dark fs-14">In App iOS</small>
                <h3 className="font-w600 mb-0">
                  $<span className="counter">{(totalIosRevenue && totalIosRevenue.toFixed(2)) ?? 0}</span>
                </h3>
              </div>
            </div>
            <div className="d-flex align-items-end mb-2">
              <img src={TotalRevenueIcon} className="me-2 mb-1 mr-3" alt="" />
              <div>
                <small className="text-dark fs-14">Total</small>
                <h3 className="font-w600 mb-0">
                  $<span className="counter">{(totalStripeRevenue + totalIosRevenue).toFixed(2) ?? 0}</span>
                </h3>
              </div>
            </div>
          </div>
          <div id="chart" className="pb-5" style={{ zoom: 1.25 }}>
            <ReactApexChart options={RevenueOptions} series={RevenueSeries} type="area" height={300} />
          </div>
        </div>
      </div>
    </>
  );
};

export default RevenueSection;
