import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { remove_session, getWeekDetails } from "../../../functions/general";
import IntroWeekOveriew from "./weekOverview";
import IntroWeekCalendar from "./introWeekCalendar";
import IntroWeekPDF from "./introWeekPDF";
import IntroWeekLecturesCategories from "./introWeekLectures";
import { usePermissions } from "../../../functions/usePermissions";
import IntroWeekLecturesListing from "./introWeekLectures/weekLectures";
import ListLectureSubcategories from "./introWeekLectures/weekLectureSubcategories";
import LectureDetails from "./introWeekLectures/lecture-details";
function IntroWeekDetails(props) {
  const { hasPermission } = usePermissions();
  const [lectureVideosShow, setLectureVideosShow] = React.useState(false);
  const [lectureSubcategoriesShow, setLectureSubcategoriesShow] = React.useState(false);
  const [showLectureDetails, setShowLectureDetails] = React.useState(false);

  const [lectureCategory, setLectureCategory] = React.useState(null);
  const [lectureSubcategory, setLectureSubcategory] = React.useState(null);
  const [lectureDetails, setLectureDetails] = React.useState(null);

  const navigate = useNavigate();

  const [weekDetails, setWeekDetails] = React.useState(null);
  const [searchParams] = useSearchParams();
  const weekId = searchParams.get("weekId");
  const tabName = searchParams.get("tabName");

  useEffect(() => {
    getWeekDetails(weekId).then((res) => {
      if (res && res.status == 200) {
        setWeekDetails(res?.data);
        props.setPageTitleAndSubTitle(res?.data?.title, `Here is the details of ${res?.data?.title} !`);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  }, []);

  const [activeTab, setActiveTab] = React.useState("IntroWeekOveriew"); // Default active tab
  const location = useLocation();

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    navigate(`?weekId=${weekDetails?._id}&tabName=${encodeURIComponent(tabId)}`);
  };

  useEffect(() => {
    if (tabName) setActiveTab(tabName);
  }, [location.search]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            {weekDetails && (
              <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
                <li class="breadcrumb-item">
                  <span onClick={() => navigate("/")}>
                    <i className="fas fa fa-home" style={{ fontSize: "28px" }}></i>
                  </span>
                </li>
                <li class="breadcrumb-item">
                  <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate("/program-categories")}>
                    Programs
                  </span>
                </li>
                <li class="breadcrumb-item">
                  <span
                    style={{ color: "#132C48", cursor: "pointer" }}
                    onClick={() => navigate(`/program-category-programs?categoryId=${weekDetails?.program?.category?._id}`)}
                  >
                    {weekDetails?.program?.category?.title}
                  </span>
                </li>
                <li class="breadcrumb-item">
                  <span
                    style={{ color: "#132C48", cursor: "pointer" }}
                    onClick={() => navigate(`/program-weeks?programId=${weekDetails?.program?._id}`)}
                  >
                    {weekDetails?.program?.title}
                  </span>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {weekDetails?.title}
                </li>
              </ol>
            )}
          </div>
          {/* <div className="col-12">
            <div className="card">
              <div className="card-body">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="pills-home-tab"
                      data-toggle="pill"
                      href="#IntroWeekOveriew"
                      role="tab"
                      aria-controls="IntroWeekOveriew"
                      aria-selected="true"
                    >
                      Overview
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="pills-profile-tab"
                      data-toggle="pill"
                      href="#IntroWeekCalendar"
                      role="tab"
                      aria-controls="IntroWeekCalendar"
                      aria-selected="false"
                    >
                      Calendar
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="pills-contact-tab"
                      data-toggle="pill"
                      href="#IntroWeekLectures"
                      role="tab"
                      aria-controls="IntroWeekLectures"
                      aria-selected="false"
                    >
                      Lectures
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="pills-contact-tab"
                      data-toggle="pill"
                      href="#IntroWeekPDF"
                      role="tab"
                      aria-controls="IntroWeekPDF"
                      aria-selected="false"
                    >
                      PDF
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="IntroWeekOveriew" role="tabpanel" aria-labelledby="IntroWeekOveriew-tab">
                    {weekDetails ? (
                      <IntroWeekOveriew week={weekDetails} />
                    ) : (
                      <div className="text-center py-5">
                        <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                      </div>
                    )}
                  </div>
                  <div class="tab-pane fade" id="IntroWeekCalendar" role="tabpanel" aria-labelledby="IntroWeekCalendar-tab">
                    {weekDetails ? (
                      <IntroWeekCalendar week={weekDetails} />
                    ) : (
                      <div className="text-center py-5">
                        <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                      </div>
                    )}
                  </div>
                  <div class="tab-pane fade" id="IntroWeekLectures" role="tabpanel" aria-labelledby="IntroWeekLectures-tab">
                    {weekDetails ? (
                      <>
                        {lectureVideosShow ? (
                          <IntroWeekLecturesVideos week={weekDetails} setLectureVideosShow={setLectureVideosShow} lectureCategory={lectureCategory} />
                        ) : (
                          <IntroWeekLecturesCategories
                            week={weekDetails}
                            setLectureVideosShow={setLectureVideosShow}
                            setLectureCategory={setLectureCategory}
                          />
                        )}
                      </>
                    ) : (
                      <div className="text-center py-5">
                        <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                      </div>
                    )}
                  </div>

                  <div class="tab-pane fade" id="IntroWeekPDF" role="tabpanel" aria-labelledby="IntroWeekPDF-tab">
                    {weekDetails ? (
                      <IntroWeekPDF week={weekDetails} />
                    ) : (
                      <div className="text-center py-5">
                        <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="col-12">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-link${activeTab === "IntroWeekOveriew" ? " active" : ""}`}
                  onClick={() => handleTabClick("IntroWeekOveriew")}
                  href=""
                  role="tab"
                  aria-controls="IntroWeekOveriew"
                  aria-selected={activeTab === "IntroWeekOveriew"}
                >
                  Overview
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link${activeTab === "IntroWeekCalendar" ? " active" : ""}`}
                  onClick={() => handleTabClick("IntroWeekCalendar")}
                  href=""
                  role="tab"
                  aria-controls="IntroWeekCalendar"
                  aria-selected={activeTab === "IntroWeekCalendar"}
                >
                  Calendar
                </a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className={`tab-pane fade${activeTab === "IntroWeekOveriew" ? " show active" : ""}`}
                id="IntroWeekOveriew"
                role="tabpanel"
                aria-labelledby="IntroWeekOveriew-tab"
              >
                {weekDetails ? (
                  <IntroWeekOveriew week={weekDetails} />
                ) : (
                  <div className="text-center py-5">
                    <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                  </div>
                )}
              </div>
              <div
                className={`tab-pane fade${activeTab === "IntroWeekCalendar" ? " show active" : ""}`}
                id="IntroWeekCalendar"
                role="tabpanel"
                aria-labelledby="IntroWeekCalendar-tab"
              >
                {weekDetails ? (
                  <IntroWeekCalendar week={weekDetails} />
                ) : (
                  <div className="text-center py-5">
                    <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                  </div>
                )}
              </div>
            </div>
          </div> */}

          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  {hasPermission("view-week-overview") && (
                    <li className="nav-item">
                      <button
                        className={`nav-link${activeTab === "IntroWeekOveriew" ? " active" : ""}`}
                        onClick={() => handleTabClick("IntroWeekOveriew")}
                        type="button"
                        role="tab"
                        aria-controls="IntroWeekOveriew"
                        aria-selected={activeTab === "IntroWeekOveriew"}
                      >
                        Overview
                      </button>
                    </li>
                  )}
                  {hasPermission("view-week-calendar") && (
                    <li className="nav-item">
                      <button
                        className={`nav-link${
                          activeTab === "IntroWeekCalendar" || (activeTab === "IntroWeekCalendar" && !hasPermission("view-week-overview"))
                            ? " active"
                            : ""
                        }`}
                        onClick={() => handleTabClick("IntroWeekCalendar")}
                        type="button"
                        role="tab"
                        aria-controls="IntroWeekCalendar"
                        aria-selected={activeTab === "IntroWeekCalendar"}
                      >
                        Calendar
                      </button>
                    </li>
                  )}
                  {hasPermission("view-intro-week-lecture-categories") && (
                    <li className="nav-item">
                      <button
                        className={`nav-link${
                          activeTab === "IntroWeekLectures" ||
                          (activeTab === "IntroWeekLectures" && !hasPermission("view-week-overview") && !hasPermission("view-week-calendar"))
                            ? " active"
                            : ""
                        }`}
                        onClick={() => handleTabClick("IntroWeekLectures")}
                        type="button"
                        role="tab"
                        aria-controls="IntroWeekLectures"
                        aria-selected={activeTab === "IntroWeekLectures"}
                      >
                        Lectures
                      </button>
                    </li>
                  )}
                  {hasPermission("view-intro-week-pdf") && (
                    <li className="nav-item">
                      <button
                        className={`nav-link${
                          activeTab === "IntroWeekPDF" ||
                          (activeTab === "IntroWeekPDF" &&
                            !hasPermission("view-week-overview") &&
                            !hasPermission("view-week-calendar") &&
                            !hasPermission("view-intro-week-lecture-categories"))
                            ? " active"
                            : ""
                        }`}
                        onClick={() => handleTabClick("IntroWeekPDF")}
                        type="button"
                        role="tab"
                        aria-controls="IntroWeekPDF"
                        aria-selected={activeTab === "IntroWeekPDF"}
                      >
                        PDF
                      </button>
                    </li>
                  )}
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  {hasPermission("view-week-overview") && (
                    <div
                      className={`tab-pane fade${activeTab === "IntroWeekOveriew" ? " show active" : ""}`}
                      id="IntroWeekOveriew"
                      role="tabpanel"
                      aria-labelledby="IntroWeekOveriew-tab"
                    >
                      {weekDetails ? (
                        <IntroWeekOveriew week={weekDetails} />
                      ) : (
                        <div className="text-center py-5">
                          <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                        </div>
                      )}
                    </div>
                  )}
                  {hasPermission("view-week-calendar") && (
                    <div
                      className={`tab-pane fade${
                        activeTab === "IntroWeekCalendar" || (activeTab === "IntroWeekCalendar" && !hasPermission("view-week-overview"))
                          ? " show active"
                          : ""
                      }`}
                      id="IntroWeekCalendar"
                      role="tabpanel"
                      aria-labelledby="IntroWeekCalendar-tab"
                    >
                      {weekDetails ? (
                        <IntroWeekCalendar week={weekDetails} />
                      ) : (
                        <div className="text-center py-5">
                          <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                        </div>
                      )}
                    </div>
                  )}
                  {hasPermission("view-intro-week-lecture-categories") && (
                    <div
                      className={`tab-pane fade${
                        activeTab === "IntroWeekLectures" ||
                        (activeTab === "IntroWeekLectures" && !hasPermission("view-week-overview") && !hasPermission("view-week-calendar"))
                          ? " show active"
                          : ""
                      }`}
                      id="IntroWeekLectures"
                      role="tabpanel"
                      aria-labelledby="IntroWeekLectures-tab"
                    >
                      {weekDetails ? (
                        <>
                          {showLectureDetails ? (
                            <LectureDetails
                              week={weekDetails}
                              lectureDetails={lectureDetails}
                              setLectureVideosShow={setLectureVideosShow}
                              setShowLectureDetails={setShowLectureDetails}
                              lectureSubcategory={lectureSubcategory}
                            />
                          ) : lectureVideosShow ? (
                            <IntroWeekLecturesListing
                              week={weekDetails}
                              setLectureVideosShow={setLectureVideosShow}
                              setLectureSubcategoriesShow={setLectureSubcategoriesShow}
                              setShowLectureDetails={setShowLectureDetails}
                              lectureCategory={lectureCategory}
                            />
                          )  : (
                            <IntroWeekLecturesCategories
                              week={weekDetails}
                              setLectureSubcategoriesShow={setLectureSubcategoriesShow}
                              setLectureVideosShow={setLectureVideosShow}
                              setLectureCategory={setLectureCategory}
                            />
                          )}
                        </>
                      ) : (
                        <div className="text-center py-5">
                          <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                        </div>
                      )}
                    </div>
                  )}
                  {hasPermission("view-intro-week-pdf") && (
                    <div
                      className={`tab-pane fade${
                        activeTab === "IntroWeekPDF" ||
                        (activeTab === "IntroWeekPDF" &&
                          !hasPermission("view-week-overview") &&
                          !hasPermission("view-week-calendar") &&
                          !hasPermission("view-intro-week-lecture-categories"))
                          ? " show active"
                          : ""
                      }`}
                      id="IntroWeekPDF"
                      role="tabpanel"
                      aria-labelledby="IntroWeekPDF-tab"
                    >
                      {weekDetails ? (
                        <IntroWeekPDF week={weekDetails} />
                      ) : (
                        <div className="text-center py-5">
                          <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IntroWeekDetails;
