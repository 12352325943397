import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  remove_session,
  getWorkoutWarmupVideos,
  updateWorkoutWarmupVideo,
  addWorkoutWarmupVideo,
  deleteWorkoutWarmupVideoPermanently,
  restoreWorkoutWarmupVideo,
  deleteWorkoutWarmupVideo,
  updateAdditionalWorkoutText,
  sortWorkoutWarmupVideos,
} from "../../../../../functions/general";
import { toast } from "react-toastify";
import Media from "../../../../../components/Media";
import MP3Icon from "../../../../../images/MP3_icon.png";
import ModalImage from "react-modal-image";
import { usePermissions } from "../../../../../functions/usePermissions";

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";

function WorkoutWarmupsList(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { hasPermission } = usePermissions();

  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [videoUrl, setvideoUrl] = React.useState("https://vimeo.com/");
  const [description, setDescription] = React.useState("");
  const [image, setImage] = React.useState("");
  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState("");
  const [workoutWarmupVideos, setWorkoutWarmupVideos] = React.useState([]);

  const [tabName, setTabName] = React.useState(state?.workout?.warmupTabName ? state?.workout?.warmupTabName : "Warm-up");
  const [appTitle, setAppTitle] = React.useState(state?.workout?.warmupTitle ? state?.workout?.warmupTitle : "");
  const [appDescription, setAppDescription] = React.useState(state?.workout?.warmupText ? state?.workout?.warmupText : "");

  const [tabNameBtnLoading, setTabNameBtnLoading] = React.useState("");
  const [tableDataLoading, setTableDataLoading] = React.useState(true);

  const [warmupType, setWarmupType] = React.useState("video");
  const [showAddAudioModal, setShowAddAudioModal] = React.useState(false);
  const [audioUrl, setAudioUrl] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  const [fileSize, setFileSize] = React.useState("");

  useEffect(() => {
    // props.setPageTitleAndSubTitle("Lecture Categories", "Here is the list of Lecture Categories !");
    fetchData();
  }, []);

  const fetchData = (type) => {
    getWorkoutWarmupVideos(state?.workout?._id).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        setWorkoutWarmupVideos(res?.data);
        if (type === "add") {
          let sortedArray = [];
          res?.data?.map((video) => sortedArray.push(video?._id));
          sortWorkoutWarmupVideos(sortedArray).then((res) => {
            if (res && res.status == 200) {
            } else if (res && res.status == 401) {
              remove_session();
              props.getLoggedInUser();
              navigate("/login");
            } else {
              toast.error("Failed to sort the list.");
            }
            setTableDataLoading(false);
          });
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };

  const modalHandler = (warmup) => {
    if (warmup && warmup._id) {
      setModalTitle("Update Warmup");
      setModalButtonText("Update");
      setTitle(warmup?.title);
      setvideoUrl(warmup?.vimeo_url);
      setDescription(warmup?.text);
      setId(warmup?._id);
      setWarmupType(warmup?.type);
      setAudioUrl(warmup?.url);
    } else {
      setId("");
      setModalTitle("Add New Warmup");
      setModalButtonText("Add");
      setTitle("");
      setvideoUrl("https://vimeo.com/");
      setDescription("");
      setAudioUrl("");
      setWarmupType("video");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const category = {
        title: title,
        type: warmupType,
        url: warmupType === "video" ? videoUrl : audioUrl,
        text: description ? description : "",
        workout: state?.workout,
      };
      let type = "add";
      if (id) {
        updateWorkoutWarmupVideo(id, category).then((res) => {
          if (res && (res.status == 200 || res.status == 201)) {
            fetchData();
            toast.success("Warmup updated successfully.");
            document.getElementById("weekLecturecategoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update video.");
          }
          setLoading(false);
        });
      } else {
        addWorkoutWarmupVideo(category).then((res) => {
          if (res && res.status == 201) {
            fetchData(type);
            toast.success("Warmup added successfully.");
            document.getElementById("weekLecturecategoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add video.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      videoUrl: "",
      audio: "",
      // description: "",
    };
    let isValid = true;
    // if (!title) {
    //   isValid = false;
    //   errors.title = "Please enter title.";
    // }
    // URL validation
    // const urlRegex = new RegExp(
    //   "(https?:\\/\\/www\\.|https?:\\/\\/|www\\.)?[a-zA-Z0-9]{2,}(\\.[a-zA-Z0-9]{2,})(\\.[a-zA-Z0-9]{2,})?\\/[a-zA-Z0-9]{2,}"
    // );
    // if (!videoUrl || !urlRegex.test(videoUrl)) {
    //   isValid = false;
    //   errors.url = "Please enter a valid URL.";
    // }
    if (warmupType === "video") {
      if (!videoUrl) {
        isValid = false;
        errors.videoUrl = "Please enter url.";
      }
    } else {
      if (!audioUrl) {
        isValid = false;
        errors.audio = "Please enter url.";
      }
    }
    // if (!description) {
    //   isValid = false;
    //   errors.description = "Please enter description";
    // }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, warmup) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete warmup?") == true) {
      if (window.confirm("Are you sure you want to delete warmup permanently?") == true) {
        deleteWorkoutWarmupVideoPermanently(warmup._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Warmup deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete warmup.");
          }
        });
      }
    }
  };

  const deleteStatusHandler = (e, warmup) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the warmup?";
    if (warmup.isDeleted) {
      confirmText = "Are you sure you want to active the warmup?";
    }
    if (window.confirm(confirmText) == true) {
      if (warmup.isDeleted) {
        restoreWorkoutWarmupVideo(warmup._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Warmup activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active warmup.");
          }
        });
      } else {
        deleteWorkoutWarmupVideo(warmup._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Warmup deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive warmup.");
          }
        });
      }
    }
  };

  const tabNameHandler = () => {
    setTabNameBtnLoading(true);
    updateAdditionalWorkoutText(
      { warmupTabName: tabName, warmupTitle: appTitle, warmupText: appDescription, isWarmup: true },
      state?.workout?._id
    ).then((res) => {
      if (res && res.status == 200) {
        toast.success("Data updated successfully.");
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to update data.");
      }
      setTabNameBtnLoading(false);
    });
  };

  const setAudioHandler = (audio) => {
    setAudioUrl(audio);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);

    const updatedWarmupVideos = arrayMove(workoutWarmupVideos, oldIndex, newIndex);

    let sortedArray = [];
    updatedWarmupVideos?.map((video) => sortedArray.push(video?._id));
    sortWorkoutWarmupVideos(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setWorkoutWarmupVideos(updatedWarmupVideos);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ warmup, index, serialNo }) => {
    return (
      <tr key={index}>
        <td>{serialNo + 1}</td>
        <td>
          <div className="maxWidth70px">
            {warmup?.type === "video" ? (
              <ModalImage small={warmup?.video_thumbnail} large={warmup?.video_thumbnail} />
            ) : (
              <img width={50} src={MP3Icon} alt="" />
            )}
          </div>
        </td>
        <td>{warmup?.title}</td>
        <td style={{ textTransform: "capitalize" }}>{warmup?.type}</td>
        {hasPermission("view-days-warmup-pdfs") && (
          <td>
            <button
              className="btn btn-dark btn-xs"
              onClick={() => {
                props?.setWarmupPdfsShow(true);
                props?.setWorkoutWarmupData(warmup);
              }}
            >
              {warmup?.count ? warmup?.count : 0}
            </button>
          </td>
        )}
        {hasPermission("edit-day-warmup") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={!warmup?.isDeleted} onClick={(e) => deleteStatusHandler(e, warmup)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {(hasPermission("edit-day-warmup") || hasPermission("delete-day-warmup")) && (
          <td>
            <div className="d-flex">
              {hasPermission("edit-day-warmup") && (
                <button
                  data-toggle="modal"
                  data-target="#addWeekLectureCategoryVideo"
                  onClick={(e) => modalHandler(warmup)}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {hasPermission("delete-day-warmup") && (
                <button onClick={(e) => deleteHandler(e, warmup)} className="btn btn-danger shadow btn-sm sharp">
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </td>
        )}
        {hasPermission("edit-day-warmup") && (
          <td>
            <DragHandle />
          </td>
        )}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ workoutWarmupVideos }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>No</th>
            <th>Image</th>
            <th>Title</th>
            <th>Type</th>
            {hasPermission("view-days-warmup-pdfs") && <th>PDFs</th>}
            {hasPermission("edit-day-warmup") && <th>Status</th>}
            {(hasPermission("edit-day-warmup") || hasPermission("delete-day-warmup")) && <th>Actions</th>}
            {hasPermission("edit-day-warmup") && <th>Sort Order</th>}
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="8" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {workoutWarmupVideos?.length > 0 ? (
                workoutWarmupVideos?.map((warmup, index) => {
                  return <SortableTableRow key={index} warmup={warmup} index={index} serialNo={index} />;
                })
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    No Warmup found !
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="row">
              <div className="form-group col-12 px-0">
                <label>Tab Name</label>
                <input type="text" className="form-control" placeholder="Warm-up" value={tabName} onChange={(e) => setTabName(e.target.value)} />
                {!tabName && <div className="text-warning font-size-12 mt-2">This field is required.</div>}
              </div>
              <div className="form-group col-12 px-0">
                <label>Screen Title</label>
                <input type="text" className="form-control" placeholder="Title" value={appTitle} onChange={(e) => setAppTitle(e.target.value)} />
              </div>
              <div className="form-group col-12 px-0">
                <label>Screen Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Enter Description"
                  rows={4}
                  value={appDescription}
                  onChange={(e) => setAppDescription(e.target.value)}
                />
              </div>
              <button className="btn btn-primary ml-auto btn-sm mt-4" onClick={tabNameHandler}>
                {tabNameBtnLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Update
              </button>
            </div>
          </div>
        </div>
        <div className="row my-4 pt-4" style={{ borderTop: "1px solid #f0f1f5" }}>
          <div className="col-12">
            {hasPermission("add-workout-warmup") && (
              <button
                className="btn btn-primary float-right"
                data-toggle="modal"
                data-target="#addWeekLectureCategoryVideo"
                onClick={(e) => modalHandler()}
              >
                Add New Warmup
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  {tableDataLoading ? (
                    <div className="text-center py-5">
                      <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                    </div>
                  ) : (
                    <>
                      <SortableTable workoutWarmupVideos={workoutWarmupVideos} onSortEnd={onSortEnd} useDragHandle={true} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="addWeekLectureCategoryVideo">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="weekLecturecategoryModalCloseButton">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="form-group px-3">
                  <label>Select Warmup Type</label>
                  <select className="form-control" value={warmupType} onChange={(e) => setWarmupType(e.target.value)}>
                    <option value="video">Video</option>
                    <option value="audio">Audio</option>
                  </select>
                </div>
                <div className="form-group px-3">
                  <label>Title</label>
                  <input type="text" className="form-control" placeholder="Enter Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                  {/* {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>} */}
                </div>
                <div className="form-group px-3">
                  <label>Description</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter Description"
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  {/* {errors.description && !description && <div className="text-warning font-size-12">{errors.description}</div>} */}
                </div>

                {warmupType === "video" ? (
                  <div className="form-group px-3">
                    <label>Video URL</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Video URL"
                      value={videoUrl}
                      onChange={(e) => setvideoUrl(e.target.value)}
                    />
                    {errors.videoUrl && !videoUrl && <div className="text-warning font-size-12 mt-2">{errors.videoUrl}</div>}
                  </div>
                ) : (
                  <div className="form-group px-3 py-4 text-center">
                    <label>Upload Audio</label>
                    <br />
                    {audioUrl && (
                      <a href={process.env.REACT_APP_MEDIAPATH + audioUrl} target="_blank">
                        <img src={MP3Icon} className="img-fluid img-thumbnail" width="100" />
                        <br />
                        <span>{fileName}</span>
                        <br />
                      </a>
                    )}
                    <button
                      type="button"
                      onClick={() => {
                        setShowAddAudioModal(true);
                      }}
                      className="btn btn-primary mt-3"
                      data-toggle="modal"
                      data-target="#media"
                    >
                      Select Audio
                    </button>
                    {errors.audio && !audioUrl && <div className="text-warning font-size-12 mt-2 pl-2">{errors.audio}</div>}
                  </div>
                )}
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showAddAudioModal && <Media setFileName={setFileName} setFileSize={setFileSize} setImageHandler={setAudioHandler} />}
    </>
  );
}

export default WorkoutWarmupsList;
