import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  remove_session,
  getProgramWeekPDF,
  addProgramWeekPDF,
  updateProgramWeekPDF,
  deleteProgramWeekPDFPermanently,
  updateIntroWeekTabs,
  sortIntroWeekPDF,
} from "../../../functions/general";
import { toast } from "react-toastify";
import Media from "../../../components/Media";
import PDFIcon from "../../../images/PDF_file_icon.png";
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";
import { usePermissions } from "../../../functions/usePermissions";

function IntroWeekCalendar(props) {
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const { state } = useLocation();

  const [title, setTitle] = React.useState("");
  // const [image, setImage] = React.useState("");
  const [pdf, setPdf] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState("");
  const [fileSize, setFileSize] = React.useState("");
  const [weekPDFData, setWeekPDFData] = React.useState([]);
  const [tabName, setTabName] = React.useState(props?.week?.pdfTabName ? props?.week?.pdfTabName : "PDF");
  const [screenTitle, setScreenTitle] = React.useState(props?.week?.pdfTitle ? props?.week?.pdfTitle : "");
  const [screenDescription, setScreenDescription] = React.useState(props?.week?.pdfText ? props?.week?.pdfText : "");
  const [tabNameBtnLoading, setTabNameBtnLoading] = React.useState("");
  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [showAddPDFModal, setShowAddPDFModal] = React.useState(false);

  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [id, setId] = React.useState("");

  const [fileName, setFileName] = React.useState("");

  useEffect(() => {
    // props.setPageTitleAndSubTitle("Lecture Categories", "Here is the list of Lecture Categories !");
    fetchData();
  }, []);

  const fetchData = () => {
    getProgramWeekPDF(props?.week?._id).then((res) => {
      if (res && res.status == 201) {
        setWeekPDFData(res?.data);
        setTitle(res?.data[0]?.title);
        setPdf(res?.data[0]?.url);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };

  const modalHandler = (pdf) => {
    if (pdf && pdf._id) {
      setModalTitle("Update Pdf");
      setModalButtonText("Update");
      setTitle(pdf?.title);
      setFileSize(pdf?.fileSize);
      setPdf(pdf?.url);
      setId(pdf?._id);
    } else {
      setModalTitle("Add New Pdf");
      setModalButtonText("Add");
      setFileSize("");
      setTitle("");
      // setDescription("");
      setPdf("");
      setId("");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const PDFData = {
        title: fileName,
        url: pdf,
        fileSize: fileSize,
        week: props?.week,
      };
      let type = "add";
      if (id) {
        updateProgramWeekPDF(id, PDFData).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("PDF data updated successfully.");
            document.getElementById("weekPDFModalCloseBtn").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update PDF data.");
          }
          setLoading(false);
        });
      } else {
        addProgramWeekPDF(PDFData).then((res) => {
          if (res && res.status == 201) {
            fetchData();
            toast.success("PDF data added successfully.");
            document.getElementById("weekPDFModalCloseBtn").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add PDF data.");
          }
          setLoading(false);
        });
      }
    }
  };
  const handleUpdateWeekCalendar = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const PDFData = {
        title: fileName,
        url: pdf,
        fileSize: fileSize,
        week: props?.week,
      };
      updateProgramWeekPDF(weekPDFData[0]?._id, PDFData).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("PDF data updated successfully.");
          document.getElementById("weekPDFModalCloseBtn").click();
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to update PDF data.");
        }
        setLoading(false);
      });
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      pdf: "",
    };
    let isValid = true;
    // if (!title) {
    //   isValid = false;
    //   errors.title = "Please enter title.";
    // }
    if (!pdf) {
      isValid = false;
      errors.pdf = "Please select pdf.";
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete pdf data permanently?") == true) {
      deleteProgramWeekPDFPermanently(id).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("PDF data deleted successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete PDF data.");
        }
      });
    }
  };

  const tabNameHandler = () => {
    setTabNameBtnLoading(true);
    updateIntroWeekTabs({ pdfTabName: tabName, pdfTitle: screenTitle, pdfText: screenDescription, isPdf: true }, props?.week?._id).then((res) => {
      if (res && res.status == 200) {
        toast.success("Tab name updated successfully.");
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to update tab name.");
      }
      setTabNameBtnLoading(false);
    });
  };

  const setImageHandler = (pdf) => {
    setPdf(pdf);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    const updatedWeekPdfs = arrayMove(weekPDFData, oldIndex, newIndex);
    let sortedArray = [];
    updatedWeekPdfs?.map((pdf) => sortedArray.push(pdf?._id));
    sortIntroWeekPDF(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setWeekPDFData(updatedWeekPdfs);
        // fetchData();
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ pdf, index, serialNo }) => {
    return (
      <tr key={index}>
        <td>{serialNo + 1}</td>
        <td>
          <div className="d-flex align-items-center">
            <img src={PDFIcon} alt="pdf-icon" width={30} />
            <span className="ml-3">{pdf?.title}</span>
          </div>
        </td>
        {(hasPermission("edit-intro-week-pdf") || hasPermission("delete-intro-week-pdf")) && (
          <td>
            <div className="d-flex">
              {hasPermission("edit-intro-week-pdf") && (
                <button
                  data-toggle="modal"
                  data-target="#add-weekPDFData"
                  onClick={(e) => {
                    modalHandler(pdf);
                  }}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {hasPermission("delete-intro-week-pdf") && (
                <button onClick={(e) => deleteHandler(e, pdf?._id)} className="btn btn-danger shadow btn-sm sharp">
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </td>
        )}
        {hasPermission("edit-intro-week-pdf") && (
          <td>
            <DragHandle />
          </td>
        )}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ weekPDFData }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>No</th>
            <th>PDF</th>
            {/* {hasPermission("edit-days-warmup-pdfs") && <th>Status</th>} */}
            {(hasPermission("edit-intro-week-pdf") || hasPermission("delete-intro-week-pdf")) && <th>Actions</th>}
            {hasPermission("edit-intro-week-pdf") && <th>Sort Order</th>}
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="6" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {weekPDFData?.length > 0 ? (
                weekPDFData?.map((pdf, index) => {
                  return <SortableTableRow key={index} pdf={pdf} index={index} serialNo={index} />;
                })
              ) : (
                <tr>
                  <td colSpan="6" className="text-center pt-5">
                    No Warmup PDF found !
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  return (
    <>
      <div className="row justify-content-center">
        <div className="form-group col-md-6">
          {hasPermission("edit-intro-week-pdf") && (
            <div className="row">
              <div className="form-group col-md-12">
                <label>Tab Name</label>
                <input type="text" className="form-control" placeholder="PDF" value={tabName} onChange={(e) => setTabName(e.target.value)} />
                {!tabName && <div className="text-warning font-size-12 mt-2">This field is required.</div>}
              </div>
              <div className="form-group col-md-12">
                <label>Screen Title</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Title"
                  value={screenTitle}
                  onChange={(e) => setScreenTitle(e.target.value)}
                />
                {/* {errors.title && !title && <div className="text-warning font-size-12">{errors.title}</div>} */}
              </div>
              <div className="form-group col-md-12">
                <label>Screen Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Enter Description"
                  rows={4}
                  value={screenDescription}
                  onChange={(e) => setScreenDescription(e.target.value)}
                />
                <button className="btn btn-primary float-right btn-sm mt-4" onClick={tabNameHandler}>
                  {tabNameBtnLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Update
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="col-12 mb-4 pt-4" style={{ borderTop: "1px solid #f0f1f5" }}>
          {/* {weekPDFData?.length === 0 && ( */}
          {hasPermission("add-intro-week-pdf") && (
            <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add-weekPDFData" onClick={() => modalHandler()}>
              Add New
            </button>
          )}
          {/* )} */}
        </div>
        <div className="table-responsive">
          <SortableTable weekPDFData={weekPDFData} onSortEnd={onSortEnd} useDragHandle={true} />
        </div>
        {/* <div className="col-6">
          {tableDataLoading ? (
            <div className="text-center py-5">
              <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
            </div>
          ) : (
            <>
              {weekPDFData?.length > 0 ? (
                <form noValidate onSubmit={handleUpdateWeekCalendar}>
                  <div className="form-group px-3">
                    <label>Title</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Calendar Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    {errors.title && !title && <div className="text-warning font-size-12">{errors.title}</div>}
                  </div>
                  <div className="form-group px-3">
                    <label>{title}</label>
                    <br />
                    {pdf && (
                      <>
                        <img src={PDFIcon} className="img-fluid img-thumbnail" width="100" />
                        <br />
                      </>
                    )}
                    <button type="button" className="btn btn-primary mt-3" data-toggle="modal" data-target="#weekPDFMedia">
                      Select PDF
                    </button>
                    {errors.pdf && !pdf && <div className="text-warning font-size-12">{errors.pdf}</div>}
                  </div>
                  <div className="modal-footer border-0">
                    <button type="submit" className="btn btn-primary">
                      {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Update
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={(e) => {
                        deleteHandler(e, weekPDFData[0]?._id);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </form>
              ) : (
                <div className="py-5 text-center">
                  <h3>Please enter PDF data !</h3>
                </div>
              )}
            </>
          )}
        </div> */}
      </div>
      <div className="modal fade" id="add-weekPDFData">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header border-0 px-3 mb-3">
              <h5 className="modal-title">{modalTitle}</h5>
              <button type="button" className="close" data-dismiss="modal" id="weekPDFModalCloseBtn">
                <span>&times;</span>
              </button>
            </div>
            <form noValidate onSubmit={onSubmit}>
              {/* <div className="form-group px-3">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Week PDF Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {errors.title && !title && <div className="text-warning font-size-12">{errors.title}</div>}
              </div> */}
              <div className="form-group px-3">
                <label>Select PDF</label>
                <br />
                <br />
                {pdf && (
                  <>
                    <span>
                      <strong>{fileName}</strong>
                    </span>
                    <br />
                    <br />
                    <img src={PDFIcon} className="img-fluid img-thumbnail" width="100" />
                    <br />
                  </>
                )}
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={() => setShowAddPDFModal(true)}
                  data-toggle="modal"
                  data-target="#weekPDFMedia"
                >
                  Select PDF
                </button>
                {errors.pdf && !pdf && <div className="text-warning font-size-12">{errors.pdf}</div>}
              </div>
              <div className="modal-footer border-0">
                <button type="submit" className="btn btn-primary">
                  {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showAddPDFModal && <Media setFileName={setFileName} setImageHandler={setImageHandler} setFileSize={setFileSize} id={"weekPDFMedia"} />}
    </>
  );
}

export default IntroWeekCalendar;
