import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  activateUserSubscription,
  deleteMember,
  getMembers,
  getMembersCount,
  remove_session,
  restoreMember,
  updateUserBadgeStatus,
} from "../../../../functions/general";
import { toast } from "react-toastify";
import Select from "react-select";
import dayjs from "dayjs";
import { usePermissions } from "../../../../functions/usePermissions";

function ListMembers(props) {
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const [members, setMembers] = React.useState([]);
  const [membersCount, setMembersCount] = React.useState([]);
  const [searchFiltersFrom, setSearchFiltersFrom] = React.useState(0);
  const [searchFiltersLimit, setSearchFiltersLimit] = React.useState(10);
  const [searchNameQuery, setSearchNameQuery] = React.useState("");
  const [searchEmailQuery, setSearchEmailQuery] = React.useState("");
  const [userTypeQuery, setUserTypeQuery] = React.useState(null);
  const [memberStatusQuery, setMemberStatusQuery] = React.useState(null);
  const [tableDataLoading, setTableDataLoading] = React.useState(true);

  useEffect(() => {
    props.setPageTitleAndSubTitle("Members", "Here is the list of Members !");
    fetchData();
  }, []);

  useEffect(() => {
    setTableDataLoading(true);
    fetchData();
  }, [searchFiltersFrom]);

  useEffect(() => {
    setTableDataLoading(true);
    if (searchFiltersFrom === 0) {
      fetchData();
    } else {
      setSearchFiltersFrom(0);
    }
  }, [searchFiltersLimit]);

  const fetchData = () => {
    setTableDataLoading(true);
    setMembers([]);
    const searchFilters = {
      from: searchFiltersFrom * searchFiltersLimit,
      limit: searchFiltersLimit,
      searchName: searchNameQuery,
      searchEmail: searchEmailQuery,
      memberType: userTypeQuery?.value,
      status: memberStatusQuery?.value,
    };
    getMembers(searchFilters).then((res) => {
      if (res && res.status === 200) {
        setMembers(res.data);
        setTableDataLoading(false);
      } else if (res && res.status === 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
        setTableDataLoading(false);
      }
    });
    getMembersCount(searchFilters).then((res) => {
      if (res && res.status === 200) {
        setMembersCount(res.data);
      } else if (res && res.status === 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      }
    });
  };

  const handlResetFilters = () => {
    setSearchNameQuery("");
    setSearchEmailQuery("");
    setUserTypeQuery(null);
    setMemberStatusQuery(null);
    fetchData();
  };
  const deleteStatusHandler = (e, filter) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the member?";
    if (filter.isDeleted) {
      confirmText = "Are you sure you want to active the member?";
    }
    if (window.confirm(confirmText) == true) {
      if (filter.isDeleted) {
        restoreMember(filter._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("User activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active user.");
          }
        });
      } else {
        deleteMember(filter._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("User deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive user.");
          }
        });
      }
    }
  };
  const activateUserSubscriptionHandler = (id) => {
    let confirmText = "Are you sure you want to activate user's subscription?";
    if (window.confirm(confirmText) == true) {
      activateUserSubscription(id).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("User subscription activated successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to activate user subscription.");
        }
      });
    }
  };
  const userBadgeStatusHandler = (id) => {
    let confirmText = "Are you sure you want to change status of user's batch?";
    if (window.confirm(confirmText) == true) {
      updateUserBadgeStatus(id).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("User's batch status updated successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to update the status.");
        }
      });
    }
  };

  return (
    <>
      <div className="container-fluid">
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            if (searchFiltersFrom === 0) {
              fetchData();
            } else {
              setSearchFiltersFrom(0);
            }
          }}
        >
          <div className="row align-align-items-center">
            <div className="col-3">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Name"
                  value={searchNameQuery}
                  onChange={(e) => setSearchNameQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Email"
                  value={searchEmailQuery}
                  onChange={(e) => setSearchEmailQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group px-3 col-md-2">
              <label>Status</label>
              <Select
                name="memberStatusQuery"
                value={memberStatusQuery}
                onChange={(e) => setMemberStatusQuery(e)}
                className="basic-single form-control"
                classNamePrefix="select"
                options={[
                  { value: "active", label: "Active" },
                  { value: "inActive", label: "InActive" },
                ]}
              />
            </div>
            <div className="form-group px-3 col-md-2">
              {/* <label>Select User Type</label>
              <Select
                name="userTypeQuery"
                value={userTypeQuery}
                onChange={(e) => setUserTypeQuery(e)}
                className="basic-single form-control"
                classNamePrefix="select"
                options={[
                  { value: "academy", label: "Academy" },
                  { value: "workoutCreator", label: "Workout Creator" },
                ]}
              /> */}
            </div>

            <div className="col-2">
              <div className="form-group">
                <p className="m-0">&nbsp;</p>
                <button
                  className="btn btn-primary float-right btn-md ml-2"
                  // disabled={searchEmailQuery && searchNameQuery}
                  onClick={() => {
                    if (searchFiltersFrom === 0) {
                      fetchData();
                    } else {
                      setSearchFiltersFrom(0);
                    }
                  }}
                >
                  Apply
                </button>
                <button className="btn btn-primary float-right btn-md" onClick={handlResetFilters}>
                  Reset
                </button>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label>Show Entries</label>
                <select className="form-control" value={searchFiltersLimit} onChange={(e) => setSearchFiltersLimit(parseInt(e.target.value))}>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="70">70</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                  <option value="500">500</option>
                </select>
              </div>
            </div>
            <div className="col-10"></div>
          </div>
        </form>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        {hasPermission("edit-members") && <th>Status</th>}
                        {hasPermission("edit-members") && <th>Has Badge</th>}
                        <th>Total Paid</th>
                        <th>Refunded</th>
                        <th>Earned</th>
                        <th>Transactions</th>
                        <th>Subscriptions</th>
                        <th>Device</th>
                        <th>Registration Date </th>
                        {hasPermission("activate-subscription") && <th>Activate Subscription </th>}
                      </tr>
                    </thead>
                    <tbody>
                      {tableDataLoading ? (
                        <tr>
                          <td colSpan="12" className="text-center pt-5">
                            <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {members.length > 0 ? (
                            members.map((memberValue, memberIndex) => {
                              return (
                                <tr key={`video-${memberIndex}`}>
                                  <td>{searchFiltersFrom * searchFiltersLimit + memberIndex + 1}</td>
                                  <td>
                                    <Link style={{ color: "blue" }} to={`/member-details?id=${memberValue?._id}`}>
                                      {memberValue.firstName} {memberValue.lastName}
                                    </Link>
                                  </td>
                                  <td>{memberValue.email}</td>
                                  {hasPermission("edit-members") && (
                                    <td>
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          checked={!memberValue.isDeleted}
                                          onClick={(e) => deleteStatusHandler(e, memberValue)}
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </td>
                                  )}
                                  {hasPermission("edit-members") && (
                                    <td>
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          checked={memberValue?.isBadge}
                                          onClick={(e) => userBadgeStatusHandler(memberValue?._id)}
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </td>
                                  )}
                                  <td>
                                    {memberValue.currencySign ? memberValue.currencySign : "$"}
                                    {memberValue.totalAmountPaid}
                                  </td>
                                  <td>
                                    {memberValue.currencySign ? memberValue.currencySign : "$"}
                                    {memberValue.totalAmountRefunded}
                                  </td>
                                  <td>
                                    {memberValue.currencySign ? memberValue.currencySign : "$"}
                                    {memberValue.amountEarned}
                                  </td>
                                  <td>{memberValue.transactionsNo}</td>
                                  <td>
                                    {hasPermission("view-subscriptions") ? (
                                      <Link style={{ color: "blue" }} to={`/subscriptions?memberEmail=${memberValue?.email}`}>
                                        {memberValue.subscriptionName}
                                      </Link>
                                    ) : (
                                      memberValue?.subscriptionName
                                    )}
                                  </td>
                                  <td>{memberValue?.loginFrom ? memberValue?.loginFrom : "--"}</td>
                                  <td>{dayjs(memberValue.createdAt)?.format("DD-MMM-YYYY HH:mm:ss")}</td>
                                  {hasPermission("activate-subscription") && (
                                    <td>
                                      {memberValue?.userSubscriptionGateway === "wordpress" && memberValue?.status === 0 ? (
                                        <button className="btn btn-primary btn-sm" onClick={() => activateUserSubscriptionHandler(memberValue?._id)}>
                                          Activate
                                        </button>
                                      ) : (
                                        "--"
                                      )}
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="12" className="text-center pt-5">
                                No member found!
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            {(membersCount && members.length) > 0 && (
              <p className="text-black">
                Showing {searchFiltersFrom * searchFiltersLimit + 1} to {searchFiltersFrom * searchFiltersLimit + searchFiltersLimit} of{" "}
                {membersCount} entries
              </p>
            )}
          </div>
          <div className="col-6">
            {(membersCount && members.length) > 0 && (
              <nav ariaLabel="Page navigation example">
                <ul className="pagination float-right">
                  <li className="page-item">
                    {searchFiltersFrom > 0 ? (
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom - 1)} ariaLabel="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                      </span>
                    ) : (
                      <span className="page-link c-default" ariaLabel="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                      </span>
                    )}
                  </li>
                  {searchFiltersFrom - 2 > 0 && (
                    <li className="page-item">
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom - 3)}>
                        {searchFiltersFrom - 2}
                      </span>
                    </li>
                  )}
                  {searchFiltersFrom - 1 > 0 && (
                    <li className="page-item">
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom - 2)}>
                        {searchFiltersFrom - 1}
                      </span>
                    </li>
                  )}
                  {searchFiltersFrom > 0 && (
                    <li className="page-item">
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom - 1)}>
                        {searchFiltersFrom}
                      </span>
                    </li>
                  )}
                  <li className="page-item">
                    <span className="page-link bg-gray-dark text-white">{searchFiltersFrom + 1}</span>
                  </li>
                  {searchFiltersFrom * searchFiltersLimit + searchFiltersLimit < membersCount && (
                    <li className="page-item">
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom + 1)}>
                        {searchFiltersFrom + 2}
                      </span>
                    </li>
                  )}
                  {(searchFiltersFrom + 1) * searchFiltersLimit + searchFiltersLimit < membersCount && (
                    <li className="page-item">
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom + 2)}>
                        {searchFiltersFrom + 3}
                      </span>
                    </li>
                  )}
                  {(searchFiltersFrom + 2) * searchFiltersLimit + searchFiltersLimit < membersCount && (
                    <li className="page-item">
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom + 3)}>
                        {searchFiltersFrom + 4}
                      </span>
                    </li>
                  )}
                  <li className="page-item">
                    {searchFiltersFrom * searchFiltersLimit + searchFiltersLimit < membersCount ? (
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom + 1)} ariaLabel="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                      </span>
                    ) : (
                      <span className="page-link c-default" ariaLabel="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                      </span>
                    )}
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ListMembers;
