import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Select from "react-select";

import { remove_session, getAllSubscriptionsList, getCurrencySymbol, downloadSubscriptionsList } from "../../../functions/general";
import dayjs from "dayjs";

import Papa from "papaparse";

const SubscriptionsList = (props) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const memberEmail = searchParams.get("memberEmail");

  const [searchValueQuery, setSearchValueQuery] = React.useState(memberEmail ?? "");
  const [searchField, setSearchField] = React.useState(memberEmail ? { value: "email", label: "Email" } : null);

  const [membershipTypeQuery, setMembershipTypeQuery] = React.useState(null);
  const [membershipOriginQuery, setMembershipOriginQuery] = React.useState(null);
  const [gatewayQuery, setGatewayQuery] = React.useState(null);
  const [platformQuery, setPlaformQuery] = React.useState(null);
  const [statusQuery, setStatusQuery] = React.useState(null);
  const [recurringValue, setRecurringValue] = React.useState();

  const [from, setFrom] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [resetPagination, setResetPagination] = React.useState(false);

  const [subscriptionsList, setSubscriptionsList] = React.useState([]);
  const [filteredDataArray, setFilteredDataArray] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [csvLoading, setCsvLoading] = React.useState(false);

  useEffect(() => {
    if (!memberEmail) {
      setTableDataLoading(true);
      setSearchValueQuery("");
      setSearchField(null);
      fetchData({ from, limit });
    }
  }, [memberEmail]);

  useEffect(() => {
    setTableDataLoading(true);
    props.setPageTitleAndSubTitle("Subscriptions List", "Here is the list of all Subscriptions!");
    fetchData({
      from,
      limit,
      origin: membershipOriginQuery?.value,
      searchValue: searchValueQuery,
      platform: platformQuery?.value,
      status: statusQuery?.value,
      paymentStatus: "",
      membership: membershipTypeQuery?.value,
      gateway: gatewayQuery?.value,
      recurringValue: recurringValue?.value,
      searchByField: searchField?.value,
    });
  }, [from, limit]);

  const fetchData = (filters) => {
    getAllSubscriptionsList(filters).then((res) => {
      if (res && res.status == 201) {
        if (res.data && res.data.data.response) {
          setSubscriptionsList(res?.data?.data?.response);
          setTotalRows(res?.data?.data?.meta?.count);
        }
        setTableDataLoading(false);
        // }
        setTableDataLoading(false);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };

  useEffect(() => {
    setResetPagination(false);
    setFilteredDataArray(subscriptionsList); // Set filteredDataArray whenever subscriptionsList changes
  }, [subscriptionsList]);

  const convertJsonToCsv = (jsonData) => {
    // Use papaparse to convert JSON to CSV
    const csvData = Papa.unparse(jsonData);
    return csvData;
  };

  const handleDownloadCSV = () => {
    setCsvLoading(true);
    const filters = {
      origin: membershipOriginQuery?.value,
      searchValue: searchValueQuery,
      searchByField: searchField?.value,
      platform: platformQuery?.value,
      status: statusQuery?.value,
      paymentStatus: "",
      membership: membershipTypeQuery?.value,
      gateway: gatewayQuery?.value,
      recurringValue: recurringValue?.value,
    };
    downloadSubscriptionsList(filters).then((res) => {
      if (res && res.status == 201) {
        // Convert JSON to CSV
        const csvData = convertJsonToCsv(res?.data);

        // Create a Blob containing the CSV data
        const blob = new Blob([csvData], { type: "text/csv" });

        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "subscriptions.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setCsvLoading(false);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
        setCsvLoading(false);
      } else {
        setCsvLoading(false);
      }
    });
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row?.paymentId,
      wrap: true,
    },
    {
      name: "Status",
      // selector: (row) => (row?.status === 1 ? <span className="text-success">Active</span> : <span className="text-danger">InActive</span>),
      selector: (row) =>
        row?.status == 1 ? (
          <span className="text-success">Active</span>
        ) : (
          <span className="text-danger text-capitalize">
            {row?.paymentStatus == "paid" || row?.paymentStatus == "complete" || row?.paymentStatus == "succeeded" ? "Inactive" : ""}
          </span>
        ),
    },
    {
      name: "Subscription Amount",
      // selector: (row) => (row?.status === 1 ? <span className="text-success">Active</span> : <span className="text-danger">InActive</span>),
      selector: (row) => getCurrencySymbol(row?.userSubscriptionCurrency) + row?.userSubscriptionAmount,
    },
    {
      name: "Total Amount",
      // selector: (row) => (row?.status === 1 ? <span className="text-success">Active</span> : <span className="text-danger">InActive</span>),
      selector: (row) => getCurrencySymbol(row?.userSubscriptionCurrency) + row?.totalAmountPaid,
    },
    {
      name: "Payments Count",
      // selector: (row) => (row?.status === 1 ? <span className="text-success">Active</span> : <span className="text-danger">InActive</span>),
      selector: (row) => row?.totalPayments,
    },
    {
      name: "Membership",
      selector: (row) => row?.userSubscriptionName,
      wrap: true,
    },
    {
      name: "Auto Rebill",
      selector: (row) => (row?.autoRebill === 1 ? <span className="text-success">Enabled</span> : <span className="text-danger">Stopped</span>),
      wrap: true,
    },
    {
      name: "Trial Days",
      selector: (row) => (row?.trialDays ? row?.trialDays : "--"),
      wrap: true,
    },
    {
      name: "Membership Type",
      selector: (row) => row?.userSubscriptionType,
    },
    {
      name: "Name",
      selector: (row) => row?.userId?.firstName + " " + row?.userId?.lastName,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row?.userId?.email,
      wrap: true,
    },
    {
      name: "Gateway",
      selector: (row) => row?.userSubscriptionGateway,
    },
    {
      name: "Plateform",
      selector: (row) => <span style={{ textTransform: "capitalize" }}>{row?.userSubscriptionPlatform}</span>,
    },
    {
      name: "Billing Date",
      selector: (row) => dayjs(row?.nextBillingDate)?.format("DD-MMM-YYYY HH:mm:ss"),
      wrap: true,
    },
    {
      name: "Created on",
      selector: (row) => dayjs(row?.createdAt)?.format("DD-MMM-YYYY HH:mm:ss"),
      wrap: true,
    },
    {
      name: "Expires on",
      selector: (row) => dayjs(row?.userSubscriptionExpiryDate)?.format("DD-MMM-YYYY HH:mm:ss"),
      wrap: true,
    },
  ];

  // const handlResetFilters = () => {
  //   setSearchParams("");
  //   setTableDataLoading(true);
  //   setSearchValueQuery("");
  //   setSearchEmailQuery("");
  //   setDateFrom("");
  //   setDateTo("");
  //   setMembershipTypeQuery(null);
  //   setMembershipOriginQuery(null);
  //   setGatewayQuery(null);
  //   setPlaformQuery(null);
  //   setStatusQuery(null);
  //   fetchData({ from: 0, limit });
  // };

  const customStyles = {
    rows: {
      style: {
        minHeight: "70px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "18px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "16px",
      },
    },
  };

  const handlePageChange = (page) => {
    setFrom((page - 1) * limit);
    setTableDataLoading(true);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setLimit(newPerPage);
  };

  const handleFilterButtonClick = () => {
    setFrom(0);
    setResetPagination(true);

    const filtersObj = {
      origin: membershipOriginQuery?.value,
      searchValue: searchValueQuery,
      platform: platformQuery?.value,
      status: statusQuery?.value,
      paymentStatus: "",
      membership: membershipTypeQuery?.value,
      gateway: gatewayQuery?.value,
      recurringValue: recurringValue?.value,
      searchByField: searchField?.value,
      limit,
    };
    fetchData(filtersObj);
    setTableDataLoading(true);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-2 align-items-center">
          <div className="col-8">
            <ol className="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li className="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li className="breadcrumb-item">Subscriptions Listing</li>
            </ol>
          </div>
        </div>

        <div className="row align-items-end justify-content-end px-3">
          <div className="form-group px-3 col-md-2">
            <label>Recurring</label>
            <Select
              name="autoRebill"
              value={recurringValue}
              onChange={(e) => setRecurringValue(e)}
              className="basic-single form-control"
              classNamePrefix="select"
              options={[
                { value: -1, label: "All" },
                { value: 1, label: "Recurring" },
                { value: 2, label: "Non-Recurring" },
              ]}
            />
          </div>
          <div className="form-group px-3 col-md-2">
            <label>Select Membership</label>
            <Select
              name="membershipTypeQuery"
              value={membershipTypeQuery}
              onChange={(e) => setMembershipTypeQuery(e)}
              className="basic-single form-control"
              classNamePrefix="select"
              options={[
                { value: "", label: "All" },
                { value: "month", label: "Monthly" },
                { value: "year", label: "Yearly" },
              ]}
            />
          </div>
          <div className="form-group px-3 col-md-2">
            <label>Select Gateway</label>
            <Select
              name="gateway"
              value={gatewayQuery}
              onChange={(e) => setGatewayQuery(e)}
              className="basic-single form-control"
              classNamePrefix="select"
              options={[
                { value: "", label: "All" },
                { value: "stripe", label: "Stripe" },
                { value: "apple", label: "InApp" },
                { value: "wordpress", label: "Wordpress" },
              ]}
            />
          </div>
          <div className="form-group px-3 col-md-2">
            <label>Select Status</label>
            <Select
              name="status"
              value={statusQuery}
              onChange={(e) => setStatusQuery(e)}
              className="basic-single form-control"
              classNamePrefix="select"
              options={[
                { value: -1, label: "All" },
                { value: 1, label: "Active" },
                { value: 0, label: "InActive" },
              ]}
            />
          </div>

          <div className="form-group px-3 col-md-2">
            <label>Select Platform</label>
            <Select
              name="platform"
              value={platformQuery}
              onChange={(e) => setPlaformQuery(e)}
              className="basic-single form-control"
              classNamePrefix="select"
              options={[
                { value: "", label: "All" },
                { value: "android", label: "Android" },
                { value: "apple", label: "Apple" },
                { value: "wp", label: "Wordpress" },
                { value: "web", label: "Web" },
              ]}
            />
          </div>
          <button
            style={{ height: "50px", marginBottom: "1.1rem" }}
            className="btn btn-primary float-right btn-md ml-2"
            onClick={handleFilterButtonClick}
          >
            Go
          </button>
        </div>
        <div className="row align-items-end justify-content-end mb-2 px-3">
          <div className="col-2">
            <div className="form-group">
              <label>Search</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={searchValueQuery}
                onChange={(e) => setSearchValueQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group px-3 col-md-2">
            <label>Search Field</label>
            <Select
              name="By"
              value={searchField}
              onChange={(e) => setSearchField(e)}
              className="basic-single form-control"
              classNamePrefix="select"
              options={[
                { value: "name", label: "User Name" },
                { value: "email", label: "Email" },
                { value: "id", label: "Id" },
              ]}
            />
          </div>

          {/*<div className="col-2">*/}
          {/*  <div className="form-group">*/}
          {/*    <label>Date From</label>*/}
          {/*    <input type="date" className="form-control" placeholder="Date from" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} />*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="col-2">*/}
          {/*  <div className="form-group">*/}
          {/*    <label>Date To</label>*/}
          {/*    <input type="date" className="form-control" placeholder="Date to" value={dateTo} onChange={(e) => setDateTo(e.target.value)} />*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/* <div className="col-2">
            <div className="form-group">
              <p className="m-0">&nbsp;</p>
              <button className="btn btn-primary float-right btn-md ml-2" onClick={handleFilterButtonClick}>
                Apply
              </button>
              <button className="btn btn-primary float-right btn-md" onClick={handlResetFilters}>
                Reset
              </button>
            </div>
          </div> */}
          <button
            style={{ height: "50px", marginBottom: "1.1rem" }}
            className="btn btn-primary float-right btn-md ml-2"
            onClick={handleFilterButtonClick}
          >
            Go
          </button>
        </div>
        <div className="row justify-content-end mb-4 px-3">
          <button className="btn btn-primary float-right btn-md ml-2" onClick={handleDownloadCSV}>
            {csvLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Download CSV
          </button>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <DataTable
                    className="custom-data-table"
                    columns={columns}
                    data={filteredDataArray && filteredDataArray}
                    pagination
                    customStyles={customStyles}
                    progressPending={tableDataLoading}
                    persistTableHead
                    onChangePage={handlePageChange}
                    paginationResetDefaultPage={resetPagination}
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[10, 50, 100, 250, 500]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    progressComponent={<div className="spinner-border text-black mt-5 mb-3" role="status" aria-hidden="true"></div>}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionsList;
