import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { remove_session, getAllTransactionsList, getCurrencySymbol, refundTransaction } from "../../../functions/general";
import Select from "react-select";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const TransactionsList = (props) => {
  const navigate = useNavigate();

  const [searchNameQuery, setSearchNameQuery] = React.useState("");
  const [searchEmailQuery, setSearchEmailQuery] = React.useState("");
  const [membershipTypeQuery, setMembershipTypeQuery] = React.useState(null);
  const [membershipOriginQuery, setMembershipOriginQuery] = React.useState(null);
  const [gatewayQuery, setGatewayQuery] = React.useState(null);
  const [statusQuery, setStatusQuery] = React.useState(null);
  const [dateFrom, setDateFrom] = React.useState("");
  const [dateTo, setDateTo] = React.useState("");

  const [amount, setAmount] = React.useState("");
  const [description, setDescription] = React.useState(null);
  const [maxAmount, setMaxAmount] = React.useState(0);

  const [errors, setErrors] = React.useState("");
  const [reasonRequired, setReasonRequired] = React.useState(false);

  const [paymentId, setPaymentId] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [tableDataLoading, setTableDataLoading] = React.useState(true);

  const [transactionsList, setTransactionsList] = React.useState([]);
  const [filteredDataArray, setFilteredDataArray] = React.useState([]);

  var utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);

  useEffect(() => {
    setTableDataLoading(true);
    props.setPageTitleAndSubTitle("Transactions List", "Here is the list of all Transactions!");
    fetchData();
  }, []);

  const fetchData = () => {
    getAllTransactionsList().then((res) => {
      if (res && res.status == 200) {
        if (res.data && res.data.length) {
          setTransactionsList(res?.data);
          setTableDataLoading(false);
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };

  useEffect(() => {
    setFilteredDataArray(transactionsList); // Set filteredDataArray whenever transactions changes
  }, [transactionsList]);

  const columns = [
    {
      name: "Invoice Id",
      selector: (row) => row?.invoiceId,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
    },
    {
      name: "Membership",
      selector: (row) => row?.subscriptionName,
      wrap: true,
    },
    {
      name: "Subscription Amount",
      selector: (row) => getCurrencySymbol(row?.currency) + row?.amount,
      wrap: true,
    },
    {
      name: "Discount",
      selector: (row) =>
        row?.coupon?.length && row?.coupon[0]?.discountAmount ? getCurrencySymbol(row?.currency) + row?.coupon[0]?.discountAmount : "--",
      wrap: true,
    },
    {
      name: "Tax",
      selector: (row) => getCurrencySymbol(row?.currency) + row?.tax,
      wrap: true,
    },
    // {
    //   name: "Total",
    //   selector: (row) =>
    //     getCurrencySymbol(row?.currency) +
    //     (row?.coupon?.length
    //       ? (row?.amount_paid && Number(row?.amount_paid) + (row?.tax && Number(row?.tax)) / 100) - row?.coupon[0]?.discountAmount
    //       : row?.amount_paid && Number(row?.amount_paid) + (row?.tax && Number(row?.tax)) / 100),
    //   wrap: true,
    // },
    {
      name: "Total",
      selector: (row) => getCurrencySymbol(row?.currency) + row?.amount_paid,
    },
    {
      name: "Coupon Code",
      selector: (row) => (row?.coupon?.length ? row?.coupon[0]?.code : "--"),
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row?.userId?.firstName + " " + row?.userId?.lastName,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row?.userId?.email,
      wrap: true,
    },
    {
      name: "Gateway",
      selector: (row) => row?.gateway,
    },
    {
      name: "Platform",
      selector: (row) => <span style={{ textTransform: "capitalize" }}>{row?.platform}</span>,
    },
    {
      name: "Created on",
      selector: (row) => dayjs(row?.createdAt).format("DD-MMM-YYYY HH:mm:ss"),
      wrap: true,
    },
    // {
    //   name: "Trial Days",
    //   selector: (row) => (row?.trialDays ? row?.trialDays : "--"),
    //   wrap: true,
    // },
    {
      name: "Refunded Amount",
      selector: (row) => (row?.refundedAmount ? getCurrencySymbol(row?.currency) + row?.refundedAmount : "--"),
      wrap: true,
    },
    {
      name: "Invoice",
      selector: (row) => (
        <a target="_blank" href={row?.hosted_invoice_url} className="btn btn-primary btn-sm text-white">
          View Invoice
        </a>
      ),
      wrap: true,
    },
    {
      name: "Refund",
      selector: (row) => {
        if (
          row?.gateway === "stripe" &&
          // ((row?.trialDays && dayjs().utc().isAfter(dayjs(row?.createdAt).add(row?.trialDays, "day"))) || !row?.trialDays) &&
          row?.amount_paid > row?.refundedAmount &&
          (row?.status === "paid" || row?.status === "complete" || row?.status === "succeeded")
        ) {
          return (
            <button data-toggle="modal" data-target="#refund" onClick={(e) => modalHandler(row)} className="btn btn-sm btn-danger">
              Refund
            </button>
          );
        } else if (row?.amount_paid == row?.refundedAmount && row?.amount_paid > 0) {
          return <span className="text-danger">Fully Refunded</span>;
        } else {
          return null;
        }
      },
      wrap: true,
    },
  ];

  // console.log(date_time_to_date_format("2023-05-30T14:31:48.000Z"));
  const customStyles = {
    rows: {
      style: {
        minHeight: "70px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "18px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "16px",
      },
    },
  };

  const handlePageChange = () => {
    setTableDataLoading(true);
    setTimeout(() => {
      setTableDataLoading(false);
    }, 500);
  };

  const handlResetFilters = () => {
    setSearchNameQuery("");
    setSearchEmailQuery("");
    setDateFrom("");
    setDateTo("");
    setMembershipTypeQuery(null);
    setMembershipOriginQuery(null);
    setGatewayQuery(null);
    setStatusQuery(null);
    fetchData();
  };

  // Function to handle the button click and apply filters
  const handleFilterButtonClick = () => {
    setTableDataLoading(true);

    // Trim the search query values before applying the filters
    const trimmedSearchNameQuery = searchNameQuery ? searchNameQuery.trim() : "";
    const trimmedSearchEmailQuery = searchEmailQuery ? searchEmailQuery.trim() : "";

    const filteredArray = transactionsList?.filter((data) => {
      // Filter by firstName (case-insensitive search) if searchQuery exists and is not an empty string
      const firstNameMatch = trimmedSearchNameQuery && data.userId.firstName.toLowerCase().includes(trimmedSearchNameQuery.toLowerCase());

      // Filter by email (case-insensitive search) if searchQuery exists and is not an empty string
      const emailMatch = trimmedSearchEmailQuery && data.userId.email.toLowerCase().includes(trimmedSearchEmailQuery.toLowerCase());

      const membershipTypeMatch = membershipTypeQuery && data?.subscriptionName?.toLowerCase().includes(membershipTypeQuery?.value?.toLowerCase());

      // Filter by userSubscriptionOrigin if userSubscriptionOriginFilter exists and is not null or undefined
      const userSubscriptionOriginMatch = membershipOriginQuery !== undefined && data?.platform === membershipOriginQuery?.value;

      const gatewayMatch = gatewayQuery !== undefined && data?.gateway === gatewayQuery?.value;

      const statusMatch = statusQuery !== undefined && data?.status === statusQuery?.value;

      // Filter by createdAt date range if both createdAtFromFilter and createdAtToFilter exist and are not null or undefined
      const createdAtDate = Date.parse(data.createdAt);
      const createdAtFrom = dateFrom !== undefined ? Date.parse(dateFrom) : null;
      const createdAtTo = dateTo !== undefined ? Date.parse(dateTo) : null;
      const createdAtDateInRange = createdAtFrom && createdAtTo ? createdAtDate >= createdAtFrom && createdAtDate <= createdAtTo : true;

      // Return true if any filter is not applied (i.e., no filtering condition is specified) or all filters match
      return (
        (!trimmedSearchNameQuery || firstNameMatch) &&
        (!trimmedSearchEmailQuery || emailMatch) &&
        (!membershipOriginQuery || userSubscriptionOriginMatch) &&
        (!gatewayQuery || gatewayMatch) &&
        (!statusQuery || statusMatch) &&
        (!membershipTypeQuery || membershipTypeMatch) &&
        ((!dateFrom && !dateTo) || createdAtDateInRange)
      );
    });

    // Simulate a delay to show loading state
    setTimeout(() => {
      setFilteredDataArray(filteredArray);
      setTableDataLoading(false); // Set loading state back to false after filtering
    }, 200); // Adjust the delay time as needed
  };

  const modalHandler = (row) => {
    setPaymentId(row?.invoiceId);
    setMaxAmount(row?.refundedAmount ? row?.amount_paid - row?.refundedAmount : row?.amount_paid);
    setErrors("");
    setReasonRequired("");
    if (amount) setAmount("");
    if (description) setDescription(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate() && !errors) {
      setLoading(true);
      const refundData = {
        amount: +amount,
        reason: description?.value,
      };
      refundTransaction(paymentId, refundData).then((res) => {
        if ((res && res.status === 200) || res.status === 201) {
          fetchData();
          toast.success("Payment refunded successfully.");
          document.getElementById("refundModalCloseButton").click();
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error(res?.data?.message);
        }
        setLoading(false);
      });
    }
  };

  const handleAmountInput = (e) => {
    setAmount(e.target.value);
    if (e.target.value > maxAmount) {
      setErrors(`Amount should not be greater than actual amount (${maxAmount})`);
    } else setErrors("");
  };

  const validate = () => {
    let isValid = true;
    if (!amount) {
      isValid = false;
      setErrors("Please enter amount.");
    }
    if (!description) {
      isValid = false;
      setReasonRequired(true);
    }
    return isValid;
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4 align-items-center">
          <div className="col-8">
            <ol className="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li className="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li className="breadcrumb-item">Transactions Listing</li>
            </ol>
          </div>
        </div>
        <div className="row align-align-items-center mb-4">
          <div className="col-3">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search Name"
                value={searchNameQuery}
                onChange={(e) => setSearchNameQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search Email"
                value={searchEmailQuery}
                onChange={(e) => setSearchEmailQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group px-3 col-md-3">
            <label>Select Membership</label>
            <Select
              name="membershipTypeQuery"
              value={membershipTypeQuery}
              onChange={(e) => setMembershipTypeQuery(e)}
              className="basic-single form-control"
              classNamePrefix="select"
              options={[
                { value: "monthly", label: "Monthly" },
                { value: "yearly", label: "Yearly" },
              ]}
            />
          </div>
          <div className="form-group px-3 col-md-3">
            <label>Select Platform</label>
            <Select
              name="membershipOriginQuery"
              value={membershipOriginQuery}
              onChange={(e) => setMembershipOriginQuery(e)}
              className="basic-single form-control"
              classNamePrefix="select"
              options={[
                { value: "android", label: "Android" },
                { value: "apple", label: "Apple" },
                { value: "wp", label: "Wordpress" },
                { value: "web", label: "Website" },
              ]}
            />
          </div>
          <div className="form-group px-3 col-md-3">
            <label>Select Gateway</label>
            <Select
              name="gateway"
              value={gatewayQuery}
              onChange={(e) => setGatewayQuery(e)}
              className="basic-single form-control"
              classNamePrefix="select"
              options={[
                { value: "stripe", label: "Stripe" },
                { value: "apple", label: "Apple" },
                { value: "wordpress", label: "Wordpress" },
              ]}
            />
          </div>
          <div className="form-group px-3 col-md-3">
            <label>Status</label>
            <Select
              name="status"
              value={statusQuery}
              onChange={(e) => setStatusQuery(e)}
              className="basic-single form-control"
              classNamePrefix="select"
              options={[
                { value: "paid", label: "Paid" },
                { value: "unpaid", label: "Unpaid" },
                { value: "draft", label: "Darft" },
                { value: "open", label: "Open" },
              ]}
            />
          </div>
          <div className="col-2">
            <div className="form-group">
              <label>Date From</label>
              <input type="date" className="form-control" placeholder="Date from" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} />
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <label>Date To</label>
              <input type="date" className="form-control" placeholder="Date to" value={dateTo} onChange={(e) => setDateTo(e.target.value)} />
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <p className="m-0">&nbsp;</p>
              <button className="btn btn-primary float-right btn-md ml-2" onClick={handleFilterButtonClick}>
                Apply
              </button>
              <button className="btn btn-primary float-right btn-md" onClick={handlResetFilters}>
                Reset
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <DataTable
                    className="custom-data-table"
                    columns={columns}
                    data={filteredDataArray && filteredDataArray}
                    pagination
                    customStyles={customStyles}
                    progressPending={tableDataLoading}
                    persistTableHead
                    onChangePage={handlePageChange}
                    progressComponent={<div className="spinner-border text-black mt-5 mb-3" role="status" aria-hidden="true"></div>}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="refund">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header border-0 px-3 mb-3">
              <h5 className="modal-title">{"Refund Trasnaction"}</h5>
              <button type="button" className="close" data-dismiss="modal" id="refundModalCloseButton">
                <span>&times;</span>
              </button>
            </div>
            <form noValidate onSubmit={onSubmit}>
              <div className="form-group px-3">
                <label>Amount</label>
                <input type="number" className="form-control" placeholder="Enter refund amount" value={amount} onChange={handleAmountInput} />
                {errors && !amount && <div className="text-warning font-size-12 mt-2">{errors}</div>}
                {errors && amount && <div className="text-warning font-size-12 mt-2">{errors}</div>}
              </div>
              <div className="form-group px-3">
                <label>Reason</label>
                <Select
                  name="membershipTypeQuery"
                  value={description}
                  onChange={(e) => setDescription(e)}
                  className="basic-single form-control"
                  classNamePrefix="select"
                  options={[
                    { value: "duplicate", label: "Duplicate" },
                    { value: "fraudulent", label: "Fraudulent" },
                    { value: "requested_by_customer", label: "Requested By Customer" },
                    { value: "expired_uncaptured_charge", label: "Expired Uncaptured Charge" },
                  ]}
                />
                {reasonRequired && !description && <div className="text-warning font-size-12 mt-2">Please select reason.</div>}
              </div>
              <div className="modal-footer border-0">
                <button type="submit" className="btn btn-primary">
                  {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {"Refund"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionsList;
