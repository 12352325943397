import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  remove_session,
  getSmTeam,
  uploadAttachments,
  updateSmTeamPost, addSmTeamPost, deleteSmTeamPost,
} from "../../../../functions/general";
import { toast } from "react-toastify";
import ModalImage from "react-modal-image";
import dayjs from "dayjs";

function ListSmTeam(props) {
  const navigate = useNavigate();
  const [posts, setPosts] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("");
  const [videos, setVideos] = React.useState("");
  const [videoOrAttachment, setVideoOrAttachment] = React.useState("video");
  const [attachments, setAttachments] = React.useState([]);
  const [attachmentsLoading, setAttachmentsLoading] = React.useState(false);
  const [from, setFrom] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [count, setCount] = React.useState(0);

  useEffect(() => {
    props.setPageTitleAndSubTitle("SM Team", "Here is the list of SM Team!");
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [limit,from]);

  const fetchData = () => {
    getSmTeam(from, limit).then((res) => {
      if ((res && res.status === 201) || res.status === 200) {
        if (res.data && res.data.posts && res.data.posts.length) {
          setPosts(res?.data.posts);
          setCount(res?.data.count);
        }
      } else if (res && res.status === 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };
  const modalHandler = (post) => {
    if (post && post._id) {
      setModalTitle("Update Post");
      setModalButtonText("Update");
      setText(post.text);
      if(post.attachments && post.attachments.length > 0) {
        setAttachments(post.attachments);
        setVideos("");
        setVideoOrAttachment("attachment")
      }else{
        setAttachments([]);
        setVideos(post.videos);
        setVideoOrAttachment("video")
      }
      setId(post._id);
    } else {
      setModalTitle("Add New Post");
      setModalButtonText("Add");
      setText("");
      setAttachments([]);
      setVideos([]);
      setVideoOrAttachment('video')
      setId("");
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const postData = {
        text: text,
        attachments: videoOrAttachment === 'attachment' ? attachments : [],
        videos: videoOrAttachment === 'video' ? videos : []
      };
      if (id) {
        updateSmTeamPost(id, postData).then((res) => {
          if (res && res.status === 200) {
            fetchData();
            toast.success("Post updated successfully.");
            document.getElementById("postModalCloseButton").click();
          } else if (res && res.status === 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update post.");
          }
          setLoading(false);
        });
      } else {
        addSmTeamPost(postData).then((res) => {
          if (res && (res.status === 201 || res.status === 200)) {
            if(from === 0) {
              fetchData();
            }else{
              setFrom(0)
            }
            toast.success("Post added successfully.");
            document.getElementById("postModalCloseButton").click();
          } else if (res && res.status === 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add post.");
          }
          setLoading(false);
        });
      }
    }
  };
  const validate = () => {
    let isValid = true;
    if (!text && attachments.length === 0 && videos.length === 0) {
      isValid = false;
    }
    return isValid;
  };
  const deleteHandler = (e, post) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete post?") === true) {
      deleteSmTeamPost(post?._id).then((res) => {
        if (res && res.status === 200) {
          fetchData();
          toast.success("Post deleted successfully.");
        } else if (res && res.status === 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete post.");
        }
      });
    }
  };
  const imageUploadHandler = (e) => {
    setLoading(true);
    setAttachmentsLoading(true);

    const files = Array.from(e.target.files);

    Promise.all(files.map((file) => uploadAttachments(file)))
        .then((responses) => {
          const successfulUploads = responses.filter(res => res && res.status === 201);
          const failedUploads = responses.filter(res => res && res.status !== 201);

          if (successfulUploads.length > 0) {
            const newAttachments = successfulUploads.map(res => res.data[0]);
            // setAttachments((prevItems) => [...prevItems, ...newAttachments]);
            setAttachments(newAttachments);
            toast.success("Attachments uploaded successfully.");
          }

          if (failedUploads.length > 0) {
            const sessionFailed = failedUploads.some(res => res.status === 203);
            if (sessionFailed) {
              remove_session();
              props.getLoggedInUser();
              navigate("/login");
            } else {
              toast.error("Some attachments failed to upload.");
            }
          }

          setLoading(false);
          setAttachmentsLoading(false);
        })
        .catch((error) => {
          console.error("Failed to upload attachments:", error);
          toast.error("Failed to upload attachments.");
          setLoading(false);
          setAttachmentsLoading(false);
        });
  };
  const removeAttachment = (index) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4 align-items-center">
          <div className="col-8">
            <ol className="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li className="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Community / SM Team
              </li>
            </ol>
          </div>
          <div className="col-4">
            <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={() => modalHandler()}>
              Add New Post
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="display dataTable" style={{ minWidth: "845px" }}>
                    <thead>
                    <tr>
                      <th>No</th>
                      <th>Text</th>
                      <th>Attachments</th>
                      <th>Video</th>
                      <th>Likes</th>
                      <th>Comments</th>
                      <th>Created On</th>
                      <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {posts?.length > 0 ? (
                          posts?.map((post, index) => {
                          return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{post?.text}</td>
                                <td>
                                  <div className="image-gallery maxWidth100px">
                                    {post?.attachments.length > 0 && (
                                        post?.attachments?.map((attachment, index) => {
                                          if(attachment) {
                                            return (
                                                <div className="image-item" key={index}>
                                                  <ModalImage
                                                      small={process.env.REACT_APP_MEDIAPATH + attachment?.key}
                                                      large={process.env.REACT_APP_MEDIAPATH + attachment?.key}/>
                                                </div>
                                            )
                                          }
                                        }))}
                                  </div>
                                </td>
                                <td>{post.videos && post.videos.length > 0 && (<a href={post?.videos[0]?.url} target="_blank">{post?.videos[0]?.url}</a>) }</td>
                                <td>{post?.likeCount}</td>
                                <td>{post?.commentCount}</td>
                                <td>{dayjs(post.createdAt)?.format("DD-MMM-YYYY HH:mm:ss")}</td>
                                <td>
                                  <div className="d-flex">
                                    <button data-toggle="modal" data-target="#add"
                                            onClick={() => modalHandler(post)}
                                            className="btn btn-primary shadow btn-sm sharp mr-1"><i
                                        className="fa fa-pencil"></i></button>
                                    <button onClick={e => deleteHandler(e, post)}
                                            className="btn btn-danger shadow btn-sm sharp"><i
                                        className="fa fa-trash"></i></button>
                                  </div>
                                </td>
                              </tr>
                          );
                          })
                    ) : (
                        <tr>
                        <td>No post found.</td>
                        </tr>
                    )}
                    </tbody>
                    <tfoot>
                    <tr>
                      <td colSpan="8">
                      <nav aria-label="Page navigation" className="float-right customPagination">
                        <ul className="pagination">
                          <li className="">
                            <span className="page-link">
                              Rows per page:
                              <select value={limit} onChange={(e) => {setLimit(e.target.value); setFrom(0)}}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                              </span>
                          </li>
                          <li className="">
                            <span className="page-link">
                            {from}-{(limit+from > count ? count : limit+from)} of {count}
                              </span>
                          </li>
                            <li className={`page-item ${from === 0 ? 'disabled' : ''}`}>
                              <a className="page-link" href="#" aria-label="Previous" onClick={() => setFrom(0)}>
                                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                              </a>
                            </li>
                          <li className={`page-item ${from === 0 ? 'disabled' : ''}`}>
                          <a className="page-link" href="#" onClick={() => setFrom((from - limit))}>
                              <i className="fa fa-angle-left" aria-hidden="true"></i>
                            </a>
                          </li>
                          <li className={`page-item ${(limit+from) >= count ? 'disabled' : ''}`}>
                            <a className="page-link" href="#" onClick={() => setFrom((from + limit))}>
                              <i className="fa fa-angle-right" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li className={`page-item ${(limit+from) >= count ? 'disabled' : ''}`}>
                              <a className="page-link" href="#" aria-label="Next" onClick={() => setFrom((Math.floor(count/limit) * limit))}>
                                <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                              </a>
                            </li>
                        </ul>
                      </nav>
                      </td>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="add">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="postModalCloseButton">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="row px-3">
                  <div className="form-group px-3 col-md-12">
                    <label>Post Content</label>
                    <textarea className="form-control" rows="4" value={text}
                              onChange={(e) => setText(e.target.value)}></textarea>
                  </div>
                  <div className="form-group px-3 col-md-12">
                    <label>Select Type</label>
                    <select className="form-control"
                            value={videoOrAttachment} onChange={(e) => setVideoOrAttachment(e.target.value)}>
                      <option value="video">Video</option>
                      <option value="attachment">Attachment</option>
                    </select>
                  </div>
                  {videoOrAttachment === "video" && (
                      <div className="form-group px-3 col-md-12">
                        <label>Video Url</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter video url"
                            value={videos[0]?.url}
                            onChange={(e) => setVideos([{url: e.target.value}])}
                        />
                      </div>
                  )}
                  {videoOrAttachment === "attachment" && (
                      <>
                        <div className="form-group px-3 col-md-12">
                          <label>Image</label>
                          <div className="image-gallery">
                            {attachments.length > 0 && (
                                attachments?.map((attachment, index) => {
                                  return (
                                      <div className="image-item maxWidth100px position-relative" key={index}>
                                        <span className="fa fa-times position-absolute" role="status"
                                              onClick={() => removeAttachment(index)}
                                              aria-hidden="true"></span>
                                        <ModalImage
                                            small={process.env.REACT_APP_MEDIAPATH + attachment?.key}
                                            large={process.env.REACT_APP_MEDIAPATH + attachment?.key}/>
                                      </div>
                                  )
                                }))}
                          </div>
                        </div>
                        <div className="col mb-5">
                          <div className="form-group px-3">
                            <button className="btn btn-primary" type="button"
                                    onClick={() => document.getElementById("mediaInput").click()}>
                              {attachmentsLoading && (
                                  <span className="spinner-border spinner-border-sm mr-2" role="status"
                                        aria-hidden="true"></span>)}
                              Upload
                            </button>
                            <input
                                type="file"
                                className="d-none"
                                id="mediaInput"
                                onChange={(e) => imageUploadHandler(e)}
                                accept="image/*"
                            />
                          </div>
                        </div>
                      </>
                  )}
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListSmTeam;
