import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import {
  remove_session,
  getAppSliders,
  updateAppSlider,
  addAppSlider,
  getAcademyAppSliders,
  deleteAppSlider,
  deleteAppSliderPermanently,
  restoreAppSlider,
  getSuggestionsList,
  getAllLectureSubcategories,
  getAllWorkoutsList,
  getAcademySliderProgramCategories,
  getAcademySliderProgramWeeks,
  getAcademySliderProgramWeekDays,
  getAcademySliderPrograms,
  getAcademySliderLectureCategories,
  getAcademySliderWorkoutCategories,
  getAcademySliderLectureSubcategories,
  getAcademySliderLectures,
  getAcademySliderWorkoutSubcategories,
  getAcademySliderWorkouts,
  sortAppSliders,
} from "../../../functions/general";
import { toast } from "react-toastify";
import Media from "../../../components/Media";
import ModalImage from "react-modal-image";
import { usePermissions } from "../../../functions/usePermissions";

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";

const AcademySliders = (props) => {
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const [appSlidersList, setAppSlidersList] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [image, setImage] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [linkType, setLinkType] = React.useState(null);
  const [bannerFor, setBannerFor] = React.useState("all");
  const [btnText, setBtnText] = React.useState("");
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [routeLinkOptions, setRouteLinkOptions] = React.useState([]);

  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [errors, setErrors] = React.useState("");
  const [searchParams] = useSearchParams();

  const [isClickAble, setIsClickAble] = useState(true);

  //route states

  const [routeName, setRouteName] = useState("");
  const [routeId, setRouteId] = useState("");
  // programs states

  const [programsListOptions, setProgramsListOptions] = React.useState([]);
  const [programWeeksListOptions, setProgramWeeksListOptions] = React.useState([]);
  const [programWeekDaysListOptions, setProgramWeekDaysListOptions] = React.useState([]);

  const [programCategoryId, setProgramCategoryId] = React.useState(null);
  const [programId, setProgramId] = React.useState(null);
  const [programWeekId, setProgramWeekId] = React.useState(null);
  const [programDayId, setProgramDayId] = React.useState(null);

  // lectures states

  const [lectureSubcategoriesListOptions, setLectureSubcategoriesListOptions] = React.useState([]);
  const [lecturesListOptions, setLecturesListOptions] = React.useState([]);

  const [lectureCategoryId, setLectureCategoryId] = React.useState(null);
  const [lectureSubcategoryId, setLectureSubcategoryId] = React.useState(null);
  const [lectureId, setLectureId] = React.useState(null);

  // workouts states

  const [workoutSubcategoriesListOptions, setWorkoutSubcategoriesListOptions] = React.useState([]);
  const [workoutsListOptions, setWorkoutsListOptions] = React.useState([]);

  const [workoutCategoryId, setWorkoutCategoryId] = React.useState(null);
  const [workoutSubcategoryId, setWorkoutSubcategoryId] = React.useState(null);
  const [workoutId, setWorkoutId] = React.useState(null);

  const sliderType = searchParams.get("type");

  useEffect(() => {
    setTableDataLoading(true);
    props.setPageTitleAndSubTitle(
      `${sliderType === "academy" ? "Academy" : "Workout Creator"} Sliders List`,
      `Here is the list of ${sliderType === "academy" ? "Academy" : "Workout Creator"} Sliders!`
    );
    fetchData();
  }, [sliderType]);

  const fetchData = () => {
    if (sliderType === "academy") {
      getAcademyAppSliders().then((res) => {
        if ((res && res?.status == 200) || res?.status === 201) {
          setAppSlidersList(res?.data);
          setTableDataLoading(false);
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          setTableDataLoading(false);
        }
      });
    } else {
      getAppSliders().then((res) => {
        if ((res && res?.status == 200) || res?.status === 201) {
          setAppSlidersList(res?.data);
          setTableDataLoading(false);
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
        }
      });
    }
  };

  const modalHandler = (slider) => {
    if (slider && slider?._id) {
      setTimeout(() => {
        if (slider?.route === "Payment") {
          setRouteName(slider?.route);
        } else {
          if (slider?.route?.toLowerCase()?.includes("program")) {
            setLinkType("Program");
            setProgramCategoryId(slider?.programCategoryId);
            setProgramId(slider?.programId);
            setProgramWeekId(slider?.programWeekId);
            setProgramDayId(slider?.programDayId);
          } else if (slider?.route?.toLowerCase()?.includes("lecture")) {
            setLinkType("Lecture");
            setLectureCategoryId(slider?.lectureCategoryId);
            setLectureSubcategoryId(slider?.lectureSubcategoryId);
            setLectureId(slider?.lectureId);
          } else {
            setLinkType("Workout");
            setWorkoutCategoryId(slider?.workoutCategoryId);
            setWorkoutSubcategoryId(slider?.workoutSubcategoryId);
            setWorkoutId(slider?.workoutId);
          }
        }
      }, 500);
      setIsClickAble(slider?.isClickable);
      setModalButtonText("Update");
      setTitle(slider.title);
      setDescription(slider?.message);
      setImage(slider?.image);
      setLinkType(slider?.route);
      setBannerFor(slider?.bannerFor);
      setBtnText(slider?.routeText);
      // setTimeout(() => {
      //   if (slider?.route?.includes("program")) {
      //   } else if (slider?.route?.includes("lecture")) {
      //   } else {
      //   }
      //   setSelectedOption(slider?.routeId);
      // }, 500);
      setId(slider._id);
    } else {
      setModalTitle("Add New Slider");
      setModalButtonText("Add");
      setTitle("");
      setSelectedOption(null);
      setLinkType(null);
      setBannerFor("all");
      setBtnText("");
      setDescription("");
      setImage("");
      setId("");
      setProgramCategoryId(null);
      setProgramId(null);
      setProgramWeekId(null);
      setProgramDayId(null);
      setLectureCategoryId(null);
      setLectureSubcategoryId(null);
      setLectureId(null);
      setWorkoutCategoryId(null);
      setWorkoutSubcategoryId(null);
      setWorkoutId(null);
      setRouteName("");
      setRouteId(null);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);

      const programSliderData = {
        programCategoryId,
        programId,
        programWeekId,
        programDayId,
      };
      const lectureSliderData = {
        lectureCategoryId,
        lectureSubcategoryId,
        lectureId,
      };
      const workoutSliderData = {
        workoutCategoryId,
        workoutSubcategoryId,
        workoutId,
      };
      const sliderData = {
        title: title,
        image: image,
        type: sliderType,
        message: description,
        route: routeName,
        bannerFor: bannerFor,
        routeId,
        routeText: btnText,
        url: image,
        isClickable: isClickAble,
        ...(linkType === "Program" && { ...programSliderData }),
        ...(linkType === "Lecture" && { ...lectureSliderData }),
        ...(linkType === "Workout" && { ...workoutSliderData }),
      };
      if (id) {
        updateAppSlider(id, sliderData).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Slider updated successfully.");
            document.getElementById("sliderModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update slider.");
          }
          setLoading(false);
        });
      } else {
        addAppSlider(sliderData).then((res) => {
          if (res && res.status == 201) {
            fetchData();
            toast.success("Slider added successfully.");
            document.getElementById("sliderModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add slider.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      description: "",
      image: "",
    };
    let isValid = true;
    // if (!title) {
    //   isValid = false;
    //   errors.title = "Please enter title.";
    // }
    // if (!description) {
    //   isValid = false;
    //   errors.description = "Please enter description.";
    // }
    if (!image) {
      isValid = false;
      errors.image = "Please select image.";
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, slider) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete slider?") == true) {
      if (window.confirm("Are you sure you want to delete slider permanently?") == true) {
        deleteAppSliderPermanently(slider._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Slider deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete slider.");
          }
        });
      }
    }
  };

  const deleteStatusHandler = (e, slider) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the Slider?";
    if (slider.isDeleted) {
      confirmText = "Are you sure you want to active the Slider?";
    }
    if (window.confirm(confirmText) == true) {
      if (slider.isDeleted) {
        restoreAppSlider(slider._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Slider activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active slider.");
          }
        });
      } else {
        deleteAppSlider(slider._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Slider deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive slider.");
          }
        });
      }
    }
  };

  // const handleLinkType = (e) => {
  //   setLinkType(e.value);
  //   setSelectedOption(null);
  //   if (e.value === "Program") {
  //     getSuggestionsList().then((res) => {
  //       if (res && (res.status == 200 || res.status == 201)) {
  //         const suggestions = res?.data?.map((item) => {
  //           return {
  //             label: item?.title,
  //             options: item?.program?.map((item) => {
  //               return { value: item?._id, label: item?.type === "microcycles" ? `${item?.title} (Microcycle)` : item?.title };
  //             }),
  //           };
  //         });
  //         setRouteLinkOptions(suggestions);
  //       } else if (res && res.status == 401) {
  //         remove_session();
  //         props.getLoggedInUser();
  //         navigate("/login");
  //       } else {
  //       }
  //     });
  //   }
  //   if (e.value === "Lecture") {
  //     getAllLectureSubcategories().then((res) => {
  //       if (res && (res.status == 200 || res.status == 201)) {
  //         const lecturs = res?.data?.map((item) => {
  //           return { value: item?._id, label: item?.title };
  //         });
  //         setRouteLinkOptions(lecturs);
  //       } else if (res && res.status == 401) {
  //         remove_session();
  //         props.getLoggedInUser();
  //         navigate("/login");
  //       } else {
  //       }
  //     });
  //   }
  //   if (e.value === "Workout") {
  //     getAllWorkoutSubcategories().then((res) => {
  //       if (res && (res.status == 200 || res.status == 201)) {
  //         const workouts = res?.data?.map((item) => {
  //           return { value: item?._id, label: item?.title };
  //         });
  //         setRouteLinkOptions(workouts);
  //       } else if (res && res.status == 401) {
  //         remove_session();
  //         props.getLoggedInUser();
  //         navigate("/login");
  //       } else {
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    selectedOption && setSelectedOption(null);
    if (linkType) {
      if (linkType === "Program") {
        getAcademySliderProgramCategories().then((res) => {
          if (res && (res.status == 200 || res.status == 201)) {
            const programs = res?.data?.map((item) => {
              return { value: item?._id, label: item?.title };
            });
            // const suggestions = res?.data?.map((item) => {
            //   return {
            //     label: item?.title,
            //     options: item?.program?.map((item) => {
            //       return { value: item?._id, label: item?.type === "microcycles" ? `${item?.title} (Microcycle)` : item?.title };
            //     }),
            //   };
            // });
            setRouteLinkOptions(programs);
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
          }
        });
      }
      if (linkType === "Lecture") {
        getAcademySliderLectureCategories().then((res) => {
          if (res && (res.status == 200 || res.status == 201)) {
            const lectures = res?.data?.map((item) => {
              return { value: item?._id, label: item?.title };
            });
            setRouteLinkOptions(lectures);
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
          }
        });
      }
      if (linkType === "Workout") {
        getAcademySliderWorkoutCategories().then((res) => {
          if (res && (res.status == 200 || res.status == 201)) {
            const workouts = res?.data?.map((item) => {
              return { value: item?._id, label: item?.title };
            });
            setRouteLinkOptions(workouts);
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
          }
        });
      }
    }
  }, [linkType]);

  /////////Programs/////////////
  const getProgramsList = (categoryId) => {
    setProgramCategoryId(categoryId);
    setRouteId(categoryId);
    setRouteName("ProgramCategory");
    setProgramId(null);
    setProgramWeekId(null);
    setProgramDayId(null);
    getAcademySliderPrograms(categoryId).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        const programs = res?.data?.map((item) => {
          return { value: item?._id, label: `${item?.title} ${item?.type === "microcycles" ? "(Microcycle)" : ""}` };
        });
        setProgramsListOptions(programs);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };

  const getProgramWeeksList = (programId) => {
    setRouteName("Program");
    setRouteId(programId);
    setProgramId(programId);
    setProgramWeekId(null);
    setProgramDayId(null);
    getAcademySliderProgramWeeks(programId).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        const programWeeks = res?.data?.map((item) => {
          return { value: item?._id, label: `${item?.title}` };
        });
        setProgramWeeksListOptions(programWeeks);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };
  const getProgramWeekDaysList = (weekDayId) => {
    setRouteName("ProgramWeek");
    setRouteId(weekDayId);
    setProgramWeekId(weekDayId);
    setProgramDayId(null);
    getAcademySliderProgramWeekDays(weekDayId).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        const programs = res?.data?.map((item) => {
          return { value: item?._id, label: item?.title };
        });
        setProgramWeekDaysListOptions(programs);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };

  //////////Lectures///////////////

  const getLectureSubcategories = (categoryId) => {
    setLectureCategoryId(categoryId);
    setRouteId(categoryId);
    setRouteName("LectureCategory");

    setLectureSubcategoryId(null);
    setLectureId(null);
    getAcademySliderLectureSubcategories(categoryId).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        const lectures = res?.data?.map((item) => {
          return { value: item?._id, label: `${item?.title}` };
        });
        setLectureSubcategoriesListOptions(lectures);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };

  const getLectures = (id) => {
    setRouteName("LectureSubcategory");
    setRouteId(id);
    setLectureId(null);
    setLectureSubcategoryId(id);
    getAcademySliderLectures(id).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        const lectures = res?.data?.map((item) => {
          return { value: item?._id, label: `${item?.title}` };
        });
        setLecturesListOptions(lectures);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };

  //////////Workouts///////////////

  const getWorkoutSubcategories = (categoryId) => {
    setWorkoutCategoryId(categoryId);
    setRouteName("WorkoutCategory");
    setRouteId(categoryId);

    setWorkoutSubcategoryId(null);
    setWorkoutId(null);

    getAcademySliderWorkoutSubcategories(categoryId).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        const workouts = res?.data?.map((item) => {
          return { value: item?._id, label: `${item?.title}` };
        });
        setWorkoutSubcategoriesListOptions(workouts);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };

  const getWorkouts = (id) => {
    setWorkoutSubcategoryId(id);
    setRouteName("WorkoutSubcategory");
    setRouteId(id);

    setWorkoutId(null);
    getAcademySliderWorkouts(id).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        const workouts = res?.data?.map((item) => {
          return { value: item?._id, label: `${item?.title}` };
        });
        setWorkoutsListOptions(workouts);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };

  const setImageHandler = (image) => {
    setImage(image);
  };

  const linkTypeOptions = [
    { value: "Program", label: "Program" },
    { value: "Lecture", label: "Lecture" },
    { value: "Workout", label: "Workout" },
    { value: "Payment", label: "Payment" },
  ];

  const bannerForOptions = [
    { value: "all", label: "All" },
    { value: "android", label: "Android" },
    { value: "apple", label: "Apple" },
  ];

  const handleSelectCategoryOption = (selected) => {
    //program states
    setProgramId(null);
    setProgramWeekId(null);
    setProgramDayId(null);

    //lecture states
    setLectureSubcategoryId(null);
    setLectureId(null);

    //Workout states
    setWorkoutSubcategoryId(null);
    setWorkoutId(null);

    setSelectedOption(selected?.value);
    if (linkType === "Program") getProgramsList(selected?.value);
    else if (linkType === "Lecture") getLectureSubcategories(selected?.value);
    else getWorkoutSubcategories(selected?.value);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    const updatedAppSliderList = arrayMove(appSlidersList, oldIndex, newIndex);
    let sortedArray = [];
    updatedAppSliderList?.map((category) => sortedArray.push(category?._id));
    sortAppSliders(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setAppSlidersList(updatedAppSliderList);
        // fetchData();
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ slider, serialNo }) => {
    return (
      <tr>
        <td>{serialNo + 1}</td>
        <td>
          <div className="maxWidth70px">
            <ModalImage small={process.env.REACT_APP_MEDIAPATH + slider?.image} large={process.env.REACT_APP_MEDIAPATH + slider?.image} />
          </div>
        </td>
        <td>{slider?.title}</td>
        <td>{slider?.message}</td>
        {(hasPermission("edit-academy-slider") || hasPermission("edit-workout-slider")) && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={!slider.isDeleted} onClick={(e) => deleteStatusHandler(e, slider)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {(hasPermission("edit-academy-slider") ||
          hasPermission("edit-workout-slider") ||
          hasPermission("delete-academy-slider") ||
          hasPermission("delete-workout-creator-slider")) && (
          <td>
            <div className="d-flex">
              {(hasPermission("edit-academy-slider") || hasPermission("edit-workout-slider")) && (
                <button
                  data-toggle="modal"
                  data-target="#add"
                  onClick={(e) => modalHandler(slider)}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {(hasPermission("delete-academy-slider") || hasPermission("delete-workout-creator-slider")) && (
                <button onClick={(e) => deleteHandler(e, slider)} className="btn btn-danger shadow btn-sm sharp">
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </td>
        )}
        {hasPermission("edit-academy-slider") && (
          <td>
            <DragHandle />
          </td>
        )}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ appSlidersList }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>No</th>
            <th>Slider Image</th>
            <th>Title</th>
            <th>Description</th>
            {(hasPermission("edit-academy-slider") || hasPermission("edit-workout-slider")) && <th>Status</th>}
            {(hasPermission("edit-academy-slider") || hasPermission("edit-workout-slider")) && <th>Status</th>}
            {(hasPermission("edit-academy-slider") ||
              hasPermission("edit-workout-slider") ||
              hasPermission("delete-academy-slider") ||
              hasPermission("delete-workout-creator-slider")) && <th>Actions</th>}
          </tr>
        </thead>

        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="6" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {appSlidersList && appSlidersList.length > 0 ? (
                appSlidersList.map((slider, index) => <SortableTableRow key={index} slider={slider} index={index} serialNo={index} />)
              ) : (
                <tr>
                  <td colSpan="6" className="text-center pt-5">
                    No slider found!
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-8">
            <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li class="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {sliderType === "academy" ? "Academy" : "Workout Creator"} Sliders
              </li>
            </ol>
          </div>
          <div className="col-4">
            {(hasPermission("add-academy-slider") || hasPermission("add-workout-creator-slider")) && (
              <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={(e) => modalHandler()}>
                Add New Slider
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  {tableDataLoading ? (
                    <div className="text-center py-5">
                      <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                    </div>
                  ) : (
                    <>
                      <SortableTable appSlidersList={appSlidersList} onSortEnd={onSortEnd} useDragHandle={true} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="add" style={{ overflowY: "auto" }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="sliderModalCloseButton">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="row px-3">
                  <div className="form-group col-md-6">
                    <label>Title</label>
                    <input type="text" className="form-control" placeholder="Slider Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                    {/* {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>} */}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Button text</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Button Text"
                      value={btnText}
                      onChange={(e) => setBtnText(e.target.value)}
                    />
                    {/* {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>} */}
                  </div>
                </div>
                <div className="form-group px-3">
                  <label>Description</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter Description"
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  {/* {errors.description && !description && <div className="text-warning font-size-12">{errors.description}</div>} */}
                </div>
                {sliderType === "academy" && (
                  <>
                    {" "}
                    <div className="row px-3">
                      <div className="col-6 col-md-6 form-group px-3">
                        <label>Slider For</label>
                        <Select
                          name="bannerFor"
                          value={bannerForOptions?.find((obj) => obj?.value === bannerFor)}
                          options={bannerForOptions}
                          onChange={(selected) => setBannerFor(selected?.value)}
                          className="basic-single form-control"
                          classNamePrefix="select"
                        />
                        {/* {errors.color && !color && <div className="text-warning font-size-12">{errors.color}</div>} */}
                      </div>
                      <div className="col-6 col-md-6 form-group px-3">
                        <label>Select link for</label>
                        <Select
                          name="linkType"
                          value={linkTypeOptions?.find((obj) => obj?.value === linkType)}
                          options={linkTypeOptions}
                          onChange={(selected) => {
                            setLinkType(selected?.value);
                            setRouteName("Payment");

                            setProgramCategoryId(null);
                            setProgramId(null);
                            setProgramWeekId(null);
                            setProgramDayId(null);

                            //lecture states
                            setLectureCategoryId(null);
                            setLectureSubcategoryId(null);
                            setLectureId(null);

                            //Workout states
                            setWorkoutCategoryId(null);
                            setWorkoutSubcategoryId(null);
                            setWorkoutId(null);
                          }}
                          className="basic-single form-control"
                          classNamePrefix="select"
                        />
                        {/* {errors.color && !color && <div className="text-warning font-size-12">{errors.color}</div>} */}
                      </div>
                    </div>
                    {linkType !== "Payment" && linkType && (
                      <>
                        <div className="col-12">
                          <div className="form-group">
                            <label>Select {linkType} Category</label>
                            <Select
                              name="sliderLink"
                              value={selectedOption && routeLinkOptions?.find((obj) => obj?.value === selectedOption)}
                              options={routeLinkOptions}
                              onChange={(selected) => handleSelectCategoryOption(selected)}
                              className="basic-single form-control"
                              classNamePrefix="select"
                              grouped
                              styles={{
                                option: (provided) => ({
                                  ...provided,
                                  paddingLeft: "30px",
                                }),
                                groupHeading: (provided) => ({
                                  ...provided,
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                }),
                              }}
                            />
                          </div>
                        </div>
                        {linkType === "Program" ? (
                          <>
                            <div className="col-12">
                              <div className="form-group">
                                <label>Select Program</label>
                                <Select
                                  name="sliderLink"
                                  value={programId && programsListOptions?.find((obj) => obj?.value === selectedOption)}
                                  options={programsListOptions}
                                  onChange={(selected) => getProgramWeeksList(selected?.value)}
                                  className="basic-single form-control"
                                  classNamePrefix="select"
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label>Select Week</label>
                                <Select
                                  name="sliderLink"
                                  value={programWeekId && programWeeksListOptions?.find((obj) => obj?.value === selectedOption)}
                                  options={programWeeksListOptions}
                                  onChange={(selected) => getProgramWeekDaysList(selected?.value)}
                                  className="basic-single form-control"
                                  classNamePrefix="select"
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label>Select Day</label>
                                <Select
                                  name="sliderLink"
                                  value={programDayId && programWeekDaysListOptions?.find((obj) => obj?.value === selectedOption)}
                                  options={programWeekDaysListOptions}
                                  onChange={(selected) => {
                                    setProgramDayId(selected?.value);
                                    setRouteName("ProgramWeekDay");
                                    setRouteId(selected?.value);
                                  }}
                                  className="basic-single form-control"
                                  classNamePrefix="select"
                                />
                              </div>
                            </div>
                          </>
                        ) : linkType === "Lecture" ? (
                          <>
                            <div className="col-12">
                              <div className="form-group">
                                <label>Select Subcategory</label>
                                <Select
                                  name="sliderLink"
                                  value={lectureSubcategoryId && lectureSubcategoriesListOptions?.find((obj) => obj?.value === selectedOption)}
                                  options={lectureSubcategoriesListOptions}
                                  onChange={(selected) => getLectures(selected?.value)}
                                  className="basic-single form-control"
                                  classNamePrefix="select"
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label>Select Lecture</label>
                                <Select
                                  name="sliderLink"
                                  value={lectureId && lecturesListOptions?.find((obj) => obj?.value === selectedOption)}
                                  options={lecturesListOptions}
                                  onChange={(selected) => {
                                    setLectureId(selected?.value);
                                    setRouteName("Lecture");
                                    setRouteId(selected?.value);
                                  }}
                                  className="basic-single form-control"
                                  classNamePrefix="select"
                                />
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-12">
                              <div className="form-group">
                                <label>Select Subcategory</label>
                                <Select
                                  name="sliderLink"
                                  value={workoutSubcategoryId && workoutSubcategoriesListOptions?.find((obj) => obj?.value === selectedOption)}
                                  options={workoutSubcategoriesListOptions}
                                  onChange={(selected) => getWorkouts(selected?.value)}
                                  className="basic-single form-control"
                                  classNamePrefix="select"
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label>Select Workout</label>
                                <Select
                                  name="sliderLink"
                                  value={workoutId && workoutsListOptions?.find((obj) => obj?.value === selectedOption)}
                                  options={workoutsListOptions}
                                  onChange={(selected) => {
                                    setWorkoutId(selected?.value);
                                    setRouteName("Workout");
                                    setRouteId(selected?.value);
                                  }}
                                  className="basic-single form-control"
                                  classNamePrefix="select"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                <div className="col-md-4">
                  <div className="d-flex align-items-center mb-3">
                    <input
                      className="mr-2 mb-1"
                      type="checkbox"
                      name="allow-score"
                      id="allow-score"
                      checked={isClickAble}
                      onChange={(e) => setIsClickAble(e.target.checked)}
                    />
                    <label className="mb-0" for="allow-score" style={{ cursor: "pointer" }}>
                      Is clickable
                    </label>
                  </div>
                </div>
                <div className="form-group px-3">
                  <label>Image</label>
                  <br />
                  {image && (
                    <>
                      <img src={process.env.REACT_APP_MEDIAPATH + image} className="img-fluid img-thumbnail" width="150" />
                      <br />
                    </>
                  )}
                  <button type="button" className="btn btn-primary mt-3" data-toggle="modal" data-target="#media">
                    Select Image
                  </button>
                  {errors.image && !image && <div className="text-warning font-size-12 mt-2">{errors.image}</div>}
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Media setImageHandler={setImageHandler} />
    </>
  );
};

export default AcademySliders;
