import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { addMedia, deleteMediaPermanently, getMedia, remove_session } from "../functions/general";
import { toast } from "react-toastify";
import PDFIcon from "../images/PDF_file_icon.png";
import MP3Icon from "../images/MP3_icon.png";
import { __esModule } from "react-metismenu/lib/actions/emitters";

function Media(props) {
  const navigate = useNavigate();
  const [media, setMedia] = React.useState([]);
  const [type, setType] = React.useState("all");
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getMedia().then((res) => {
      if (res && res.status === 200) {
        setLoading(false);
        setMedia(res.data);
      } else if (res && res.status === 203) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setLoading(false);
    });
  };

  const imageUploadHandler = (e) => {
    setLoading(true);
    var file = e.target.files[0];
    addMedia(file).then((res) => {
      if (res && res.status === 201) {
        fetchData();
        toast.success("Media added successfully.");
      } else if (res && res.status === 203) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to add media.");
      }
      setLoading(false);
    });
  };

  const deleteHandler = (e, media) => {
    setLoading(true);
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete media?") == true) {
      deleteMediaPermanently(media?._id).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("Media deleted successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete media.");
        }
        setLoading(false);
      });
    } else setLoading(false);
  };

  return (
    <>
      <div className="modal fade" id={props?.id ? props?.id : "media"}>
        <div className="modal-dialog" role="document">
          <div className="modal-content p-3">
            <div className="modal-header border-0 px-3 mb-3">
              <h5 className="modal-title">Media</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                id={props?.id ? props?.id + "mediaModalCloseButton" : "mediaModalCloseButton"}
              >
                <span>&times;</span>
              </button>
            </div>

            <div className="row">
              <div className="col-6">
                <button className={type === "all" ? "btn btn-primary mr-3" : "btn btn-light mr-3"} onClick={() => setType("all")}>
                  All
                </button>
                <button className={type === "pdf" ? "btn btn-primary mr-3" : "btn btn-light mr-3"} onClick={() => setType("pdf")}>
                  PDFs
                </button>
                <button className={type === "img" ? "btn btn-primary" : "btn btn-light mr-3"} onClick={() => setType("img")}>
                  Images
                </button>
                <button className={type === "audio" ? "btn btn-primary" : "btn btn-light"} onClick={() => setType("audio")}>
                  Audio
                </button>
              </div>
              <div className="col text-right mb-5">
                <div className="form-group px-3">
                  <button className="btn btn-primary float-right" onClick={() => document.getElementById("mediaInput").click()}>
                    Upload
                  </button>
                  <input
                    type="file"
                    className="d-none"
                    id="mediaInput"
                    onChange={(e) => imageUploadHandler(e)}
                    accept="image/*,application/pdf,audio/mp3,audio/wav,audio/mpeg"
                  />
                </div>
              </div>
            </div>
            {/* <div className="row" style={{ minHeight: "300px" }}>
              {loading ? (
                <div className="col-12 text-center py-5" style={{ minHeight: "400px" }}>
                  <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                </div>
              ) : media?.length > 0 ? (
                media?.map((mediaValue, mediaIndex) => {
                  let renderedItem = null;
                  let hasMatchingData = false;
                  if (type === "pdf" && mediaValue?.fileType && mediaValue?.fileType === "application/pdf") {
                    renderedItem =  (
                      <div className="col-md-2 mb-2" key={`media-${mediaIndex}`}>
                        <button
                          onClick={(e) => deleteHandler(e, mediaValue)}
                          style={{ position: "absolute", border: "none", width: "25px", height: "25px", padding: "0", right: "15px" }}
                          className="btn btn-danger shadow btn-sm"
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                        <img
                          width={"100%"}
                          src={PDFIcon}
                          className="img-fluid img-thumbnail mb-2"
                          onClick={() => {
                            props?.setImageHandler(mediaValue.key);
                            props?.setFileName && props?.setFileName(mediaValue?.fileName);
                            props?.setFileSize && props?.setFileSize(mediaValue?.fileSize);
                            document.getElementById(props?.id ? props?.id + "mediaModalCloseButton" : "mediaModalCloseButton").click();
                          }}
                        />
                        <span style={{ wordBreak: "break-all" }}>{mediaValue?.fileName}</span>
                      </div>
                    );
                    hasMatchingData = true;
                  } else if (type === "img" && mediaValue?.fileType && mediaValue?.fileType?.includes("image")) {
                    renderedItem = (
                      <div className="col-md-2 mb-2" key={`media-${mediaIndex}`}>
                        <button
                          onClick={(e) => deleteHandler(e, mediaValue)}
                          style={{ position: "absolute", border: "none", width: "25px", height: "25px", padding: "0", right: "15px" }}
                          className="btn btn-danger shadow btn-sm"
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                        <img
                          src={process.env.REACT_APP_MEDIAPATH + mediaValue.key}
                          className="img-fluid img-thumbnail mb-2"
                          onClick={() => {
                            props?.setImageHandler(mediaValue.key);
                            props?.setFileName && props?.setFileName(mediaValue?.fileName);
                            document.getElementById(props?.id ? props?.id + "mediaModalCloseButton" : "mediaModalCloseButton").click();
                          }}
                        />
                        <span style={{ wordBreak: "break-all" }}>{mediaValue?.fileName}</span>
                      </div>
                    );
                    hasMatchingData = true;
                  } else if (type === "all") {
                    renderedItem = (
                      <div className="col-md-2 mb-2" key={`media-${mediaIndex}`}>
                        <button
                          onClick={(e) => deleteHandler(e, mediaValue)}
                          className="btn btn-danger shadow btn-sm"
                          style={{ position: "absolute", border: "none", width: "25px", height: "25px", padding: "0", right: "15px" }}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                        {mediaValue?.key?.substr(mediaValue?.key?.length - 4) === ".pdf" ? (
                          <img
                            width={"100%"}
                            src={PDFIcon}
                            className="img-fluid img-thumbnail mb-2"
                            onClick={() => {
                              props?.setImageHandler(mediaValue.key);
                              props?.setFileName && props?.setFileName(mediaValue?.fileName);
                              props?.setFileSize && props?.setFileSize(mediaValue?.fileSize);
                              document.getElementById(props?.id ? props?.id + "mediaModalCloseButton" : "mediaModalCloseButton").click();
                            }}
                          />
                        ) : (
                          <img
                            src={process.env.REACT_APP_MEDIAPATH + mediaValue.key}
                            className="img-fluid img-thumbnail mb-2"
                            onClick={() => {
                              props?.setImageHandler(mediaValue.key);
                              props?.setFileName && props?.setFileName(mediaValue?.fileName);
                              document.getElementById(props?.id ? props?.id + "mediaModalCloseButton" : "mediaModalCloseButton").click();
                            }}
                          />
                        )}
                        <span style={{ wordBreak: "break-all" }}>{mediaValue?.fileName}</span>
                      </div>
                    );
                    hasMatchingData = true;
                  }
                  return renderedItem;
                })
              ) : (
                <span className="text-center mt-5 w-100" style={{ fontSize: "18px", fontWeight: 500 }}>No Data Found!</span>
              )}
            </div> */}

            <div className="row" style={{ minHeight: "300px" }}>
              {loading ? (
                <div className="col-12 text-center py-5" style={{ minHeight: "300px" }}>
                  <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                </div>
              ) : (
                (() => {
                  let hasMatchingData = false;
                  const renderedMedia = media?.map((mediaValue, mediaIndex) => {
                    let renderedItem = null;
                    if (type === "pdf" && mediaValue?.fileType === "application/pdf") {
                      renderedItem = (
                        <div className="col-md-2 mb-2" key={`media-${mediaIndex}`}>
                          <button
                            onClick={(e) => deleteHandler(e, mediaValue)}
                            style={{ position: "absolute", border: "none", width: "25px", height: "25px", padding: "0", right: "15px" }}
                            className="btn btn-danger shadow btn-sm"
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                          <img
                            width={"100%"}
                            src={PDFIcon}
                            className="img-fluid img-thumbnail mb-2"
                            onClick={() => {
                              props?.setImageHandler(mediaValue.key);
                              props?.setFileName && props?.setFileName(mediaValue?.fileName);
                              props?.setFileSize && props?.setFileSize(mediaValue?.fileSize);
                              document.getElementById(props?.id ? props?.id + "mediaModalCloseButton" : "mediaModalCloseButton").click();
                            }}
                          />
                          <span style={{ wordBreak: "break-all" }}>{mediaValue?.fileName}</span>
                        </div>
                      );
                      hasMatchingData = true;
                    } else if (type === "img" && mediaValue?.fileType?.includes("image")) {
                      renderedItem = (
                        <div className="col-md-2 mb-2" key={`media-${mediaIndex}`}>
                          <button
                            onClick={(e) => deleteHandler(e, mediaValue)}
                            style={{ position: "absolute", border: "none", width: "25px", height: "25px", padding: "0", right: "15px" }}
                            className="btn btn-danger shadow btn-sm"
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                          <img
                            src={process.env.REACT_APP_MEDIAPATH + mediaValue.key}
                            className="img-fluid img-thumbnail mb-2"
                            onClick={() => {
                              props?.setImageHandler(mediaValue.key);
                              props?.setFileName && props?.setFileName(mediaValue?.fileName);
                              document.getElementById(props?.id ? props?.id + "mediaModalCloseButton" : "mediaModalCloseButton").click();
                            }}
                          />
                          <span style={{ wordBreak: "break-all" }}>{mediaValue?.fileName}</span>
                        </div>
                      );
                      hasMatchingData = true;
                    } else if (type === "audio" && mediaValue?.fileType?.includes("audio")) {
                      renderedItem = (
                        <div className="col-md-2 mb-2" key={`media-${mediaIndex}`}>
                          <button
                            onClick={(e) => deleteHandler(e, mediaValue)}
                            style={{ position: "absolute", border: "none", width: "25px", height: "25px", padding: "0", right: "15px" }}
                            className="btn btn-danger shadow btn-sm"
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                          <img
                            src={MP3Icon}
                            className="img-fluid img-thumbnail mb-2"
                            onClick={() => {
                              props?.setImageHandler(mediaValue.key);
                              props?.setFileName && props?.setFileName(mediaValue?.fileName);
                              document.getElementById(props?.id ? props?.id + "mediaModalCloseButton" : "mediaModalCloseButton").click();
                            }}
                          />
                          <span style={{ wordBreak: "break-all" }}>{mediaValue?.fileName}</span>
                        </div>
                      );
                      hasMatchingData = true;
                    } else if (type === "all") {
                      renderedItem = (
                        <div className="col-md-2 mb-2" key={`media-${mediaIndex}`}>
                          <button
                            onClick={(e) => deleteHandler(e, mediaValue)}
                            className="btn btn-danger shadow btn-sm"
                            style={{ position: "absolute", border: "none", width: "25px", height: "25px", padding: "0", right: "15px" }}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                          {mediaValue?.key?.substr(mediaValue?.key?.length - 4) === ".pdf" ? (
                            <img
                              width={"100%"}
                              src={PDFIcon}
                              className="img-fluid img-thumbnail mb-2"
                              onClick={() => {
                                props?.setImageHandler(mediaValue.key);
                                props?.setFileName && props?.setFileName(mediaValue?.fileName);
                                props?.setFileSize && props?.setFileSize(mediaValue?.fileSize);
                                document.getElementById(props?.id ? props?.id + "mediaModalCloseButton" : "mediaModalCloseButton").click();
                              }}
                            />
                          ) : mediaValue?.fileType?.includes("audio") ? (
                            <img
                              width={"100%"}
                              src={MP3Icon}
                              className="img-fluid img-thumbnail mb-2"
                              onClick={() => {
                                props?.setImageHandler(mediaValue.key);
                                props?.setFileName && props?.setFileName(mediaValue?.fileName);
                                props?.setFileSize && props?.setFileSize(mediaValue?.fileSize);
                                document.getElementById(props?.id ? props?.id + "mediaModalCloseButton" : "mediaModalCloseButton").click();
                              }}
                            />
                          ) : (
                            <img
                              src={process.env.REACT_APP_MEDIAPATH + mediaValue.key}
                              className="img-fluid img-thumbnail mb-2"
                              onClick={() => {
                                props?.setImageHandler(mediaValue.key);
                                props?.setFileName && props?.setFileName(mediaValue?.fileName);
                                document.getElementById(props?.id ? props?.id + "mediaModalCloseButton" : "mediaModalCloseButton").click();
                              }}
                            />
                          )}
                          <span style={{ wordBreak: "break-all" }}>{mediaValue?.fileName}</span>
                        </div>
                      );
                      hasMatchingData = true;
                    }

                    return renderedItem;
                  });

                  if (!hasMatchingData) {
                    return (
                      <div className="col-12 text-center mt-5 w-100" style={{ fontSize: "18px", fontWeight: 500 }}>
                        No Data Found!
                      </div>
                    );
                  }

                  return renderedMedia;
                })()
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Media;
