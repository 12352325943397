import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  addLectureSubcategory,
  deleteLectureSubcategory,
  getLectureSubcategories,
  remove_session,
  restoreLectureSubcategory,
  updateLectureSubcategory,
  deleteLectureSubcategoryPermanently,
  sortLectureSubcategories,
} from "../../../functions/general";
import { toast } from "react-toastify";
import Media from "../../../components/Media";
import ModalImage from "react-modal-image";
import { usePermissions } from "../../../functions/usePermissions";

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";

function ListLectureSubcategories(props) {
  const { state } = useLocation();
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [category, setCategory] = React.useState("");
  const [subcategories, setSubcategories] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [image, setImage] = React.useState("");
  const [lectureCategoryId, setLectureCategoryId] = React.useState("");
  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [tableDataLoading, setTableDataLoading] = React.useState(true);

  const [errors, setErrors] = React.useState("");
  useEffect(() => {
    props.setPageTitleAndSubTitle("Lecture Sub catagories", "Here is the list of Subcategories !");
    fetchData();
  }, []);

  const fetchData = (type) => {
    const categoryId = searchParams.get("categoryId");
    getLectureSubcategories({ categoryId, v: 1 }).then((res) => {
      if (res && res.status == 201) {
        setSubcategories(res?.data);
        if (type === "add") {
          let sortedArray = [];
          res?.data?.map((item) => sortedArray.push(item?._id));
          sortLectureSubcategories(sortedArray).then((res) => {
            if (res && res.status == 200) {
              // fetchData();
            } else if (res && res.status == 401) {
              remove_session();
              props.getLoggedInUser();
              navigate("/login");
            } else {
              toast.error("Failed to sort the list.");
            }
            setTableDataLoading(false);
          });
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };

  const modalHandler = (categoryId, subcategory) => {
    if (subcategory && subcategory._id) {
      setModalTitle("Update Lecture Subcategory");
      setModalButtonText("Update");
      setTitle(subcategory.title);
      setImage(subcategory.image);
      setLectureCategoryId(categoryId);
      setId(subcategory._id);
    } else {
      setModalTitle("Add New Lecture Subcategory");
      setModalButtonText("Add");
      setTitle("");
      setImage("");
      setLectureCategoryId("");
      setId("");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const subcategory = {
        title: title,
        image: image,
        category: state?.category,
      };
      let type = "add";
      if (id) {
        updateLectureSubcategory(id, subcategory).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Lecture Subcategory updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update Lecture Subcategory .");
          }
          setLoading(false);
        });
      } else {
        addLectureSubcategory(subcategory).then((res) => {
          if (res && res.status == 201) {
            fetchData(type);
            toast.success("Lecture subcategory added successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else if (res && res.status == 400) {
            res.message.map((message) => {
              toast.error(message);
            });
          } else {
            toast.error("Failed to add lecture subcategory.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      image: "",
    };
    let isValid = true;
    if (!title) {
      isValid = false;
      errors.title = "Please enter title.";
    }
    if (!image) {
      isValid = false;
      errors.image = "Please select image.";
    }

    // if (!lectureCategoryId) {
    //     isValid = false;
    //     errors.lectureCategoryId = "Please select category.";
    // }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, subcategory) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete lecture subcategory?") == true) {
      if (window.confirm("Are you sure you want to delete lecture subcategory permanently?") == true) {
        deleteLectureSubcategoryPermanently(subcategory._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Lecture subcategory deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete lecture subcategory.");
          }
        });
      }
    }
  };

  const deleteStatusHandler = (e, subcategory) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the lecture subcategory?";
    if (subcategory.isDeleted) {
      confirmText = "Are you sure you want to active the lecture subcategory?";
    }
    if (window.confirm(confirmText) == true) {
      if (subcategory.isDeleted) {
        restoreLectureSubcategory(subcategory._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Lecture subcategory activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active lecture subcategory.");
          }
        });
      } else {
        deleteLectureSubcategory(subcategory._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Lecture subcategory deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive lecture subcategory.");
          }
        });
      }
    }
  };

  const setImageHandler = (image) => {
    setImage(image);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    const updatedLectureSubCategories = arrayMove(subcategories, oldIndex, newIndex);
    let sortedArray = [];
    updatedLectureSubCategories?.map((subCategory) => sortedArray.push(subCategory?._id));
    sortLectureSubcategories(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setSubcategories(updatedLectureSubCategories);
        // fetchData();
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ subcategory, serialNo }) => {
    return (
      <tr>
        <td>{serialNo + 1}</td>
        <td>
          <div className="maxWidth70px">
            <ModalImage small={process.env.REACT_APP_MEDIAPATH + subcategory.image} large={process.env.REACT_APP_MEDIAPATH + subcategory.image} />
          </div>
        </td>
        <td>{subcategory.title}</td>
        {/* <td>{categoryValue.title}</td> */}
        {hasPermission("view-lecture-videos") && (
          <td>
            <Link
              to={`/lecture-subcategory-videos?subcategoryId=${subcategory._id}&subcategoryName=${subcategory.title}`}
              state={{
                category: state?.category,
                subcategory: { _id: subcategory._id, isDeleted: subcategory?.isDeleted, title: subcategory?.title },
              }}
              className="btn btn-dark btn-xs"
            >
              {subcategory?.count}
            </Link>
          </td>
        )}
        {hasPermission("edit-lecture-subcategory") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={!subcategory.isDeleted} onClick={(e) => deleteStatusHandler(e, subcategory)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {(hasPermission("edit-lecture-subcategory") || hasPermission("delete-lecture-subcategory")) && (
          <td>
            <div className="d-flex">
              {hasPermission("edit-lecture-subcategory") && (
                <button
                  data-toggle="modal"
                  data-target="#add"
                  onClick={() => modalHandler(subcategory?._id, subcategory)}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {hasPermission("delete-lecture-subcategory") && (
                <button onClick={(e) => deleteHandler(e, subcategory)} className="btn btn-danger shadow btn-sm sharp">
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </td>
        )}
        {hasPermission("edit-lecture-subcategory") && (
          <td>
            <DragHandle />
          </td>
        )}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ subcategories }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Image</th>
            <th>Title</th>
            {/* <th>Category</th> */}
            {hasPermission("view-lecture-videos") && <th>Lectures</th>}
            {hasPermission("edit-lecture-subcategory") && <th>Status</th>}
            {(hasPermission("edit-lecture-subcategory") || hasPermission("delete-lecture-subcategory")) && <th>Actions</th>}
            {hasPermission("edit-lecture-subcategory") && <th>Sort Order</th>}
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="7" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {subcategories?.length > 0 ? (
                subcategories?.map((subcategory, index) => {
                  return <SortableTableRow key={index} subcategory={subcategory} index={index} serialNo={index} />;
                })
              ) : (
                <tr>
                  <td colSpan="8" className="text-center pt-5">
                    No lecture subcategory found!
                  </td>
                </tr>
              )}
              {/* {categories.length > 0 && (
                <>
                  {categories.map((categoryValue) => {
                    if (category && category._id != categoryValue._id) {
                      return;
                    }
                    if (categoryValue.subcategories.length > 0) {
                      return (
                        categoryValue.subcategories.length > 0 &&
                        categoryValue.subcategories.map((subcategory, index) => {
                          tempCounter++;
                          return <SortableTableRow key={index} subcategory={subcategory} index={index} categoryValue={categoryValue} />;
                        })
                      );
                    } else {
                      return (
                        <tr>
                          <td colSpan={8} className="text-center pt-5">
                            There is no record found.
                          </td>
                        </tr>
                      );
                    }
                  })}
                </>
              )} */}
            </>
          )}
        </tbody>
      </table>
    );
  });
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-8">
            <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li class="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li class="breadcrumb-item">
                <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate(-1)}>
                  Lecture Categories
                </span>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {state?.category?.title}
              </li>
            </ol>
          </div>
          <div className="col-4">
            {hasPermission("add-lecture-subcategory") && (
              <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={() => modalHandler()}>
                Add New
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <SortableTable subcategories={subcategories} onSortEnd={onSortEnd} useDragHandle={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="add">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="form-group px-3">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Lecture Subcategory Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <div className="text-warning font-size-12">{errors.title}</div>
                </div>
                <div className="form-group px-3">
                  <label>Image</label>
                  <br />
                  {image && <img src={process.env.REACT_APP_MEDIAPATH + image} className="img-fluid img-thumbnail" width="150" />}
                  <br />
                  <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#media">
                    Select Image
                  </button>
                  <div className="text-warning font-size-12">{errors.image}</div>
                </div>
                {/* <div className="form-group px-3">
                                    <label>Category</label>
                                    <select className="form-control"
                                            value={lectureCategoryId}
                                            onChange={(e) => setLectureCategoryId(e.target.value)}>
                                        <option value="">Select Category</option>
                                        {categories.length > 0 && categories.map((categoryValue) => {
                                            return (
                                                <option value={categoryValue._id}>{categoryValue.title}</option>
                                            );
                                        })}
                                    </select>
                                    <div className="text-warning font-size-12">{errors.lectureCategoryId}</div>
                                </div> */}
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Media setImageHandler={setImageHandler} />
      </div>
    </>
  );
}

export default ListLectureSubcategories;
