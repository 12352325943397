import React, { useEffect } from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {
    addFilter, deleteFilter, deleteFilterPermanently,
    getFilters,
    remove_session, restoreFilter, updateFilter
} from "../../../functions/general";
import { toast } from 'react-toastify';
import Media from "../../../components/Media";
import ModalImage from "react-modal-image";

function ListFilters(props) {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [categories, setCategories] = React.useState([]);
    const [category, setCategory] = React.useState("");
    const [filters, setFilters] = React.useState([]);
    const [modalTitle, setModalTitle] = React.useState("");
    const [modalButtonText, setModalButtonText] = React.useState("");
    const [value, setValue] = React.useState("");
    const [label, setLabel] = React.useState("");
    const [image, setImage] = React.useState("");
    const [filterCategoryId, setFilterCategoryId] = React.useState("");
    const [id, setId] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState("");
    useEffect(() => {
        props.setPageTitleAndSubTitle("Filters", "Here is the list of Filters !");
        fetchData();
    }, [])

    const fetchData = () => {
        const categoryId = searchParams.get('categoryId')
        let tempFilters = []
        getFilters().then(res => {
            if (res && res.status == 200) {
                if(res.data && res.data.length) {
                    res.data.map((categoryValue) => {
                        if(categoryId && categoryValue._id == categoryId){
                            setCategory(categoryValue);
                            props.setPageTitleAndSubTitle("Filters (" + categoryValue.title + ")", "Here is the list of Filters !");
                        }
                        categoryValue.filters.length > 0 && categoryValue.filters.map((filter) => {
                            tempFilters.push(filter)
                        })
                    })
                }
                setCategories(res.data);
                setFilters(tempFilters);
            } else if (res && res.status == 401) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
            }
        })
    }

    const modalHandler = (categoryId, filter) => {
        if(filter && filter._id){
            setModalTitle("Update Filter")
            setModalButtonText("Update")
            setValue(filter.value)
            setLabel(filter.label)
            setImage(filter.image)
            setFilterCategoryId(categoryId)
            setId(filter._id)
        } else {
            setModalTitle("Add New Filter")
            setModalButtonText("Add")
            setValue("")
            setLabel("")
            setImage("")
            setFilterCategoryId("")
            setId("")
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            let filterCategory = ""
            categories.map((categoryValue) => {
                if(categoryValue._id == filterCategoryId){
                    filterCategory = {
                        isDeleted: categoryValue.isDeleted,
                        _id: categoryValue._id,
                        title: categoryValue.title
                    };
                }
            })
            const filter = {
                value: value,
                label: label,
                image: image,
                category: filterCategory
            }
            if(id){
                updateFilter(id, filter).then(res => {
                    if (res && res.status == 200) {
                        fetchData();
                        toast.success("Filter updated successfully.");
                        document.getElementById('categoryModalCloseButton').click();
                    } else if (res && res.status == 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate('/login');
                    } else {
                        toast.error("Failed to update filter.");
                    }
                    setLoading(false)
                })
            } else {
                addFilter(filter).then(res => {
                    if (res && res.status == 201) {
                        fetchData();
                        toast.success("Filter added successfully.");
                        document.getElementById('categoryModalCloseButton').click();
                    } else if (res && res.status == 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate('/login');
                    } else {
                        toast.error("Failed to add filter.");
                    }
                    setLoading(false)
                })
            }
        }
    }

    const validate = () => {
        let errors = {
            value: '',
            label: '',
            image: '',
        };
        let isValid = true;
        if (!value) {
            isValid = false;
            errors.value = "Please enter value.";
        }
        if (!label) {
            isValid = false;
            errors.label = "Please enter label.";
        }
        if (!image) {
            isValid = false;
            errors.image = "Please select image.";
        }
        if (!filterCategoryId) {
            isValid = false;
            errors.filterCategoryId = "Please select category.";
        }
        setErrors(errors);
        return isValid;
    }

    const deleteHandler = (e, filter) => {
        e.preventDefault()
        if (window.confirm("Are you sure you want to delete filter?") == true) {
            if (window.confirm("Are you sure you want to delete filter permanently?") == true) {
                deleteFilterPermanently(filter._id).then(res => {
                    if (res && res.status == 200) {
                        fetchData();
                        toast.success("Filter deleted successfully.");
                    } else if (res && res.status == 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate('/login');
                    } else {
                        toast.error("Failed to delete filter.");
                    }
                })
            }
        }
    }

    const deleteStatusHandler = (e, filter) => {
        e.preventDefault()
        let confirmText = "Are you sure you want to in-active the filter?"
        if(filter.isDeleted){
            confirmText = "Are you sure you want to active the filter?"
        }
        if (window.confirm(confirmText) == true) {
            if(filter.isDeleted) {
                restoreFilter(filter._id).then(res => {
                    if (res && res.status == 200) {
                        fetchData();
                        toast.success("Filter activated successfully.");
                    } else if (res && res.status == 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate('/login');
                    } else {
                        toast.error("Failed to active filter.");
                    }
                })
            }else{
                deleteFilter(filter._id).then(res => {
                    if (res && res.status == 200) {
                        fetchData();
                        toast.success("Filter deactivated successfully.");
                    } else if (res && res.status == 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate('/login');
                    } else {
                        toast.error("Failed to inactive filter.");
                    }
                })
            }
        }
    }

    const setImageHandler = (image) => {
        console.log("image",image)
        setImage(image);
    }

    let tempCounter = 0;
  return (
      <>
          <div className="container-fluid">
              <div className="row mb-4">
                  <div className="col-12">
                      <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={() => modalHandler()}>Add New
                      </button>
                  </div>
              </div>
              <div className="row">
                  <div className="col-12">
                      <div className="card">
                          <div className="card-body">
                              <div className="table-responsive">
                                  <table id="roles-table" className="display dataTable" style={{minWidth: "845px"}}>
                                      <thead>
                                      <tr>
                                          <th>ID</th>
                                          <th>Image</th>
                                          <th>Name</th>
                                          <th>Category</th>
                                          <th>Videos</th>
                                          <th>Status</th>
                                          <th>Actions</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      { categories.length > 0 && <>
                                          {categories.map((categoryValue) => {
                                              if(category && category._id != categoryValue._id){
                                                return;
                                              }
                                              return (categoryValue.filters.length > 0 && categoryValue.filters.map((filter) => {
                                                  tempCounter++;
                                                  return (
                                                      <tr>
                                                          <td>{tempCounter}</td>
                                                          <td>
                                                              <div className="maxWidth70px">
                                                                  <ModalImage
                                                                      small={process.env.REACT_APP_MEDIAPATH + filter.image}
                                                                      large={process.env.REACT_APP_MEDIAPATH + filter.image}
                                                                  />
                                                              </div>
                                                          </td>
                                                          <td>{filter.label}</td>
                                                          <td>{categoryValue.title}</td>
                                                          <td>
                                                              <Link to={`/videos?filterId=${filter._id}&filterName=${filter.label}`} className="btn btn-dark btn-xs">
                                                                  {filter.count}
                                                              </Link>
                                                          </td>
                                                          <td>
                                                              <label className="switch">
                                                                  <input type="checkbox" checked={!filter.isDeleted} onClick={e => deleteStatusHandler(e, filter)}/>
                                                                      <span className="slider round"></span>
                                                              </label>
                                                          </td>
                                                          <td>
                                                              <div className="d-flex">
                                                                  <button data-toggle="modal" data-target="#add" onClick={() => modalHandler(categoryValue._id, filter)}
                                                                          className="btn btn-primary shadow btn-sm sharp mr-1"><i
                                                                      className="fa fa-pencil"></i></button>
                                                                  <button onClick={e => deleteHandler(e, filter)} className="btn btn-danger shadow btn-sm sharp"><i
                                                                      className="fa fa-trash"></i></button>
                                                              </div>
                                                          </td>
                                                      </tr>
                                                  );
                                              }))
                                          })}
                                      </>}
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="modal fade" id="add">
                  <div className="modal-dialog" role="document">
                      <div className="modal-content">
                          <div className="modal-header border-0 px-3 mb-3">
                              <h5 className="modal-title">{modalTitle}</h5>
                              <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton"><span>&times;</span>
                              </button>
                          </div>
                          <form noValidate onSubmit={onSubmit}>
                              <div className="form-group px-3">
                                  <label>Label</label>
                                  <input type="text" className="form-control" placeholder="Filter Label"
                                         value={label} onChange={(e) => setLabel(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.label}</div>
                              </div>
                              <div className="form-group px-3">
                                  <label>Value</label>
                                  <input type="text" className="form-control" placeholder="Filter Value"
                                         value={value} onChange={(e) => setValue(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.value}</div>
                              </div>
                              <div className="form-group px-3">
                                  <label>Image</label>
                                  <br />
                                  {image && <img src={process.env.REACT_APP_MEDIAPATH + image} className="img-fluid img-thumbnail" width="150"/>}
                                  <br />
                                  <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#media">Select Image
                                  </button>
                                  <div className="text-warning font-size-12">{errors.image}</div>
                              </div>
                              <div className="form-group px-3">
                                  <label>Category</label>
                                  <select className="form-control"
                                          value={filterCategoryId} onChange={(e) => setFilterCategoryId(e.target.value)}>
                                      <option value="">Select Category</option>
                                      {categories.length > 0 && categories.map((categoryValue) => {
                                          return (
                                              <option value={categoryValue._id}>{categoryValue.title}</option>
                                          );
                                      })}
                                  </select>
                                  <div className="text-warning font-size-12">{errors.filterCategoryId}</div>
                              </div>
                              <div className="modal-footer border-0">
                                  <button type="submit" className="btn btn-primary">{modalButtonText}</button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
              <Media setImageHandler={setImageHandler} />
          </div>
      </>
  );
}

export default ListFilters;
