import React from "react";

const PaymentFailed = () => {
  return (
    <div className="subscriptions-list-wrapper" style={{ background: "#0F1217", minHeight: "125vh", fontFamily: "Poppins", padding: "24px" }}>
      <div className="d-flex justify-content-center align-items-center vh-100 mt-5">
        <h1 className='text-white text-center'>Your payment was Failed!</h1>
      </div>
    </div>
  );
};

export default PaymentFailed;
