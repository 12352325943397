import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  remove_session,
  getAllPermissionTypes,
  updatePermissionType,
  addPermissionType,
  deletePermissionTypePermanently,
  restorePermissionType,
  deletePermissionType,
  sortPermissionTypesList,
} from "../../../functions/general";
import { toast } from "react-toastify";
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";

const PermissionTypes = (props) => {
  const navigate = useNavigate();
  const [permissionTypesList, setPermissionTypesList] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState("");
  const [tableDataLoading, setTableDataLoading] = React.useState(true);

  let { state } = useLocation();

  useEffect(() => {
    props.setPageTitleAndSubTitle("Permission Types", "Here is the list of Permission Types!");
    fetchData();
  }, []);

  const fetchData = () => {
    getAllPermissionTypes().then((res) => {
      if (res && res.status == 200) {
        if (res.data && res.data.length) {
          setPermissionTypesList(res?.data);
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };
  const modalHandler = (permissionType) => {
    if (permissionType && permissionType._id) {
      setModalTitle("Update Permission Type");
      setModalButtonText("Update");
      setTitle(permissionType?.name);
      setId(permissionType?._id);
    } else {
      setModalTitle("Add New Permission Type");
      setModalButtonText("Add");
      setTitle("");
      setId("");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const permissionTypeData = {
        name: title,
      };
      if (id) {
        updatePermissionType(id, permissionTypeData).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Permission type updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update Permission type.");
          }
          setLoading(false);
        });
      } else {
        addPermissionType(permissionTypeData).then((res) => {
          if (res && res.status == 201) {
            fetchData();
            toast.success("Permission type added successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add Permission type.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
    };
    let isValid = true;
    if (!title) {
      isValid = false;
      errors.title = "Please enter permission type name";
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, permissionType) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete permission type?") == true) {
      deletePermissionTypePermanently(permissionType?._id).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("Permission type deleted successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete permission type.");
        }
      });
    }
  };

  const deleteStatusHandler = (e, permissionType) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active permission type?";
    if (permissionType?.isDeleted) {
      confirmText = "Are you sure you want to active permission type?";
    }
    if (window.confirm(confirmText) == true) {
      if (permissionType?.isDeleted) {
        restorePermissionType(permissionType._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Permission type activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active permission type.");
          }
        });
      } else {
        deletePermissionType(permissionType?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Permission type deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive permission type.");
          }
        });
      }
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    const updatedPermissionTypesList = arrayMove(permissionTypesList, oldIndex, newIndex);
    let sortedArray = [];
    updatedPermissionTypesList?.map((programCategory) => sortedArray.push(programCategory?._id));
    sortPermissionTypesList(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setPermissionTypesList(updatedPermissionTypesList);
        // fetchData();
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ permissionType, serialNo }) => {
    return (
      <tr style={{ width: "100%" }}>
        <td>{serialNo + 1}</td>
        <td>{permissionType?.name}</td>
        <td>
          <label className="switch">
            <input type="checkbox" checked={!permissionType?.isDeleted} onClick={(e) => deleteStatusHandler(e, permissionType)} />
            <span className="slider round"></span>
          </label>
        </td>
        <td>
          <div className="d-flex">
            <button
              data-toggle="modal"
              data-target="#add"
              onClick={(e) => modalHandler(permissionType)}
              className="btn btn-primary shadow btn-sm sharp mr-1"
            >
              <i className="fa fa-pencil"></i>
            </button>
            <button onClick={(e) => deleteHandler(e, permissionType)} className="btn btn-danger shadow btn-sm sharp">
              <i className="fa fa-trash"></i>
            </button>
          </div>
        </td>
        <td>
          <DragHandle />
        </td>
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ permissionTypesList }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>Status</th>
            <th>Actions</th>
            <th>Sort Order</th>
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="7" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {permissionTypesList?.length > 0 ? (
                permissionTypesList?.map((permissionType, index) => {
                  return <SortableTableRow key={index} permissionType={permissionType} index={index} serialNo={index} />;
                })
              ) : (
                <tr>
                  <td colSpan="7" className="text-center pt-5">
                    No permission type found!
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4 align-items-center">
          <div className="col-8">
            <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li class="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li class="breadcrumb-item">Permission Types</li>
            </ol>
          </div>
          <div className="col-4">
            <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={() => modalHandler()}>
              Add New
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <SortableTable permissionTypesList={permissionTypesList} onSortEnd={onSortEnd} useDragHandle={true} />
                </div>
                {/* <div className="table-responsive">
                  <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {permissionTypesList?.length > 0 ? (
                        permissionTypesList?.map((permissionType, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{permissionType?.name}</td>
                              <td>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={!permissionType?.isDeleted}
                                    onClick={(e) => deleteStatusHandler(e, permissionType)}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                              <td>
                                <div className="d-flex">
                                  <button
                                    data-toggle="modal"
                                    data-target="#add"
                                    onClick={(e) => modalHandler(permissionType)}
                                    className="btn btn-primary shadow btn-sm sharp mr-1"
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </button>
                                  <button onClick={(e) => deleteHandler(e, permissionType)} className="btn btn-danger shadow btn-sm sharp">
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <td>No Permission Type found.</td>
                      )}
                    </tbody>
                  </table>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="add">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="form-group px-3">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter permission type name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {errors.title && !title && <div className="text-warning font-size-12">{errors.title}</div>}
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PermissionTypes;
