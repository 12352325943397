import React, { useEffect } from "react";
import { stripePaymentResponse } from "../../../functions/general";
import { useNavigate, useSearchParams } from "react-router-dom";

// console.log(params, "params");

const PaymentSuccess = () => {
  // const navigate = useNavigate();

  // const [searchParams] = useSearchParams();

  // const userToken = searchParams.get("token");
  // const origin = searchParams.get("origin");
  // const sessionId = searchParams.get("sessionId");

  // useEffect(() => {
  //   stripePaymentResponse({ sessionId, origin }, userToken).then((res) => {
  //     if ((res && res.status == 201) || res.status == 200) {
  //       if (res.data) {
  //         navigate("/payment-success");
  //       }
  //     } else if (res && res.status == 401) {
  //       navigate("/payment-failed");
  //     } else {
  //     }
  //   });
  // }, []);
  return (
    <div className="subscriptions-list-wrapper" style={{ background: "#0F1217", minHeight: "125vh", fontFamily: "Poppins", padding: "24px" }}>
      <div className="d-flex justify-content-center align-items-center vh-100 mt-5">
        <h1 className="text-white text-center">Your payment was Succesfull!</h1>
      </div>
    </div>
  );
};

export default PaymentSuccess;
