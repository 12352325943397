import React, { useEffect } from "react";
import { deleteWeekDayOverviewPermanently, getSuggestionsList, remove_session, updateWeekDayOverview } from "../functions/general";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ArrowDown from "../images/ArrowDown.svg";
import DragHandler from "./DragHandler";
import Select from "react-select";
import { usePermissions } from "../functions/usePermissions";

const SuggestionOverview = ({ item, fetchData, getLoggedInUser, type, index }) => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [isOpen, setIsopen] = React.useState(index === 0 ? true : false);
  const [errors, setErrors] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [suggestionsList, setSuggestionsList] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [selectedOptionIds, setSelectedOptionIds] = React.useState([]);

  useEffect(() => {
    getSuggestionsList().then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        const suggestions = res?.data?.map((item) => {
          return {
            label: item?.title,
            options: item?.program?.map((item) => {
              return {
                value: item?._id,
                label: item?.type === "microcycles" ? `${item?.title} (Microcycle)` : item?.title,
              };
            }),
          };
        });
        setSuggestionsList(suggestions);
      } else if (res && res.status == 401) {
        remove_session();
        navigate("/login");
      } else {
      }
    });
  }, []);

  useEffect(() => {
    if (suggestionsList) {
      const suggestionIds = item?.suggestedPrograms;

      const filteredSuggestions = suggestionsList
        .map((category) => {
          const filteredOptions = category.options.filter((option) => suggestionIds.includes(option.value));
          if (filteredOptions.length > 0) {
            return {
              label: category.label,
              options: filteredOptions,
            };
          }
          return null;
        })
        .filter(Boolean);

      // Flatten the filtered suggestions into a single array
      const suggestions = filteredSuggestions.flatMap((category) => category.options);

      setSelectedOptions(suggestions);
    }
  }, [suggestionsList?.length]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedOptionIds(selectedValues);
  };

  const handleUpdateOverviewVideo = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const programsData = {
        type: "suggestion",
        suggestedPrograms: selectedOptionIds,
        weekDay: item?.weekDay,
      };
      updateWeekDayOverview(programsData, item?._id).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("Suggested programs updated successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to update suggested programs.");
        }
        setLoading(false);
      });
    }
  };

  const deleteHandler = (e, item) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete suggested programs permanently?") == true) {
      deleteWeekDayOverviewPermanently(item._id).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("Suggested programs deleted successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete suggested programs.");
        }
      });
    }
  };
  const validate = () => {
    let errors = {
      suggestedPrograms: "",
    };
    let isValid = true;
    if (selectedOptions?.length === 0) {
      isValid = false;
      errors.suggestedPrograms = "Please select suggested programs.";
    }
    setErrors(errors);
    return isValid;
  };

  return (
    <div className="col-6 bg-white" style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", borderRadius: "10px", position: "relative" }}>
      <div
        className="py-4 text-black d-flex justify-content-between"
        style={{ fontSize: "18px", fontWeight: "500", cursor: "pointer" }}
        onClick={() => setIsopen(!isOpen)}
      >
        <span className="d-flex align-items-center" style={{ gap: "15px" }}>
          <DragHandler /> Suggested Programs
        </span>
        <img src={ArrowDown} />
      </div>
      {isOpen && (
        <div>
          <form noValidate onSubmit={handleUpdateOverviewVideo}>
            <div className="col-12">
              <div className="form-group">
                <label>Suggested Programs</label>
                <Select
                  // defaultValue={searchFiltersFilters}
                  isMulti
                  name="colors"
                  value={selectedOptions}
                  options={suggestionsList}
                  onChange={handleSelectChange}
                  className="basic-multi-select form-control"
                  classNamePrefix="select"
                  grouped
                  styles={{
                    option: (provided) => ({
                      ...provided,
                      paddingLeft: "30px", // Adjust the padding value as per your preference
                    }),
                    groupHeading: (provided) => ({
                      ...provided,
                      fontWeight: "bold",
                      fontSize: "14px",
                    }),
                  }}
                />
                {errors.suggestedPrograms && !selectedOptions?.length && (
                  <div className="text-warning font-size-12 mt-2">{errors.suggestedPrograms}</div>
                )}
              </div>
              <div className="modal-footer border-0 px-0">
                {((hasPermission("edit-day-overview") && type === "day") ||
                  (hasPermission("edit-workout-overview") && type === "workout") ||
                  type === "week") && (
                  <button type="submit" className="btn btn-primary btn-sm">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Update
                  </button>
                )}
                {((hasPermission("delete-day-overview") && type === "day") ||
                  (hasPermission("delete-workout-overview") && type === "workout") ||
                  type === "week") && (
                  <button onClick={(e) => deleteHandler(e, item)} className="btn btn-danger btn-sm">
                    Delete
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default SuggestionOverview;
