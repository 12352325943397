import React from "react";
import {addEditAdmin, get_response_or_errors, remove_session} from "../../../functions/general";
import {useNavigate} from "react-router-dom";

function AddAdmins(props) {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState("");
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [emiratesId, setEmiratesId] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [status, setStatus] = React.useState(1);
    const [errors, setErrors] = React.useState("");
    const onSubmit = (e) => {
        e.preventDefault()
        setResponse("");
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const admin = {
                name: name,
                phone: phone,
                email: email,
                password: password,
                emiratesId: emiratesId,
                country: country,
                city: city,
                state: state,
                address: address,
                status: status
            }
            addEditAdmin(admin).then(res => {
                if (res && res.status == 200) {
                    navigate("/admins");
                } else if (res && res.status == 401) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    setResponse(get_response_or_errors(res))
                    setErrors(get_response_or_errors(res))
                    setLoading(false)
                }
            })
        }
    }
    const validate = () => {
        let errors = {
            name: '',
            phone: '',
            email: '',
            password: '',
            emiratesId: '',
            country: '',
            city: '',
            state: '',
            address: '',
        };
        let isValid = true;
        if (!name) {
            isValid = false;
            errors.name = "Please enter name.";
        }
        if (!phone) {
            isValid = false;
            errors.phone = "Please enter phone.";
        }
        if (!email) {
            isValid = false;
            errors.email = "Please enter email.";
        }
        if (!password) {
            isValid = false;
            errors.password = "Please enter password.";
        }
        if (!emiratesId) {
            isValid = false;
            errors.emiratesId = "Please enter emirates id.";
        }
        if (!country) {
            isValid = false;
            errors.country = "Please enter country.";
        }
        if (!city) {
            isValid = false;
            errors.city = "Please enter city.";
        }
        if (!state) {
            isValid = false;
            errors.state = "Please enter state.";
        }
        if (!address) {
            isValid = false;
            errors.address = "Please enter address.";
        }
        setErrors(errors);
        return isValid;
    }
    return (
        <>
        <div className="col-lg-12">
          <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
              <div>
                  <h4 className="mb-3">Add Admins</h4>
              </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="card">
              <div className="bg-black-custom card-header d-flex justify-content-between rounded-top">
                  <div className="header-title">
                      <h4 className="card-title text-white">Add New Admin</h4>
                  </div>
              </div>
              <div className="card-body">
                  <form noValidate onSubmit={onSubmit}>
                      {response?.responseMessage &&
                      <div
                          className={`alert alert-${response?.responseFlag} fade show`}
                          role="alert">
                          {response?.responseMessage}
                      </div>
                      }
                      <div className="row">
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Name *</label>
                                  <input type="text" className="form-control" placeholder="Enter Name"
                                         value={name} onChange={(e) => setName(e.target.value)}/>
                                      <div className="text-warning font-size-12">{errors.name}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Phone Number *</label>
                                  <input type="text" className="form-control" placeholder="Enter Phone Number"
                                         value={phone} onChange={(e) => setPhone(e.target.value)}/>
                                      <div className="text-warning font-size-12">{errors.phone}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Email *</label>
                                  <input type="text" className="form-control" placeholder="Enter Email"
                                         value={email} onChange={(e) => setEmail(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.email}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Password *</label>
                                  <input type="password" className="form-control" placeholder="Enter Password"
                                         value={password} onChange={(e) => setPassword(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.password}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Emirates Id *</label>
                                  <input type="text" className="form-control" placeholder="Enter Emirates Id"
                                         value={emiratesId} onChange={(e) => setEmiratesId(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.emiratesId}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>Country *</label>
                                  <input type="text" className="form-control" placeholder="Enter Country"
                                         value={country} onChange={(e) => setCountry(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.country}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>City *</label>
                                  <input type="text" className="form-control" placeholder="Enter City"
                                         value={city} onChange={(e) => setCity(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.city}</div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div className="form-group">
                                  <label>State *</label>
                                  <input type="text" className="form-control" placeholder="Enter State"
                                         value={state} onChange={(e) => setState(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.state}</div>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Address *</label>
                                  <textarea className="form-control" rows="4" value={address}
                                            onChange={(e) => setAddress(e.target.value)}></textarea>
                                  <div className="text-warning font-size-12">{errors.address}</div>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="form-group">
                                  <label>Status *</label>
                                  <select className="form-control"
                                         value={status} onChange={(e) => setStatus(e.target.value)}>
                                      <option value="1">Active</option>
                                      <option value="2">Inactive</option>
                                  </select>
                                  <div className="text-warning font-size-12">{errors.state}</div>
                              </div>
                          </div>
                      </div>
                      <button type="submit" className="btn btn-primary mr-2">Add</button>
                  </form>
              </div>
          </div>
        </div>
        </>
    );
}

export default AddAdmins;
