import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";
import Select from "react-select";
import { toast } from "react-toastify";
import { createCouponCode, deleteCouponCode, getAllCustomersList, getCouponCodesList, remove_session } from "../../../../functions/general";
import { usePermissions } from "../../../../functions/usePermissions";

const PromotionsList = (props) => {
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const { state } = useLocation();

  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [codeName, setCodeName] = React.useState("");
  const [firstTimeOrderCheckbox, setFirstTimeOrderCheckbox] = React.useState(false);
  const [specficCustomerCheckbox, setSpecficCustomerCheckbox] = React.useState(false);
  const [redeemLimitCheckbox, setRedeemLimitCheckbox] = React.useState(false);
  const [redemtionLimitCount, setRedemtionLimitCount] = React.useState("");
  const [expiryDateCheckbox, setExpiryDateCheckbox] = React.useState(false);
  const [codeExpiryDate, setCodeExpiryDate] = React.useState("");
  const [minimumOrderValueCheckbox, setMinimumOrderValueCheckbox] = React.useState(false);
  const [codeMinimumOrderValue, setCodeMinimumOrderValue] = React.useState("");
  const [customersList, setCustomersList] = React.useState([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [promotionCodesList, setPromotionCodesList] = React.useState([]);
  const [errors, setErrors] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [searchNameQuery, setSearchNameQuery] = React.useState("");
  const [statusQuery, setStatusQuery] = React.useState(null);

  const [filteredDataArray, setFilteredDataArray] = React.useState([]);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchTimeout, setSearchTimeout] = React.useState(null);
  const [customersListLoading, setCustomersListLoading] = React.useState(false);

  //   useEffect(() => {
  //     setTimeout(() => {
  //       setTableDataLoading(false);
  //     }, 300);
  //   }, []);

  const fetchData = () => {
    getCouponCodesList(state?.coupon?.id).then((res) => {
      if (res && res.status == 200) {
        if (res.data) {
          setPromotionCodesList(res?.data?.data);
          setTableDataLoading(false);
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };

  useEffect(() => {
    setFilteredDataArray(promotionCodesList); // Set filteredDataArray whenever promotionCodesList changes
  }, [promotionCodesList]);

  useEffect(() => {
    props.setPageTitleAndSubTitle("Promotions List", "Here is the list of all Promotions!");
    // const delayedAction = () => {
    //   setTableDataLoading(false);
    // };

    //Customers list
    getAllCustomersList(searchQuery).then((res) => {
      if ((res && res.status === 200) || res.status === 201) {
        const customers = res?.data?.map((customer) => {
          return {
            value: customer?.id,
            label: `${customer?.name} (${customer?.email})` ?? customer?.email,
          };
        });
        setCustomersList(customers);
      } else if (res && res.status === 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        // Handle other cases as needed
      }
    });
    fetchData();
    // // Set the timeout
    // const timeoutId = setTimeout(delayedAction, 400);
    // // Clean up the timeout when the component unmounts or when the effect re-runs
    // return () => {
    //   clearTimeout(timeoutId);
    // };
  }, []);

  const handleInputChange = (inputValue) => {
    setCustomersListLoading(true);

    setSearchQuery(inputValue);

    // Clear any existing timeout
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set a new timeout to trigger the API call after a delay (e.g., 500ms)
    const newTimeout = setTimeout(() => {
      // Perform the delayed API call with the searchQuery
      getAllCustomersList(inputValue).then((res) => {
        if ((res && res.status === 200) || res.status === 201) {
          const customers = res?.data?.map((customer) => {
            return {
              value: customer?.id,
              label: `${customer?.name} (${customer?.email})` ?? customer?.email,
            };
          });
          setCustomersList(customers);
        } else if (res && res.status === 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          // Handle other cases as needed
        }
        setCustomersListLoading(false);
      });
    }, 500); // Adjust the delay time as needed

    setSearchTimeout(newTimeout);
  };

  const modalHandler = () => {
    setCodeName("");
    setFirstTimeOrderCheckbox(false);
    setSpecficCustomerCheckbox(false);
    setSelectedCustomer(null);
    setRedeemLimitCheckbox(false);
    setRedemtionLimitCount("");
    setExpiryDateCheckbox(false);
    setCodeExpiryDate("");
    setMinimumOrderValueCheckbox(false);
    setCodeMinimumOrderValue("");
  };

  // Date time conversions to milliseconds

  const codeTimestampInSeconds = Math.floor(new Date(codeExpiryDate).getTime() / 1000);
  const codeExpiryUnixTimestamp = codeTimestampInSeconds.toString();

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;

    if (validate()) {
      setLoading(true);

      const promotionCode = {
        code: codeName,
        ...(expiryDateCheckbox && codeExpiryUnixTimestamp && { expires_at: codeExpiryUnixTimestamp }),
        ...(redeemLimitCheckbox && redemtionLimitCount && { max_redemptions: redemtionLimitCount }),
        ...((firstTimeOrderCheckbox || codeMinimumOrderValue) && {
          restrictions: {
            ...(firstTimeOrderCheckbox && { first_time_transaction: firstTimeOrderCheckbox }),
            ...(codeMinimumOrderValue && { minimum_amount_currency: "usd" }),
            ...(codeMinimumOrderValue && { minimum_amount: codeMinimumOrderValue }),
          },
        }),
        ...(specficCustomerCheckbox && selectedCustomer && { customer: selectedCustomer?.value }),
      };

      createCouponCode(promotionCode, state?.coupon?.id).then((res) => {
        if (res && res.status == 201) {
          fetchData();
          setPromotionCodesList([...promotionCodesList, res?.data]);
          toast.success("Promotion code created successfully.");
          document.getElementById("createCodeModalId").click();
          setLoading(false);
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error(res?.data?.message);
        }
        setLoading(false);
      });
    }
  };

  const validate = () => {
    let errors = {
      codeTitle: "",
    };
    let isValid = true;
    if (!codeName) {
      isValid = false;
      errors.codeTitle = "Please enter code.";
    }

    setErrors(errors);
    return isValid;
  };
  const columns = [
    {
      name: "Code",
      selector: (row) => row?.code,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => (row?.active ? <span className="text-success">Active</span> : <span className="text-danger">InActive</span>),
    },
    {
      name: "Usage Count",
      selector: (row) => row?.times_redeemed,
      wrap: true,
    },
    {
      name: "Creation Date",
      selector: (row) => dayjs(row?.created * 1000).format("DD-MMM-YYYY HH:mm:ss"),
    },
    {
      name: "Expiry Date",
      selector: (row) => (row?.expires_at ? dayjs(row?.expires_at * 1000).format("DD-MMM-YYYY HH:mm:ss") : "--"),
    },
    {
      name: "Actions",
      selector: (row) =>
        hasPermission("edit-coupon-code") && (
          <label className="switch">
            <input type="checkbox" checked={row?.active} onClick={(e) => deleteStatusHandler(e, row)} />
            <span className="slider round"></span>
          </label>
        ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "70px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "18px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "16px",
      },
    },
  };

  const deleteStatusHandler = (e, code) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the promo code?";
    if (!code?.active) {
      confirmText = "Are you sure you want to active the promo code?";
    }
    if (window.confirm(confirmText) == true) {
      deleteCouponCode(code?.id, e.target.checked).then((res) => {
        if (res.status == 200 || res.status == 201) {
          fetchData();
          toast.success("Status updated successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error(res?.data?.message);
        }
      });
    }
  };

  const handlePageChange = () => {
    setTableDataLoading(true);
    setTimeout(() => {
      setTableDataLoading(false);
    }, 500);
  };

  const handlResetFilters = () => {
    setSearchNameQuery("");
    setStatusQuery(null);
    fetchData();
  };

  const handleFilterButtonClick = () => {
    setTableDataLoading(true);

    // Trim the search query values before applying the filters
    const trimmedSearchNameQuery = searchNameQuery ? searchNameQuery.trim() : "";

    const filteredArray = promotionCodesList?.filter((data) => {
      // Filter by firstName (case-insensitive search) if searchQuery exists and is not an empty string
      const codeNameMatch = trimmedSearchNameQuery && data?.code.toLowerCase().includes(trimmedSearchNameQuery.toLowerCase());

      const statusMatch = statusQuery !== undefined && data?.active === statusQuery?.value;

      // Return true if any filter is not applied (i.e., no filtering condition is specified) or all filters match
      return (!trimmedSearchNameQuery || codeNameMatch) && (!statusQuery || statusMatch);
    });

    // Simulate a delay to show loading state
    setTimeout(() => {
      setFilteredDataArray(filteredArray);
      setTableDataLoading(false); // Set loading state back to false after filtering
    }, 200); // Adjust the delay time as needed
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4 align-items-center">
          <div className="col-8">
            <ol className="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li className="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li class="breadcrumb-item">
                <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate(-1)}>
                  Coupons Listing
                </span>
              </li>
              <li className="breadcrumb-item">Promotions</li>
            </ol>
          </div>
          <div className="col-4">
            {hasPermission("create-coupon-code") && (
              <button className="btn btn-primary float-right" data-toggle="modal" data-target="#createCodeModal" onClick={() => modalHandler()}>
                Create new code
              </button>
            )}
          </div>
        </div>
        <div className="row align-align-items-center mb-4">
          <div className="col-3">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search Name"
                value={searchNameQuery}
                onChange={(e) => setSearchNameQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group px-3 col-md-3">
            <label>Status</label>
            <Select
              name="status"
              value={statusQuery}
              onChange={(e) => setStatusQuery(e)}
              className="basic-single form-control"
              classNamePrefix="select"
              options={[
                { value: true, label: "Active" },
                { value: false, label: "InActive" },
              ]}
            />
          </div>
          <div className="col-2">
            <div className="form-group">
              <p className="m-0">&nbsp;</p>
              <button className="btn btn-primary float-right btn-md ml-2" onClick={handleFilterButtonClick}>
                Apply
              </button>
              <button className="btn btn-primary float-right btn-md" onClick={handlResetFilters}>
                Reset
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <DataTable
                    className="custom-data-table"
                    columns={columns}
                    data={filteredDataArray && filteredDataArray}
                    pagination
                    customStyles={customStyles}
                    progressPending={tableDataLoading}
                    persistTableHead
                    onChangePage={handlePageChange}
                    progressComponent={<div className="spinner-border text-black mt-5 mb-3" role="status" aria-hidden="true"></div>}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="createCodeModal" data-backdrop="static">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header border-0 px-3 mb-3">
              <h5 className="modal-title">Create new code</h5>
              <button type="button" className="close" data-dismiss="modal" id="createCodeModalId">
                <span>&times;</span>
              </button>
            </div>
            <form noValidate onSubmit={onSubmit}>
              <div className="row px-3">
                <div className="col-12 my-2">
                  <div>
                    <label>{codeName}</label>
                    <div className="form-group col-md-6 px-0">
                      <label>Code</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter code"
                        value={codeName}
                        onChange={(e) => setCodeName(e.target.value)}
                      />
                      {errors.codeTitle && !codeName && <div className="text-warning font-size-12 mt-2">{errors.codeTitle}</div>}
                    </div>
                    <input
                      className="mr-2"
                      type="checkbox"
                      name="first-time-only"
                      id="first-time-only"
                      checked={firstTimeOrderCheckbox}
                      onChange={(e) => setFirstTimeOrderCheckbox(e.target.checked)}
                    />
                    <label for="first-time-only" style={{ cursor: "pointer" }}>
                      Eligible for first-time orders only
                    </label>
                    <div>
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="specific-customer"
                        id="specific-customer"
                        checked={specficCustomerCheckbox}
                        onChange={(e) => setSpecficCustomerCheckbox(e.target.checked)}
                      />
                      <label for="specific-customer" style={{ cursor: "pointer" }}>
                        Limit to specifice customer
                      </label>
                      {specficCustomerCheckbox && (
                        <div className="col-6">
                          <div className="form-group">
                            <Select
                              name="customers"
                              // value={selectedOptions}
                              onInputChange={handleInputChange}
                              options={customersList}
                              onChange={(selectedOption) => setSelectedCustomer(selectedOption)}
                              className="basic-single form-control"
                              classNamePrefix="select"
                              isLoading={customersListLoading}
                            />
                            {/* {errors.timeType && !timeType && <div className="text-warning font-size-12 mt-2">{errors.timeType}</div>} */}
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="redemption-limit"
                        id="redemption-limit"
                        checked={redeemLimitCheckbox}
                        onChange={(e) => setRedeemLimitCheckbox(e.target.checked)}
                      />
                      <label for="redemption-limit" style={{ cursor: "pointer" }}>
                        Limit the number of times this code can be redeemed
                      </label>
                      {redeemLimitCheckbox && (
                        <div class="input-group mb-3 col-md-6 px-0">
                          <img alt="" srcset="" />
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Number of times"
                            value={redemtionLimitCount}
                            onChange={(e) => setRedemtionLimitCount(e.target.value)}
                          />
                          <span class="input-group-text" id="basic-addon1">
                            time
                          </span>
                        </div>
                      )}
                    </div>
                    <div>
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="expiry-date"
                        id="expiry-date"
                        checked={expiryDateCheckbox}
                        onChange={(e) => setExpiryDateCheckbox(e.target.checked)}
                      />
                      <label for="expiry-date" style={{ cursor: "pointer" }}>
                        Add an expiry date
                      </label>

                      {expiryDateCheckbox && (
                        <div className="row align-items-center px-3 mb-3">
                          <label>Expired at</label>
                          <div className="col-4">
                            <input onChange={(e) => setCodeExpiryDate(e.target.value)} className="form-control" type="datetime-local" name="" id="" />
                          </div>
                          {/* <div className="col-3">
                              <input onChange={(e) => console.log(e.target.value)} className="form-control" type="time" name="" id="" />
                            </div> */}
                        </div>
                      )}
                    </div>
                    <div>
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="minimum-order-value"
                        id="minimum-order-value"
                        checked={minimumOrderValueCheckbox}
                        onChange={(e) => setMinimumOrderValueCheckbox(e.target.checked)}
                      />
                      <label for="minimum-order-value" style={{ cursor: "pointer" }}>
                        Require minimum order value
                      </label>
                      {minimumOrderValueCheckbox && (
                        <div class="input-group mb-3 col-md-6 px-0">
                          <img alt="" srcset="" />
                          <span class="input-group-text" id="basic-addon1">
                            $
                          </span>
                          <input
                            type="number"
                            class="form-control"
                            placeholder="Amount"
                            value={codeMinimumOrderValue}
                            onChange={(e) => setCodeMinimumOrderValue(e.target.value)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-0 mb-4 ml-auto mt-4">
                <button type="submit" className="btn btn-primary">
                  {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Create
                </button>
              </div>
              {/* </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromotionsList;
