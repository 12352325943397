import { Navigate } from 'react-router-dom';

const LoginRoute = ({ isLoggedIn, children }) => {
    if (isLoggedIn) {
        return <Navigate to="/" replace />;
    }

    return children;
};

export default LoginRoute
