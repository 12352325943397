import { Helmet } from "react-helmet";
import Logo from "../../../src/images/logo.png";
const ShareApp = () => {
  return (
    <>
      <h1>Share</h1>
    </>
  );
};

export default ShareApp;
