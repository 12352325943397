import React, { useEffect, useState } from "react";
import { getDashboardStats, remove_session } from "../../../functions/general";
import { useNavigate } from "react-router-dom";
import MembersSummarySection from "./MembersSummarySection";
import RevenueSection from "./RevenueSection";
import AppSummarySection from "./AppSummarySection";

const Dashboard = (props) => {
  const navigate = useNavigate();

  const currentUser = props.currentUser;
  const [dashboardStats, setDashboardStats] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props.currentUser && props.setPageTitleAndSubTitle("Dashboard", `Welcome ${currentUser?.firstName}!`);
  }, [props.currentUser]);

  useEffect(() => {
    const duration = "yearly";
    getDashboardStats(duration).then((res) => {
      if ((res && res.status == 200) || res.status == 201) {
        setDashboardStats(res?.data);
        setLoading(false);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setLoading(false);
    });
  }, []);
  return (
    <div className="container-fluid">
      {loading ? (
        <div className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh" }}>
          <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-6">
            <MembersSummarySection dashboardStats={dashboardStats} getLoggedInUser={props.getLoggedInUser} />
          </div>
          <div className="col-md-6">
            <RevenueSection dashboardStats={dashboardStats} getLoggedInUser={props.getLoggedInUser} />
          </div>
          <div className="col-md-6">
            <AppSummarySection dashboardStats={dashboardStats} getLoggedInUser={props.getLoggedInUser} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
