import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import CommunityPosts from "./components/posts";
import ReportedCommunityPosts from "./components/reported-posts";

const CommunityPostsList = (props) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("tab") || "posts";

  useEffect(() => {
    props.setPageTitleAndSubTitle("SM Community Posts", "Here is the list of all SM Community's Posts!");
  }, []);

  const handleTabClick = (tab) => {
    setSearchParams({ tab });
  };


  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4 align-items-center">
          <div className="col-8">
            <ol className="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li className="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li className="breadcrumb-item">SM Community Posts</li>
            </ol>
          </div>
        </div>

        <ul className="nav nav-pills mb-4">
          <li className="nav-item mr-1">
            <button className={`nav-link ${activeTab === "posts" ? "active" : ""}`} onClick={() => handleTabClick("posts")} href="#">
              Posts
            </button>
          </li>
          <li className="nav-item">
            <button className={`nav-link ${activeTab === "reported" ? "active" : ""}`} onClick={() => handleTabClick("reported")} href="#">
              Reported Posts
            </button>
          </li>
        </ul>

        {activeTab === "posts" ? <CommunityPosts props={props} /> : <ReportedCommunityPosts props={props} />}
      </div>
    </>
  );
};

export default CommunityPostsList;
