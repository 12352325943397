import React, {useEffect, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {
    remove_session,
    updateNotification,
    deleteNotification,
    addNotification, notificationsList, reSendNotification,
} from "../../../functions/general";
import {toast} from "react-toastify";
import Media from "../../../components/Media";
import ModalImage from "react-modal-image";
import {usePermissions} from "../../../functions/usePermissions";

import {SortableContainer, SortableElement, SortableHandle, arrayMove} from "react-sortable-hoc";

const ListNotifications = (props) => {
    const {hasPermission} = usePermissions();
    const navigate = useNavigate();
    const [notifications, setNotifications] = React.useState([]);
    const [modalTitle, setModalTitle] = React.useState("");
    const [modalButtonText, setModalButtonText] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [text, setText] = React.useState("");
    const [sendTo, setSendTo] = React.useState("");
    const [image, setImage] = React.useState("");
    const [id, setId] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [tableDataLoading, setTableDataLoading] = React.useState(true);
    const [errors, setErrors] = React.useState("");
    const selectRef = useRef(null); // Create a ref for the select element
    useEffect(() => {
        setTableDataLoading(true);
        props.setPageTitleAndSubTitle("Notifications", "Here is the list of Notifications!");
        fetchData();
    }, []);

    const fetchData = (type) => {
        notificationsList().then((res) => {
            if (res && res.status == 200) {
                setNotifications(res?.data);
                setTableDataLoading(false);
                if (type === "add") {
                    let sortedArray = [];
                    res?.data?.map((notification) => sortedArray.push(notification?._id));
                    notificationsList(sortedArray).then((res) => {
                        if (res && res.status == 200) {
                            // fetchData();
                        } else if (res && res.status == 401) {
                            remove_session();
                            props.getLoggedInUser();
                            navigate("/login");
                        } else {
                            toast.error("Failed to sort the list.");
                        }
                        setTableDataLoading(false);
                    });
                }
            } else if (res && res.status == 401) {
                remove_session();
                props.getLoggedInUser();
                navigate("/login");
            } else {
                setTableDataLoading(false);
            }
        });
    };

    const modalHandler = (notification) => {
        if (notification && notification._id) {
            setModalTitle("Update Notification");
            setModalButtonText("Update");
            setTitle(notification?.title);
            setText(notification?.text);
            setSendTo(notification?.sendTo);
            setImage(notification?.image);
            setId(notification._id);

            // Manually update the select element's value using the ref
            selectRef.current.value = notification?.sendTo;
            // Trigger a change event to notify Bootstrap Selectpicker
            const event = new Event("change", {
                bubbles: true,
                cancelable: true,
            });
            selectRef.current.dispatchEvent(event);
        } else {
            setModalTitle("Add and Send Notification");
            setModalButtonText("Save and Send");
            setTitle("");
            setText("");
            setSendTo("");
            setImage("");
            setId("");
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (loading) return false;
        if (validate()) {
            setLoading(true);
            const notification = {
                title: title,
                text: text,
                sendTo: sendTo,
                image: image,
            };
            let type = "add";
            if (id) {
                updateNotification(id, notification).then((res) => {
                    if (res && res.status == 200) {
                        fetchData();
                        toast.success("Notification updated successfully.");
                        document.getElementById("ModalCloseButton").click();
                    } else if (res && res.status == 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate("/login");
                    } else {
                        toast.error("Failed to update notification.");
                    }
                    setLoading(false);
                });
            } else {
                addNotification(notification).then((res) => {
                    if (res && res.status == 201) {
                        fetchData(type);
                        toast.success("Notification added and sent successfully.");
                        document.getElementById("ModalCloseButton").click();
                    } else if (res && res.status == 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate("/login");
                    } else {
                        toast.error("Failed to add notification.");
                    }
                    setLoading(false);
                });
            }
        }
    };

    const validate = () => {
        let errors = {
            title: "",
            text: "",
            image: "",
            sendTo: "",
        };
        let isValid = true;
        if (!title) {
            isValid = false;
            errors.title = "Please enter title.";
        }
        if (!text) {
            isValid = false;
            errors.text = "Please enter text.";
        }
        if (!sendTo) {
            isValid = false;
            errors.sendTo = "Please select send to.";
        }
        setErrors(errors);
        return isValid;
    };

    const deleteHandler = (e, notification) => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to delete notification?") == true) {
            if (window.confirm("Are you sure you want to delete notification permanently?") == true) {
                deleteNotification(notification._id).then((res) => {
                    if (res && res.status == 200) {
                        fetchData();
                        toast.success("Notification deleted successfully.");
                    } else if (res && res.status == 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate("/login");
                    } else {
                        toast.error("Failed to delete notification.");
                    }
                });
            }
        }
    };

    const reSendHandler = (e, notification) => {
        e.preventDefault();
        let confirmText = "Are you sure you want to resend the notification?";
        console.log(notification._id);
        if (window.confirm(confirmText) == true) {
            reSendNotification(notification._id).then((res) => {
                if (res && (res.status == 201 || res.status == 201)) {
                    toast.success("Notification resent successfully.");
                } else if (res && res.status == 401) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate("/login");
                } else {
                    toast.error("Failed to resend notification.");
                }
            });
        }
    };


    const setImageHandler = (image) => {
        setImage(image);
    };
    const onSortEnd = ({oldIndex, newIndex}) => {
        setTableDataLoading(true);
        const notifications = arrayMove(notifications, oldIndex, newIndex);
        let sortedArray = [];
        notifications?.map((notifications) => sortedArray.push(notifications?._id));
        notifications(sortedArray).then((res) => {
            if (res && res.status == 200) {
                setNotifications(notifications);
                // fetchData();
            } else if (res && res.status == 401) {
                remove_session();
                props.getLoggedInUser();
                navigate("/login");
            } else {
                toast.error("Failed to sort the list.");
            }
            setTableDataLoading(false);
        });
    };

    const DragHandle = SortableHandle(() => (
        <div
            style={{
                cursor: "move",
                width: "20px",
                height: "20px",
            }}
        >
            <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="grip-vertical"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
            >
                <path
                    fill="#6e6e6e"
                    d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
                ></path>
            </svg>
        </div>
    ));

    const SortableTableRow = SortableElement(({notification, serialNo}) => {
        return (
            <tr style={{width: "100%"}}>
                <td>{serialNo + 1}</td>
                <td>
                    <div className="maxWidth70px">
                        {notification.image ?
                            <ModalImage small={process.env.REACT_APP_MEDIAPATH + notification.image} large={process.env.REACT_APP_MEDIAPATH + notification.image}/>
                            : 'No Image'}
                    </div>
                </td>
                <td>{notification.title}</td>
                <td>{notification.text}</td>
                <td>{notification.sendTo}</td>

                {(hasPermission("edit-notification") || hasPermission("delete-notification")) && (
                    <td>
                        <div className="d-flex">
                            {hasPermission("send-notification") && (
                                <button
                                    onClick={(e) => reSendHandler(e, notification)}
                                    className="btn btn-secondary shadow btn-sm sharp mr-1"
                                >
                                    <i className="fa fa-send"></i>
                                </button>
                            )}
                            {hasPermission("edit-notification") && (
                                <button
                                    data-toggle="modal"
                                    data-target="#add"
                                    onClick={(e) => modalHandler(notification)}
                                    className="btn btn-primary shadow btn-sm sharp mr-1"
                                >
                                    <i className="fa fa-pencil"></i>
                                </button>
                            )}
                            {hasPermission("delete-notification") && (
                                <button onClick={(e) => deleteHandler(e, notification)} className="btn btn-danger shadow btn-sm sharp">
                                    <i className="fa fa-trash"></i>
                                </button>
                            )}
                        </div>
                    </td>
                )}
                {/* {hasPermission("edit-notification") && (
          <td>
            <DragHandle />
          </td>
        )} */}
            </tr>
        );
    });

    const SortableTable = SortableContainer(({notifications}) => {
        return (
            <table id="roles-table" className="display dataTable" style={{minWidth: "845px"}}>
                <thead>
                <tr>
                    <th>No</th>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Text</th>
                    <th>Receivers</th>

                    {(hasPermission("edit-notification") || hasPermission("resend-notification") || hasPermission("delete-notification")) && <th>Actions</th>}
                    {/* {hasPermission("edit-notification") && <th>Sort Order</th>} */}
                </tr>
                </thead>
                <tbody>
                {tableDataLoading ? (
                    <tr>
                        <td colSpan="7" className="text-center pt-5">
                            <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                        </td>
                    </tr>
                ) : (
                    <>
                        {notifications ? (
                            notifications?.map((notification, index) => {
                                return <SortableTableRow key={index} notification={notification} index={index} serialNo={index}/>;
                            })
                        ) : (
                            <tr>
                                <td colSpan="7" className="text-center pt-5">
                                    No notification found!
                                </td>
                            </tr>
                        )}
                    </>
                )}
                </tbody>
            </table>
        );
    });

    return (
        <>
            <div className="container-fluid">
                <div className="row mb-4">
                    <div className="col-8">
                        <ol class="breadcrumb bg-transparent mb-0" style={{fontSize: "18px"}}>
                            <li class="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{fontSize: "28px", cursor: "pointer"}}></i>
                </span>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">
                                Notifications
                            </li>
                        </ol>
                    </div>
                    <div className="col-4">
                        {hasPermission("add-notification") && (
                            <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={(e) => modalHandler()}>
                                Add New
                            </button>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <SortableTable notifications={notifications} onSortEnd={onSortEnd} useDragHandle={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="add">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-0 px-3 mb-3">
                                <h5 className="modal-title">{modalTitle}</h5>
                                <button type="button" className="close" data-dismiss="modal" id="ModalCloseButton">
                                    <span>&times;</span>
                                </button>
                            </div>
                            <form noValidate onSubmit={onSubmit}>
                                <div className="form-group px-3">
                                    <label>Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Notification Title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                    {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>}
                                </div>
                                <div className="form-group px-3">
                                    <label>Text</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder="Notification Text"
                                        value={text}
                                        onChange={(e) => setText(e.target.value)}
                                    />
                                    {errors.text && !title && <div className="text-warning font-size-12 mt-2">{errors.text}</div>}
                                </div>

                                <div className="form-group px-3">
                                    <label>Send To</label>
                                    <select
                                        className="form-control"
                                        ref={selectRef} // Assign the ref to the select element
                                        value={sendTo}
                                        onChange={(e) => {
                                            setSendTo(e.target.value);
                                        }}
                                    >
                                        <option value="">Select List</option>
                                        <option value="all">All</option>
                                        <option value="android">Android</option>
                                        <option value="apple">Apple</option>
                                        <option value="members">Members</option>
                                        <option value="non-members">Non Members</option>

                                    </select>
                                    {errors.sendTo && !sendTo && <div className="text-warning font-size-12">{errors.sendTo}</div>}
                                </div>

                                <div className="form-group px-3">
                                    <label>Image</label>
                                    <br/>
                                    {image && (
                                        <>
                                            <img src={process.env.REACT_APP_MEDIAPATH + image} className="img-fluid img-thumbnail" width="150"/>
                                            <br/>
                                        </>
                                    )}
                                    <button type="button" className="btn btn-primary mt-3" data-toggle="modal" data-target="#media">
                                        Select Image
                                    </button>
                                    <button type="button" className="btn btn-danger ml-3 mt-3" onClick={() => setImage("")}>
                                        Remove Image
                                    </button>
                                    {errors.title && !image && <div className="text-warning font-size-12 mt-2">{errors.image}</div>}
                                </div>
                                <div className="modal-footer border-0">
                                    <button type="submit" className="btn btn-primary">
                                        {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Media setImageHandler={setImageHandler}/>

            </div>
        </>
    );
}

export default ListNotifications;
