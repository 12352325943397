import React, { useEffect } from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
    addVideo,
    deleteVideoPermanently,
    getCategories,
    getVideos,
    getVideosCount,
    importVideos,
    loadThumbnails,
    remove_session,
    updateVideo
} from "../../../functions/general";
import { toast } from 'react-toastify';
import Select from 'react-select';
import ModalImage from "react-modal-image";

function ListVideos(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const filterId = searchParams.get('filterId');
    const filterName = searchParams.get('filterName');
    let searchFiltersFiltersArray = [];
    if(filterId && filterName){
        searchFiltersFiltersArray.push({value: filterId, label: filterName})
    }
    const navigate = useNavigate();
    const [videos, setVideos] = React.useState([]);
    const [videosCount, setVideosCount] = React.useState([]);
    const [filters, setFilters] = React.useState([]);
    const [filterOptions, setFilterOptions] = React.useState([]);
    const [modalTitle, setModalTitle] = React.useState("");
    const [modalButtonText, setModalButtonText] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [vimeoUrl, setVimeoUrl] = React.useState("");
    const [videoFilters, setVideoFilters] = React.useState([]);
    const [id, setId] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [importLoading, setImportLoading] = React.useState(false);
    const [thumbnailLoading, setThumbnailLoading] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [searchFiltersFrom, setSearchFiltersFrom] = React.useState(0);
    const [searchFiltersLimit, setSearchFiltersLimit] = React.useState(20);
    const [searchFiltersQuery, setSearchFiltersQuery] = React.useState('');
    const [searchFiltersFilters, setSearchFiltersFilters] = React.useState(searchFiltersFiltersArray);
    useEffect(() => {
        props.setPageTitleAndSubTitle("Videos", "Here is the list of Videos !");
        fetchData();
        fetchCategoriesData();
    }, [])

    useEffect(() => {
        fetchData();
    }, [searchFiltersFrom])

    useEffect(() => {
        if(searchFiltersFrom === 0) {
            fetchData();
        } else {
            setSearchFiltersFrom(0)
        }
    }, [searchFiltersLimit])

    const fetchData = () => {
        const searchFiltersIds = []
        if(searchFiltersFilters.length){
            searchFiltersFilters.map((filter) => {
                searchFiltersIds.push(filter.value)
            })
        }
        setVideos([]);
        const searchFilters = {
            from: (searchFiltersFrom * searchFiltersLimit),
            limit: searchFiltersLimit,
            filters: searchFiltersIds,
            search: searchFiltersQuery
        }
        getVideos(searchFilters).then(res => {
            if (res && res.status === 200) {
                setVideos(res.data);
            } else if (res && res.status === 401) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
        getVideosCount(searchFilters).then(res => {
            if (res && res.status === 200) {
                setVideosCount(res.data);
            } else if (res && res.status === 401) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            }
        })
    }

    const fetchCategoriesData = () => {
        let tempFilters = []
        let tempFiltersOptions = []
        getCategories().then(res => {
            if (res && res.status === 200) {
                if(res.data && res.data.length) {
                    res.data.map((categoryValue) => {
                        if(categoryValue.isDeleted === false) {
                            categoryValue.filters.length > 0 && categoryValue.filters.map((filterValue) => {
                                if(filterValue.isDeleted === false) {
                                    tempFilters.push(filterValue)
                                    tempFiltersOptions.push({ value: filterValue._id, label: filterValue.label })
                                }
                            })
                        }
                    })
                }
                setFilterOptions(tempFiltersOptions);
                setFilters(tempFilters);
            } else if (res && res.status === 401) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
            }
        })
    }

    const modalHandler = (categoryId, video) => {
        if(video && video._id){
            setModalTitle("Update Video")
            setModalButtonText("Update")
            setTitle(video.title)
            setVimeoUrl(video.vimeo_url)
            let tempVideoFilters = []
            video.filters && video.filters.map((filterId, filterIndex) => {
                const filterValue = filters.find(o => o._id === filterId)
                if(filterValue) {
                    tempVideoFilters.push({ value: filterValue._id, label: filterValue.label })
                }
            })
            setVideoFilters(tempVideoFilters)
            setId(video._id)
        } else {
            setModalTitle("Add New Video")
            setModalButtonText("Add")
            setTitle("")
            setVimeoUrl("")
            setVideoFilters([])
            setId("")
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const videoFiltersIds = []
            if(videoFilters.length){
                videoFilters.map((filter) => {
                    videoFiltersIds.push(filter.value)
                })
            }
            const video = {
                title: title,
                vimeo_url: vimeoUrl,
                filters: videoFiltersIds,
                vimeo_id: '',
                vimeo_uri: '',
                video_thumbnail: ''
            }
            if(id){
                updateVideo(id, video).then(res => {
                    if (res && res.status === 200) {
                        fetchData();
                        toast.success("Video updated successfully.");
                        document.getElementById('categoryModalCloseButton').click();
                    } else if (res && res.status === 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate('/login');
                    } else {
                        toast.error("Failed to update video.");
                    }
                    setLoading(false)
                })
            } else {
                addVideo(video).then(res => {
                    if (res && res.status === 201) {
                        fetchData();
                        toast.success("Video added successfully.");
                        document.getElementById('categoryModalCloseButton').click();
                    } else if (res && res.status === 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate('/login');
                    } else {
                        toast.error("Failed to add video.");
                    }
                    setLoading(false)
                })
            }
        }
    }

    const validate = () => {
        let errors = {
            title: '',
            vimeoUrl: '',
            filters: '',
        };
        let isValid = true;
        if (!title) {
            isValid = false;
            errors.title = "Please enter title.";
        }
        if (!vimeoUrl) {
            isValid = false;
            errors.vimeoUrl = "Please enter vimeo url.";
        }
        if (videoFilters.length == 0) {
            isValid = false;
            errors.filters = "Please select filters.";
        }
        setErrors(errors);
        return isValid;
    }

    const deleteHandler = (e, video) => {
        e.preventDefault()
        if (window.confirm("Are you sure you want to delete video?") === true) {
            if (window.confirm("Are you sure you want to delete video permanently?") === true) {
                deleteVideoPermanently(video._id).then(res => {
                    if (res && res.status === 200) {
                        fetchData();
                        toast.success("Video deleted successfully.");
                    } else if (res && res.status === 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate('/login');
                    } else {
                        toast.error("Failed to delete video.");
                    }
                })
            }
        }
    }
    const videoUploadHandler = (e) => {
        if(!importLoading) {
            var file = e.target.files[0];
            setImportLoading(true);
            importVideos(file).then(res => {
                if (res && res.status === 201) {
                    fetchData();
                    toast.success("Imported successfully.");
                } else if (res && res.status === 401) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    toast.error("Failed to import.");
                }
                document.getElementById('importVideos').value = null;
                setImportLoading(false);
            })
        }
    }
    const loadThumbnailsHandler = () => {
        if(!thumbnailLoading) {
            setThumbnailLoading(true);
            loadThumbnails().then(res => {
                if (res && res.status === 200) {
                    fetchData();
                    toast.success("Thumbnails uploaded successfully.");
                } else if (res && res.status === 401) {
                    remove_session();
                    props.getLoggedInUser();
                    navigate('/login');
                } else {
                    toast.error("Failed to upload thumbnails.");
                }
                setThumbnailLoading(false);
            })
        }
    }
  return (
      <>
          <div className="container-fluid">
              <div className="row mb-4">
                  <div className="col-12">
                      <button className="btn btn-primary float-right" onClick={() => {
                          if(!importLoading){
                            document.getElementById('importVideos').click()
                          }
                      }}>
                          {importLoading &&
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                          }
                          Import
                      </button>
                      <input type="file" className="d-none" id="importVideos"
                             onChange={e => videoUploadHandler(e)}
                             accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                      <button className="btn btn-primary float-right mr-3" onClick={() => loadThumbnailsHandler()}>
                          {thumbnailLoading &&
                              <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                          }
                          Load Thumbnails
                      </button>
                      <button className="btn btn-primary float-right mr-3" data-toggle="modal" data-target="#add" onClick={() => modalHandler()}>Add New
                      </button>
                  </div>
              </div>
              <form noValidate onSubmit={(e) => {
                  e.preventDefault()
                  if(searchFiltersFrom === 0){
                      fetchData()
                  } else {
                      setSearchFiltersFrom(0)
                  }
              }}>
                <div className="row">
                  <div className="col-5">
                      <div className="form-group">
                          <label>Title</label>
                          <input type="text" className="form-control" placeholder="Filter Title"
                                 value={searchFiltersQuery} onChange={(e) => setSearchFiltersQuery(e.target.value)}/>
                      </div>
                  </div>
                  <div className="col-5">
                      <div className="form-group">
                          <label>Filters</label>
                          <Select
                              defaultValue={searchFiltersFilters}
                              isMulti
                              name="colors"
                              options={filterOptions}
                              onChange={setSearchFiltersFilters}
                              className="basic-multi-select form-control"
                              classNamePrefix="select"/>
                      </div>
                  </div>
                  <div className="col-2">
                      <div className="form-group">
                          <p className="m-0">&nbsp;</p>
                          <button className="btn btn-primary float-right"
                                  onClick={() => {
                                      if(searchFiltersFrom === 0){
                                          fetchData()
                                      } else {
                                          setSearchFiltersFrom(0)
                                      }
                                  }}>
                              Search
                          </button>
                      </div>
                  </div>
                  <div className="col-2">
                      <div className="form-group">
                          <label>Show Entries</label>
                          <select className="form-control"
                                  value={searchFiltersLimit} onChange={(e) => setSearchFiltersLimit(parseInt(e.target.value))}>
                                      <option value="20">20</option>
                                      <option value="40">40</option>
                                      <option value="50">50</option>
                                      <option value="70">70</option>
                                      <option value="100">100</option>
                                      <option value="150">150</option>
                                      <option value="200">200</option>
                                      <option value="300">300</option>
                                      <option value="500">500</option>
                          </select>
                      </div>
                  </div>
                  <div className="col-10">
                  </div>
              </div>
              </form>
              <div className="row">
                  <div className="col-12">
                      <div className="card">
                          <div className="card-body">
                              <div className="table-responsive">
                                  <table id="roles-table" className="display dataTable" style={{minWidth: "845px"}}>
                                      <thead>
                                      <tr>
                                          <th>ID</th>
                                          <th>Image</th>
                                          <th>Title</th>
                                          <th>Vimeo Url</th>
                                          <th>Filters</th>
                                          <th>Actions</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      { videos.length > 0 &&
                                        <>
                                          {videos.map((videoValue, videoIndex) => {
                                              return (
                                                  <tr key={`video-${videoIndex}`}>
                                                      <td>{(searchFiltersFrom * searchFiltersLimit) + videoIndex + 1}</td>
                                                      <td>
                                                          <div className="maxWidth70px">
                                                              <ModalImage
                                                                  small={videoValue.video_thumbnail}
                                                                  large={videoValue.video_thumbnail}
                                                              />
                                                          </div>
                                                      </td>
                                                      <td>{videoValue.title}</td>
                                                      <td>{videoValue.vimeo_url}</td>
                                                      <td>
                                                          <ol className="list-style-disc">
                                                              {videoValue.filters && videoValue.filters.map((filterId, filterIndex) => {
                                                                  const filterValue = filters.find(o => o._id === filterId)
                                                                  if(filterValue) {
                                                                      return (
                                                                              <li key={`video-${filterIndex}`}>{filterValue.label}</li>
                                                                      )
                                                                  }
                                                              })}
                                                          </ol>
                                                      </td>
                                                      <td>
                                                          <div className="d-flex">
                                                              <button data-toggle="modal" data-target="#add" onClick={() => modalHandler(videoValue, videoValue)}
                                                                      className="btn btn-primary shadow btn-sm sharp mr-1"><i
                                                                  className="fa fa-pencil"></i></button>
                                                              <button onClick={e => deleteHandler(e, videoValue)} className="btn btn-danger shadow btn-sm sharp"><i
                                                                  className="fa fa-trash"></i></button>
                                                          </div>
                                                      </td>
                                                  </tr>
                                              );
                                          })}
                                        </>
                                      }
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-6">
                      {(videosCount && videos.length) > 0 && (
                          <p className="text-black">Showing {(searchFiltersFrom * searchFiltersLimit) + 1} to {(searchFiltersFrom * searchFiltersLimit) + searchFiltersLimit} of {videosCount} entries</p>
                      )}
                  </div>
                  <div className="col-6">
                      {(videosCount && videos.length) > 0 && (
                          <nav ariaLabel="Page navigation example">
                              <ul className="pagination float-right">
                                  <li className="page-item">
                                      {searchFiltersFrom > 0 ? (
                                          <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom - 1)} ariaLabel="Previous">
                                              <span aria-hidden="true">&laquo;</span>
                                              <span className="sr-only">Previous</span>
                                          </span>
                                      ) : (
                                          <span className="page-link c-default" ariaLabel="Previous">
                                              <span aria-hidden="true">&laquo;</span>
                                              <span className="sr-only">Previous</span>
                                          </span>
                                      )}
                                  </li>
                                  {(searchFiltersFrom - 2) > 0 && (
                                      <li className="page-item">
                                          <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom - 3)}>
                                              {searchFiltersFrom - 2}
                                          </span>
                                      </li>
                                  )}
                                  {(searchFiltersFrom - 1) > 0 && (
                                      <li className="page-item">
                                          <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom - 2)}>
                                              {searchFiltersFrom - 1}
                                          </span>
                                      </li>
                                  )}
                                  {searchFiltersFrom > 0 && (
                                      <li className="page-item">
                                          <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom - 1)}>
                                              {searchFiltersFrom}
                                          </span>
                                      </li>
                                  )}
                                  <li className="page-item">
                                      <span className="page-link bg-gray-dark text-white">
                                          {searchFiltersFrom + 1}
                                      </span>
                                  </li>
                                  {((searchFiltersFrom * searchFiltersLimit) + searchFiltersLimit < videosCount) && (
                                      <li className="page-item">
                                          <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom + 1)}>
                                              {searchFiltersFrom + 2}
                                          </span>
                                      </li>
                                  )}
                                  {(((searchFiltersFrom + 1) * searchFiltersLimit) + searchFiltersLimit < videosCount) && (
                                      <li className="page-item">
                                          <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom + 2)}>
                                              {searchFiltersFrom + 3}
                                          </span>
                                      </li>
                                  )}
                                  {(((searchFiltersFrom + 2) * searchFiltersLimit) + searchFiltersLimit < videosCount) && (
                                      <li className="page-item">
                                          <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom + 3)}>
                                              {searchFiltersFrom + 4}
                                          </span>
                                      </li>
                                  )}
                                  <li className="page-item">
                                      {((searchFiltersFrom * searchFiltersLimit) + searchFiltersLimit < videosCount) ? (
                                          <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom + 1)} ariaLabel="Next">
                                              <span aria-hidden="true">&raquo;</span>
                                              <span className="sr-only">Next</span>
                                          </span>
                                      ) : (
                                          <span className="page-link c-default" ariaLabel="Next">
                                              <span aria-hidden="true">&raquo;</span>
                                              <span className="sr-only">Next</span>
                                          </span>
                                      )}
                                  </li>
                              </ul>
                          </nav>
                      )}
                  </div>
              </div>
              <div className="modal fade" id="add">
                  <div className="modal-dialog" role="document">
                      <div className="modal-content">
                          <div className="modal-header border-0 px-3 mb-3">
                              <h5 className="modal-title">{modalTitle}</h5>
                              <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton"><span>&times;</span>
                              </button>
                          </div>
                          <form noValidate onSubmit={onSubmit}>
                              <div className="form-group px-3">
                                  <label>Title</label>
                                  <input type="text" className="form-control" placeholder="Video Title"
                                         value={title} onChange={(e) => setTitle(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.title}</div>
                              </div>
                              <div className="form-group px-3">
                                  <label>Vimeo Url</label>
                                  <input type="text" className="form-control" placeholder="Vimeo Url"
                                         value={vimeoUrl} onChange={(e) => setVimeoUrl(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.vimeoUrl}</div>
                              </div>
                              <div className="form-group px-3">
                                  <label>Filters</label>
                                  <Select
                                      value={videoFilters}
                                      isMulti
                                      name="colors"
                                      options={filterOptions}
                                      onChange={setVideoFilters}
                                      className="basic-multi-select form-control"
                                      classNamePrefix="select"/>
                                  <div className="text-warning font-size-12">{errors.filters}</div>
                              </div>
                              <div className="modal-footer border-0">
                                  <button type="submit" className="btn btn-primary">{modalButtonText}</button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </>
  );
}

export default ListVideos;
