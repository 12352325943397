import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./common/PrivateRoute";
import React, { useEffect } from "react";
import Login from "./pages/public/login";
import LoginRoute from "./common/PublicRoute";
import { current_user, get_response_or_errors, getAdmins, getUser, is_logged_in, login, remove_session } from "./functions/general";
import Loading from "./components/Loading";
import Sidebar from "./components/Sidebar";
import TopNavigation from "./components/TopNavigation";
import Footer from "./components/Footer";
import Profile from "./pages/private/Profile";
import Dashboard from "./pages/private/dashboard";
import ListAdmins from "./pages/private/admins/ListAdmins";
import AddAdmins from "./pages/private/admins/AddAdmins";
import EditAdmins from "./pages/private/admins/EditAdmins";
import NavHeader from "./components/NavHeader";
import ListCategories from "./pages/private/categories/ListCategories";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ListFilters from "./pages/private/filters/ListFilters";
import ListLectureCategories from "./pages/private/lectureCategories/ListLectureCategories";
import ListLectureSubcategories from "./pages/private/lectureSubcategories/ListLectureSubcategories";
import ListLectures from "./pages/private/lectures/ListLectures.js";
import ListVideos from "./pages/private/videos/ListVideos";
import ListMembers from "./pages/private/userManagement/members/ListMembers";
import ListProgramCategories from "./pages/private/programCategories/ListProgramCategories";
import ListPrograms from "./pages/private/programs/ListPrograms";
import ListProgramWeeks from "./pages/private/weeks/ListProgramWeeks";
import ListWeekDays from "./pages/private/weekDays/ListWeekDays";
import IntroWeekDetails from "./pages/private/intoWeek";
import WeekDayDetails from "./pages/private/weekDayDetails";
import ListWorkoutCategories from "./pages/private/workouts/workoutCategories/ListWorkoutCategories";
import ListWorkoutSubcategories from "./pages/private/workouts/workoutSubcategories/ListWorkoutSubcategories";
import ListWorkouts from "./pages/private/workouts/workouts/ListWorkouts";
import WorkoutDetails from "./pages/private/workouts/workoutDetails";
import RolesList from "./pages/private/rolesManagement";
import PermissionsList from "./pages/private/rolesManagement/permissions.js";
import AcademySliders from "./pages/private/appSliders/academySliders";
import PermissionsCrud from "./pages/private/permissions-crud/PermissionsCrud";
import PermissionTypes from "./pages/private/permission-types/PermissionTypes";
import AdminUsersList from "./pages/private/admin-users/AdminUsers";
import StripeWebview from "./pages/public/payment-webview/StripeWebview";
import CheckoutForm from "./pages/public/payment-webview/CheckoutForm";
import CheckoutFormWrapper from "./pages/public/payment-webview/CheckoutFormWrapper";
import PaymentSuccess from "./pages/public/payment-webview/PaymentSuccess";
import PaymentFailed from "./pages/public/payment-webview/PaymentFailed";
import SubscriptionsList from "./pages/private/userManagement/SubscriptionsList";
import TransactionsList from "./pages/private/userManagement/Transactions";
import CouponsList from "./pages/private/userManagement/coupons/Coupons";
import PromotionsList from "./pages/private/userManagement/coupons/PromotionsList";
import ShareApp from "./pages/public/share";
import ListFAQCategories from "./pages/private/faqs/ListFAQCategories";
import FAQS from "./pages/private/faqs/FAQs";
import SuccessLoading from "./pages/public/payment-webview/SuccessLoading";
import ListNotifications from "./pages/private/notifications/ListNotifications";
import MemberDetails from "./pages/private/userManagement/members/member-details.js";
import LectureDetails from "./pages/private/lecture-details/index.js";
// import CommunityAdminPostsList from "./pages/private/community/sm-team-posts/index.js";
import SMTeamPostComments from "./pages/private/community/sm-team-posts/commnets/index.jsx";
import SMTeamPostCommentReplies from "./pages/private/community/sm-team-posts/replies/index.js";
import CommunityPostsList from "./pages/private/community/community-posts/index.js";
import ListSmTeam from "./pages/private/community/sm-team/ListSmTeam";
import CommunityPostComments from "./pages/private/community/community-posts/commnets/index.jsx";
import CommunityPostReplies from "./pages/private/community/community-posts/replies/index.js";

function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(is_logged_in());
  const [currentUser, setCurrentUser] = React.useState(current_user());
  const [title, setTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const getLoggedInUser = () => {
    if (is_logged_in() && !current_user()) {
      getUser().then((res) => {
        if (res && res.status == 200) {
          setCurrentUser(current_user());
        } else {
        }
      });
    }
    setIsLoggedIn(is_logged_in());
    setCurrentUser(current_user());
  };
  const setPageTitleAndSubTitle = (title, subTitle) => {
    setTitle(title);
    setSubTitle(subTitle);
  };
  return (
    <BrowserRouter>
      <div id="main-wrapper" className="show">
        <Loading />
        {isLoggedIn && (
          <>
            <NavHeader />
            <TopNavigation getLoggedInUser={getLoggedInUser} currentUser={currentUser} title={title} subTitle={subTitle} />
            <Sidebar currentUser={currentUser} />
          </>
        )}
        <div className={isLoggedIn ? "content-body" : ""}>
          <Routes>
            <Route
              path="/"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <Dashboard setPageTitleAndSubTitle={setPageTitleAndSubTitle} currentUser={currentUser} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/members"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListMembers setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/member-details"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <MemberDetails setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/categories"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListCategories setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/category-filters"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListFilters setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/filters"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListFilters setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/videos"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListVideos setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/lecture-categories"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListLectureCategories setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />

            <Route
              path="/lecture-subcategories"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListLectureSubcategories setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />

            <Route
              path="/lecture-videos"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListLectures setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />

            <Route
              path="/lecture-category-subcategories"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListLectureSubcategories setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/lecture-subcategory-videos"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListLectures setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/lecture-details"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <LectureDetails setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/admins/add"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <AddAdmins getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/admins/edit/:id"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <EditAdmins getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/login"
              element={
                <LoginRoute isLoggedIn={isLoggedIn}>
                  <Login getLoggedInUser={getLoggedInUser} />
                </LoginRoute>
              }
            />
            <Route
              path="/program-categories"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListProgramCategories setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/program-category-programs"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListPrograms setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/program-weeks"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListProgramWeeks setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/week-days"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListWeekDays setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/intro-week-details"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <IntroWeekDetails setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/week-day-details"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <WeekDayDetails setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/workout-categories"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListWorkoutCategories setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/workout-subcategories"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListWorkoutSubcategories setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/additional-workouts"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListWorkouts setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/workout-details"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <WorkoutDetails setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/roles-list"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <RolesList setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/permissions-list"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <PermissionsList setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/permission-types"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <PermissionTypes setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/permissions"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <PermissionsCrud setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/academy-sliders"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <AcademySliders setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/academy-sliders"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <AcademySliders setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-users"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <AdminUsersList setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/subscriptions"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <SubscriptionsList setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/transactions"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <TransactionsList setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/coupons"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <CouponsList setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/coupon-promotions-list"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <PromotionsList setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/faq-categories"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListFAQCategories setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListNotifications setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/faqs"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <FAQS setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/community/sm-team"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <ListSmTeam setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/sm-team-post-comments"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <SMTeamPostComments setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />
            <Route
              path="/sm-team-post-comment-replies"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <SMTeamPostCommentReplies setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />

            {/* SM Community Posts  */}

            <Route
              path="/community-posts"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <CommunityPostsList setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />

            <Route
              path="/community-post-comments"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <CommunityPostComments setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />

            <Route
              path="/community-post-replies"
              element={
                <PrivateRoute isLoggedIn={isLoggedIn}>
                  <CommunityPostReplies setPageTitleAndSubTitle={setPageTitleAndSubTitle} getLoggedInUser={getLoggedInUser} />
                </PrivateRoute>
              }
            />

            {/* Stripe webview route */}

            <Route path="/payment-webview" element={<StripeWebview />} />
            <Route path="/stripe-form" element={<CheckoutFormWrapper />} />
            <Route path="/success-loading" element={<SuccessLoading />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/payment-failed" element={<PaymentFailed />} />
            <Route path="/share/saturnomovement" element={<ShareApp />} />
          </Routes>
        </div>
        {isLoggedIn && (
          <>
            <Footer />
          </>
        )}

        <ToastContainer />
      </div>
    </BrowserRouter>
  );
}

export default App;
