import { useStripe, useElements, PaymentElement, AddressElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { getCurrencySymbol, stripePaymentResponse } from "../../../functions/general";
import { useState } from "react";

const CheckoutForm = ({ data }) => {
  const [btnLoading, setBtnLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBtnLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.origin + "/stripe-form",
      },
      redirect: "if_required",
    });

    if (result.error) {
      setBtnLoading(false);
      // Show error to your customer (for example, payment details incomplete)
      // toast.error("Error.");
    } else {
      const payload = {
        amount: result?.paymentIntent?.amount,
        currency: result?.paymentIntent?.currency,
        client_secret: result?.paymentIntent?.client_secret,
      };
      stripePaymentResponse(payload, data?.userToken).then((res) => {
        if ((res && res.status == 201) || res.status == 200) {
          if (res?.data) {
            setBtnLoading(false);
            navigate("/success-loading");
          }
        } else if (res && res.status == 401) {
          setBtnLoading(false);
          navigate("/payment-failed");
        } else {
        }
      });
    }
  };

  return (
    <div>
      <div>
        <i
          class="fas fa fa-arrow-circle-left"
          style={{ fontSize: "32px", color: "white", marginBottom: "15px", cursor: "pointer" }}
          onClick={() => {
            navigate(-1);
          }}
        ></i>
      </div>
      <div className="text-center text-white">
        <span>Try Saturnomovement Membership</span>
        <h1 className="text-white my-2">7 days free</h1>
        <span>
          Then {getCurrencySymbol(data?.selectedSubscription?.price?.currency)}
          {data?.selectedSubscription?.price?.unit_amount / 100} per{" "}
          {data?.selectedSubscription?.price?.recurring && data?.selectedSubscription?.price?.recurring?.interval}
        </span>
        <div>
          <h3 className="mt-3 mb-4 text-white">
            Total Amount after trial will be {getCurrencySymbol(data?.selectedSubscription?.price?.currency)}
            {data?.discountedAmount ? data?.discountedAmount / 100 : data?.selectedSubscription?.price?.unit_amount / 100}
          </h3>
        </div>
      </div>
      <form onSubmit={handleSubmit} style={{ minHeight: "320px" }}>
        <PaymentElement />
        {stripe && elements && (
          <button className="btn btn-custom w-100 mt-2 py-3 d-flex justify-content-center align-items-center" disabled={btnLoading}>
            {btnLoading && <span className="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>} Continue
          </button>
        )}
      </form>
    </div>
  );
};

export default CheckoutForm;
