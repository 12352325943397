import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  addLectureCategory,
  deleteLectureCategory,
  deleteLectureCategoryPermanently,
  getLectureCategories,
  remove_session,
  updateLectureCategory,
  restoreLectureCategory,
  getProgramCategories,
  updateProgramCategory,
  addProgramCategory,
  deleteProgramCategoryPermanently,
  restoreProgramCategory,
  deleteProgramCategory,
  getRolesList,
  updateRole,
  addRole,
  deleteRolePermanently,
  restoreRole,
  deleteRole,
} from "../../../functions/general";
import { toast } from "react-toastify";
import Media from "../../../components/Media";
import ModalImage from "react-modal-image";
const RolesList = (props) => {
  const navigate = useNavigate();
  const [rolesList, setRolesList] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [image, setImage] = React.useState("");
  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState("");
  useEffect(() => {
    props.setPageTitleAndSubTitle("Roles List", "Here is the list of Roles !");
    fetchData();
  }, []);

  const fetchData = () => {
    getRolesList().then((res) => {
      if ((res && res?.status == 200) || res?.status === 201) {
        setRolesList(res?.data);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };

  const modalHandler = (role) => {
    if (role && role._id) {
      setModalTitle("Update role");
      setModalButtonText("Update");
      setTitle(role.name);
      setId(role._id);
    } else {
      setModalTitle("Add New role");
      setModalButtonText("Add");
      setTitle("");
      setId("");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const role = {
        name: title,
      };
      if (id) {
        updateRole(id, role).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Role updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update role.");
          }
          setLoading(false);
        });
      } else {
        addRole(role).then((res) => {
          if (res && res.status == 201) {
            fetchData();
            toast.success("Role added successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add role.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
    };
    let isValid = true;
    if (!title) {
      isValid = false;
      errors.title = "Please enter role name.";
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, category) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete role?") == true) {
      if (window.confirm("Are you sure you want to delete role permanently?") == true) {
        deleteRolePermanently(category._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Role deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete role.");
          }
        });
      }
    }
  };

  const deleteStatusHandler = (e, category) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the Role?";
    if (category.isDeleted) {
      confirmText = "Are you sure you want to active the Role?";
    }
    if (window.confirm(confirmText) == true) {
      if (category.isDeleted) {
        restoreRole(category._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Role activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active role.");
          }
        });
      } else {
        deleteRole(category._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Role deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive category.");
          }
        });
      }
    }
  };

  const setImageHandler = (image) => {
    setImage(image);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-8">
            <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li class="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Roles
              </li>
            </ol>
          </div>
          <div className="col-4">
            <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={(e) => modalHandler()}>
              Add New Role
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Role Name</th>
                        <th>Permissions</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rolesList?.length > 0 ? (
                        rolesList?.map((role, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{role?.name}</td>
                              <td>
                                <Link to={`/permissions-list`} state={{ role }} className="btn btn-success btn-xs">
                                  Assign
                                </Link>
                              </td>
                              <td>
                                <label className="switch">
                                  <input type="checkbox" checked={!role.isDeleted} onClick={(e) => deleteStatusHandler(e, role)} />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                              <td>
                                <div className="d-flex">
                                  <button
                                    data-toggle="modal"
                                    data-target="#add"
                                    onClick={(e) => modalHandler(role)}
                                    className="btn btn-primary shadow btn-sm sharp mr-1"
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </button>
                                  <button onClick={(e) => deleteHandler(e, role)} className="btn btn-danger shadow btn-sm sharp">
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No role found !</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="add">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="form-group px-3">
                  <label>Role Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter role name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>}
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RolesList;
