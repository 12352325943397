// import React from 'react';
// import {login, get_response_or_errors} from "../../../functions/general";
// import {useNavigate} from "react-router-dom";

// function Login(props) {
//   const navigate = useNavigate();
//   const [loading, setLoading] = React.useState(false);
//   const [response, setResponse] = React.useState("");
//   const [email, setEmail] = React.useState("");
//   const [password, setPassword] = React.useState("");
//   const onSubmit = (e) => {
//     e.preventDefault()
//     setResponse("");
//     if (loading)
//       return false;
//     if (validate()) {
//       setLoading(true);
//       const user = {
//         email: email,
//         password: password
//       }
//       login(user).then(res => {
//         if (res && res.status == 200) {
//           props.getLoggedInUser();
//           navigate("/");
//         } else {
//           setResponse(get_response_or_errors(res))
//           setLoading(false)
//         }
//       })
//     }
//   }

//   const validate = () => {
//     let isValid = true;
//     if (!email) {
//       isValid = false;
//     }
//     if (!password) {
//       isValid = false;
//     }
//     return isValid;
//   }

//   return (
//       <div className="vh-100">
//         <div className="authincation h-100">
//         <div className="container h-100">
//           <div className="row justify-content-center h-100 align-items-center">
//             <div className="col-md-6">
//               <div className="authincation-content">
//                 <div className="row no-gutters">
//                   <div className="col-xl-12">
//                     <div className="auth-form">
//                       <h4 className="text-center mb-4">Sign in your account</h4>
//                       {response?.responseMessage &&
//                       <div
//                           className={`alert alert-${response?.responseFlag} fade show`}
//                           role="alert">
//                         {response?.responseMessage}
//                       </div>
//                       }
//                       <form onSubmit={onSubmit}>
//                         <div className="form-group">
//                           <label className="mb-1"><strong>Email</strong></label>
//                           <input type="email" className="form-control" placeholder="Email"
//                                  value={email} required
//                                  onChange={(e) => setEmail(e.target.value)}/>
//                         </div>
//                         <div className="form-group">
//                           <label className="mb-1"><strong>Password</strong></label>
//                           <input type="password" className="form-control" placeholder="Password"
//                                  value={password} required
//                                  onChange={(e) => setPassword(e.target.value)}/>
//                         </div>
//                         <div className="text-center">
//                           <button type="submit" className="btn btn-primary btn-block">
//                             {loading &&
//                                 <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
//                             } Sign Me In</button>
//                         </div>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       </div>
//   );
// }

// export default Login;

import React from "react";
import { login, get_response_or_errors } from "../../../functions/general";
import { useLocation, useNavigate } from "react-router-dom";

const Login = (props) => {
  const { state } = useLocation();

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [response, setResponse] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [showPassword, setShowPassword] = React.useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setResponse("");
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const user = {
        email: email,
        password: password,
      };
      login(user).then((res) => {
        if (res && res.status == 200) {
          props.getLoggedInUser();
          navigate("/");
        } else {
          setResponse(get_response_or_errors(res));
          setLoading(false);
        }
      });
    }
  };

  const validate = () => {
    let isValid = true;
    if (!email) {
      isValid = false;
    }
    if (!password) {
      isValid = false;
    }
    return isValid;
  };

  return (
    <div className="vh-100">
      <div className="authincation h-100">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="row justify-content-center mb-4">
                <img src="/logo.png" alt="" width={200} className="ms-auto" />
              </div>
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <h4 className="text-center mb-4">Sign in your account</h4>
                      {response?.responseMessage && (
                        <div className={`alert alert-${response?.responseFlag} fade show`} role="alert">
                          {response?.responseMessage}
                        </div>
                      )}

                      {state?.resetPassword && (
                        <div class="alert alert-success alert-dismissible fade show" role="alert">
                          Password updated successfully!
                          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      )}
                      <form onSubmit={onSubmit}>
                        <div className="form-group">
                          <label className="mb-1">
                            <strong>Email</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            value={email}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="password-input">
                          <label className="mb-1">
                            <strong>Password</strong>
                          </label>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="Password"
                            value={password}
                            required
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                            <i className={`fs-20 fas fa fa-eye${showPassword ? "-slash" : ""}`}></i>
                          </span>
                        </div>
                        <div className="text-center mt-4">
                          <button type="submit" className="btn btn-primary btn-block">
                            {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Sign Me In
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
