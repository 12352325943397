import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { assignPermissions, getAllPermissionsList, getAssignedPermissionsList, remove_session } from "../../../../functions/general";
import { toast } from "react-toastify";

const PermissionsList = (props) => {
  const [permissionsList, setPermissionsList] = React.useState([]);
  const [permissionIds, setPermissionIds] = React.useState([]);

  const navigate = useNavigate();

  const { state } = useLocation();

  useEffect(() => {
    props.setPageTitleAndSubTitle("Permissions List", "Here is the list of Permissions !");
    fetchData();
  }, []);

  const fetchData = () => {
    getAllPermissionsList().then((res) => {
      if ((res && res?.status == 200) || res?.status === 201) {
        setPermissionsList(res?.data);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
    getAssignedPermissionsList(state?.role?._id).then((res) => {
      if ((res && res?.status == 200) || res?.status === 201) {
        setPermissionIds(res?.data?.permissions);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };

  // const assignPermissionsHandler = (e) => {
  //   e.preventDefault();
  //   const payload = {
  //     roleId: state?.role?._id,
  //     permissionIds: permissionIds,
  //   };
  //   assignPermissions(payload).then((res) => {
  //     if (res && res.status == 201) {
  //       fetchData();
  //       toast.success("Permissions assigned successfully.");
  //     } else if (res && res.status == 401) {
  //       remove_session();
  //       props.getLoggedInUser();
  //       navigate("/login");
  //     } else {
  //       toast.error("Failed to assign permission.");
  //     }
  //   });
  // };

  const assignPermissionsHandler = (e) => {
    e.preventDefault();

    // Filter the permissionIds array to remove IDs that are not included in permissionsList
    const filteredPermissionIds = permissionIds?.filter((id) =>
      permissionsList?.some((permission) => permission?.permissions?.some((p) => p?.id === id))
    );

    const payload = {
      roleId: state?.role?._id,
      permissionIds: filteredPermissionIds,
    };

    assignPermissions(payload).then((res) => {
      if (res && res.status === 201) {
        fetchData();
        toast.success("Permissions assigned successfully.");
      } else if (res && res.status === 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to assign permission.");
      }
    });
  };

  // Function to handle checkbox change event
  const handleCheckboxChange = (event) => {
    const checkboxId = event.target.id;

    if (event.target.checked) {
      // Add the checkbox ID to the array if it's not already present
      if (!permissionIds.includes(checkboxId)) {
        setPermissionIds((prevCheckedIds) => [...prevCheckedIds, checkboxId]);
      }
    } else {
      // Remove the checkbox ID from the array
      setPermissionIds((prevCheckedIds) => prevCheckedIds.filter((id) => id !== checkboxId));
    }
  };

  // Function to handle "Select All" button click event
  const handleSelectAll = () => {
    const allCheckboxIds = permissionsList.flatMap((item) => item.permissions.map((permission) => permission.id));
    setPermissionIds(allCheckboxIds);
  };

  // Function to handle "Remove All" button click event
  const handleRemoveAll = () => {
    setPermissionIds([]);
  };

  return (
    <>
      <div class="container-fluid">
        <div className="row mb-4">
          <div className="col-8">
            <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li class="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li class="breadcrumb-item">
                <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate(-1)}>
                  Roles
                </span>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Assign Permissions
              </li>
            </ol>
          </div>
          <div className="col-4">
            {/* <button className="btn btn-primary float-right" data-toggle="modal" data-target="#createPermission" onClick={(e) => modalHandler()}>
            <button className="btn btn-primary float-right" data-toggle="modal" data-target="#createPermission">
              Add New Role
            </button> */}
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row mb-5">
                  <div class="col-12">
                    <button class="btn btn-secondary float-right btn-sm" onClick={handleSelectAll}>
                      Select All
                    </button>
                    <button class="btn btn-danger float-right btn-sm mr-3" onClick={handleRemoveAll}>
                      Remove All
                    </button>
                  </div>
                </div>
                <div class="row mb-4">
                  {permissionsList?.map((permissionsListItem, index) => (
                    <div class="col-md-3 mb-3" key={index}>
                      <h4 class="mb-4">{permissionsListItem?.name}</h4>
                      {permissionsListItem?.permissions &&
                        permissionsListItem?.permissions?.map((permission, index) => (
                          <div class="form-check permission-checkbox mb-2" key={index}>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id={permission?.id}
                              onChange={handleCheckboxChange}
                              checked={permissionIds.includes(permission?.id)}
                            />
                            <label class="form-check-label mb-2 pl-1" for={permission?.id}>
                              {permission?.name}
                            </label>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
                <div class="row mb-4">
                  <div class="col-12">
                    <button class="btn btn-primary float-right mt-5" onClick={assignPermissionsHandler}>
                      Assign
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="createPermission">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header border-0 px-3 mb-3">
              {/* <h5 className="modal-title">{modalTitle}</h5> */}
              <h5 className="modal-title">Create New Permission</h5>
              <button type="button" className="close" data-dismiss="modal" id="permissionModalCloseButton">
                <span>&times;</span>
              </button>
            </div>
            {/* <form noValidate onSubmit={onSubmit}>
              <div className="form-group px-3">
                <label>Role Name</label>
                <input type="text" className="form-control" placeholder="Enter role name" value={title} onChange={(e) => setTitle(e.target.value)} />
                {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>}
              </div>
              <div className="modal-footer border-0">
                <button type="submit" className="btn btn-primary">
                  {modalButtonText}
                </button>
              </div>
            </form> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PermissionsList;
