import React from "react";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";

function NavHeader() {
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        <img className="logo-abbr" src={logo} alt="" />
      </Link>
      <div className="nav-control">
        <div className="hamburger">
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
}

export default NavHeader;
