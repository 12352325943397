import React from "react";
import { Link } from "react-router-dom";
// import PerfectScrollbar from 'perfect-scrollbar';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Helmet } from "react-helmet";
import { usePermissions } from "../functions/usePermissions";
function Sidebar(props) {
  const currentUser = props.currentUser;
  const { hasPermission } = usePermissions();

  let pathName = window.location.pathname;

  const programPathnames = [
    "/program-categories",
    "/program-category-programs",
    "/program-weeks",
    "/intro-week-details",
    "/week-days",
    "/week-day-details",
  ];
  const lecturePathnames = ["/lecture-categories", "/lecture-category-subcategories", "/lecture-subcategory-videos"];
  const workoutPathnames = ["/workout-categories", "/workout-subcategories", "/additional-workouts", "/workout-details"];

  const communityPathnames = ["community/sm-team", "/community-posts"];
  return (
    <>
      <Helmet>
        <script>$("#menu").metisMenu();</script>
      </Helmet>
      <PerfectScrollbar className="deznav">
        <div className="deznav-scroll">
          <ul className="metismenu" id="menu">
            <li className={pathName === "/" ? "mm-active" : ""}>
              <Link to="/">
                <i className="fas fa fa-dashboard"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>

            {currentUser?.isSuperAdmin && (
              <li className={pathName === "/roles-list" || pathName === "/admin-users" ? "mm-active" : ""}>
                <a className="has-arrow ai-icon" aria-expanded="false">
                  <i className="flaticon-381-user-2"></i>
                  <span className="nav-text">System User</span>
                </a>
                <ul aria-expanded="false">
                  <li>
                    <Link to="/roles-list">Roles Management</Link>
                  </li>
                  <li>
                    <Link to="/admin-users">Admin Users</Link>
                  </li>
                </ul>
              </li>
            )}

            {(hasPermission("view-academy-sliders") || hasPermission("view-workout-creator-sliders")) && (
              <li className={pathName === "/academy-sliders" ? "mm-active" : ""}>
                <a className="has-arrow ai-icon" aria-expanded="false">
                  <i className="flaticon-381-notepad"></i>
                  <span className="nav-text">App Sliders</span>
                </a>
                <ul aria-expanded="false">
                  {hasPermission("view-academy-sliders") && (
                    <li>
                      <Link to="/academy-sliders?type=academy">Academy Sliders</Link>
                    </li>
                  )}
                  {hasPermission("view-workout-creator-sliders") && (
                    <li>
                      <Link to="/academy-sliders?type=workout">Workout Creator Sliders</Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {(hasPermission("view-members") ||
              hasPermission("view-subscriptions") ||
              hasPermission("view-transactions") ||
              hasPermission("view-coupons")) && (
              <li className={pathName === "/members" ? "mm-active" : ""}>
                <a className="has-arrow ai-icon" aria-expanded="false">
                  <i className="flaticon-381-user-2"></i>
                  <span className="nav-text">User Management</span>
                </a>
                <ul aria-expanded="false">
                  {hasPermission("view-members") && (
                    <li>
                      <Link to="members" style={{ color: pathName === "/members" ? "#132C48" : "" }}>
                        Members
                      </Link>
                    </li>
                  )}
                  {hasPermission("view-subscriptions") && (
                    <li>
                      <Link to="/subscriptions" style={{ color: pathName === "/subscriptions" ? "#132C48" : "" }}>
                        Subscriptions
                      </Link>
                    </li>
                  )}
                  {hasPermission("view-transactions") && (
                    <li>
                      <Link to="/transactions" style={{ color: pathName === "/transactions" ? "#132C48" : "" }}>
                        Transactions
                      </Link>
                    </li>
                  )}
                  {hasPermission("view-coupons") && (
                    <li>
                      <Link to="/coupons" style={{ color: pathName === "/coupons" || pathName === "/coupon-promotions-list" ? "#132C48" : "" }}>
                        Coupons
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {(hasPermission("view-workout-creator-videos") ||
              hasPermission("view-workout-creator-categories") ||
              hasPermission("view-worktout-creator-filters")) && (
              <li
                className={
                  pathName === "/videos" || pathName === "/categories" || pathName === "/category-filters" || pathName === "/filters"
                    ? "mm-active"
                    : ""
                }
              >
                <a className="has-arrow ai-icon" aria-expanded="false">
                  <i className="flaticon-381-notepad"></i>
                  <span className="nav-text">Workout Creator</span>
                </a>
                <ul aria-expanded="false">
                  {hasPermission("view-workout-creator-videos") && (
                    <li>
                      <Link to="videos">Videos</Link>
                    </li>
                  )}
                  {hasPermission("view-workout-creator-categories") && (
                    <li>
                      <Link to="categories">Categories</Link>
                    </li>
                  )}
                  {hasPermission("view-worktout-creator-filters") && (
                    <li>
                      <Link to="filters">Filters</Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {(hasPermission("view-program-categories") ||
              hasPermission("view-lecture-categories") ||
              hasPermission("view-workout-categories") ||
              hasPermission("view-faqs") ||
              hasPermission("view-notifications")) && (
              <li
                className={
                  programPathnames.includes(pathName) || lecturePathnames.includes(pathName) || workoutPathnames.includes(pathName) ? "mm-active" : ""
                }
              >
                <a className="has-arrow ai-icon" aria-expanded="false">
                  <i className="flaticon-381-notepad"></i>
                  <span className="nav-text">Academy</span>
                </a>
                <ul aria-expanded="false">
                  {hasPermission("view-program-categories") && (
                    <li className={programPathnames.includes(pathName) ? "mm-active" : ""}>
                      <Link style={{ color: programPathnames.includes(pathName) ? "#132C48" : "" }} to="program-categories">
                        Programs
                      </Link>
                    </li>
                  )}

                  {hasPermission("view-lecture-categories") && (
                    <li className={lecturePathnames.includes(pathName) ? "mm-active" : ""}>
                      <Link style={{ color: lecturePathnames.includes(pathName) ? "#132C48" : "" }} to="/lecture-categories">
                        Lectures
                      </Link>
                    </li>
                  )}
                  {hasPermission("view-workout-categories") && (
                    <li className={workoutPathnames.includes(pathName) ? "mm-active" : ""}>
                      <Link style={{ color: workoutPathnames.includes(pathName) ? "#132C48" : "" }} to="/workout-categories">
                        Workouts
                      </Link>
                    </li>
                  )}
                  {hasPermission("view-faqs") && (
                    <li className={pathName === "faqs" ? "mm-active" : ""}>
                      <Link style={{ color: pathName === "faqs" ? "#132C48" : "" }} to="/faq-categories">
                        FAQs
                      </Link>
                    </li>
                  )}
                  {hasPermission("view-notifications") && (
                    <li className={pathName === "notifications" ? "mm-active" : ""}>
                      <Link style={{ color: pathName === "notifications" ? "#132C48" : "" }} to="/notifications">
                        Notifications
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {hasPermission("view-sm-team") && (
              <li className={communityPathnames.includes(pathName) ? "mm-active" : ""}>
                <a className="has-arrow ai-icon" aria-expanded="false">
                  <i className="flaticon-381-notepad"></i>
                  <span className="nav-text">Community</span>
                </a>
                <ul aria-expanded="false">
                  {hasPermission("view-sm-team") && (
                    <li className={communityPathnames.includes(pathName) ? "mm-active" : ""}>
                      <Link style={{ color: communityPathnames.includes(pathName) ? "#132C48" : "" }} to="community/sm-team">
                        SM Team
                      </Link>
                    </li>
                  )}
                  <li className={communityPathnames.includes(pathName) ? "mm-active" : ""}>
                    <Link style={{ color: communityPathnames.includes(pathName) ? "#132C48" : "" }} to="/community-posts">
                      Community Posts
                    </Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>

          {/* <div className="copyright position-absolute">
            <p>
              <strong>SaturnoMovement </strong> © 2023 All Rights Reserved
            </p>
            <p>
              Made with <i className="fa fa-heart text-danger"></i> by Softzee
            </p>
          </div> */}
        </div>
      </PerfectScrollbar>
    </>
  );
}

export default Sidebar;
