import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  remove_session,
  getProgramWeeksList,
  restoreWeek,
  deleteWeek,
  deleteWeekPermanently,
  updateWeek,
  addWeek,
  cloneWeek,
  sortProgramWeeks,
  postReferenceDay,
  getReferenceDaysList,
  updateReferenceDay,
  deleteReferenceDayPermanently,
  getProgramDetails,
  getProgramSwapsList,
  updateProgramSwap,
  postProgramSwap,
  deleteProgramSwapPermanently,
} from "../../../functions/general";
import { toast } from "react-toastify";
import { usePermissions } from "../../../functions/usePermissions";

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";
import DataTable from "react-data-table-component";

function ListProgramWeeks(props) {
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const [programWeeksList, setProgramWeeksList] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [weekType, setWeekType] = React.useState("");
  const [id, setId] = React.useState("");
  const [isPaid, setIsPaid] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [errors, setErrors] = React.useState("");
  const [referenceDay, setReferenceDay] = React.useState("");
  const [referenceDaysList, setReferenceDaysList] = React.useState([]);
  const [referenceDaysLoading, setReferenceDaysLoading] = React.useState(false);
  const [referenceDayId, setReferenceDayId] = React.useState("");

  //program swaps
  const [programSwap, setProgramSwap] = React.useState("");
  const [programSwapsList, setProgramSwapsList] = React.useState([]);
  const [programSwapsLoading, setProgramSwapsLoading] = React.useState(false);
  const [programSwapId, setProgramSwapId] = React.useState("");

  const [program, setProgram] = React.useState(null);
  const [searchParams] = useSearchParams();
  const programId = searchParams.get("programId");

  useEffect(() => {
    getProgramDetails(programId).then((res) => {
      if (res && res.status == 200) {
        setProgram(res?.data);
        props.setPageTitleAndSubTitle(res?.data?.title, "Here is the list of Weeks!");
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
    fetchData();
  }, []);

  const fetchData = (type) => {
    // const programId = searchParams.get("programId");
    getProgramWeeksList(programId).then((res) => {
      if (res && res.status == 201) {
        if (res.data) {
          setProgramWeeksList(res?.data);
          if (type === "add") {
            let sortedArray = [];
            res?.data?.map((week) => sortedArray.push(week?._id));
            sortProgramWeeks(sortedArray).then((res) => {
              if (res && res.status == 200) {
                // fetchData();
              } else if (res && res.status == 401) {
                remove_session();
                props.getLoggedInUser();
                navigate("/login");
              } else {
                toast.error("Failed to sort the list.");
              }
              setTableDataLoading(false);
            });
          }
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });

    getReferenceDaysList(programId).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        setReferenceDaysList(res.data);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setReferenceDaysLoading(false);
    });

    getProgramSwapsList(programId).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        setProgramSwapsList(res.data);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setProgramSwapsLoading(false);
    });
  };
  const modalHandler = (week) => {
    if (week && week?._id) {
      setModalTitle("Update Week");
      setModalButtonText("Update");
      setTitle(week?.title);
      setWeekType(week?.type);
      setIsPaid(week?.isPaid);
      setId(week?._id);
    } else {
      setModalTitle("Add New Week");
      setModalButtonText("Add");
      setTitle("");
      setWeekType("");
      setIsPaid(true);
      setId("");
    }
  };

  ///reference day modal handler
  const referenceModalHandler = () => {
    setModalTitle("Add Reference Day");
    setModalButtonText("Save");
    setReferenceDay("");
  };

  const onDayReferenceSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (referenceValidate()) {
      setLoading(true);
      if (referenceDayId) {
        updateReferenceDay(referenceDay, program, referenceDayId).then((res) => {
          if (res && (res.status == 201 || res.status == 200)) {
            fetchData();
            toast.success("Reference day update successfully.");
            setReferenceDay("");
            setReferenceDayId("");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error(res?.data?.message);
          }
          setLoading(false);
        });
      } else {
        postReferenceDay(referenceDay, program).then((res) => {
          if (res && (res.status == 201 || res.status == 200)) {
            fetchData();
            toast.success("Reference day added successfully.");
            setReferenceDay("");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error(res?.data?.message);
          }
          setLoading(false);
        });
      }
    }
  };

  const deleteDayReferenceHandler = (e, day) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete reference day permanently?") == true) {
      deleteReferenceDayPermanently(day?._id).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("Reference Day deleted successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete day.");
        }
      });
    }
  };

  ///program swap modal handler
  const programSwapModalHandler = () => {
    setModalButtonText("Save");
    setProgramSwap("");
  };

  const onProgramSwapSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (programSwapValidate()) {
      setLoading(true);
      if (programSwapId) {
        updateProgramSwap(programSwap, programId, programSwapId).then((res) => {
          if (res && (res.status == 201 || res.status == 200)) {
            fetchData();
            toast.success("Program swap updated successfully.");
            setProgramSwap("");
            setProgramSwapId("");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error(res?.data?.message);
          }
          setLoading(false);
        });
      } else {
        postProgramSwap(programSwap, programId).then((res) => {
          if (res && (res.status == 201 || res.status == 200)) {
            fetchData();
            toast.success("Program swap added successfully.");
            setProgramSwap("");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error(res?.data?.message);
          }
          setLoading(false);
        });
      }
    }
  };

  const deleteProgramSwapHandler = (e, day) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete program swap permanently?") == true) {
      deleteProgramSwapPermanently(day?._id).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("Program swap deleted successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete program swap.");
        }
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const weekData = {
        title: title,
        type: weekType,
        isPaid: isPaid,
        program: program,
      };
      let type = "add";
      if (id) {
        updateWeek(id, weekData).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Program week updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update program week.");
          }
          setLoading(false);
        });
      } else {
        addWeek(weekData).then((res) => {
          if (res && (res.status == 201 || res.status == 201)) {
            fetchData(type);
            toast.success("Program week added successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add program week.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      weekType: "",
    };
    let isValid = true;
    if (!title) {
      isValid = false;
      errors.title = "Please enter title";
    }
    if (!weekType) {
      isValid = false;
      errors.weekType = "Please select program type";
    }
    setErrors(errors);
    return isValid;
  };

  const referenceValidate = () => {
    let errors = {
      referenceDay: "",
    };
    let isValid = true;
    if (!referenceDay) {
      isValid = false;
      errors.referenceDay = "Please enter title";
    }
    setErrors(errors);
    return isValid;
  };

  //program swap validate

  const programSwapValidate = () => {
    let errors = {
      programSwap: "",
    };
    let isValid = true;
    if (!programSwap) {
      isValid = false;
      errors.programSwap = "Please enter title";
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, week) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete week?") == true) {
      deleteWeekPermanently(week?._id).then((res) => {
        if (res && (res.status == 200 || res.status == 201)) {
          fetchData();
          toast.success("Week deleted successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete Week.");
        }
      });
    }
  };

  const deleteStatusHandler = (e, week) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the week?";
    if (week?.isDeleted) {
      confirmText = "Are you sure you want to active the week?";
    }
    if (window.confirm(confirmText) == true) {
      if (week?.isDeleted) {
        restoreWeek(week._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Week activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active week.");
          }
        });
      } else {
        deleteWeek(week?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Week deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive week.");
          }
        });
      }
    }
  };

  const paymentStatusHandler = (e, week) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to change payment status of week?";
    if (window.confirm(confirmText) == true) {
      const programData = {
        title: week?.title,
        type: week?.type,
        program: program,
        isPaid: e.target.checked,
      };
      updateWeek(week._id, programData).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("Payment status changed successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to change the payment status of week.");
        }
      });
    }
  };

  const weekCloneHandler = (e, week) => {
    setTableDataLoading(true);
    e.preventDefault();
    if (window.confirm("Are you sure you want to clone week?") == true) {
      cloneWeek(week?._id).then((res) => {
        if (res && (res.status == 200 || res.status == 201)) {
          fetchData();
          toast.success("Week cloned successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to clone Week.");
        }
        setTableDataLoading(false);
      });
    } else {
      setTableDataLoading(false);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    const updatedProgramWeeks = arrayMove(programWeeksList, oldIndex, newIndex);
    let sortedArray = [];
    updatedProgramWeeks?.map((week) => sortedArray.push(week?._id));
    sortProgramWeeks(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setProgramWeeksList(updatedProgramWeeks);
        // fetchData();
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ week, serialNo }) => {
    return (
      <tr>
        <td>{serialNo + 1}</td>
        <td>
          {week?.type === "intro" ? (
            <Link
              style={{ color: "blue" }}
              to={`/intro-week-details?weekId=${week?._id}`}
              // state={{ week: week, program: state?.program?.title, category: state?.category }}
            >
              {week?.title}
            </Link>
          ) : (
            week?.title
          )}
        </td>
        <td>{week?.type}</td>
        {hasPermission("view-days") && (
          <td>
            <Link
              to={week?.type === "intro" ? window?.location : `/week-days?weekId=${week?._id}`}
              // state={{ week: week, program: state?.program?.title, category: state?.category }}
              className={week?.type === "intro" ? "btn btn-dark btn-xs disabled-link" : "btn btn-dark btn-xs"}
            >
              {week?.count ? week?.count : 0}
            </Link>
          </td>
        )}
        {hasPermission("edit-week") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={week?.isPaid} onClick={(e) => paymentStatusHandler(e, week)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {hasPermission("edit-week") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={!week.isDeleted} onClick={(e) => deleteStatusHandler(e, week)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {(hasPermission("edit-week") || hasPermission("delete-week")) && (
          <td>
            <div className="d-flex">
              {hasPermission("edit-week") && (
                <button
                  data-toggle="modal"
                  data-target="#add"
                  onClick={(e) => modalHandler(week)}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {hasPermission("delete-week") && (
                <button onClick={(e) => deleteHandler(e, week)} className="btn btn-danger shadow btn-sm sharp mr-1">
                  <i className="fa fa-trash"></i>
                </button>
              )}
              {hasPermission("edit-week") && (
                <button onClick={(e) => weekCloneHandler(e, week)} className="btn btn-secondary shadow btn-sm" style={{ padding: "9px 13px" }}>
                  <i className="fa fa-copy" style={{ fontSize: "16px" }}></i>
                </button>
              )}
            </div>
          </td>
        )}
        {hasPermission("edit-week") && (
          <td>
            <DragHandle />
          </td>
        )}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ programWeeksList }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>No</th>
            <th>Title</th>
            <th>Type</th>
            {hasPermission("view-days") && <th>Days</th>}
            {hasPermission("edit-week") && <th>Paid</th>}
            {hasPermission("edit-week") && <th>Status</th>}
            {(hasPermission("edit-week") || hasPermission("delete-week")) && <th>Actions</th>}
            {hasPermission("edit-week") && <th>Sort Order</th>}
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="8" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {programWeeksList?.length > 0 ? (
                programWeeksList?.map((week, index) => {
                  return <SortableTableRow key={index} week={week} index={index} serialNo={index} />;
                })
              ) : (
                <tr>
                  <td colSpan="8" className="text-center pt-5">
                    No week found!
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  const columns = [
    {
      name: "Serial",
      selector: (row, index) => index + 1,
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      left: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <button
            onClick={() => {
              setReferenceDayId(row?._id);
              setReferenceDay(row?.title);
            }}
            className="btn btn-primary shadow btn-sm sharp mr-1"
          >
            <i className="fa fa-pencil"></i>
          </button>
          <button onClick={(e) => deleteDayReferenceHandler(e, row)} className="btn btn-danger shadow btn-sm sharp">
            {/* <button className="btn btn-danger shadow btn-sm sharp"> */}
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
      right: true,
    },
  ];

  // program swap columns

  const programSwapColumns = [
    {
      name: "Serial",
      selector: (row, index) => index + 1,
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      left: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <button
            onClick={() => {
              setProgramSwapId(row?._id);
              setProgramSwap(row?.title);
            }}
            className="btn btn-primary shadow btn-sm sharp mr-1"
          >
            <i className="fa fa-pencil"></i>
          </button>
          <button onClick={(e) => deleteProgramSwapHandler(e, row)} className="btn btn-danger shadow btn-sm sharp">
            {/* <button className="btn btn-danger shadow btn-sm sharp"> */}
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
      right: true,
    },
  ];

  // console.log(date_time_to_date_format("2023-05-30T14:31:48.000Z"));
  const customStyles = {
    rows: {
      style: {
        minHeight: "70px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "18px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "16px",
      },
    },
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4 align-items-center">
          <div className="col-6">
            {program && (
              <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
                <li class="breadcrumb-item">
                  <span onClick={() => navigate("/")}>
                    <i className="fas fa fa-home" style={{ fontSize: "28px" }}></i>
                  </span>
                </li>
                <li class="breadcrumb-item">
                  <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate("/program-categories")}>
                    Programs
                  </span>
                </li>
                <li class="breadcrumb-item">
                  <span
                    style={{ color: "#132C48", cursor: "pointer" }}
                    onClick={() => navigate(`/program-category-programs?categoryId=${program?.category?._id}`)}
                  >
                    {program?.category?.title}
                  </span>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {program?.title}
                </li>
              </ol>
            )}
          </div>
          <div className="col-6">
            {hasPermission("add-week") && (
              <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={() => modalHandler()}>
                Add New
              </button>
            )}
            {hasPermission("add-week") && (
              <button
                className="btn btn-primary float-right mr-2"
                data-toggle="modal"
                data-target="#addReference"
                onClick={() => referenceModalHandler()}
              >
                Add Day Reference
              </button>
            )}
            <button
              className="btn btn-primary float-right mr-2"
              data-toggle="modal"
              data-target="#addProgramSwap"
              onClick={() => programSwapModalHandler()}
            >
              Add Program Swap
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <SortableTable programWeeksList={programWeeksList} onSortEnd={onSortEnd} useDragHandle={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="add">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="form-group px-3">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Week Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {errors.title && !title && <div className="text-warning font-size-12">{errors.title}</div>}
                </div>
                <div className="form-group px-3">
                  <label>Week Type</label>
                  <select className="form-control" value={weekType} onChange={(e) => setWeekType(e.target.value)}>
                    <option value="">Select Type</option>
                    <option value="intro">Intro</option>
                    <option value="normal">Normal</option>
                  </select>
                  {errors.weekType && !weekType && <div className="text-warning font-size-12">{errors.weekType}</div>}
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="modal fade" id="addReference" data-backdrop="static">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="addReference">
                  <span>&times;</span>
                </button>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <form noValidate onSubmit={onDayReferenceSubmit}>
                    <div className="form-group px-3">
                      <label>Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter reference day"
                        value={referenceDay}
                        onChange={(e) => setReferenceDay(e.target.value)}
                      />
                      {errors.referenceDay && !referenceDay && <div className="text-warning font-size-12">{errors.referenceDay}</div>}
                    </div>
                    <div className="modal-footer border-0">
                      <button type="submit" className="btn btn-primary">
                        {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}{" "}
                        {modalButtonText}
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <DataTable
                          className="custom-data-table"
                          columns={columns}
                          data={referenceDaysList && referenceDaysList}
                          pagination={false}
                          customStyles={customStyles}
                          progressPending={referenceDaysLoading}
                          persistTableHead
                          progressComponent={<div className="spinner-border text-black mt-5 mb-3" role="status" aria-hidden="true"></div>}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="addProgramSwap" data-backdrop="static">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">Add Program Swap</h5>
                <button type="button" className="close" data-dismiss="modal" id="addProgramSwap">
                  <span>&times;</span>
                </button>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <form noValidate onSubmit={onProgramSwapSubmit}>
                    <div className="form-group px-3">
                      <label>Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter program swap"
                        value={programSwap}
                        onChange={(e) => setProgramSwap(e.target.value)}
                      />
                      {errors.programSwap && !programSwap && <div className="text-warning font-size-12">{errors.programSwap}</div>}
                    </div>
                    <div className="modal-footer border-0">
                      <button type="submit" className="btn btn-primary">
                        {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}{" "}
                        {modalButtonText}
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <DataTable
                          className="custom-data-table"
                          columns={programSwapColumns}
                          data={programSwapsList && programSwapsList}
                          pagination={false}
                          customStyles={customStyles}
                          progressPending={programSwapsLoading}
                          persistTableHead
                          progressComponent={<div className="spinner-border text-black mt-5 mb-3" role="status" aria-hidden="true"></div>}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListProgramWeeks;
