import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  remove_session,
  getFinalAssesmentResultsList,
  addFinalAssesmentResult,
  updateFinalAssesmentResult,
  deleteFinalAssesmentResult,
  deleteFinalAssesmentResultPermanently,
  restoreFinalAssesmentResult,
  getSuggestionsList,
  updateWeekDayText,
} from "../../../functions/general";
import { toast } from "react-toastify";
import Select from "react-select";
import { usePermissions } from "../../../functions/usePermissions";

function FinalAssessmentResult(props) {
  const { hasPermission } = usePermissions();
  const { state } = useLocation();

  const navigate = useNavigate();
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [rangeFrom, setRangeFrom] = React.useState("");
  const [rangeTo, setRangeTo] = React.useState("");
  const [color, setColor] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState("");
  //   const [finalAssesmentResultsList, setfinalAssesmentResultsList] = React.useState([]);
  const [finalAssesmentResultsList, setfinalAssesmentResultsList] = React.useState([]);
  const [suggestionsList, setSuggestionsList] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [microcycles, setMicrocycles] = React.useState([]);
  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [tabName, setTabName] = React.useState(props?.day?.resultTabName ? props?.day?.resultTabName : "Result");
  const [tabNameBtnLoading, setTabNameBtnLoading] = React.useState("");

  useEffect(() => {
    // props.setPageTitleAndSubTitle("Lecture Videos", "Here is the list of Lecture Categories !");
    fetchData();
  }, []);

  const fetchData = () => {
    getFinalAssesmentResultsList(props?.day._id).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        setfinalAssesmentResultsList(res?.data);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
    getSuggestionsList(props?.category?._id).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        const suggestions = res?.data?.map((item) => {
          return {
            label: item?.title,
            options: item?.program?.map((item) => {
              return { value: item?._id, label: item?.type === "microcycles" ? `${item?.title} (Microcycle)` : item?.title };
            }),
          };
        });
        setSuggestionsList(suggestions);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };

  const modalHandler = (result) => {
    if (result && result._id) {
      setModalTitle("Update Result");
      setModalButtonText("Update");
      setTitle(result?.title);
      setDescription(result?.remarks);
      setRangeFrom(result?.percentage_from);
      setRangeTo(result?.percentage_to);
      setColor(result?.color);
      setDescription(result?.remarks);
      setId(result._id);
      if (result?.microcycles?.length > 0) {
        const suggestions = result?.microcycles?.map((suggestion) => {
          return { value: suggestion?._id, label: suggestion?.title };
        });
        setSelectedOptions(suggestions);
      }
      setMicrocycles([]);
    } else {
      setModalTitle("Add New Result");
      setModalButtonText("Add");
      setTitle("");
      setDescription("");
      setRangeFrom("");
      setRangeTo("");
      setColor("");
      setSelectedOptions([]);
      setDescription("");
      setId("");
      setMicrocycles([]);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const resultData = {
        title: title,
        percentage_from: rangeFrom,
        percentage_to: rangeTo,
        color: color,
        remarks: description,
        weekDay: props?.day,
        microcycles: microcycles?.length > 0 ? microcycles : selectedOptions?.map((item) => item.value),
      };
      if (id) {
        updateFinalAssesmentResult(id, resultData).then((res) => {
          if (res && (res.status == 200 || res.status == 201)) {
            fetchData();
            toast.success("Result updated successfully.");
            document.getElementById("finalAssesmentResultModal").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update Result.");
          }
          setLoading(false);
        });
      } else {
        addFinalAssesmentResult(resultData).then((res) => {
          if (res && res.status == 201) {
            fetchData();
            toast.success("Result added successfully.");
            document.getElementById("finalAssesmentResultModal").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add Result.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      from: "",
      to: "",
      description: "",
      color: "",
    };
    let isValid = true;
    if (!title) {
      isValid = false;
      errors.title = "Please enter title.";
    }
    if (!rangeFrom) {
      isValid = false;
      errors.from = "Please select range.";
    }
    if (!rangeTo) {
      isValid = false;
      errors.to = "Please select range.";
    }
    if (!color) {
      isValid = false;
      errors.color = "Please select color.";
    }
    if (!description) {
      isValid = false;
      errors.description = "Please enter remarks.";
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, result) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete result?") == true) {
      if (window.confirm("Are you sure you want to delete result permanently?") == true) {
        deleteFinalAssesmentResultPermanently(result._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Result deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete result.");
          }
        });
      }
    }
  };

  const deleteStatusHandler = (e, result) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the result?";
    if (result.isDeleted) {
      confirmText = "Are you sure you want to active the result?";
    }
    if (window.confirm(confirmText) == true) {
      if (result.isDeleted) {
        restoreFinalAssesmentResult(result._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Result activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active result.");
          }
        });
      } else {
        deleteFinalAssesmentResult(result._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Result deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive result.");
          }
        });
      }
    }
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    const selectedValues = selectedOptions.map((option) => option.value);
    setMicrocycles(selectedValues);
  };

  const tabNameHandler = () => {
    setTabNameBtnLoading(true);
    updateWeekDayText({ resultTabName: tabName, isResult: true }, props?.day?._id).then((res) => {
      if (res && res.status == 200) {
        toast.success("Tab name updated successfully.");
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to update tab name.");
      }
      setTabNameBtnLoading(false);
    });
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center">
          {hasPermission("edit-day-result") && (
            <div className="form-group col-md-6">
              <label>Tab Name</label>
              <input type="text" className="form-control" placeholder="Result" value={tabName} onChange={(e) => setTabName(e.target.value)} />
              {!tabName && <div className="text-warning font-size-12 mt-2">This field is required.</div>}
              <button className="btn btn-primary float-right btn-sm mt-4" onClick={tabNameHandler}>
                {tabNameBtnLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Update
              </button>
            </div>
          )}
        </div>
        <div className="row my-4 my-4 pt-4" style={{ borderTop: "1px solid #f0f1f5" }}>
          <div className="col-12">
            {hasPermission("add-day-result") && (
              <button
                className="btn btn-primary float-right"
                data-toggle="modal"
                data-target="#addFinalAssesmentResult"
                onClick={(e) => modalHandler()}
              >
                Add New Result
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Title</th>
                        <th>Range</th>
                        <th>Color</th>
                        <th>Remarks</th>
                        {hasPermission("edit-day-result") && <th>Status</th>}
                        {(hasPermission("edit-day-result") || hasPermission("delete-day-result")) && <th>Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {tableDataLoading ? (
                        <tr>
                          <td colSpan="7" className="text-center pt-5">
                            <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {finalAssesmentResultsList?.length > 0 ? (
                            finalAssesmentResultsList?.map((result, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{result?.title}</td>
                                  <td>{result?.percentage_from + " - " + result?.percentage_to}</td>
                                  <td>
                                    {result?.color === "#EBBC2E"
                                      ? "Yellow"
                                      : result?.color === "#ED3A3A"
                                      ? "Red"
                                      : result?.color === "#33B469"
                                      ? "Green"
                                      : result?.color}
                                  </td>
                                  <td>{result?.remarks}</td>
                                  {hasPermission("edit-day-result") && (
                                    <td>
                                      <label className="switch">
                                        <input type="checkbox" checked={!result.isDeleted} onClick={(e) => deleteStatusHandler(e, result)} />
                                        <span className="slider round"></span>
                                      </label>
                                    </td>
                                  )}
                                  {(hasPermission("edit-day-result") || hasPermission("delete-day-result")) && (
                                    <td>
                                      <div className="d-flex">
                                        {hasPermission("edit-day-result") && (
                                          <button
                                            data-toggle="modal"
                                            data-target="#addFinalAssesmentResult"
                                            onClick={(e) => {
                                              selectedOptions?.length > 0 && setSelectedOptions([]);
                                              modalHandler(result);
                                            }}
                                            className="btn btn-primary shadow btn-sm sharp mr-1"
                                          >
                                            <i className="fa fa-pencil"></i>
                                          </button>
                                        )}
                                        {hasPermission("delete-day-result") && (
                                          <button onClick={(e) => deleteHandler(e, result)} className="btn btn-danger shadow btn-sm sharp">
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="7" className="text-center pt-5">
                                No Result found !
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="addFinalAssesmentResult">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="finalAssesmentResultModal">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="row px-3">
                  <div className="col-12 col-md-6 form-group px-3">
                    <label>Title</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter result title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>}
                  </div>
                  <div className="col-12 col-md-6 form-group px-3">
                    <label>Score From</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter score from"
                      value={rangeFrom}
                      onChange={(e) => setRangeFrom(e.target.value)}
                    />
                    {errors.from && !rangeFrom && <div className="text-warning font-size-12 mt-2">{errors.from}</div>}
                  </div>
                  <div className="col-12 col-md-6 form-group px-3">
                    <label>Score to</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter score to"
                      value={rangeTo}
                      onChange={(e) => setRangeTo(e.target.value)}
                    />
                    {errors.to && !rangeTo && <div className="text-warning font-size-12 mt-2">{errors.to}</div>}
                  </div>
                  <div className="col-12 col-md-6 form-group px-3">
                    <label>Select Color</label>
                    {color ? (
                      <select className="form-control" value={color} onChange={(e) => setColor(e.target.value)}>
                        <option value="">Select color</option>
                        <option value="#33B469">Green</option>
                        <option value="#ED3A3A">Red</option>
                        <option value="#EBBC2E">Yellow</option>
                        <option value="orange">Orange</option>
                      </select>
                    ) : (
                      <select className="form-control" value={color} onChange={(e) => setColor(e.target.value)}>
                        <option value="">Select color</option>
                        <option value="#33B469">Green</option>
                        <option value="#ED3A3A">Red</option>
                        <option value="#EBBC2E">Yellow</option>
                        <option value="orange">Orange</option>
                      </select>
                    )}
                    {errors.color && !color && <div className="text-warning font-size-12">{errors.color}</div>}
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Suggested Programs</label>
                      <Select
                        // defaultValue={searchFiltersFilters}
                        isMulti
                        name="colors"
                        value={selectedOptions}
                        options={suggestionsList}
                        onChange={handleSelectChange}
                        className="basic-multi-select form-control"
                        classNamePrefix="select"
                        grouped
                        styles={{
                          option: (provided) => ({
                            ...provided,
                            paddingLeft: "30px", // Adjust the padding value as per your preference
                          }),
                          groupHeading: (provided) => ({
                            ...provided,
                            fontWeight: "bold",
                            fontSize: "14px",
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group px-3 col-12">
                    <label>Remarks</label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Enter Remarks"
                      rows={4}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    {errors.description && !description && <div className="text-warning font-size-12">{errors.description}</div>}
                  </div>
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FinalAssessmentResult;
