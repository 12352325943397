import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  remove_session,
  getProgramWeekCalendar,
  addProgramWeekCalendar,
  updateProgramWeekCalendar,
  deleteProgramWeekCalendarPermanently,
  updateIntroWeekTabs,
  sortIntroWeekCalendar,
} from "../../../functions/general";
import { toast } from "react-toastify";
import Media from "../../../components/Media";
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";
import ModalImage from "react-modal-image";
import { usePermissions } from "../../../functions/usePermissions";
function IntroWeekCalendar(props) {
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const { state } = useLocation();

  const [title, setTitle] = React.useState("");
  const [screenTitle, setScreenTitle] = React.useState(props?.week?.calendarTitle ? props?.week?.calendarTitle : "");
  const [image, setImage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [screenDescription, setScreenDescription] = React.useState(props?.week?.calendarText ? props?.week?.calendarText : "");
  const [weekCalendarData, setweekCalendarData] = React.useState([]);
  const [tabName, setTabName] = React.useState(props?.week?.calendarTabName ? props?.week?.calendarTabName : "Calendar");
  const [tabNameBtnLoading, setTabNameBtnLoading] = React.useState("");
  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [showAddImgModal, setShowAddImgModal] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [id, setId] = React.useState("");

  useEffect(() => {
    // props.setPageTitleAndSubTitle("Lecture Categories", "Here is the list of Lecture Categories !");
    fetchData();
  }, []);

  const fetchData = () => {
    getProgramWeekCalendar(props?.week?._id).then((res) => {
      if (res && res.status == 201) {
        setweekCalendarData(res?.data);
        setTitle(res?.data[0]?.title);
        setDescription(res?.data[0]?.text);
        setImage(res?.data[0]?.image);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const calendarData = {
        title: title,
        image: image,
        text: description,
        week: props?.week,
      };
      if (id) {
        updateProgramWeekCalendar(calendarData, id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Calendar data updated successfully.");
            document.getElementById("calendarModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update calendar data.");
          }
          setLoading(false);
        });
      } else {
        addProgramWeekCalendar(calendarData).then((res) => {
          if (res && res.status == 201) {
            fetchData();
            toast.success("Calendar data added successfully.");
            document.getElementById("calendarModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add Calendar data.");
          }
          setLoading(false);
        });
      }
    }
  };

  // const handleUpdateWeekCalendar = (e) => {
  //   e.preventDefault();
  //   if (loading) return false;
  //   if (validate()) {
  //     setLoading(true);
  //     const calendarData = {
  //       title: title ? title : "",
  //       text: description ? description : "",
  //       image: image,
  //       week: props?.week,
  //     };
  //     updateProgramWeekCalendar(calendarData, weekCalendarData[0]?._id).then((res) => {
  //       if (res && res.status == 200) {
  //         fetchData();
  //         toast.success("Calendar data updated successfully.");
  //         document.getElementById("calendarModalCloseButton").click();
  //       } else if (res && res.status == 401) {
  //         remove_session();
  //         props.getLoggedInUser();
  //         navigate("/login");
  //       } else {
  //         toast.error("Failed to update calendar data.");
  //       }
  //       setLoading(false);
  //     });
  //   }
  // };

  const validate = () => {
    let errors = {
      title: "",
      description: "",
      image: "",
    };
    let isValid = true;
    // if (!title) {
    //   isValid = false;
    //   errors.title = "Please enter title.";
    // }
    // if (!description) {
    //   isValid = false;
    //   errors.description = "Please enter description.";
    // }
    if (!image) {
      isValid = false;
      errors.image = "Please select image.";
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete calendar data permanently?") == true) {
      deleteProgramWeekCalendarPermanently(id).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("Calendar data deleted successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete calendar data.");
        }
      });
    }
  };

  const tabNameHandler = () => {
    setTabNameBtnLoading(true);
    updateIntroWeekTabs(
      { calendarTabName: tabName, calendarTitle: screenTitle, calendarText: screenDescription, isCalendar: true },
      props?.week?._id
    ).then((res) => {
      if (res && res.status == 200) {
        toast.success("Tab name updated successfully.");
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to update tab name.");
      }
      setTabNameBtnLoading(false);
    });
  };

  const setImageHandler = (image) => {
    setImage(image);
  };

  const modalHandler = (calendar) => {
    if (calendar && calendar._id) {
      setModalTitle("Update calendar data");
      setModalButtonText("Update");
      setTitle(calendar?.title);
      setImage(calendar?.image);
      setId(calendar?._id);
    } else {
      setModalTitle("Add New calendar");
      setModalButtonText("Add");
      setTitle("");
      setImage("");
      setId("");
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    const updatedWeekCalendarData = arrayMove(weekCalendarData, oldIndex, newIndex);
    let sortedArray = [];
    updatedWeekCalendarData?.map((calendar) => sortedArray.push(calendar?._id));
    sortIntroWeekCalendar(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setweekCalendarData(updatedWeekCalendarData);
        // fetchData();
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ calendar, serialNo }) => {
    return (
      <tr style={{ width: "100%" }}>
        <td>{serialNo + 1}</td>
        <td>
          <div className="maxWidth70px">
            <ModalImage small={process.env.REACT_APP_MEDIAPATH + calendar.image} large={process.env.REACT_APP_MEDIAPATH + calendar.image} />
          </div>
        </td>
        <td>{calendar.title}</td>
        {/* {hasPermission("edit-program-category") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={!category.isDeleted} onClick={(e) => deleteStatusHandler(e, category)} />
              <span className="slider round"></span>
            </label>
          </td>
        )} */}
        {(hasPermission("edit-week-calendar") || hasPermission("delete-week-calendar")) && (
          <td>
            <div className="d-flex">
              {hasPermission("edit-week-calendar") && (
                <button
                  data-toggle="modal"
                  data-target="#add-calendar-data"
                  onClick={(e) => modalHandler(calendar)}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {hasPermission("delete-week-calendar") && (
                <button onClick={(e) => deleteHandler(e, calendar?._id)} className="btn btn-danger shadow btn-sm sharp">
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </td>
        )}
        {hasPermission("edit-week-calendar") && (
          <td>
            <DragHandle />
          </td>
        )}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ weekCalendarData }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>No</th>
            <th>Calendar Image</th>
            <th>Title</th>
            {(hasPermission("edit-week-calendar") || hasPermission("delete-week-calendar")) && <th>Actions</th>}
            {hasPermission("edit-week-calendar") && <th>Sort Order</th>}
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="7" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {weekCalendarData ? (
                weekCalendarData?.map((calendar, index) => {
                  return <SortableTableRow key={index} calendar={calendar} index={index} serialNo={index} />;
                })
              ) : (
                <tr>
                  <td colSpan="7" className="text-center pt-5">
                    No calendar data found!
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-6">
          {hasPermission("edit-week-calendar") && (
            <div className="row">
              <div className="form-group col-md-12">
                <label>Tab Name</label>
                <input type="text" className="form-control" placeholder="Calendar" value={tabName} onChange={(e) => setTabName(e.target.value)} />
                {!tabName && <div className="text-warning font-size-12 mt-2">This field is required.</div>}
              </div>
              <div className="form-group col-md-12">
                <label>Screen Title</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Calendar Title"
                  value={screenTitle}
                  onChange={(e) => setScreenTitle(e.target.value)}
                />
                {/* {errors.title && !title && <div className="text-warning font-size-12">{errors.title}</div>} */}
              </div>
              <div className="form-group col-md-12">
                <label>Screen Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Enter Description"
                  rows={4}
                  value={screenDescription}
                  onChange={(e) => setScreenDescription(e.target.value)}
                />
                {/* {errors.description && !description && <div className="text-warning font-size-12">{errors.description}</div>} */}
                <button className="btn btn-primary float-right btn-sm mt-4" onClick={tabNameHandler}>
                  {tabNameBtnLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Update
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="col-12 my-4 pt-4" style={{ borderTop: "1px solid #f0f1f5" }}>
          {hasPermission("add-week-calendar") && (
            <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add-calendar-data" onClick={(e) => modalHandler()}>
              Add New
            </button>
          )}
        </div>
        {/* <div className="col-6">
          {tableDataLoading ? (
            <div className="text-center py-5">
              <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
            </div>
          ) : (
            <>
              {weekCalendarData?.length > 0 ? (
                <form noValidate onSubmit={handleUpdateWeekCalendar}>
                  <div className="form-group px-3">
                    <label>Title</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Calendar Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="form-group px-3">
                    <label>Image</label>
                    <br />
                    {image && (
                      <>
                        <img src={process.env.REACT_APP_MEDIAPATH + image} className="img-fluid img-thumbnail" width="150" />
                        <br />
                      </>
                    )}
                    <button
                      type="button"
                      onClick={() => setShowAddImgModal(true)}
                      className="btn btn-primary mt-3"
                      data-toggle="modal"
                      data-target="#calendarMedia"
                    >
                      Select Image
                    </button>
                    {errors.image && !image && <div className="text-warning font-size-12">{errors.image}</div>}
                  </div>
                  <div className="modal-footer border-0">
                    <button type="submit" className="btn btn-primary">
                      {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Update
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={(e) => {
                        deleteHandler(e, weekCalendarData[0]?._id);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </form>
              ) : (
                <div className="py-5 text-center">
                  <h3>Please enter calendar data !</h3>
                </div>
              )}
            </>
          )}
        </div> */}

        <div className="table-responsive">
          <SortableTable weekCalendarData={weekCalendarData} onSortEnd={onSortEnd} useDragHandle={true} />
        </div>
      </div>
      <div className="modal fade" id="add-calendar-data">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header border-0 px-3 mb-3">
              <h5 className="modal-title">{modalTitle}</h5>
              <button type="button" className="close" data-dismiss="modal" id="calendarModalCloseButton">
                <span>&times;</span>
              </button>
            </div>
            <form noValidate onSubmit={onSubmit}>
              <div className="form-group px-3">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Calendar Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {/* {errors.title && !title && <div className="text-warning font-size-12">{errors.title}</div>} */}
              </div>
              {/* <div className="form-group px-3">
                <label>Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Enter Description"
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                {errors.description && !description && <div className="text-warning font-size-12">{errors.description}</div>}
              </div> */}
              <div className="form-group px-3">
                <label>Calendar Image</label>
                <br />
                {image && (
                  <>
                    <img src={process.env.REACT_APP_MEDIAPATH + image} className="img-fluid img-thumbnail" width="150" />
                    <br />
                  </>
                )}
                <button
                  type="button"
                  onClick={() => setShowAddImgModal(true)}
                  className="btn btn-primary mt-3"
                  data-toggle="modal"
                  data-target="#calendarMedia"
                >
                  Select Image
                </button>
                {errors.image && !image && <div className="text-warning font-size-12">{errors.image}</div>}
              </div>
              <div className="modal-footer border-0">
                <button type="submit" className="btn btn-primary">
                  {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showAddImgModal && <Media setImageHandler={setImageHandler} id={"calendarMedia"} />}
    </>
  );
}

export default IntroWeekCalendar;
