import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  remove_session,
  getProgramWeekLectureExerciseCategories,
  sortProgramWeekLectureExerciseCategories,
  updateProgramWeekLectureExerciseCategory,
  addProgramWeekLectureExerciseCategory,
  deleteProgramWeekLectureExerciseCategoryPermanently,
  restoreProgramWeekLectureExerciseCategory,
  deleteProgramWeekLectureExerciseCategory,
  updateProgramWeekExerciseText,
} from "../../../../../../functions/general";
import { toast } from "react-toastify";
import Media from "../../../../../../components/Media";
import { usePermissions } from "../../../../../../functions/usePermissions";

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";

function LectureExerciseCategories(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { hasPermission } = usePermissions();

  const [lectureExerciseCategories, setLectureExerciseCategories] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [image, setImage] = React.useState("");
  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState("");
  const [tabName, setTabName] = React.useState(state?.lecture?.exerciseTabName ? state?.lecture?.exerciseTabName : "Routine");
  const [appTitle, setAppTitle] = React.useState(state?.lecture?.exerciseTitle ? state?.lecture?.exerciseTitle : "");
  const [appDescription, setAppDescription] = React.useState(state?.lecture?.exerciseText ? state?.lecture?.exerciseText : "");

  const [tabNameBtnLoading, setTabNameBtnLoading] = React.useState("");
  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  useEffect(() => {
    // props.setPageTitleAndSubTitle("Lecture Categories", "Here is the list of Lecture Categories !");
    fetchData();
  }, []);

  const fetchData = (type) => {
    getProgramWeekLectureExerciseCategories(state?.lecture?._id).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        setLectureExerciseCategories(res.data);
        if (type === "add") {
          let sortedArray = [];
          res?.data?.map((category) => sortedArray.push(category?._id));
          sortProgramWeekLectureExerciseCategories(sortedArray).then((res) => {
            if (res && res.status == 200) {
              // fetchData();
            } else if (res && res.status == 401) {
              remove_session();
              props.getLoggedInUser();
              navigate("/login");
            } else {
              toast.error("Failed to sort the list.");
            }
            setTableDataLoading(false);
          });
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };

  const modalHandler = (category) => {
    if (category && category._id) {
      setModalTitle("Update Exercise Category");
      setModalButtonText("Update");
      setTitle(category.title);
      setImage(category.image);
      setId(category._id);
    } else {
      setModalTitle("Add New Exercise Category");
      setModalButtonText("Add");
      setTitle("");
      setImage("");
      setId("");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const category = {
        title: title,
        lecture: state?.lecture,
      };
      let type = "add";
      if (id) {
        updateProgramWeekLectureExerciseCategory(id, category).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Exercise Category updated successfully.");
            document.getElementById("programWeekLectureExerciseCategoryModal").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update exercise category.");
          }
          setLoading(false);
        });
      } else {
        addProgramWeekLectureExerciseCategory(category).then((res) => {
          if (res && res.status == 201) {
            fetchData(type);
            toast.success("Exercise Category added successfully.");
            document.getElementById("programWeekLectureExerciseCategoryModal").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add exercise category.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      image: "",
    };
    let isValid = true;
    if (!title) {
      isValid = false;
      errors.title = "Please enter title.";
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, category) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete exercise category?") == true) {
      if (window.confirm("Are you sure you want to delete exercise category permanently?") == true) {
        deleteProgramWeekLectureExerciseCategoryPermanently(category._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Exercise Category deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete category.");
          }
        });
      }
    }
  };

  const deleteStatusHandler = (e, category) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the exercise category?";
    if (category.isDeleted) {
      confirmText = "Are you sure you want to active the exercise category?";
    }
    if (window.confirm(confirmText) == true) {
      if (category.isDeleted) {
        restoreProgramWeekLectureExerciseCategory(category._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Exercise Category activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active category.");
          }
        });
      } else {
        deleteProgramWeekLectureExerciseCategory(category._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Exercise Category deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive category.");
          }
        });
      }
    }
  };

  const tabNameHandler = () => {
    setTabNameBtnLoading(true);
    updateProgramWeekExerciseText(
      { exerciseTabName: tabName, exerciseTitle: appTitle, exerciseText: appDescription, isExercises: true },
      state?.lecture?._id
    ).then((res) => {
      if (res && res.status == 200) {
        toast.success("Data updated successfully.");
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to update data.");
      }
      setTabNameBtnLoading(false);
    });
  };
  const setImageHandler = (image) => {
    setImage(image);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    const updatedLectureExerciseCategories = arrayMove(lectureExerciseCategories, oldIndex, newIndex);
    let sortedArray = [];
    updatedLectureExerciseCategories?.map((category) => sortedArray.push(category?._id));
    sortProgramWeekLectureExerciseCategories(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setLectureExerciseCategories(updatedLectureExerciseCategories);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ category, serialNo }) => {
    return (
      <tr>
        <td>{serialNo + 1}</td>
        <td>{category.title}</td>
        {hasPermission("view-lecture-exercise-videos") && (
          <td>
            <button
              className="btn btn-dark btn-xs"
              onClick={() => {
                props?.setExerciseVideosShow(true);
                props?.setExerciseCategory(category);
              }}
            >
              {category?.count ? category?.count : 0}
            </button>
          </td>
        )}
        {hasPermission("edit-lecture-exercise") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={!category.isDeleted} onClick={(e) => deleteStatusHandler(e, category)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {(hasPermission("edit-lecture-exercise") || hasPermission("delete-lecture-exercise")) && (
          <td>
            <div className="d-flex">
              {hasPermission("edit-lecture-exercise") && (
                <button
                  data-toggle="modal"
                  data-target="#addProgramWeekLectureExerciseCategoryModal"
                  onClick={(e) => modalHandler(category)}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {hasPermission("delete-lecture-exercise") && (
                <button onClick={(e) => deleteHandler(e, category)} className="btn btn-danger shadow btn-sm sharp">
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </td>
        )}
        {hasPermission("edit-lecture-exercise") && (
          <td>
            <DragHandle />
          </td>
        )}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ lectureExerciseCategories }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>No</th>
            <th>Exercise Categories</th>
            {hasPermission("view-lecture-exercise-videos") && <th>Videos</th>}
            {hasPermission("edit-lecture-exercise") && <th>Status</th>}
            {(hasPermission("edit-lecture-exercise") || hasPermission("delete-lecture-exercise")) && <th>Actions</th>}
            {hasPermission("edit-lecture-exercise") && <th>Sort Order</th>}
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="6" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {lectureExerciseCategories?.length > 0 ? (
                lectureExerciseCategories?.map((category, index) => {
                  return <SortableTableRow key={index} category={category} index={index} serialNo={index} />;
                })
              ) : (
                <tr>
                  <td colSpan="6" className="text-center pt-5">
                    No Exercise Category found !
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="row">
              <div className="form-group col-12 px-0">
                <label>Tab Name</label>
                <input type="text" className="form-control" placeholder="Routine" value={tabName} onChange={(e) => setTabName(e.target.value)} />
                {!tabName && <div className="text-warning font-size-12 mt-2">This field is required.</div>}
              </div>
              <div className="form-group col-12 px-0">
                <label>Screen Title</label>
                <input type="text" className="form-control" placeholder="Title" value={appTitle} onChange={(e) => setAppTitle(e.target.value)} />
              </div>
              <div className="form-group col-12 px-0">
                <label>Screen Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Enter Description"
                  rows={4}
                  value={appDescription}
                  onChange={(e) => setAppDescription(e.target.value)}
                />
              </div>
              <button className="btn btn-primary ml-auto btn-sm mt-4" onClick={tabNameHandler}>
                {tabNameBtnLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Update
              </button>
            </div>
          </div>
        </div>
        <div className="row my-4 pt-4" style={{ borderTop: "1px solid #f0f1f5" }}>
          <div className="col-12">
            {hasPermission("add-lecture-exercise") && (
              <button
                className="btn btn-primary float-right"
                data-toggle="modal"
                data-target="#addProgramWeekLectureExerciseCategoryModal"
                onClick={(e) => modalHandler()}
              >
                Add New
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  {tableDataLoading ? (
                    <div className="text-center py-5">
                      <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                    </div>
                  ) : (
                    <>
                      <SortableTable lectureExerciseCategories={lectureExerciseCategories} onSortEnd={onSortEnd} useDragHandle={true} />

                      {/* <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Exercise Categories</th>
                            {hasPermission("view-lecture-exercise-videos") && <th>Videos</th>}
                            {hasPermission("edit-lecture-exercise") && <th>Status</th>}
                            {(hasPermission("edit-lecture-exercise") || hasPermission("delete-lecture-exercise")) && <th>Actions</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {lectureExerciseCategories?.length > 0 ? (
                            lectureExerciseCategories?.map((category, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{category.title}</td>
                                  {hasPermission("view-lecture-exercise-videos") && (
                                    <td>
                                      <button
                                        className="btn btn-dark btn-xs"
                                        onClick={() => {
                                          props?.setExerciseVideosShow(true);
                                          props?.setExerciseCategory(category);
                                        }}
                                      >
                                        {category?.count ? category?.count : 0}
                                      </button>
                                    </td>
                                  )}
                                  {hasPermission("edit-lecture-exercise") && (
                                    <td>
                                      <label className="switch">
                                        <input type="checkbox" checked={!category.isDeleted} onClick={(e) => deleteStatusHandler(e, category)} />
                                        <span className="slider round"></span>
                                      </label>
                                    </td>
                                  )}
                                  {(hasPermission("edit-lecture-exercise") || hasPermission("delete-lecture-exercise")) && (
                                    <td>
                                      <div className="d-flex">
                                        {hasPermission("edit-lecture-exercise") && (
                                          <button
                                            data-toggle="modal"
                                            data-target="#addProgramWeekLectureExerciseCategoryModal"
                                            onClick={(e) => modalHandler(category)}
                                            className="btn btn-primary shadow btn-sm sharp mr-1"
                                          >
                                            <i className="fa fa-pencil"></i>
                                          </button>
                                        )}
                                        {hasPermission("delete-lecture-exercise") && (
                                          <button onClick={(e) => deleteHandler(e, category)} className="btn btn-danger shadow btn-sm sharp">
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center pt-5">
                                No Exercise Category found !
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="addProgramWeekLectureExerciseCategoryModal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="programWeekLectureExerciseCategoryModal">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="form-group px-3">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Exercise Category Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>}
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Media setImageHandler={setImageHandler} id={"weekLectureCategoryImg"} />
    </>
  );
}

export default LectureExerciseCategories;
