import React from "react";

function Profile() {
  const onSubmit = (e) => {
    e.preventDefault()
  }
  return (
      <>
        <div className="col-lg-12">
          <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div>
              <h4 className="mb-3">Profile</h4>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-6">
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Change Password</h4>
              </div>
            </div>
            <div className="card-body">
              <form noValidate onSubmit={onSubmit}>
                <div className="form-group">
                  <label htmlFor="pwd">New Password:</label>
                  <input type="password" className="form-control" id="pwd" />
                </div>
                <div className="form-group">
                  <label htmlFor="pwd">Confirm Password:</label>
                  <input type="password" className="form-control" id="pwd" />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </>
  );
}

export default Profile;
