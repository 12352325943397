import React from "react";

function Footer() {
  return (
      <div className="footer">
          <div className="copyright">
              <p>Copyright © Designed &amp; Developed by
                  <a href="https://softzee.com/" target="_blank"> Softzee </a> 2023
              </p>
          </div>
      </div>
  );
}

export default Footer;
