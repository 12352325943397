import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import WeekDayOverview from "./weekDayOverview";
import WeekDayExcersiseCategories from "./weeDaykExercises";
import WeekDayEcerciseVideos from "./weeDaykExercises/weekDayExerciseVideos";
import WeekDayEcerciseSwapVideos from "./weeDaykExercises/weekDayExerciseSwapVideos";
import WeekDayWarmupsList from "./weekDayWarmups";
import WeekDayWarmupPdfs from "./weekDayWarmups/weekDayWarmupPdfs";
import FinalAssessmentResult from "./finalAssesmentResult";
import { usePermissions } from "../../../functions/usePermissions";
import { getDayDetails } from "../../../functions/general";
function WeekDayDetails(props) {
  const { hasPermission } = usePermissions();

  const [exerciseVideosShow, setExerciseVideosShow] = React.useState(false);
  const [exerciseSwapVideosShow, setExerciseSwapVideosShow] = React.useState(false);
  const [warmupPdfsShow, setWarmupPdfsShow] = React.useState(false);
  const [exerciseCategory, setExerciseCategory] = React.useState();
  const [exerciseCategoryVideo, setExerciseCategoryVideo] = React.useState();
  const [weekDayWarmupData, setWeekDayWarmupData] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const { state } = useLocation();
  const navigate = useNavigate();

  const [dayDetails, setDayDetails] = React.useState(null);
  const [searchParams] = useSearchParams();
  const dayId = searchParams.get("dayId");
  const tabName = searchParams.get("tabName");

  useEffect(() => {
    getDayDetails(dayId).then((res) => {
      if (res && res.status == 200) {
        setDayDetails(res?.data);
        props.setPageTitleAndSubTitle(res?.data?.title, `Here is the details of ${res?.data?.title}!`);
      } else if (res && res.status == 401) {
        props.remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
    const loader = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => {
      clearTimeout(loader);
    };
  }, []);

  // const [activeTab, setActiveTab] = React.useState(
  //   hasPermission("view-days-overview")
  //     ? "WeekDayOveriew"
  //     : hasPermission("view-days-warmup")
  //     ? "WeekDayWarmUp"
  //     : hasPermission("view-days-exercise")
  //     ? "WeekDayLectures"
  //     : hasPermission("view-days-result")
  //     ? "finalAssessmentResult"
  //     : ""
  // );

  const [activeTab, setActiveTab] = React.useState("WeekDayOveriew"); // Default active tab

  // !hasPermission("view-days-overview") && !hasPermission("view-days-warmup") && !hasPermission("view-days-exercise");
  // Default active tab
  const location = useLocation();

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    navigate(`?dayId=${dayDetails?._id}&tabName=${encodeURIComponent(tabId)}`);
  };

  useEffect(() => {
    if (tabName) setActiveTab(tabName);
  }, [location.search]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {dayDetails && (
              <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
                <li class="breadcrumb-item">
                  <span onClick={() => navigate("/")}>
                    <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                  </span>
                </li>
                <li class="breadcrumb-item">
                  <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate("/program-categories")}>
                    Programs
                  </span>
                </li>
                <li class="breadcrumb-item">
                  <span
                    style={{ color: "#132C48", cursor: "pointer" }}
                    onClick={() => navigate(`/program-category-programs?categoryId=${dayDetails?.week?.program?.category?._id}`)}
                  >
                    {dayDetails?.week?.program?.category?.title}
                  </span>
                </li>
                <li class="breadcrumb-item">
                  <span
                    style={{ color: "#132C48", cursor: "pointer" }}
                    onClick={() => navigate(`/program-weeks?programId=${dayDetails?.week?.program?._id}`)}
                  >
                    {dayDetails?.week?.program?.title}
                  </span>
                </li>
                <li class="breadcrumb-item">
                  <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate(`/week-days?weekId=${dayDetails?.week?._id}`)}>
                    {dayDetails?.week?.title}
                  </span>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {dayDetails?.title}
                </li>
              </ol>
            )}
          </div>
          <div className="col-12">
            <div className="card">
              {loading ? (
                <div className="text-center py-5">
                  <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                </div>
              ) : (
                <div className="card-body" style={{ minHeight: "300px" }}>
                  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    {hasPermission("view-days-overview") && (
                      <li className="nav-item">
                        <button
                          className={`nav-link${activeTab === "WeekDayOveriew" ? " active" : ""}`}
                          onClick={() => handleTabClick("WeekDayOveriew")}
                          type="button"
                          role="tab"
                          aria-controls="WeekDayOveriew"
                          aria-selected={activeTab === "WeekDayOveriew"}
                        >
                          Overview
                        </button>
                        {/* <a
                          className="nav-link active"
                          id="pills-home-tab"
                          data-toggle="pill"
                          href="#WeekDayOveriew"
                          role="tab"
                          aria-controls="WeekDayOveriew"
                          aria-selected="true"
                        >
                          Overview
                        </a> */}
                      </li>
                    )}
                    {hasPermission("view-days-warmup") && (
                      <li className="nav-item">
                        <button
                          className={`nav-link${activeTab === "WeekDayWarmUp" || !hasPermission("view-days-overview") ? " active" : ""}`}
                          onClick={() => handleTabClick("WeekDayWarmUp")}
                          type="button"
                          role="tab"
                          aria-controls="WeekDayWarmUp"
                          aria-selected={activeTab === "WeekDayWarmUp"}
                        >
                          Warm-up
                        </button>
                        {/* <a
                          className={!hasPermission("view-days-overview") ? "nav-link active" : "nav-link"}
                          id="pills-profile-tab"
                          data-toggle="pill"
                          href="#WeekDayWarmUp"
                          role="tab"
                          aria-controls="WeekDayWarmUp"
                          aria-selected="false"
                        >
                          Warm-up
                        </a> */}
                      </li>
                    )}
                    {hasPermission("view-days-exercise") && (
                      <li className="nav-item">
                        <button
                          className={`nav-link${
                            activeTab === "WeekDayLectures" || (!hasPermission("view-days-overview") && !hasPermission("view-days-warmup"))
                              ? " active"
                              : ""
                          }`}
                          onClick={() => handleTabClick("WeekDayLectures")}
                          type="button"
                          role="tab"
                          aria-controls="WeekDayLectures"
                          aria-selected={activeTab === "WeekDayLectures"}
                        >
                          Routine
                        </button>
                        {/* <a
                          className={!hasPermission("view-days-overview") && !hasPermission("view-days-warmup") ? "nav-link active" : "nav-link"}
                          id="pills-contact-tab"
                          data-toggle="pill"
                          href="#WeekDayLectures"
                          role="tab"
                          aria-controls="WeekDayLectures"
                          aria-selected="false"
                        >
                          Routine
                        </a> */}
                      </li>
                    )}
                    {(dayDetails?.type === "finalAssessment" || dayDetails?.type === "assessment") && hasPermission("view-days-result") && (
                      <li className="nav-item">
                        <button
                          className={`nav-link${
                            activeTab === "finalAssessmentResult" || (!hasPermission("view-days-overview") && !hasPermission("view-days-warmup"))
                              ? " active"
                              : ""
                          }`}
                          onClick={() => handleTabClick("finalAssessmentResult")}
                          type="button"
                          role="tab"
                          aria-controls="finalAssessmentResult"
                          aria-selected={activeTab === "finalAssessmentResult"}
                        >
                          Result
                        </button>
                        {/* <a
                          className={
                            !hasPermission("view-days-overview") && !hasPermission("view-days-warmup") && !hasPermission("view-days-exercise")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="pills-contact-tab"
                          data-toggle="pill"
                          href="#finalAssessmentResult"
                          role="tab"
                          aria-controls="finalAssessmentResult"
                          aria-selected="false"
                        >
                          Result
                        </a> */}
                      </li>
                    )}
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    {hasPermission("view-days-overview") && (
                      <div
                        className={`tab-pane fade${activeTab === "WeekDayOveriew" ? " show active" : ""}`}
                        id="WeekDayOveriew"
                        role="tabpanel"
                        aria-labelledby="WeekDayOveriew-tab"
                      >
                        {dayDetails ? (
                          <WeekDayOverview day={dayDetails} />
                        ) : (
                          <div className="text-center py-5">
                            <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                          </div>
                        )}
                      </div>
                    )}
                    {hasPermission("view-days-warmup") && (
                      <div
                        className={`tab-pane fade${activeTab === "WeekDayWarmUp" || !hasPermission("view-days-overview") ? " show active" : ""}`}
                        // className={!hasPermission("view-days-overview") ? "tab-pane fade show active" : "tab-pane fade"}
                        id="WeekDayWarmUp"
                        role="tabpanel"
                        aria-labelledby="WeekDayWarmUp-tab"
                      >
                        {dayDetails ? (
                          <>
                            {warmupPdfsShow ? (
                              <WeekDayWarmupPdfs weekDayWarmupData={weekDayWarmupData} setWarmupPdfsShow={setWarmupPdfsShow} />
                            ) : (
                              <WeekDayWarmupsList
                                day={dayDetails}
                                setWarmupPdfsShow={setWarmupPdfsShow}
                                setWeekDayWarmupData={setWeekDayWarmupData}
                              />
                            )}
                          </>
                        ) : (
                          <div className="text-center py-5">
                            <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                          </div>
                        )}
                      </div>
                    )}
                    {hasPermission("view-days-exercise") && (
                      <div
                        className={`tab-pane fade${
                          activeTab === "WeekDayLectures" || (!hasPermission("view-days-overview") && !hasPermission("view-days-warmup"))
                            ? " show active"
                            : ""
                        }`}
                        // className={
                        //   !hasPermission("view-days-overview") && !hasPermission("view-days-warmup") ? "tab-pane fade show active" : "tab-pane fade"
                        // }
                        id="WeekDayLectures"
                        role="tabpanel"
                        aria-labelledby="WeekDayLectures-tab"
                      >
                        {dayDetails ? (
                          <>
                            {exerciseVideosShow ? (
                              <WeekDayEcerciseVideos
                                setExerciseVideosShow={setExerciseVideosShow}
                                exerciseCategory={exerciseCategory}
                                setExerciseSwapVideosShow={setExerciseSwapVideosShow}
                                setExerciseCategoryVideo={setExerciseCategoryVideo}
                                day={dayDetails}
                              />
                            ) : exerciseSwapVideosShow ? (
                              <WeekDayEcerciseSwapVideos
                                exerciseCategoryVideo={exerciseCategoryVideo}
                                setExerciseVideosShow={setExerciseVideosShow}
                                setExerciseSwapVideosShow={setExerciseSwapVideosShow}
                                day={dayDetails}
                              />
                            ) : (
                              <WeekDayExcersiseCategories
                                day={dayDetails}
                                setExerciseVideosShow={setExerciseVideosShow}
                                setExerciseCategory={setExerciseCategory}
                              />
                            )}
                          </>
                        ) : (
                          <div className="text-center py-5">
                            <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                          </div>
                        )}
                      </div>
                    )}
                    {(dayDetails?.type === "finalAssessment" || dayDetails?.type === "assessment") && hasPermission("view-days-result") && (
                      <div
                        className={`tab-pane fade${
                          activeTab === "finalAssessmentResult" ||
                          (!hasPermission("view-days-overview") && !hasPermission("view-days-warmup") && !hasPermission("view-days-exercise"))
                            ? " show active"
                            : ""
                        }`}
                        // className={
                        //   !hasPermission("view-days-overview") && !hasPermission("view-days-warmup") && !hasPermission("view-days-exercise")
                        //     ? "tab-pane fade show active"
                        //     : "tab-pane fade"
                        // }
                        id="finalAssessmentResult"
                        role="tabpanel"
                        aria-labelledby="finalAssessmentResult-tab"
                      >
                        {dayDetails ? (
                          <FinalAssessmentResult day={dayDetails} category={dayDetails?.week?.program?.category} />
                        ) : (
                          <div className="text-center py-5">
                            <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WeekDayDetails;
