import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DataTable from "react-data-table-component";

import { remove_session, getCommunityPostComments, deleteCommunityPostComment } from "../../../../../functions/general";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const CommunityPostComments = (props) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  // const memberEmail = searchParams.get("memberEmail");

  // const [searchValueQuery, setSearchValueQuery] = React.useState(memberEmail ?? "");
  // const [searchField, setSearchField] = React.useState(memberEmail ? { value: "email", label: "Email" } : null);

  // const [membershipTypeQuery, setMembershipTypeQuery] = React.useState(null);
  // const [membershipOriginQuery, setMembershipOriginQuery] = React.useState(null);
  // const [gatewayQuery, setGatewayQuery] = React.useState(null);
  // const [platformQuery, setPlaformQuery] = React.useState(null);
  // const [statusQuery, setStatusQuery] = React.useState(null);
  // const [recurringValue, setRecurringValue] = React.useState();

  const [from, setFrom] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [resetPagination, setResetPagination] = React.useState(false);

  const [commentsList, setCommentsList] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const [tableDataLoading, setTableDataLoading] = React.useState(true);

  // useEffect(() => {
  //   if (!memberEmail) {
  //     setTableDataLoading(true);
  //     setSearchValueQuery("");
  //     setSearchField(null);
  //     fetchData({ from, limit });
  //   }
  // }, [memberEmail]);

  useEffect(() => {
    setTableDataLoading(true);
    props.setPageTitleAndSubTitle("Comments List", "Here is the list of Comments!");
    fetchData();
  }, [from, limit]);

  const fetchData = () => {
    getCommunityPostComments({ from, limit, post: searchParams?.get("id") }).then((res) => {
      if (res && res.status === 200) {
        if (res?.data) {
          setCommentsList(res?.data?.comments);
          setTotalRows(searchParams?.get("comments_count"));
        }
        setTableDataLoading(false);
        // }
        setTableDataLoading(false);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };

  // useEffect(() => {
  //   setResetPagination(false);
  //   setFilteredDataArray(commentsList); // Set filteredDataArray whenever commentsList changes
  // }, [commentsList]);

  // const convertJsonToCsv = (jsonData) => {
  //   // Use papaparse to convert JSON to CSV
  //   const csvData = Papa.unparse(jsonData);
  //   return csvData;
  // };

  // const handleDownloadCSV = () => {
  //   setCsvLoading(true);
  //   const filters = {
  //     origin: membershipOriginQuery?.value,
  //     searchValue: searchValueQuery,
  //     searchByField: searchField?.value,
  //     platform: platformQuery?.value,
  //     status: statusQuery?.value,
  //     paymentStatus: "",
  //     membership: membershipTypeQuery?.value,
  //     gateway: gatewayQuery?.value,
  //     recurringValue: recurringValue?.value,
  //   };
  //   commentsList(filters).then((res) => {
  //     if (res && res.status == 201) {
  //       // Convert JSON to CSV
  //       const csvData = convertJsonToCsv(res?.data);

  //       // Create a Blob containing the CSV data
  //       const blob = new Blob([csvData], { type: "text/csv" });

  //       // Create a link element and trigger the download
  //       const link = document.createElement("a");
  //       link.href = window.URL.createObjectURL(blob);
  //       link.download = "subscriptions.csv";
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       setCsvLoading(false);
  //     } else if (res && res.status == 401) {
  //       remove_session();
  //       props.getLoggedInUser();
  //       navigate("/login");
  //       setCsvLoading(false);
  //     } else {
  //       setCsvLoading(false);
  //     }
  //   });
  // };

  const deleteHandler = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this comment?") === true) {
      deleteCommunityPostComment(id).then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          fetchData();
          toast.success("Comment deleted successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete comment.");
        }
      });
    }
  };

  const columns = [
    {
      name: "Comment",
      selector: (row) => row?.text,
      wrap: true,
    },
    {
      name: "Comment By",
      selector: (row) => (
        <div className="d-flex align-items-center">
          <img
            src={
              row?.userDetails?.profileImage
                ? process.env.REACT_APP_MEDIAPATH + row?.userDetails?.profileImage
                : `https://ui-avatars.com/api/?name=${row?.userDetails?.firstName}&bold=true`
            }
            alt={row?.userDetails?.firstName}
            style={{ width: 45, height: 45, objectFit: "cover", borderRadius: "50px", marginRight: 14 }}
          />
          <p className="mb-0">{row?.userDetails?.firstName}</p>
        </div>
      ),
    },
    {
      name: "Date",
      selector: (row) => dayjs(row?.createdAt)?.format("DD MMM YYYY"),
    },
    {
      name: "Replies",
      selector: (row) => (
        <Link to={`/community-post-replies?id=${row?._id}&post_id=${searchParams?.get("id")}`} className="btn btn-dark btn-xs">
          {row?.replyCount}
        </Link>
      ),
    },
    {
      name: "Likes",
      selector: (row) => row?.likeCount,
    },
    {
      name: "Actions",
      selector: (row) => (
        <button onClick={(e) => deleteHandler(e, row?._id)} className="btn btn-danger shadow btn-sm sharp">
          <i className="fa fa-trash"></i>
        </button>
      ),
    },
  ];

  // const handlResetFilters = () => {
  //   setSearchParams("");
  //   setTableDataLoading(true);
  //   setSearchValueQuery("");
  //   setSearchEmailQuery("");
  //   setDateFrom("");
  //   setDateTo("");
  //   setMembershipTypeQuery(null);
  //   setMembershipOriginQuery(null);
  //   setGatewayQuery(null);
  //   setPlaformQuery(null);
  //   setStatusQuery(null);
  //   fetchData({ from: 0, limit });
  // };

  const customStyles = {
    rows: {
      style: {
        minHeight: "70px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "18px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "16px",
      },
    },
  };

  const handlePageChange = (page) => {
    setFrom((page - 1) * limit);
    setTableDataLoading(true);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setLimit(newPerPage);
  };

  // const handleFilterButtonClick = () => {
  //   setFrom(0);
  //   setResetPagination(true);

  //   const filtersObj = {
  //     origin: membershipOriginQuery?.value,
  //     searchValue: searchValueQuery,
  //     platform: platformQuery?.value,
  //     status: statusQuery?.value,
  //     paymentStatus: "",
  //     membership: membershipTypeQuery?.value,
  //     gateway: gatewayQuery?.value,
  //     recurringValue: recurringValue?.value,
  //     searchByField: searchField?.value,
  //     limit,
  //   };
  //   fetchData(filtersObj);
  //   setTableDataLoading(true);
  // };

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-2 align-items-center">
          <div className="col-8">
            <ol className="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li className="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li class="breadcrumb-item">
                <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate("/community-posts")}>
                  Community Posts
                </span>
              </li>
              <li className="breadcrumb-item">Comments</li>
            </ol>
          </div>
        </div>

        {/* <div className="row align-items-end justify-content-end mb-2 px-3">
          <div className="col-2">
            <div className="form-group">
              <label>Search</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={searchValueQuery}
                onChange={(e) => setSearchValueQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group px-3 col-md-2">
            <label>Search Field</label>
            <Select
              name="By"
              value={searchField}
              onChange={(e) => setSearchField(e)}
              className="basic-single form-control"
              classNamePrefix="select"
              options={[
                { value: "name", label: "User Name" },
                { value: "email", label: "Email" },
                { value: "id", label: "Id" },
              ]}
            />
          </div> */}

        {/*<div className="col-2">*/}
        {/*  <div className="form-group">*/}
        {/*    <label>Date From</label>*/}
        {/*    <input type="date" className="form-control" placeholder="Date from" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="col-2">*/}
        {/*  <div className="form-group">*/}
        {/*    <label>Date To</label>*/}
        {/*    <input type="date" className="form-control" placeholder="Date to" value={dateTo} onChange={(e) => setDateTo(e.target.value)} />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/* <div className="col-2">
            <div className="form-group">
              <p className="m-0">&nbsp;</p>
              <button className="btn btn-primary float-right btn-md ml-2" onClick={handleFilterButtonClick}>
                Apply
              </button>
              <button className="btn btn-primary float-right btn-md" onClick={handlResetFilters}>
                Reset
              </button>
            </div>
          </div> */}
        {/* <button
            style={{ height: "50px", marginBottom: "1.1rem" }}
            className="btn btn-primary float-right btn-md ml-2"
            onClick={handleFilterButtonClick}
          >
            Go
          </button>
        </div> */}

        {/* <div className="row justify-content-end mb-4 px-3">
          <button className="btn btn-primary float-right btn-md ml-2" onClick={handleDownloadCSV}>
            {csvLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Download CSV
          </button>
        </div> */}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <DataTable
                    className="custom-data-table"
                    columns={columns}
                    data={commentsList && commentsList}
                    pagination
                    customStyles={customStyles}
                    progressPending={tableDataLoading}
                    persistTableHead
                    onChangePage={handlePageChange}
                    paginationResetDefaultPage={resetPagination}
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[10, 50, 100, 250, 500]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    progressComponent={<div className="spinner-border text-black mt-5 mb-3" role="status" aria-hidden="true"></div>}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunityPostComments;
