import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { remove_session, getFaqsList, updateFaq, addFaq, deleteFaqPermanently, restoreFaq, deleteFaq } from "../../../functions/general";
import { toast } from "react-toastify";
import { usePermissions } from "../../../functions/usePermissions";

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";

const FAQS = (props) => {
  const { hasPermission } = usePermissions();

  const { state } = useLocation();

  const navigate = useNavigate();
  const [faqsList, setFaqsList] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [id, setId] = React.useState("");
  const [question, setQuestion] = React.useState("");
  const [answer, setAnswer] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [errors, setErrors] = React.useState("");

  useEffect(() => {
    props.setPageTitleAndSubTitle("Frequently Asked Questions", "Here is the list of FAQs!");
    fetchData();
  }, []);

  const fetchData = () => {
    getFaqsList(state?.category?._id).then((res) => {
      if ((res && res.status == 201) || res.status == 200) {
        if (res.data) {
          setFaqsList(res?.data);
          setTableDataLoading(false);
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        setTableDataLoading(false);
      }
    });
  };
  const modalHandler = (faq) => {
    if (faq && faq._id) {
      setModalTitle("Update FAQ");
      setModalButtonText("Update");
      setQuestion(faq?.question);
      setAnswer(faq?.answer);
      setId(faq._id);
    } else {
      setModalTitle("Add New FAQ");
      setModalButtonText("Add");
      setQuestion("");
      setAnswer("");
      setId("");
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const faqData = {
        question,
        answer,
        category: state?.category,
      };
      if (id) {
        updateFaq(id, faqData).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("FAQ updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update FAQ.");
          }
          setLoading(false);
        });
      } else {
        addFaq(faqData).then((res) => {
          if (res && res.status == 201) {
            fetchData();
            toast.success("FAQ added successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add FAQ.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      question: "",
      answer: "",
    };
    let isValid = true;
    if (!question) {
      isValid = false;
      errors.question = "Please enter question";
    }
    if (!answer) {
      isValid = false;
      errors.answer = "Please select answer";
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, faq) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete FAQ?") == true) {
      deleteFaqPermanently(faq?._id).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("FAQ deleted successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete FAQ.");
        }
      });
    }
  };

  const deleteStatusHandler = (e, faq) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the FAQ?";
    if (faq?.isDeleted) {
      confirmText = "Are you sure you want to active the FAQ?";
    }
    if (window.confirm(confirmText) == true) {
      if (faq?.isDeleted) {
        restoreFaq(faq._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("FAQ activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active FAQ.");
          }
        });
      } else {
        deleteFaq(faq?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("FAQ deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive FAQ.");
          }
        });
      }
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedFaqs = arrayMove(faqsList, oldIndex, newIndex);
    setFaqsList(updatedFaqs);
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ faq, serialNo }) => {
    return (
      <tr>
        <td>{serialNo + 1}</td>
        <td>{faq?.question}</td>
        <td>{faq?.answer}</td>
        {hasPermission("edit-faq") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={!faq?.isDeleted} onClick={(e) => deleteStatusHandler(e, faq)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {(hasPermission("edit-faq") || hasPermission("delete-faq")) && (
          <td>
            <div className="d-flex">
              {hasPermission("edit-faq") && (
                <button
                  data-toggle="modal"
                  data-target="#add"
                  onClick={(e) => modalHandler(faq)}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {hasPermission("delete-faq") && (
                <button onClick={(e) => deleteHandler(e, faq)} className="btn btn-danger shadow btn-sm sharp">
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </td>
        )}
        {/* {hasPermission("edit-program") && (
          <td>
            <DragHandle />
          </td>
        )} */}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ faqsList }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>No</th>
            <th>Question</th>
            <th>Answer</th>
            {hasPermission("edit-faq") && <th>Status</th>}
            {(hasPermission("edit-faq") || hasPermission("delete-faq")) && <th>Actions</th>}
            {/* {hasPermission("edit-faq") && <th>Sort Order</th>} */}
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="8" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {faqsList?.length > 0 ? (
                faqsList?.map((faq, index) => {
                  return <SortableTableRow key={index} faq={faq} index={index} serialNo={index} />;
                })
              ) : (
                <tr>
                  <td colSpan="8" className="text-center pt-5">
                    No FAQ found!
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4 align-items-center">
          <div className="col-8">
            <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li class="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li class="breadcrumb-item">
                <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate(-1)}>
                  FAQ Categories
                </span>
              </li>
              <li class="breadcrumb-item">
                <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate(-1)}>
                  Frequently Asked Questions
                </span>
              </li>
            </ol>
          </div>
          <div className="col-4">
            {hasPermission("add-program") && (
              <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={() => modalHandler()}>
                Add New
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <SortableTable faqsList={faqsList} onSortEnd={onSortEnd} useDragHandle={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="add">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="form-group px-3">
                  <label>Question</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Question"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                  {errors.question && !question && <div className="text-warning font-size-12">{errors.question}</div>}
                </div>
                <div className="form-group px-3">
                  <label>Answer</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter Answer"
                    rows={4}
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                  />
                  {errors.answer && !answer && <div className="text-warning font-size-12">{errors.answer}</div>}
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQS;
