import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  remove_session,
  getProgramsList,
  updateProgram,
  addProgram,
  deleteProgramPermanently,
  restoreProgram,
  deleteProgram,
  sortPrograms,
  getProgramCategoryDetails,
} from "../../../functions/general";
import { toast } from "react-toastify";
import Media from "../../../components/Media";
import ModalImage from "react-modal-image";
import { usePermissions } from "../../../functions/usePermissions";
import Select from "react-select";

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";

function ListPrograms(props) {
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const [programsList, setProgramsList] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [image, setImage] = React.useState("");
  const [programType, setProgramType] = React.useState("");
  const [id, setId] = React.useState("");
  const [isPaid, setIsPaid] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [errors, setErrors] = React.useState("");
  const [programTypeFilter, setProgramTypeFilter] = React.useState("normal");
  const [originalProgramsList, setOriginalProgramsList] = React.useState([]);

  let { state } = useLocation();

  const [programCategory, setProgramCategory] = React.useState(null);
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get("categoryId");

  useEffect(() => {
    getProgramCategoryDetails(categoryId).then((res) => {
      if (res && res.status == 200) {
        setProgramCategory(res?.data);
        props.setPageTitleAndSubTitle(res?.data?.title, "Here is the list of Programs!");
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
    fetchData();
  }, []);

  const fetchData = (type) => {
    getProgramsList(categoryId).then((res) => {
      if (res && res.status == 201) {
        if (res.data) {
          const filteredData = programTypeFilter ? res?.data?.programs.filter((program) => program?.type === programTypeFilter) : res?.data?.programs;
          setProgramsList(filteredData);
          // setProgramsList(res?.data?.programs);
          setOriginalProgramsList(res?.data?.programs);

          if (type === "add") {
            let sortedArray = [];
            res?.data?.map((program) => sortedArray.push(program?._id));
            sortPrograms(sortedArray).then((res) => {
              if (res && res.status == 200) {
                // fetchData();
              } else if (res && res.status == 401) {
                remove_session();
                props.getLoggedInUser();
                navigate("/login");
              } else {
                toast.error("Failed to sort the list.");
              }
              setTableDataLoading(false);
            });
          }
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };
  const modalHandler = (program) => {
    if (program && program._id) {
      setModalTitle("Update Program");
      setModalButtonText("Update");
      setTitle(program.title);
      setImage(program.image);
      setProgramType(program?.type);
      setIsPaid(program?.isPaid);
      setId(program._id);
    } else {
      setModalTitle("Add New Program");
      setModalButtonText("Add");
      setTitle("");
      setImage("");
      setProgramType("");
      setIsPaid(true);
      setId("");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const programData = {
        title: title,
        image: image,
        type: programType,
        category: programCategory,
        isPaid: isPaid,
      };
      let type = "add";
      if (id) {
        updateProgram(id, programData).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Program Category updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update program category.");
          }
          setLoading(false);
        });
      } else {
        addProgram(programData).then((res) => {
          if (res && res.status == 201) {
            fetchData(type);
            toast.success("Program Category added successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add program category.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      image: "",
      programType: "",
    };
    let isValid = true;
    if (!title) {
      isValid = false;
      errors.title = "Please enter title";
    }
    if (!image) {
      isValid = false;
      errors.image = "Please select an image";
    }
    if (!programType) {
      isValid = false;
      errors.programType = "Please select program type";
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, program) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete program?") == true) {
      deleteProgramPermanently(program?._id).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("Program deleted successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete program.");
        }
      });
    }
  };

  const deleteStatusHandler = (e, program) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the program?";
    if (program?.isDeleted) {
      confirmText = "Are you sure you want to active the program?";
    }
    if (window.confirm(confirmText) == true) {
      if (program?.isDeleted) {
        restoreProgram(program._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Program activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active program.");
          }
        });
      } else {
        deleteProgram(program?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Program deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive program.");
          }
        });
      }
    }
  };

  const paymentStatusHandler = (e, program) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to change payment status of program?";
    if (window.confirm(confirmText) == true) {
      const programData = {
        title: program?.title,
        image: program?.image,
        type: program?.type,
        category: programCategory,
        isPaid: e.target.checked,
      };
      updateProgram(program._id, programData).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("Payment status changed successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to change the payment status of program.");
        }
      });
    }
  };

  const setImageHandler = (image) => {
    setImage(image);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    const updatedPrograms = arrayMove(programsList, oldIndex, newIndex);
    let sortedArray = [];
    updatedPrograms?.map((program) => sortedArray.push(program?._id));
    sortPrograms(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setProgramsList(updatedPrograms);
        // fetchData();
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ program, serialNo }) => {
    return (
      <tr>
        <td>{serialNo + 1}</td>
        <td>
          <div className="maxWidth70px">
            <ModalImage small={process.env.REACT_APP_MEDIAPATH + program?.image} large={process.env.REACT_APP_MEDIAPATH + program?.image} />
          </div>
        </td>
        <td>{program?.title}</td>
        <td>{program?.type}</td>
        {hasPermission("view-weeks") && (
          <td>
            <Link to={`/program-weeks?programId=${program?._id}`} className="btn btn-dark btn-xs">
              {program?.count ? program?.count : 0}
            </Link>
          </td>
        )}
        {hasPermission("edit-program") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={program?.isPaid} onChange={(e) => paymentStatusHandler(e, program)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {hasPermission("edit-program") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={!program?.isDeleted} onChange={(e) => deleteStatusHandler(e, program)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {(hasPermission("edit-program") || hasPermission("delete-program")) && (
          <td>
            <div className="d-flex">
              {hasPermission("edit-program") && (
                <button
                  data-toggle="modal"
                  data-target="#add"
                  onClick={(e) => modalHandler(program)}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {hasPermission("delete-program") && (
                <button onClick={(e) => deleteHandler(e, program)} className="btn btn-danger shadow btn-sm sharp">
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </td>
        )}
        {hasPermission("edit-program") && (
          <td>
            <DragHandle />
          </td>
        )}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ programsList }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>No</th>
            <th>Image</th>
            <th>Title</th>
            <th>Type</th>
            {hasPermission("view-weeks") && <th>Weeks</th>}
            {hasPermission("edit-program") && <th>Paid</th>}
            {hasPermission("edit-program") && <th>Status</th>}
            {(hasPermission("edit-program") || hasPermission("delete-program")) && <th>Actions</th>}
            {hasPermission("edit-program") && <th>Sort Order</th>}
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="9" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {programsList?.length > 0 ? (
                programsList?.map((program, index) => {
                  return <SortableTableRow key={index} program={program} index={index} serialNo={index} />;
                })
              ) : (
                <tr>
                  <td colSpan="9" className="text-center pt-5">
                    No program found!
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  const programTypeFilterOptions = [
    { value: "microcycles", label: "Microcycles" },
    { value: "normal", label: "Normal" },
  ];

  const handleProgramTypeFilter = async (selected) => {
    setTableDataLoading(true);
    setProgramTypeFilter(selected?.value);

    // Introduce some delay to mimic loading
    await new Promise((resolve) => setTimeout(resolve, 300));

    // If no filter is selected, return the original array
    if (!selected) {
      setProgramsList(originalProgramsList);
    } else {
      // Filter from the originalProgramsList using the selected program type
      const filteredPrograms = originalProgramsList?.filter((program) => program?.type === selected?.value);
      setProgramsList(filteredPrograms);
    }
    setTableDataLoading(false);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4 align-items-center">
          <div className="col-8">
            <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li class="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li class="breadcrumb-item">
                <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate('/program-categories')}>
                  Programs
                </span>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {programCategory?.title}
              </li>
            </ol>
          </div>
          <div className="col-4">
            {hasPermission("add-program") && (
              <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={() => modalHandler()}>
                Add New
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-3 form-group px-3 mb-4">
            <Select
              name="programTypeFilter"
              value={programTypeFilter && programTypeFilterOptions?.find((obj) => obj?.value === programTypeFilter)}
              options={programTypeFilterOptions}
              onChange={(selected) => handleProgramTypeFilter(selected)}
              className="basic-single form-control"
              classNamePrefix="select"
              placeholder="Select program type"
            />
            {/* {errors.color && !color && <div className="text-warning font-size-12">{errors.color}</div>} */}
          </div>
          <div className="col-md-2">
            <button
              className="btn btn-primary"
              onClick={() => {
                setProgramTypeFilter("");
                setProgramsList(originalProgramsList);
              }}
            >
              Clear
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <SortableTable programsList={programsList} onSortEnd={onSortEnd} useDragHandle={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="add">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="form-group px-3">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Program Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {errors.title && !title && <div className="text-warning font-size-12">{errors.title}</div>}
                </div>
                <div className="form-group px-3">
                  <label>Program Type</label>
                  <select className="form-control" value={programType} onChange={(e) => setProgramType(e.target.value)}>
                    <option value="">Select Type</option>
                    <option value="normal">Normal</option>
                    <option value="microcycles">Microcycles</option>
                  </select>
                  {errors.programType && !programType && <div className="text-warning font-size-12">{errors.programType}</div>}
                </div>
                <div className="form-group px-3">
                  <label>Image</label>
                  <br />
                  {image && (
                    <>
                      <img src={process.env.REACT_APP_MEDIAPATH + image} className="img-fluid img-thumbnail" width="150" />
                      <br />
                    </>
                  )}
                  <button type="button" className="btn btn-primary mt-3" data-toggle="modal" data-target="#media">
                    Select Image
                  </button>
                  {errors.image && !image && <div className="text-warning font-size-12">{errors.image}</div>}
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Media setImageHandler={setImageHandler} />
      </div>
    </>
  );
}

export default ListPrograms;
