import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  remove_session,
  getWeekDayExerciseCategoryVideos,
  deleteWeekDayExerciseCategoryVideoPermanently,
  deleteWeekDayExerciseCategoryVideo,
  updateWeekDayExerciseCategoryVideo,
  addWeekDayExerciseCategoryVideo,
  restoreWeekDayExerciseCategoryVideo,
  cloneWeekDayExerciseCategoryVideo,
  sortWeekDayExerciseVideos,
} from "../../../../functions/general";
import { toast } from "react-toastify";
import ModalImage from "react-modal-image";
import { usePermissions } from "../../../../functions/usePermissions";

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";

function WeekDayEcerciseVideos(props) {
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [videoUrl, setvideoUrl] = React.useState("https://vimeo.com/");
  const [score, setScore] = React.useState("");
  const [isReps, setIsReps] = React.useState("reps");
  const [repsValue, setrepsValue] = React.useState("");
  const [time, setTime] = React.useState("");
  const [timeType, setTimeType] = React.useState("sec");
  const [rest, setRest] = React.useState("");
  const [tempo, setTempo] = React.useState("");
  const [minSets, setMinSets] = React.useState("");
  const [maxSets, setMaxSets] = React.useState("");
  const [srNo, setSrNo] = React.useState("");
  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState("");
  const [exerciseType, setExerciseType] = React.useState("");
  const [weekDayExerciseCategoryVideos, setWeekDayExerciseCategoryVideos] = React.useState([]);
  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [allowScore, setAllowScore] = React.useState(true);
  const [hideFromUser, setHideFromUser] = React.useState(true);
  const [isTwoSided, setIsTwoSided] = React.useState(false);
  const [scoreType, setScoreType] = React.useState("reps");
  const [leftScore, setLeftScore] = React.useState("");
  const [rightScore, setRightScore] = React.useState("");

  const [swapType, setSwapType] = React.useState("normal");

  const { state } = useLocation();
  useEffect(() => {
    // props.setPageTitleAndSubTitle("Lecture Videos", "Here is the list of Lecture Categories !");
    fetchData();
  }, []);

  const fetchData = (type) => {
    getWeekDayExerciseCategoryVideos(props?.exerciseCategory?._id).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        setWeekDayExerciseCategoryVideos(res?.data);
        if (type === "add") {
          let sortedArray = [];
          res?.data?.map((video) => sortedArray.push(video?._id));
          sortWeekDayExerciseVideos(sortedArray).then((res) => {
            if (res && res.status == 200) {
              // fetchData();
            } else if (res && res.status == 401) {
              remove_session();
              props.getLoggedInUser();
              navigate("/login");
            } else {
              toast.error("Failed to sort the list.");
            }
            setTableDataLoading(false);
          });
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };

  const modalHandler = (video) => {
    if (video && video._id) {
      var value = video?.sec && video?.sec?.split(" ");

      setModalTitle("Update Exercise Video");
      setModalButtonText("Update");
      setTitle(video?.title);
      setvideoUrl(video?.vimeo_url);
      setScore(video?.score);
      setLeftScore(video?.leftScore);
      setRightScore(video?.rightScore);
      setrepsValue(video?.reps);
      // setTime(value[0] && value[0]);
      setTime(video?.sec);
      setTimeType(value[1] && value[1]);
      setIsReps(video?.isReps === true ? "reps" : "seconds");
      setRest(video?.rest);
      setTempo(video?.tempo);
      setMinSets(video?.minSets);
      setMaxSets(video?.maxSets);
      setSrNo(video?.srNo);
      setId(video._id);
      setAllowScore(video?.allowScore);
      setHideFromUser(video?.hideFromUser);
      setExerciseType(video?.exerciseType);
      setIsTwoSided(video?.isTwoSided);
      setScoreType(video?.scoreType);
      setSwapType(video?.swapType);
    } else {
      setModalTitle("Add New Exercise Video");
      setModalButtonText("Add");
      setTitle("");
      setvideoUrl("https://vimeo.com/");
      setScore("");
      setLeftScore("");
      setRightScore("");
      setrepsValue("");
      setTime("");
      // setTimeType("sec");
      setIsReps("reps");
      setRest("");
      setTempo("");
      setMinSets("");
      setMaxSets("");
      setSrNo("");
      setId("");
      setAllowScore(true);
      setHideFromUser(false);
      setExerciseType("");
      setIsTwoSided(false);
      setScoreType("");
      setSwapType("normal");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const exerciseData = {
        title: title,
        url: videoUrl,
        ...(allowScore && { leftScore: leftScore ? leftScore : "0" }),
        ...(allowScore && { rightScore: rightScore ? rightScore : "0" }),
        score: score ? score : "0",
        isReps: isReps === "reps" ? true : false,
        reps: repsValue,
        sec: time,
        minSets: +minSets,
        maxSets: +maxSets,
        rest: rest,
        tempo: tempo,
        srNo: srNo,
        category: props?.exerciseCategory,
        activeRest: exerciseType === "activeRest" ? true : false,
        allowScore,
        hideFromUser,
        isTwoSided: isTwoSided,
        scoreType: allowScore && (props?.day?.type === "finalAssessment" || props?.day?.type === "assessment") ? scoreType : "",
        exerciseType,
        swapType,
      };
      let type = "add";
      if (id) {
        updateWeekDayExerciseCategoryVideo(id, exerciseData).then((res) => {
          if (res && (res.status == 200 || res.status == 201)) {
            fetchData();
            toast.success("Video updated successfully.");
            document.getElementById("weekDayExerciseModalId").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update video.");
          }
          setLoading(false);
        });
      } else {
        addWeekDayExerciseCategoryVideo(exerciseData).then((res) => {
          if (res && res.status == 201) {
            fetchData(type);
            toast.success("Video added successfully.");
            document.getElementById("weekDayExerciseModalId").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add video.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      // title: "",
      url: "",
      reps: "",
      time: "",
      // timeType: "",
      rest: "",
      tempo: "",
      minSets: "",
      maxSets: "",
      srNo: "",
      // score: "",
    };
    let isValid = true;
    // if (!title) {
    //   isValid = false;
    //   errors.title = "Please enter title.";
    // }
    if (!srNo) {
      isValid = false;
      errors.srNo = "Please enter serial number.";
    }
    // // URL validation
    // const urlRegex = new RegExp(
    //   "(https?:\\/\\/www\\.|https?:\\/\\/|www\\.)?[a-zA-Z0-9]{2,}(\\.[a-zA-Z0-9]{2,})(\\.[a-zA-Z0-9]{2,})?\\/[a-zA-Z0-9]{2,}"
    // );
    // if (!videoUrl || !urlRegex.test(videoUrl)) {
    //   isValid = false;
    //   errors.url = "Please enter a valid URL.";
    // }
    if (!videoUrl) {
      isValid = false;
      errors.url = "Please enter url.";
    }
    if (exerciseType === "normal") {
      if (isReps === "reps") {
        if (!repsValue) {
          isValid = false;
          errors.reps = "Please enter reps.";
        }
      }
      if (isReps === "seconds") {
        if (!time) {
          isValid = false;
          errors.time = "Please enter time.";
        }
        // if (!timeType) {
        //   isValid = false;
        //   errors.timeType = "Please select timeType.";
        // }
      }
      if (!rest) {
        isValid = false;
        errors.rest = "Please enter rest.";
      }
      if (!tempo) {
        isValid = false;
        errors.tempo = "Please enter tempo.";
      }
      if (!minSets && minSets != 0) {
        isValid = false;
        errors.minSets = "Please enter min sets.";
      }
      if (!maxSets) {
        isValid = false;
        errors.maxSets = "Please enter max sets.";
      }
      // if (props?.day?.type === "finalAssessment") {
      //   if (allowScore && scoreType) {
      //     if (!score) {
      //       isValid = false;
      //       errors.score = "Required Field.";
      //     }
      //   }
      // }
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, video) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete exercise category video?") == true) {
      if (window.confirm("Are you sure you want to delete exercise category video permanently?") == true) {
        deleteWeekDayExerciseCategoryVideoPermanently(video._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Exercise Category video deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete video.");
          }
        });
      }
    }
  };

  const deleteStatusHandler = (e, video) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the exercise video?";
    if (video.isDeleted) {
      confirmText = "Are you sure you want to active the exercise video?";
    }
    if (window.confirm(confirmText) == true) {
      if (video.isDeleted) {
        restoreWeekDayExerciseCategoryVideo(video._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Exercise video activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active video.");
          }
        });
      } else {
        deleteWeekDayExerciseCategoryVideo(video._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Exercise video deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive video.");
          }
        });
      }
    }
  };

  const exerciseCloneHandler = (e, video) => {
    setTableDataLoading(true);
    e.preventDefault();
    if (window.confirm("Are you sure you want to clone exercise?") == true) {
      cloneWeekDayExerciseCategoryVideo(video?._id).then((res) => {
        if (res && (res.status == 200 || res.status == 201)) {
          fetchData();
          toast.success("Exercise cloned successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to clone exercise.");
        }
        setTableDataLoading(false);
      });
    } else {
      setTableDataLoading(false);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    const updatedExerciseVideos = arrayMove(weekDayExerciseCategoryVideos, oldIndex, newIndex);
    let sortedArray = [];
    updatedExerciseVideos?.map((video) => sortedArray.push(video?._id));
    sortWeekDayExerciseVideos(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setWeekDayExerciseCategoryVideos(updatedExerciseVideos);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ video, index }) => {
    return (
      <tr key={index}>
        <td>{video?.srNo}</td>
        <td>
          <div className="maxWidth70px">
            <ModalImage small={video?.video_thumbnail} large={video?.video_thumbnail} />
          </div>
        </td>
        <td>{video.title}</td>
        {(props?.day?.type === "finalAssessment" || props?.day?.type === "assessment") && (
          <td>{video?.exerciseType === "twoSides" ? `${video?.leftScore} ${video?.rightScore}` : video.score}</td>
        )}
        {hasPermission("view-day-exercise-swap-videos") && (
          <td>
            <button
              className="btn btn-dark btn-xs"
              onClick={() => {
                props?.setExerciseCategoryVideo(video);
                props?.setExerciseSwapVideosShow(true);
                props?.setExerciseVideosShow(false);
              }}
            >
              {video?.count ? video?.count : 0}
            </button>
          </td>
        )}
        <td>{video?.exerciseType}</td>
        {hasPermission("edit-day-exercise-videos") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={!video.isDeleted} onClick={(e) => deleteStatusHandler(e, video)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {(hasPermission("edit-day-exercise-videos") || hasPermission("delete-day-exercise-videos")) && (
          <td>
            <div className="d-flex">
              {hasPermission("edit-day-exercise-videos") && (
                <button
                  data-toggle="modal"
                  data-target="#weekDayExerciseModal"
                  onClick={(e) => modalHandler(video)}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {hasPermission("delete-day-exercise-videos") && (
                <button onClick={(e) => deleteHandler(e, video)} className="btn btn-danger shadow btn-sm sharp">
                  <i className="fa fa-trash"></i>
                </button>
              )}
              {hasPermission("edit-day-exercise-videos") && (
                <button
                  onClick={(e) => exerciseCloneHandler(e, video)}
                  className="btn btn-secondary shadow btn-sm ml-1"
                  style={{ padding: "9px 13px" }}
                >
                  <i className="fa fa-copy" style={{ fontSize: "16px" }}></i>
                </button>
              )}
            </div>
          </td>
        )}
        {hasPermission("edit-day-warmup") && (
          <td>
            <DragHandle />
          </td>
        )}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ weekDayExerciseCategoryVideos }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>Sr No</th>
            <th>Image</th>
            <th>Title</th>
            {(props?.day?.type === "finalAssessment" || props?.day?.type === "assessment") && <th>Score</th>}
            {hasPermission("view-day-exercise-swap-videos") && <th>Swap Videos</th>}
            <th>Type</th>
            {hasPermission("edit-day-exercise-videos") && <th>Status</th>}
            {(hasPermission("edit-day-exercise-videos") || hasPermission("delete-day-exercise-videos")) && <th>Actions</th>}
            {hasPermission("edit-day-exercise-videos") && <th>Sort Order</th>}
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="9" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {weekDayExerciseCategoryVideos?.length > 0 ? (
                weekDayExerciseCategoryVideos?.map((video, index) => {
                  return <SortableTableRow key={index} video={video} index={index} />;
                })
              ) : (
                <tr>
                  <td colSpan="9" className="text-center pt-5">
                    No Exercise Category Video found !
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-12">
            <button
              className="btn btn-primary"
              onClick={() => {
                props?.setExerciseVideosShow(false);
              }}
            >
              <i className="fas fa fa-arrow-circle-left mr-3"></i>
              Back to Exercise Category Videos
            </button>
            {hasPermission("add-day-exercise-videos") && (
              <button className="btn btn-primary float-right" data-toggle="modal" data-target="#weekDayExerciseModal" onClick={(e) => modalHandler()}>
                Add New Video
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <SortableTable weekDayExerciseCategoryVideos={weekDayExerciseCategoryVideos} onSortEnd={onSortEnd} useDragHandle={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PerfectScrollbar>
          <div className="modal fade" id="weekDayExerciseModal" data-backdrop="static">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header border-0 px-3 mb-3">
                  <h5 className="modal-title">{modalTitle}</h5>
                  <button type="button" className="close" data-dismiss="modal" id="weekDayExerciseModalId">
                    <span>&times;</span>
                  </button>
                </div>
                <form noValidate onSubmit={onSubmit}>
                  <div className="row justify-content-center mb-3">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Select Exercise Type</label>
                        <select className="form-control" value={exerciseType} onChange={(e) => setExerciseType(e.target.value)}>
                          <option value="">Select Exercise Type</option>
                          <option value="normal">Exercise</option>
                          <option value="activeRest">Active Rest</option>
                          {(props?.day?.type === "finalAssessment" || props?.day?.type === "assessment") && (
                            <option value="twoSides">Two Sides</option>
                          )}
                        </select>
                        {errors.timeType && !timeType && <div className="text-warning font-size-12 mt-2">{errors.timeType}</div>}
                      </div>
                    </div>
                  </div>
                  {exerciseType ? (
                    <div className="row px-3">
                      <div className="form-group col-md-6">
                        <label>Title</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Video Title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        {/* {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>} */}
                      </div>
                      <div className="form-group col-md-6">
                        <label>Video URL</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Video URL"
                          value={videoUrl}
                          onChange={(e) => setvideoUrl(e.target.value)}
                        />
                        {errors.url && !videoUrl && <div className="text-warning font-size-12 mt-2">{errors.url}</div>}
                      </div>
                      <div className="form-group col-md-6">
                        <label>Serial Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Serial Number"
                          value={srNo}
                          onChange={(e) => setSrNo(e.target.value)}
                        />
                        {errors.srNo && !srNo && <div className="text-warning font-size-12 mt-2">{errors.srNo}</div>}
                      </div>

                      {exerciseType !== "activeRest" && (
                        <>
                          <div className="col-5">
                            <div className="form-group">
                              <label>Select Swap Type</label>
                              <select className="form-control" value={swapType} onChange={(e) => setSwapType(e.target.value)}>
                                <option value="">Select Swap Type</option>
                                <option value="normal">Normal Swap</option>
                                <option value="2.0">Swap 2.0</option>
                              </select>
                              {/* {errors.timeType && !timeType && <div className="text-warning font-size-12 mt-2">{errors.timeType}</div>} */}
                            </div>
                          </div>
                          <div className="col-12 mb-3">
                            <div class="form-check form-check-inline mr-4">
                              <input
                                class="form-check-input mr-1"
                                type="radio"
                                name="isReps"
                                id="reps"
                                value="reps"
                                onChange={(e) => setIsReps(e.target.value)}
                                checked={isReps === "reps"}
                              />
                              <label class="form-check-label" for="reps">
                                Reps
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input mr-1"
                                type="radio"
                                name="isReps"
                                id="seconds"
                                value="seconds"
                                onChange={(e) => setIsReps(e.target.value)}
                                checked={isReps === "seconds"}
                              />
                              <label class="form-check-label" for="seconds">
                                Seconds
                              </label>
                            </div>
                          </div>
                          {isReps === "reps" ? (
                            <>
                              <div className="form-group col-md-6">
                                <label>Reps</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Reps"
                                  value={repsValue}
                                  onChange={(e) => setrepsValue(e.target.value)}
                                />
                                {errors.reps && !repsValue && <div className="text-warning font-size-12 mt-2">{errors.reps}</div>}
                              </div>
                              <div className="col-md-6"></div>
                            </>
                          ) : (
                            <>
                              <div className="form-group col-md-6">
                                <label>Time</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter time"
                                  value={time}
                                  onChange={(e) => setTime(e.target.value)}
                                />
                                {errors.time && !time && <div className="text-warning font-size-12 mt-2">{errors.time}</div>}
                              </div>
                              <div className="form-group col-md-6">
                                {/* <label>Select Time Type</label>
                                <select className="form-control" value={timeType} onChange={(e) => setTimeType(e.target.value)}>
                                  <option value="sec">sec</option>
                                  <option value="min">min</option>
                                </select>
                                {errors.timeType && !timeType && <div className="text-warning font-size-12 mt-2">{errors.timeType}</div>} */}
                              </div>
                            </>
                          )}
                          <div className="form-group col-md-6">
                            <label>Rest</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Rest time"
                              value={rest}
                              onChange={(e) => setRest(e.target.value)}
                            />
                            {errors.rest && !rest && <div className="text-warning font-size-12 mt-2">{errors.rest}</div>}
                          </div>
                          <div className="form-group col-md-6">
                            <label>Tempo</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter tempo"
                              value={tempo}
                              onChange={(e) => setTempo(e.target.value)}
                            />
                            {errors.tempo && !tempo && <div className="text-warning font-size-12 mt-2">{errors.tempo}</div>}
                          </div>
                          <div className="form-group col-md-6">
                            <label>MinSets</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter min sets"
                              value={minSets}
                              onChange={(e) => setMinSets(e.target.value)}
                            />
                            {errors.minSets && !minSets && <div className="text-warning font-size-12 mt-2">{errors.minSets}</div>}
                          </div>
                          <div className="form-group col-md-6">
                            <label>MaxSets</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter max sets"
                              value={maxSets}
                              onChange={(e) => setMaxSets(e.target.value)}
                            />
                            {errors.maxSets && !maxSets && <div className="text-warning font-size-12 mt-2">{errors.maxSets}</div>}
                          </div>
                          {props?.day?.type === "finalAssessment" || props?.day?.type === "assessment" ? (
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="d-flex align-items-center mb-3">
                                    <input
                                      className="mr-2 mb-1"
                                      type="checkbox"
                                      name="allow-score"
                                      id="allow-score"
                                      value={allowScore}
                                      checked={allowScore}
                                      onChange={(e) => setAllowScore(e.target.checked)}
                                    />
                                    <label className="mb-0" for="allow-score" style={{ cursor: "pointer" }}>
                                      Allow Target
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="d-flex align-items-center mb-3">
                                    <input
                                      className="mr-2 mb-1"
                                      type="checkbox"
                                      name="hide-from-user"
                                      id="hide-from-user"
                                      value={hideFromUser}
                                      checked={hideFromUser}
                                      onChange={(e) => setHideFromUser(e.target.checked)}
                                    />
                                    <label className="mb-0" htmlFor="hide-from-user" style={{ cursor: "pointer" }}>
                                      Hide From User
                                    </label>
                                  </div>
                                </div>
                                {allowScore && (
                                  <>
                                    <div className="col-12 mb-3">
                                      <div class="form-check form-check-inline mr-4">
                                        <input
                                          class="form-check-input mr-1"
                                          type="radio"
                                          name="target"
                                          id="targetReps"
                                          value="reps"
                                          onChange={(e) => setScoreType(e.target.value)}
                                          checked={scoreType === "reps"}
                                        />
                                        <label class="form-check-label" for="targetReps">
                                          Reps
                                        </label>
                                      </div>
                                      <div class="form-check form-check-inline">
                                        <input
                                          class="form-check-input mr-1"
                                          type="radio"
                                          name="target"
                                          id="targetSeconds"
                                          value="seconds"
                                          onChange={(e) => setScoreType(e.target.value)}
                                          checked={scoreType === "seconds"}
                                        />
                                        <label class="form-check-label" for="targetSeconds">
                                          Seconds
                                        </label>
                                      </div>
                                    </div>
                                    {scoreType === "reps" ? (
                                      <>
                                        {exerciseType === "twoSides" ? (
                                          <>
                                            <div className="form-group col-md-3">
                                              <label>Left</label>
                                              <input
                                                type="text"
                                                class="form-control"
                                                placeholder="0"
                                                value={leftScore}
                                                onChange={(e) => setLeftScore(e.target.value)}
                                              />
                                            </div>
                                            <div className="form-group col-md-3">
                                              <label>Right</label>
                                              <input
                                                type="text"
                                                class="form-control"
                                                placeholder="0"
                                                value={rightScore}
                                                onChange={(e) => setRightScore(e.target.value)}
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <div className="form-group col-md-6">
                                            <label>Reps</label>
                                            <div class="input-group mb-2">
                                              <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Enter Reps"
                                                value={score}
                                                onChange={(e) => setScore(e.target.value)}
                                              />
                                              <span class="input-group-text" id="basic-addon1">
                                                r
                                              </span>
                                            </div>
                                            {/* {errors.score && !score && <div className="text-warning font-size-12 mt-2">{errors.score}</div>} */}
                                          </div>
                                        )}
                                      </>
                                    ) : exerciseType === "twoSides" ? (
                                      <>
                                        <div className="form-group col-md-3">
                                          <label>Left</label>
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder="0"
                                            value={leftScore}
                                            onChange={(e) => setLeftScore(e.target.value)}
                                          />
                                        </div>
                                        <div className="form-group col-md-3">
                                          <label>Right</label>
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder="0"
                                            value={rightScore}
                                            onChange={(e) => setRightScore(e.target.value)}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <div className="form-group col-md-6">
                                        <label>Seconds</label>
                                        <div class="input-group mb-2">
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Enter Seconds"
                                            value={score}
                                            onChange={(e) => setScore(e.target.value)}
                                          />
                                          <span class="input-group-text" id="basic-addon1">
                                            s
                                          </span>
                                        </div>
                                        {/* {errors.score && !score && <div className="text-warning font-size-12 mt-2">{errors.score}</div>} */}
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="d-flex align-items-center mb-3">
                                    <input
                                      className="mr-2 mb-1"
                                      type="checkbox"
                                      name="allow-score"
                                      id="allow-score"
                                      value={allowScore}
                                      checked={allowScore}
                                      onChange={(e) => setAllowScore(e.target.checked)}
                                    />
                                    <label className="mb-0" for="allow-score" style={{ cursor: "pointer" }}>
                                      Allow Score
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="d-flex align-items-center mb-3">
                                    <input
                                      className="mr-2 mb-1"
                                      type="checkbox"
                                      name="hide-from-user"
                                      id="hide-from-user"
                                      value={hideFromUser}
                                      checked={hideFromUser}
                                      onChange={(e) => setHideFromUser(e.target.checked)}
                                    />
                                    <label className="mb-0" for="hide-from-user" style={{ cursor: "pointer" }}>
                                      Hide From User
                                    </label>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center mb-3">
                                  <input
                                    className="mr-2 mb-1"
                                    type="checkbox"
                                    name="two-sided"
                                    id="two-sided"
                                    value={isTwoSided}
                                    checked={isTwoSided}
                                    onChange={(e) => setIsTwoSided(e.target.checked)}
                                  />
                                  <label className="mb-0" htmlFor="two-sided" style={{ cursor: "pointer" }}>
                                    Two Sided
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      <div className="modal-footer border-0 mb-4 ml-auto mt-4">
                        <button type="submit" className="btn btn-primary">
                          {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}{" "}
                          {modalButtonText}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <h3 className="text-center my-4">Please Select an Exercise type first !</h3>
                  )}
                </form>
              </div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </>
  );
}

export default WeekDayEcerciseVideos;
