import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  remove_session,
  getProgramWeekLecturesOverviewList,
  addProgramWeekLectureOverview,
  updateProgramWeekLectureText,
  sortProgramWeekLectureOverviews,
} from "../../../../../functions/general";
import { toast } from "react-toastify";
import Media from "../../../../../components/Media";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PDFIcon from "../../../../../images/PDF_file_icon.png";
import MP3Icon from "../../../../../images/MP3_icon.png";
import VideoOverview from "../../../../../components/VideoOverview";
import TextOverview from "../../../../../components/TextOverview";
import PDFOverview from "../../../../../components/PDFOverview";
import { usePermissions } from "../../../../../functions/usePermissions";

import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import AudioOverview from "../../../../../components/AudioOverview";

function LectureOverview(props) {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { hasPermission } = usePermissions();

  const [title, setTitle] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState("");
  const [contentType, setContentType] = React.useState("");
  const [pdf, setPdf] = React.useState("");
  const [lectureOverviewList, setLectureOverviewList] = React.useState([]);
  const [videoUrl, setVideoUrl] = React.useState("https://vimeo.com/");
  const [overviewText, setOverviewText] = React.useState();
  const [fileName, setFileName] = React.useState("");
  const [fileSize, setFileSize] = React.useState("");
  const [tabName, setTabName] = React.useState(state?.lecture?.overviewTabName ? state?.lecture?.overviewTabName : "Overview");
  const [tabNameBtnLoading, setTabNameBtnLoading] = React.useState("");
  const [tableDataLoading, setTableDataLoading] = React.useState(true);

  const [showAddPDFModal, setShowAddPDFModal] = React.useState(false);
  const [showAddAudioModal, setShowAddAudioModal] = React.useState(false);
  const [audio, setAudio] = React.useState(null);

  useEffect(() => {
    // props.setPageTitleAndSubTitle(state?.lecture?.title, `Here is the details of ${state?.lecture?.title} lecture !`);
    fetchData();
  }, []);

  delete state?.lecture?.subcategory?.week

  const fetchData = (type) => {
    getProgramWeekLecturesOverviewList(state?.lecture?._id).then((res) => {
      if (res && (res.status == 201 || res.status == 201)) {
        setLectureOverviewList(res.data);
        if (type === "add") {
          let sortedArray = [];
          res?.data?.map((overview) => sortedArray.push(overview?._id));
          sortProgramWeekLectureOverviews(sortedArray).then((res) => {
            if (res && res.status == 200) {
              // fetchData();
            } else if (res && res.status == 401) {
              remove_session();
              props.getLoggedInUser();
              navigate("/login");
            } else {
              toast.error("Failed to sort the list.");
            }
            setTableDataLoading(false);
          });
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };

  const handleAddOverviewVideo = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const videoData = {
        title: title,
        url: videoUrl,
        fileSize: "",
        text: "",
        type: contentType,
        lecture: state?.lecture,
      };
            let type = "add";
      addProgramWeekLectureOverview(videoData).then((res) => {
        if (res && res.status == 201) {
          fetchData(type);
          toast.success("Video added successfully.");
          document.getElementById("programWeekLectureOverviewModalCloseButton").click();
          setContentType("");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to add video.");
        }
        setLoading(false);
      });
    }
  };
  const handleAddOverviewText = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (textValidate()) {
      setLoading(true);
      const overviewTextData = {
        title: "",
        url: "",
        text: overviewText,
        type: contentType,
        fileSize: "",
        lecture: state?.lecture,
      };
      let type = "add";
      addProgramWeekLectureOverview(overviewTextData).then((res) => {
        if (res && res.status == 201) {
          fetchData(type);
          toast.success("Text added successfully.");
          document.getElementById("programWeekLectureOverviewModalCloseButton").click();
          setContentType("");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to add text.");
        }
        setLoading(false);
      });
    }
  };
  const handleAddOverviewPDF = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (pdfValidate()) {
      setLoading(true);
      const overviewPDFData = {
        title: title ?? "",
        fileSize: fileSize ?? "",
        url: pdf,
        text: "",
        type: contentType,
        lecture: state?.lecture,
      };
      let type = "add";
      addProgramWeekLectureOverview(overviewPDFData).then((res) => {
        if (res && res.status == 201) {
          fetchData(type);
          toast.success("PDF added successfully.");
          document.getElementById("programWeekLectureOverviewModalCloseButton").click();
          setContentType("");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to add PDF.");
        }
        setLoading(false);
      });
    }
  };

  const handleAddOverviewAudio = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (audioValidate()) {
      setLoading(true);
      const overviewAudioData = {
        title: title ?? "",
        url: audio,
        text: "",
        type: contentType,
        fileSize: fileSize,
        lecture: state?.lecture,
      };
      let type = "add";
      addProgramWeekLectureOverview(overviewAudioData).then((res) => {
        if (res && res.status == 201) {
          fetchData(type);
          toast.success("Audio added successfully.");
          document.getElementById("programWeekLectureOverviewModalCloseButton").click();
          setContentType("");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to add audio.");
        }
        setLoading(false);
      });
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      videoUrl: "",
    };
    let isValid = true;
    // if (!title) {
    //   isValid = false;
    //   errors.title = "Please enter title.";
    // }
    // URL validation
    // const urlRegex = new RegExp(
    //   "(https?:\\/\\/www\\.|https?:\\/\\/|www\\.)?[a-zA-Z0-9]{2,}(\\.[a-zA-Z0-9]{2,})(\\.[a-zA-Z0-9]{2,})?\\/[a-zA-Z0-9]{2,}"
    // );
    // if (!videoUrl || !urlRegex.test(videoUrl)) {
    //   isValid = false;
    //   errors.videoUrl = "Please enter a valid URL.";
    // }
    if (!videoUrl) {
      isValid = false;
      errors.videoUrl = "Please enter URL.";
    }
    setErrors(errors);
    return isValid;
  };
  const textValidate = () => {
    let errors = {
      overviewText: "",
    };
    let isValid = true;
    if (!overviewText) {
      isValid = false;
      errors.overviewText = "Text field is required.";
    }
    setErrors(errors);
    return isValid;
  };
  const pdfValidate = () => {
    let errors = {
      pdf: "",
    };
    let isValid = true;
    if (!pdf) {
      isValid = false;
      errors.pdf = "PDF is required.";
    }
    setErrors(errors);
    return isValid;
  };

  const audioValidate = () => {
    let errors = {
      audio: "",
    };
    let isValid = true;
    if (!audio) {
      isValid = false;
      errors.audio = "Audio is required.";
    }
    setErrors(errors);
    return isValid;
  };

  const tabNameHandler = () => {
    setTabNameBtnLoading(true);
    updateProgramWeekLectureText({ overviewTabName: tabName, isOverview: true }, state?.lecture?._id).then((res) => {
      if (res && res.status == 200) {
        toast.success("Tab name updated successfully.");
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to update tab name.");
      }
      setTabNameBtnLoading(false);
    });
  };

  const setImageHandler = (pdf) => {
    if (showAddAudioModal) {
      setAudio(pdf);
    } else setPdf(pdf);
  };
  const SortableItem = SortableElement(({ item, index, serialNumber }) => (
    <div className="row justify-content-center py-2">
      {item?.type === "video" && (
        <VideoOverview
          type={"programWeekLecture"}
          key={index}
          index={serialNumber}
          item={item}
          fetchData={fetchData}
          getLoggedInUser={props.getLoggedInUser}
        />
      )}
      {item?.type === "text" && (
        <TextOverview
          type={"programWeekLecture"}
          key={index}
          index={serialNumber}
          item={item}
          fetchData={fetchData}
          getLoggedInUser={props.getLoggedInUser}
        />
      )}
      {item?.type === "pdf" && (
        <PDFOverview
          type={"programWeekLecture"}
          key={index}
          index={serialNumber}
          item={item}
          fetchData={fetchData}
          getLoggedInUser={props.getLoggedInUser}
        />
      )}
      {item?.type === "audio" && (
        <AudioOverview
          type={"programWeekLecture"}
          key={index}
          index={serialNumber}
          item={item}
          fetchData={fetchData}
          getLoggedInUser={props.getLoggedInUser}
        />
      )}
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => (
    <div>
      {items.map((item, index) => (
        <SortableItem key={`item-${index}`} index={index} serialNumber={index} item={item} />
      ))}
    </div>
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    // Reorder the items in weekDayOverviewList based on the drag and drop
    const reorderedList = arrayMove(lectureOverviewList, oldIndex, newIndex);

    // Update the state or perform any other necessary actions with the reorderedList

    let sortedArray = [];
    reorderedList?.map((overview) => sortedArray.push(overview?._id));
    sortProgramWeekLectureOverviews(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setLectureOverviewList(reorderedList);
        // fetchData();
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const modalHandler = () => {
    setContentType("");
    setTitle("");
    setVideoUrl("https://vimeo.com/");
    setOverviewText();
    setPdf("");
  };
  return (
    <>
      <div className="row justify-content-center">
        <div className="form-group col-md-6">
          <label>Tab Name</label>
          <input type="text" className="form-control" placeholder="Overview" value={tabName} onChange={(e) => setTabName(e.target.value)} />
          {!tabName && <div className="text-warning font-size-12 mt-2">This field is required.</div>}
          <button className="btn btn-primary float-right btn-sm mt-4" onClick={tabNameHandler}>
            {tabNameBtnLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Update
          </button>
        </div>
        <div className="col-12 my-4 pt-4" style={{ borderTop: "1px solid #f0f1f5" }}>
          {hasPermission("add-lecture-overview") && (
            <button className="btn btn-primary float-right" data-toggle="modal" data-target="#addProgramWeekLectureOverview" onClick={modalHandler}>
              Add Overview
            </button>
          )}
        </div>
        <div className="col-12">
          {tableDataLoading ? (
            <div className="text-center py-5">
              <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
            </div>
          ) : (
            <>
              {lectureOverviewList?.length > 0 ? (
                <SortableList items={lectureOverviewList} onSortEnd={onSortEnd} useDragHandle={true} />
              ) : (
                <h3 className="text-center">No Overview found !</h3>
              )}
            </>
          )}
        </div>
      </div>
      <div className="modal fade" id="addProgramWeekLectureOverview" data-backdrop="static">
        <div className="modal-dialog" role="document">
          <div className="modal-content pb-2">
            <div className="modal-header border-0 px-3 mb-3">
              <h5 className="modal-title">Add Content</h5>
              <button type="button" className="close" data-dismiss="modal" id="programWeekLectureOverviewModalCloseButton">
                <span>&times;</span>
              </button>
            </div>
            <div className="form-group px-3">
              <label>Select Content Type</label>
              <select
                className="form-control"
                value={contentType}
                onChange={(e) => {
                  setContentType(e.target.value);
                  setFileName("");
                  setFileSize("");
                  setPdf("");
                  setAudio("");
                }}
              >
                <option value="">Select content type</option>
                <option value="video">Video</option>
                <option value="text">Text</option>
                <option value="pdf">PDF</option>
                <option value="audio">Audio</option>
              </select>
            </div>
            {!contentType && <h4 className="text-center my-4">Please select content type first!</h4>}
            {contentType === "video" && (
              <form noValidate onSubmit={handleAddOverviewVideo}>
                <div className="form-group px-3">
                  <label>Video Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter video title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {/* {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>} */}
                </div>
                <div className="form-group px-3">
                  <label>Video URL</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter video URL"
                    value={videoUrl}
                    onChange={(e) => {
                      setVideoUrl(e.target.value);
                      validate();
                    }}
                  />
                  {errors.videoUrl && <div className="text-warning font-size-12 mt-2">{errors.videoUrl}</div>}
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Save
                  </button>
                </div>
              </form>
            )}
            {contentType === "text" && (
              <form noValidate onSubmit={handleAddOverviewText}>
                <div className="col-md-12">
                  <CKEditor
                    editor={ClassicEditor}
                    on
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setOverviewText(data);
                    }}
                    data={overviewText}
                  />
                </div>
                {errors.overviewText && !overviewText && <div className="text-warning font-size-12 mt-2 pl-3">{errors.overviewText}</div>}

                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Save
                  </button>
                </div>
              </form>
            )}
            {contentType === "pdf" && (
              <form noValidate onSubmit={handleAddOverviewPDF}>
                <div className="form-group px-3">
                  <label>Title</label>
                  <input type="text" className="form-control" placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)} />
                  {/* {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>} */}
                </div>
                <div className="form-group px-3 py-4 text-center">
                  <label>{fileName ? fileName : "Upload PDF"}</label>
                  <br />
                  {pdf && (
                    <>
                      {pdf?.substr(pdf?.length - 4) === ".pdf" ? (
                        <img src={PDFIcon} className="img-fluid img-thumbnail" width="100" />
                      ) : (
                        <img src={process.env.REACT_APP_MEDIAPATH + pdf} className="img-fluid img-thumbnail" width="150" />
                      )}
                      <br />
                    </>
                  )}
                  <button
                    onClick={() => {
                      setShowAddAudioModal(false);
                      setShowAddPDFModal(true);
                    }}
                    type="button"
                    className="btn btn-primary mt-3"
                    data-toggle="modal"
                    data-target="#media"
                  >
                    Select PDF
                  </button>
                  {errors.pdf && !pdf && <div className="text-warning font-size-12 mt-2 pl-2">{errors.pdf}</div>}
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Save
                  </button>
                </div>
              </form>
            )}
            {contentType === "audio" && (
              <form noValidate onSubmit={handleAddOverviewAudio}>
                <div className="form-group px-3">
                  <label>Title</label>
                  <input type="text" className="form-control" placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)} />
                  {/* {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>} */}
                </div>
                <div className="form-group px-3 py-4 text-center">
                  <label>Upload Audio</label>
                  <br />
                  {audio && (
                    <a href={process.env.REACT_APP_MEDIAPATH + audio} target="_blank">
                      <img src={MP3Icon} className="img-fluid img-thumbnail" width="100" />
                      <br />
                      <span>{fileName}</span>
                      <br />
                    </a>
                  )}
                  <button
                    type="button"
                    onClick={() => {
                      setShowAddPDFModal(false);
                      setShowAddAudioModal(true);
                    }}
                    className="btn btn-primary mt-3"
                    data-toggle="modal"
                    data-target="#media"
                  >
                    Select Audio
                  </button>
                  {errors.audio && !audio && <div className="text-warning font-size-12 mt-2 pl-2">{errors.audio}</div>}
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Save
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      {(showAddPDFModal || showAddAudioModal) && <Media setFileName={setFileName} setFileSize={setFileSize} setImageHandler={setImageHandler} />}
    </>
  );
}

export default LectureOverview;
