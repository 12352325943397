import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  addLecture,
  deleteLecture,
  deleteLecturePermanently,
  getLectureCategories,
  getLectures,
  getLecturesCount,
  getSubcategoryLectures,
  importVideos,
  loadLectureThumbnails,
  remove_session,
  restoreLecture,
  sortLectures,
  updateLecture,
} from "../../../functions/general";
import { toast } from "react-toastify";
import ModalImage from "react-modal-image";
import { usePermissions } from "../../../functions/usePermissions";

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";
import Media from "../../../components/Media";

function ListLectures(props) {
  const { hasPermission } = usePermissions();

  const [searchParams, setSearchParams] = useSearchParams();

  const { state } = useLocation();

  const filterId = searchParams.get("filterId");
  const filterName = searchParams.get("filterName");
  const subcategoryId = searchParams.get("subcategoryId");
  let searchFiltersFiltersArray = [];
  if (filterId && filterName) {
    searchFiltersFiltersArray.push({ value: filterId, label: filterName });
  }
  const navigate = useNavigate();
  const [lectures, setLectures] = React.useState([]);
  const [videos, setVideos] = React.useState([]);
  const [videosCount, setVideosCount] = React.useState([]);
  const [lecturesCount, setLecturesCount] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [subcategories, setSubategories] = React.useState([]);
  const [lectureCategoryId, setLectureCategoryId] = React.useState("");
  const [lectureSubcategoryId, setLectureSubcategoryId] = React.useState("");
  const [subcategoryOptions, setSubcategoryOptions] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [image, setImage] = React.useState("");
  const [description, setDescription] = React.useState("");

  const [vimeoUrl, setVimeoUrl] = React.useState("https://vimeo.com/");
  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [importLoading, setImportLoading] = React.useState(false);
  const [thumbnailLoading, setThumbnailLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [searchFiltersFrom, setSearchFiltersFrom] = React.useState(0);
  const [searchFiltersLimit, setSearchFiltersLimit] = React.useState(20);
  const [searchFiltersQuery, setSearchFiltersQuery] = React.useState("");
  const [searchFiltersFilters, setSearchFiltersFilters] = React.useState(searchFiltersFiltersArray);
  const [isPaid, setIsPaid] = React.useState(true);

  useEffect(() => {
    props.setPageTitleAndSubTitle(`Lectures (${state?.subcategory?.title})`, "Here is the list of Lectures!");
    fetchData();
    // fetchCategoriesData();
  }, []);

  // useEffect(() => {
  //   setTableDataLoading(true);
  //   fetchData();
  // }, [searchFiltersFrom]);

  // useEffect(() => {
  //   setTableDataLoading(true);
  //   if (searchFiltersFrom === 0) {
  //     fetchData();
  //   } else {
  //     setSearchFiltersFrom(0);
  //   }
  // }, [searchFiltersLimit]);
  // useEffect(() => {}, [lectureCategoryId]);
  const fetchData = (type) => {
    // const searchFiltersIds = [];
    // if (searchFiltersFilters.length) {
    //   searchFiltersFilters.map((filter) => {
    //     searchFiltersIds.push(filter.value);
    //   });
    // }
    // setVideos([]);
    // setLectures([]);
    // const searchFilters = {
    //   from: searchFiltersFrom * searchFiltersLimit,
    //   limit: searchFiltersLimit,
    //   filters: searchFiltersIds,
    //   search: searchFiltersQuery,
    //   subcategoryId: state?.subcategory?._id,
    // };
    getSubcategoryLectures(subcategoryId).then((res) => {
      if (res && (res.status === 200 || res.status === 201)) {
        setLectures(res?.data?.lectures);
        if (type === "add") {
          let sortedArray = [];
          res?.data?.map((video) => sortedArray.push(video?._id));
          sortLectures(sortedArray).then((res) => {
            if (res && res.status == 200) {
              // fetchData();
            } else if (res && res.status == 401) {
              remove_session();
              props.getLoggedInUser();
              navigate("/login");
            } else {
              toast.error("Failed to sort the list.");
            }
          });
        }
        setTableDataLoading(false);
      } else if (res && res.status === 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      }
      setTableDataLoading(false);
    });
    // getLecturesCount(searchFilters).then((res) => {
    //   if (res && res.status === 200) {
    //     setVideosCount(res.data);
    //     setLecturesCount(res.data);
    //   } else if (res && res.status === 401) {
    //     remove_session();
    //     props.getLoggedInUser();
    //     navigate("/login");
    //   }
    // });
  };

  // const fetchCategoriesData = () => {
  //   let tempCategories = [];
  //   let tempCategoriesOptions = [];
  //   let tempSubcategories = [];
  //   let tempSubcategoriesOptions = [];
  //   getLectureCategories().then((res) => {
  //     if (res && res.status === 200) {
  //       if (res.data && res.data.length) {
  //         res.data.map((categoryValue) => {
  //           if (categoryValue.isDeleted === false) {
  //             tempCategories.push(categoryValue);
  //             tempCategoriesOptions.push({ value: categoryValue._id, label: categoryValue.title });
  //             categoryValue.length > 0 &&
  //               categoryValue.map((subcategoryValue) => {
  //                 if (subcategoryValue.isDeleted === false) {
  //                   tempSubcategories.push(subcategoryValue);
  //                   tempSubcategoriesOptions.push({ value: subcategoryValue._id, label: subcategoryValue.title });
  //                 }
  //               });
  //           }
  //         });
  //       }

  //       setCategoryOptions(tempCategoriesOptions);
  //       setCategories(tempCategories);
  //       setSubcategoryOptions(tempSubcategoriesOptions);
  //       setSubategories(tempSubcategories);
  //     } else if (res && res.status === 401) {
  //       remove_session();
  //       props.getLoggedInUser();
  //       navigate("/login");
  //     } else {
  //     }
  //   });
  // };

  const modalHandler = (lecture) => {
    if (lecture && lecture?._id) {
      setId(lecture._id);
      setModalTitle("Update Lecture");
      setModalButtonText("Update");
      setIsPaid(lecture?.isPaid);
      setTitle(lecture?.title);
      setImage(lecture?.image);
      setDescription(lecture?.text);
      setVimeoUrl(lecture.vimeo_url);
      setLectureCategoryId(lecture.category._id);
      setSubcategoryHandler(lecture.category._id);
      setLectureSubcategoryId(lecture.subcategory._id);
    } else {
      setModalTitle("Add New Lecture");
      setModalButtonText("Add");
      setTitle("");
      setImage("");
      setDescription("");
      setVimeoUrl("https://vimeo.com/");
      setLectureCategoryId("");
      setIsPaid(true);
      setId("");
    }
  };
  const setSubcategoryHandler = (categoryId) => {
    setLectureCategoryId(categoryId);
    if (categoryId) {
      const category = categories.filter((categoryValue) => categoryValue._id === categoryId);
      if (category.length > 0) {
        setSubategories(category[0].subcategories);
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const lectureData = {
        title: title,
        image,
        text: description,
        category: state?.category,
        subcategory: state?.subcategory,
        isPaid: isPaid,
      };
      let type = "add";
      if (id) {
        updateLecture(id, lectureData).then((res) => {
          if (res && res.status === 200) {
            fetchData();
            toast.success("Lecture updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status === 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update lecture.");
          }
          setLoading(false);
        });
      } else {
        addLecture(lectureData).then((res) => {
          if (res && res.status === 201) {
            fetchData(type);
            toast.success("Lecture added successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status === 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add lecture.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      image: "",
    };
    let isValid = true;
    if (!title) {
      isValid = false;
      errors.title = "Please enter title.";
    }

    // // URL validation
    // const urlRegex = new RegExp(
    //   "(https?:\\/\\/www\\.|https?:\\/\\/|www\\.)?[a-zA-Z0-9]{2,}(\\.[a-zA-Z0-9]{2,})(\\.[a-zA-Z0-9]{2,})?\\/[a-zA-Z0-9]{2,}"
    // );
    // if (!image || !urlRegex.test(image)) {
    //   isValid = false;
    //   errors.image = "Please enter a valid URL.";
    // }

    if (!image) {
      isValid = false;
      errors.image = "Please select an image.";
    }

    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, video) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete lecture?") === true) {
      if (window.confirm("Are you sure you want to delete lecture permanently?") === true) {
        deleteLecturePermanently(video._id).then((res) => {
          if (res && res.status === 200) {
            fetchData();
            toast.success("Lecture deleted successfully.");
          } else if (res && res.status === 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete video.");
          }
        });
      }
    }
  };

  const deleteStatusHandler = (e, video) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the lecture?";
    if (video.isDeleted) {
      confirmText = "Are you sure you want to active the lecture?";
    }
    if (window.confirm(confirmText) == true) {
      if (video.isDeleted) {
        restoreLecture(video._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Lecture activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active video.");
          }
        });
      } else {
        deleteLecture(video._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Lecture deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive video.");
          }
        });
      }
    }
  };

  const paymentStatusHandler = (e, lecture) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to change payment status of lecture?";
    if (window.confirm(confirmText) == true) {
      const lectureData = {
        title: lecture?.title,
        image: lecture?.image,
        text: lecture?.description,
        category: state?.category,
        subcategory: state?.subcategory,
        isPaid: e.target.checked,
      };
      updateLecture(lecture?._id, lectureData).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("Payment status changed successfully.");
          //   loadThumbnailsHandler();
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to change the payment status of lecture.");
        }
      });
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    const updatedLectures = arrayMove(lectures, oldIndex, newIndex);
    let sortedArray = [];
    updatedLectures?.map((video) => sortedArray.push(video?._id));
    sortLectures(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setLectures(updatedLectures);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ videoValue, videoIndex }) => {
    return (
      <tr key={`video-${videoIndex}`}>
        <td>{searchFiltersFrom * searchFiltersLimit + videoIndex + 1}</td>
        <td>
          <div className="maxWidth70px">
            <ModalImage small={process.env.REACT_APP_MEDIAPATH + videoValue.image} large={process.env.REACT_APP_MEDIAPATH + videoValue.image} />
          </div>
        </td>
        <td>
          {hasPermission("view-lecture-overview") || hasPermission("view-lecture-warmups") || hasPermission("view-lecture-exercises") ? (
            <Link
              style={{ color: "blue" }}
              to="/lecture-details"
              state={{ lecture: videoValue, category: state?.category, subCategory: state?.subcategory?.title }}
            >
              {videoValue?.title}
            </Link>
          ) : (
            videoValue?.title
          )}
        </td>
        {/* <td>{videoValue.vimeo_url}</td> */}
        {hasPermission("edit-lecture-video") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={videoValue?.isPaid} onClick={(e) => paymentStatusHandler(e, videoValue)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {hasPermission("edit-lecture-video") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={!videoValue.isDeleted} onClick={(e) => deleteStatusHandler(e, videoValue)} />
              {/* <input type="checkbox" checked={!videoValue.isDeleted} /> */}
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {(hasPermission("edit-lecture-video") || hasPermission("delete-lecture-video")) && (
          <td>
            <div className="d-flex">
              {hasPermission("edit-lecture-video") && (
                <button
                  data-toggle="modal"
                  data-target="#add"
                  onClick={() => modalHandler(videoValue)}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {hasPermission("delete-lecture-video") && (
                <button onClick={(e) => deleteHandler(e, videoValue)} className="btn btn-danger shadow btn-sm sharp">
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </td>
        )}
        {hasPermission("edit-day-warmup") && (
          <td>
            <DragHandle />
          </td>
        )}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ lectures }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Image</th>
            <th>Title</th>
            {/* <th>Vimeo Url</th> */}
            {hasPermission("edit-lecture-video") && <th>Paid</th>}
            {hasPermission("edit-lecture-video") && <th>Status</th>}
            {(hasPermission("edit-lecture-video") || hasPermission("delete-lecture-video")) && <th>Actions</th>}
            {hasPermission("edit-lecture-video") && <th>Sort Order</th>}
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="8" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {lectures?.length > 0 ? (
                <>
                  {lectures?.map((videoValue, videoIndex) => {
                    return <SortableTableRow key={videoIndex} videoValue={videoValue} index={videoIndex} videoIndex={videoIndex} />;
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan="8" className="text-center pt-5">
                    No Lecture found!
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  const setImageHandler = (image) => {
    setImage(image);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-8">
            <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li class="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li class="breadcrumb-item">
                <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate(-2)}>
                  Lecture Categories
                </span>
              </li>
              <li class="breadcrumb-item">
                <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate(-1)}>
                  {state?.category?.title}
                </span>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {state?.subcategory?.title}
              </li>
            </ol>
          </div>
          <div className="col-4">
            {/* <button
              className="btn btn-primary float-right"
              onClick={() => {
                if (!importLoading) {
                  document.getElementById("importVideos").click();
                }
              }}
            >
              {importLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
              Import
            </button> */}
            {/* <input
              type="file"
              className="d-none"
              id="importVideos"
              onChange={(e) => videoUploadHandler(e)}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            /> */}
            {/* <button className="btn btn-primary float-right mr-3" onClick={() => loadThumbnailsHandler()}>
              {thumbnailLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
              Load Thumbnails
            </button> */}
            {hasPermission("add-lecture-video") && (
              <button className="btn btn-primary float-right mr-3" data-toggle="modal" data-target="#add" onClick={() => modalHandler()}>
                Add New
              </button>
            )}
          </div>
        </div>
        {/* <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            if (searchFiltersFrom === 0) {
              fetchData();
            } else {
              setSearchFiltersFrom(0);
            }
          }}
        >
          <div className="row">
            <div className="col-5">
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Filter Title"
                  value={searchFiltersQuery}
                  onChange={(e) => setSearchFiltersQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="col-5"></div>
            <div className="col-2">
              <div className="form-group">
                <p className="m-0">&nbsp;</p>
                <button
                  className="btn btn-primary float-right"
                  onClick={() => {
                    if (searchFiltersFrom === 0) {
                      fetchData();
                    } else {
                      setSearchFiltersFrom(0);
                    }
                  }}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label>Show Entries</label>
                <select className="form-control" value={searchFiltersLimit} onChange={(e) => setSearchFiltersLimit(parseInt(e.target.value))}>
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="70">70</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                  <option value="500">500</option>
                </select>
              </div>
            </div>
            <div className="col-10"></div>
          </div>
        </form> */}
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <SortableTable lectures={lectures} onSortEnd={onSortEnd} useDragHandle={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-6">
            {(lecturesCount && lectures.length) > 0 && (
              <p className="text-black">
                Showing {searchFiltersFrom * searchFiltersLimit + 1} to {searchFiltersFrom * searchFiltersLimit + searchFiltersLimit} of {videosCount}{" "}
                entries
              </p>
            )}
          </div>
          <div className="col-6">
            {(lecturesCount && lectures.length) > 0 && (
              <nav ariaLabel="Page navigation example">
                <ul className="pagination float-right">
                  <li className="page-item">
                    {searchFiltersFrom > 0 ? (
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom - 1)} ariaLabel="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                      </span>
                    ) : (
                      <span className="page-link c-default" ariaLabel="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                      </span>
                    )}
                  </li>
                  {searchFiltersFrom - 2 > 0 && (
                    <li className="page-item">
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom - 3)}>
                        {searchFiltersFrom - 2}
                      </span>
                    </li>
                  )}
                  {searchFiltersFrom - 1 > 0 && (
                    <li className="page-item">
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom - 2)}>
                        {searchFiltersFrom - 1}
                      </span>
                    </li>
                  )}
                  {searchFiltersFrom > 0 && (
                    <li className="page-item">
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom - 1)}>
                        {searchFiltersFrom}
                      </span>
                    </li>
                  )}
                  <li className="page-item">
                    <span className="page-link bg-gray-dark text-white">{searchFiltersFrom + 1}</span>
                  </li>
                  {searchFiltersFrom * searchFiltersLimit + searchFiltersLimit < videosCount && (
                    <li className="page-item">
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom + 1)}>
                        {searchFiltersFrom + 2}
                      </span>
                    </li>
                  )}
                  {(searchFiltersFrom + 1) * searchFiltersLimit + searchFiltersLimit < videosCount && (
                    <li className="page-item">
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom + 2)}>
                        {searchFiltersFrom + 3}
                      </span>
                    </li>
                  )}
                  {(searchFiltersFrom + 2) * searchFiltersLimit + searchFiltersLimit < videosCount && (
                    <li className="page-item">
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom + 3)}>
                        {searchFiltersFrom + 4}
                      </span>
                    </li>
                  )}
                  <li className="page-item">
                    {searchFiltersFrom * searchFiltersLimit + searchFiltersLimit < videosCount ? (
                      <span className="page-link c-pointer" onClick={() => setSearchFiltersFrom(searchFiltersFrom + 1)} ariaLabel="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                      </span>
                    ) : (
                      <span className="page-link c-default" ariaLabel="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                      </span>
                    )}
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div> */}
        <div className="modal fade" id="add">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="form-group px-3">
                  <label>Title</label>
                  <input type="text" className="form-control" placeholder="Lecture Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                  <div className="text-warning font-size-12">{errors.title}</div>
                </div>
                <div className="form-group px-3">
                  <label>Description</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter Description"
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  {/* {errors.description && !description && <div className="text-warning font-size-12">{errors.description}</div>} */}
                </div>
                <div className="form-group px-3">
                  <label>Image</label>
                  <br />
                  {image && <img src={process.env.REACT_APP_MEDIAPATH + image} className="img-fluid img-thumbnail" width="150" />}
                  <br />
                  <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#media">
                    Select Image
                  </button>
                  <div className="text-warning font-size-12">{errors.image}</div>
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Media setImageHandler={setImageHandler} />
      </div>
    </>
  );
}

export default ListLectures;
