import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/css/style.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <App />
    <Helmet>
      {/*<script src="/assets/vendor/bootstrap-select/dist/js/bootstrap-select.min.js"></script>*/}
      {/* <script src="/assets/vendor/chart.js/Chart.bundle.min.js"></script> */}
      <script src="/assets/js/custom.min.js"></script>
      <script src="/assets/vendor/waypoints/jquery.waypoints.min.js"></script>
      <script src="/assets/vendor/jquery.counterup/jquery.counterup.min.js"></script>
      {/* <script src="/assets/vendor/apexchart/apexchart.js"></script> */}
      {/* <script src="/assets/js/dashboard/dashboard-1.js"></script> */}
      <script src="/assets/vendor/datatables/js/jquery.dataTables.min.js"></script>
    </Helmet>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
