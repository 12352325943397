import React from "react";
import dummyProfileImage from '../images/dummy_profile_image.jpg'
import {remove_session} from "../functions/general";
import {Link, useNavigate} from "react-router-dom";

function TopNavigation(props) {
    const navigate = useNavigate();
    const currentUser = props.currentUser;
    const title = props.title;
    const subTitle = props.subTitle;
    const signOut = () => {
        remove_session();
        props.getLoggedInUser();
        navigate('/login');
    }
    return (
        <div className="header">
            <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">
                        <div className="header-left">
                            <div className="d-none d-lg-block">
                                <h2 className="text-black font-w600 mb-0">{title}</h2>
                                <p className="mb-0">{subTitle}</p>
                            </div>
                        </div>
                        <ul className="navbar-nav header-right">
                            <li className="nav-item dropdown header-profile">
                                <a className="nav-link" href="#" role="button" data-toggle="dropdown">
                                    <div className="header-info">
                                        <span>Hello, <strong>{currentUser.firstName}</strong></span>
                                    </div>
                                    <img src={dummyProfileImage} width="20" alt=""/>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <a href="#" className="dropdown-item ai-icon">
                                        <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary"
                                             width="18" height="18" viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                             strokeLinejoin="round">
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                        <span className="ml-2">Profile </span>
                                    </a>
                                    <a onClick={() => signOut()}  className="dropdown-item ai-icon">
                                        <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" className="text-danger"
                                             width="18" height="18" viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                             strokeLinejoin="round">
                                            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                            <polyline points="16 17 21 12 16 7"></polyline>
                                            <line x1="21" y1="12" x2="9" y2="12"></line>
                                        </svg>
                                        <span className="ml-2">Logout </span>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default TopNavigation;
