import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  remove_session,
  getAllPermissions,
  getAllPermissionTypes,
  updatePermission,
  addPermission,
  deletePermissionPermanently,
  restorePermission,
  deletePermission,
} from "../../../functions/general";
import { toast } from "react-toastify";

const PermissionsCrud = (props) => {
  const navigate = useNavigate();
  const [permissionsList, setPermissions] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState("");
  const [permissionTypesList, setPermissionTypesList] = React.useState([]);
  const [permissionTypeId, setPermissionTypeId] = React.useState("");
  const [permissionType, setPermissionType] = React.useState({});

  useEffect(() => {
    props.setPageTitleAndSubTitle("Permissions List", "Here is the list of all Permissions!");
    fetchData();
    getAllPermissionTypesList();
  }, []);

  const fetchData = () => {
    getAllPermissions().then((res) => {
      if (res && res.status == 200) {
        if (res.data && res.data.length) {
          setPermissions(
            res?.data?.map((item, index) => {
              return { serialNumber: index + 1, item };
            })
          );
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };

  const getAllPermissionTypesList = () => {
    getAllPermissionTypes().then((res) => {
      if (res && res.status == 200) {
        if (res.data && res.data.length) {
          setPermissionTypesList(res?.data);
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };

  const modalHandler = (permission) => {
    if (permission && permission?._id) {
      setModalTitle("Update permission");
      setModalButtonText("Update");
      setTitle(permission?.name);
      setPermissionTypeId(permission?.type?._id);
      getPermissionType(permission?.type?._id);
      setId(permission._id);
    } else {
      setModalTitle("Add New permission");
      setModalButtonText("Add");
      setTitle("");
      setPermissionTypeId("");
      setPermissionType({});
      setId("");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const permissionData = {
        name: title,
        type: permissionType,
      };
      if (id) {
        updatePermission(id, permissionData).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Permission updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update permission.");
          }
          setLoading(false);
        });
      } else {
        addPermission(permissionData).then((res) => {
          if (res && res.status == 201) {
            fetchData();
            toast.success("Permission added successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add permission.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      type: "",
    };
    let isValid = true;
    if (!title) {
      isValid = false;
      errors.title = "Please enter permission name";
    }
    if (!permissionTypeId) {
      isValid = false;
      errors.type = "Please select a permission type";
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, permission) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete permission?") == true) {
      deletePermissionPermanently(permission?._id).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("Permission deleted successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete permission.");
        }
      });
    }
  };

  const deleteStatusHandler = (e, permission) => {
    e.preventDefault();
    console.log(permission, "permission");
    let confirmText = "Are you sure you want to in-active the permission?";
    if (permission?.isDeleted) {
      confirmText = "Are you sure you want to active the permission?";
    }
    if (window.confirm(confirmText) == true) {
      if (permission?.isDeleted) {
        restorePermission(permission._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Permission activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active permission.");
          }
        });
      } else {
        deletePermission(permission?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Permission deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive permission.");
          }
        });
      }
    }
  };

  const getPermissionType = (id) => {
    const permissionType = permissionTypesList?.filter((item) => item._id === id);
    setPermissionType(...permissionType);
  };

  const columns = [
    {
      name: "No",
      selector: (row) => row?.serialNumber,
    },
    {
      name: "Name",
      selector: (row) => row?.item?.name,
    },
    {
      name: "Permission Type",
      selector: (row) => row?.item?.type?.name,
    },
    {
      name: "Status",
      cell: (permission) => (
        <label className="switch">
          <input type="checkbox" checked={!permission?.item?.isDeleted} onClick={(e) => deleteStatusHandler(e, permission?.item)} />
          <span className="slider round"></span>
        </label>
      ),
    },
    {
      name: "Actions",
      cell: (permission) => (
        <div className="d-flex">
          <button
            data-toggle="modal"
            data-target="#add"
            onClick={(e) => modalHandler(permission?.item)}
            className="btn btn-primary shadow btn-sm sharp mr-1"
          >
            <i className="fa fa-pencil"></i>
          </button>
          <button onClick={(e) => deleteHandler(e, permission?.item)} className="btn btn-danger shadow btn-sm sharp">
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "70px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize:"18px",
        fontWeight:"bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize:"16px",
      },
    },
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4 align-items-center">
          <div className="col-8">
            <ol className="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li className="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li className="breadcrumb-item">Permissions Listing</li>
            </ol>
          </div>
          <div className="col-4">
            <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={() => modalHandler()}>
              Add New
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <DataTable
                    className="custom-data-table"
                    columns={columns}
                    data={permissionsList && permissionsList}
                    pagination
                    customStyles={customStyles}
                  />
                  {/* <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Permission Type</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {permissionsList?.length > 0 ? (
                        permissionsList?.map((permission, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{permission?.name}</td>
                              <td>{permission?.type?.name}</td>
                              <td>
                                <label className="switch">
                                  <input type="checkbox" checked={!permission?.isDeleted} onClick={(e) => deleteStatusHandler(e, permission)} />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                              <td>
                                <div className="d-flex">
                                  <button
                                    data-toggle="modal"
                                    data-target="#add"
                                    onClick={(e) => modalHandler(permission)}
                                    className="btn btn-primary shadow btn-sm sharp mr-1"
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </button>
                                  <button onClick={(e) => deleteHandler(e, permission)} className="btn btn-danger shadow btn-sm sharp">
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr><td>No permission found.</td></tr>
                      )}
                    </tbody>
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="add">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="form-group px-3">
                  <label>Permission Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Permission Name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {errors.title && !title && <div className="text-warning font-size-12">{errors.title}</div>}
                </div>
                {permissionTypesList?.length > 0 && (
                  <div className="form-group px-3">
                    <label>Permission Type</label>
                    <select
                      className="form-control"
                      value={permissionTypeId}
                      onChange={(e) => {
                        setPermissionTypeId(e.target.value);
                        getPermissionType(e.target.value);
                      }}
                    >
                      <option value="">Select Type</option>
                      {permissionTypesList?.length > 0 &&
                        permissionTypesList?.map((permissionType, index) => (
                          <option key={index} value={permissionType?._id}>
                            {permissionType?.name}
                          </option>
                        ))}
                    </select>
                    {errors.type && !permissionTypeId && <div className="text-warning font-size-12">{errors.type}</div>}
                  </div>
                )}
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PermissionsCrud;
