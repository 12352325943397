import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  remove_session,
  getCurrencySymbol,
  getAllProductsList,
  getAllCustomersList,
  createCoupon,
  deleteCouponPermanently,
} from "../../../../functions/general";
import { getAllCouponsList } from "../../../../functions/general";
import Select from "react-select";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { usePermissions } from "../../../../functions/usePermissions";

const CouponsList = (props) => {
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();

  const [couponsList, setCouponsList] = React.useState([]);
  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [title, setTitle] = React.useState("");
  const [type, setType] = React.useState("percentage");
  const [percentage, setPercentage] = React.useState("");
  const [discountAmount, setDiscountAmount] = React.useState("");
  const [specificeProductCheck, setSpecificeProductCheck] = React.useState(false);
  const [productsList, setProdctsList] = React.useState([]);
  const [selecteProductsList, setSelecteProductsList] = React.useState([]);
  const [duration, setDuration] = React.useState("forever");
  const [monthsCount, setMonthsCount] = React.useState("");
  const [couponRedemptionsLimit, setCouponRedemptionsLimit] = React.useState(1);
  const [dateRangeLimitCheckbox, setDateRangeLimitCheckbox] = React.useState(false);
  const [numberOfTimesCheckbox, setNumberOfTimesCheckbox] = React.useState(false);
  const [showCodesToggle, setShowCodesToggle] = React.useState(false);
  const [couponExpiryDate, setCouponExpiryDate] = React.useState("");
  const [codeName, setCodeName] = React.useState("");
  const [firstTimeOrderCheckbox, setFirstTimeOrderCheckbox] = React.useState(false);
  const [specficCustomerCheckbox, setSpecficCustomerCheckbox] = React.useState(false);
  const [redeemLimitCheckbox, setRedeemLimitCheckbox] = React.useState(false);
  const [redemtionLimitCount, setRedemtionLimitCount] = React.useState("");
  const [expiryDateCheckbox, setExpiryDateCheckbox] = React.useState(false);
  const [codeExpiryDate, setCodeExpiryDate] = React.useState("");
  const [minimumOrderValueCheckbox, setMinimumOrderValueCheckbox] = React.useState(false);
  const [codeMinimumOrderValue, setCodeMinimumOrderValue] = React.useState("");
  const [customersList, setCustomersList] = React.useState([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [oneTimeUseOnly, setOneTimeUseOnly] = React.useState(null);
  const [errors, setErrors] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchTimeout, setSearchTimeout] = React.useState(null);
  const [customersListLoading, setCustomersListLoading] = React.useState(false);

  const [searchNameQuery, setSearchNameQuery] = React.useState("");

  const [filteredDataArray, setFilteredDataArray] = React.useState([]);

  useEffect(() => {
    props.setPageTitleAndSubTitle("Coupons List", "Here is the list of all Coupons!");
    fetchData();
  }, []);

  const fetchData = () => {
    getAllCouponsList().then((res) => {
      if (res && res.status == 200) {
        if (res.data) {
          setCouponsList(res?.data?.data);
          setTableDataLoading(false);
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });

    //Products list
    getAllProductsList().then((res) => {
      if ((res && res.status == 200) || res.status == 201) {
        const products = res?.data?.map((product) => {
          return { value: product?.id, label: product?.name };
        });
        setProdctsList(products);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });

    getAllCustomersList(searchQuery).then((res) => {
      if ((res && res.status === 200) || res.status === 201) {
        const customers = res?.data?.map((customer) => {
          return {
            value: customer?.id,
            label: `${customer?.name} (${customer?.email})` ?? customer?.email,
          };
        });
        setCustomersList(customers);
      } else if (res && res.status === 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        // Handle other cases as needed
      }
    });
  };

  const handleInputChange = (inputValue) => {
    setCustomersListLoading(true);

    setSearchQuery(inputValue);

    // Clear any existing timeout
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set a new timeout to trigger the API call after a delay (e.g., 500ms)
    const newTimeout = setTimeout(() => {
      // Perform the delayed API call with the searchQuery
      getAllCustomersList(inputValue).then((res) => {
        if ((res && res.status === 200) || res.status === 201) {
          const customers = res?.data?.map((customer) => {
            return {
              value: customer?.id,
              label: `${customer?.name} (${customer?.email})` ?? customer?.email,
            };
          });
          setCustomersList(customers);
        } else if (res && res.status === 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          // Handle other cases as needed
        }
        setCustomersListLoading(false);
      });
    }, 500); // Adjust the delay time as needed

    setSearchTimeout(newTimeout);
  };
  useEffect(() => {
    setFilteredDataArray(couponsList); // Set filteredDataArray whenever couponsList changes
  }, [couponsList]);

  const modalHandler = () => {
    setTitle("");
    setPercentage("");
    setDiscountAmount("");
    setSpecificeProductCheck(false);
    setSelecteProductsList([]);
    setDuration("forever");
    setDateRangeLimitCheckbox(false);
    setCouponExpiryDate("");
    setNumberOfTimesCheckbox(false);
    setCouponRedemptionsLimit(1);
    setShowCodesToggle(false);
    setCodeName("");
    setFirstTimeOrderCheckbox(false);
    setSpecficCustomerCheckbox(false);
    setSelectedCustomer(null);
    setRedeemLimitCheckbox(false);
    setRedemtionLimitCount("");
    setExpiryDateCheckbox(false);
    setCodeExpiryDate("");
    setMinimumOrderValueCheckbox(false);
    setCodeMinimumOrderValue("");
  };

  // Date time conversions to milliseconds

  const couponTimestampInSeconds = Math.floor(new Date(couponExpiryDate).getTime() / 1000);
  const couponExpiryUnixTimestamp = couponTimestampInSeconds.toString();

  const codeTimestampInSeconds = Math.floor(new Date(codeExpiryDate).getTime() / 1000);
  const codeExpiryUnixTimestamp = codeTimestampInSeconds.toString();

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;

    if (validate()) {
      setLoading(true);

      const couponData = {
        name: title,
        ...(type === "percentage" && { percent_off: +percentage }),
        // percent_off && percent_off: type === "percentage" ? +percentage : "",
        duration: duration === "multipleMonths" ? "repeating" : duration,
        // duration_in_months: +monthsCount ? +monthsCount : null,
        ...(monthsCount && duration === "multipleMonths" && { duration_in_months: +monthsCount }),
        ...(dateRangeLimitCheckbox && couponExpiryDate && { redeem_by: couponExpiryUnixTimestamp }),
        ...(numberOfTimesCheckbox && { max_redemptions: couponRedemptionsLimit ? +couponRedemptionsLimit : 1 }),

        //metadata

        metadata: {
          ...(oneTimeUseOnly && { usage_limit_per_customer: 1 }),
        },

        applies_to: {
          products: selecteProductsList,
        },
        currency: "usd",
        ...(type === "fixedAmount" && { amount_off: discountAmount ? +discountAmount * 100 : "" }),
      };
      const promotionCodes = [
        {
          code: codeName,
          ...(expiryDateCheckbox && codeExpiryDate && { expires_at: codeExpiryUnixTimestamp }),
          ...(redeemLimitCheckbox && redemtionLimitCount && { max_redemptions: redemtionLimitCount }),
          ...((firstTimeOrderCheckbox || codeMinimumOrderValue) && {
            restrictions: {
              ...(firstTimeOrderCheckbox && { first_time_transaction: firstTimeOrderCheckbox }),
              ...(codeMinimumOrderValue && { minimum_amount_currency: "usd" }),
              ...(codeMinimumOrderValue && { minimum_amount: codeMinimumOrderValue }),
            },
          }),
          ...(specficCustomerCheckbox && selectedCustomer && { customer: selectedCustomer?.value }),
        },
      ];

      createCoupon({ couponData, ...(codeName && { promotionCodes }) }).then((res) => {
        if (res && res.status == 201) {
          fetchData();
          toast.success("Coupon created successfully.");
          document.getElementById("createCouponModalId").click();
          setLoading(false);
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error(res?.data?.message);
        }
        setLoading(false);
      });
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      codeTitle: "",
      percentage: "",
      fixedAmount: "",
    };
    let isValid = true;
    if (!title) {
      isValid = false;
      errors.title = "Please enter title.";
    }
    if (showCodesToggle) {
      if (!codeName) {
        isValid = false;
        errors.codeTitle = "Please enter code.";
      }
    }
    if (type === "percentage") {
      if (!percentage) {
        isValid = false;
        errors.percentage = "Please enter percentage.";
      }
    }
    if (type === "fixedAmount") {
      if (!discountAmount) {
        isValid = false;
        errors.fixedAmount = "Please enter discount amount.";
      }
    }

    setErrors(errors);
    return isValid;
  };

  const columns = [
    {
      name: "Coupon Name",
      selector: (row) => (
        <Link to="/coupon-promotions-list" style={{ color: "blue" }} state={{ coupon: row }}>
          {row?.name}
        </Link>
      ),
      wrap: true,
    },
    // {
    //   name: "Coupon Code",
    //   selector: (row) => row?.promotionCodes && row?.promotionCodes.map((code) => code?.code + ", "),
    // },
    {
      name: "Discount",
      selector: (row) =>
        row?.percent_off ? row?.percent_off + "%" : getCurrencySymbol(row?.currency) + (row?.amount_off && Number(row?.amount_off / 100)),
      wrap: true,
    },
    // {
    //   name: "Subscription Plan",
    //   selector: (row) => row?.id,
    //   wrap: true,
    // },
    {
      name: "Usage Count",
      selector: (row) => row?.times_redeemed,
      wrap: true,
    },
    // {
    //   name: "One Time Use Only",
    //   selector: (row) =>
    //     row?.metadata?.usage_limit_per_customer ? <span className="text-success">Yes</span> : <span className="text-danger">No</span>,
    //   wrap: true,
    // },
    {
      name: "Duration",
      selector: (row) => (row?.duration_in_months ? row?.duration_in_months + " Months" : row?.duration),
      wrap: true,
      style: {
        textTransform: "capitalize",
      },
    },
    {
      name: "Created on",
      selector: (row) => dayjs(row?.created * 1000).format("DD-MMM-YYYY HH:mm:ss"),
    },
    {
      name: "Expires on",
      selector: (row) => (row?.redeem_by ? dayjs(row?.redeem_by * 1000).format("DD-MMM-YYYY HH:mm:ss") : "--"),
    },
    {
      name: "Actions",
      selector: (row) =>
        hasPermission("delete-coupon") && (
          <button onClick={(e) => deleteHandler(e, row)} className="btn btn-danger shadow btn-sm sharp">
            <i className="fa fa-trash"></i>
          </button>
        ),
    },
  ];

  const deleteHandler = (e, coupon) => {
    setTableDataLoading(true);
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete coupon permanently?") == true) {
      deleteCouponPermanently(coupon?.id).then((res) => {
        if (res.status == 200 || res.status == 201) {
          setTimeout(() => {
            setTableDataLoading(true);
            fetchData();
            toast.success("Coupon deleted successfully.");
          }, 1000);
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete coupon.");
        }
        setTableDataLoading(false);
      });
    }
    setTableDataLoading(false);
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "70px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        fontSize: "18px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "16px",
      },
    },
  };

  const handlePageChange = () => {
    setTableDataLoading(true);
    setTimeout(() => {
      setTableDataLoading(false);
    }, 500);
  };

  const handlResetFilters = () => {
    setSearchNameQuery("");
    fetchData();
  };

  const handleFilterButtonClick = () => {
    setTableDataLoading(true);

    // Trim the search query values before applying the filters
    const trimmedSearchNameQuery = searchNameQuery ? searchNameQuery.trim() : "";

    const filteredArray = couponsList?.filter((data) => {
      // Filter by firstName (case-insensitive search) if searchQuery exists and is not an empty string
      const couponNameMatch = trimmedSearchNameQuery && data?.name.toLowerCase().includes(trimmedSearchNameQuery.toLowerCase());

      // Return true if any filter is not applied (i.e., no filtering condition is specified) or all filters match
      return !trimmedSearchNameQuery || couponNameMatch;
    });

    // Simulate a delay to show loading state
    setTimeout(() => {
      setFilteredDataArray(filteredArray);
      setTableDataLoading(false); // Set loading state back to false after filtering
    }, 200); // Adjust the delay time as needed
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4 align-items-center">
          <div className="col-8">
            <ol className="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li className="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li className="breadcrumb-item">Coupons Listing</li>
            </ol>
          </div>
          <div className="col-4">
            {hasPermission("create-coupon") && (
              <button className="btn btn-primary float-right" data-toggle="modal" data-target="#createCouponModal" onClick={() => modalHandler()}>
                Create new Coupon
              </button>
            )}
          </div>
        </div>
        <div className="row align-align-items-center mb-4">
          <div className="col-3">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Search Name"
                value={searchNameQuery}
                onChange={(e) => setSearchNameQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <p className="m-0">&nbsp;</p>
              <button className="btn btn-primary float-right btn-md ml-2" onClick={handleFilterButtonClick}>
                Apply
              </button>
              <button className="btn btn-primary float-right btn-md" onClick={handlResetFilters}>
                Reset
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <DataTable
                    className="custom-data-table"
                    columns={columns}
                    data={filteredDataArray && filteredDataArray}
                    pagination
                    customStyles={customStyles}
                    progressPending={tableDataLoading}
                    persistTableHead
                    onChangePage={handlePageChange}
                    progressComponent={<div className="spinner-border text-black mt-5 mb-3" role="status" aria-hidden="true"></div>}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="createCouponModal" data-backdrop="static">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header border-0 px-3 mb-3">
              <h5 className="modal-title">Create new Coupon</h5>
              <button type="button" className="close" data-dismiss="modal" id="createCouponModalId">
                <span>&times;</span>
              </button>
            </div>
            <form noValidate onSubmit={onSubmit}>
              <div className="row px-3">
                <div className="form-group col-md-6">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Coupon Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>}
                </div>
              </div>
              <div className="row px-3">
                <div className="col-12 mb-3">
                  <label>Type</label>
                  <br />
                  <div class="form-check form-check-inline mr-4">
                    <input
                      class="form-check-input mr-1"
                      type="radio"
                      name="type"
                      id="percentage"
                      value="percentage"
                      onChange={(e) => setType(e.target.value)}
                      checked={type === "percentage"}
                    />
                    <label class="form-check-label" for="percentage">
                      Percentage discount
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input mr-1"
                      type="radio"
                      name="type"
                      id="fixedAmount"
                      value="fixedAmount"
                      onChange={(e) => setType(e.target.value)}
                      checked={type === "fixedAmount"}
                    />
                    <label class="form-check-label" for="fixedAmount">
                      Fixed amount discount
                    </label>
                  </div>
                </div>
                {type === "percentage" ? (
                  <div className="form-group col-md-6">
                    <label>Percentage off</label>

                    <div class="input-group mb-3">
                      <img alt="" srcset="" />
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Percentage off"
                        value={percentage}
                        onChange={(e) => setPercentage(e.target.value)}
                      />
                      <span class="input-group-text" id="basic-addon1">
                        %
                      </span>
                    </div>
                    {errors.percentage && !percentage && <div className="text-warning font-size-12 mt-2">{errors.percentage}</div>}
                  </div>
                ) : (
                  <div className="form-group col-md-6">
                    <label>Discount amount</label>
                    <div class="input-group mb-3">
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Enter amount"
                        value={discountAmount}
                        onChange={(e) => setDiscountAmount(e.target.value)}
                      />
                      <span class="input-group-text" id="basic-addon1">
                        $
                      </span>
                    </div>
                    {errors.fixedAmount && !discountAmount && <div className="text-warning font-size-12 mt-2">{errors.fixedAmount}</div>}
                  </div>
                )}
              </div>
              <div className="row px-3">
                <div className="d-flex align-items-center px-3 mb-3">
                  <label className="switch">
                    <input type="checkbox" checked={specificeProductCheck} onClick={(e) => setSpecificeProductCheck(e.target.checked)} />
                    <span className="slider round"></span>
                  </label>
                  <label htmlFor="" className="ml-2">
                    Apply to specific products
                  </label>
                </div>
                {specificeProductCheck && (
                  <div className="col-12">
                    <div className="form-group w-50">
                      <label>Select products</label>
                      <Select
                        isMulti
                        name="products"
                        // value={selectedOptions}
                        options={productsList}
                        onChange={(selectedOptions) => setSelecteProductsList(selectedOptions.map(({ value }) => value))}
                        className="basic-multi-select form-control"
                        classNamePrefix="select"
                      />
                      {/* {errors.timeType && !timeType && <div className="text-warning font-size-12 mt-2">{errors.timeType}</div>} */}
                    </div>
                  </div>
                )}
              </div>
              <div className="row px-3">
                <div className="col-4">
                  <div className="form-group">
                    <label>Duration</label>
                    <select className="form-control" value={duration} onChange={(e) => setDuration(e.target.value)}>
                      <option value="forever">Forever</option>
                      <option value="once">Once</option>
                      <option value="multipleMonths">Multiple Months</option>
                    </select>
                    {/* {errors.timeType && !timeType && <div className="text-warning font-size-12 mt-2">{errors.timeType}</div>} */}
                  </div>
                </div>
                {duration === "multipleMonths" && (
                  <div className="form-group col-md-6">
                    <label>Number of months</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter months (number)"
                      value={monthsCount}
                      onChange={(e) => setMonthsCount(e.target.value)}
                    />
                    {/* {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>} */}
                  </div>
                )}
              </div>
              <div className="row px-3">
                <div className="col-12 my-2">
                  <h4>Redemption limits</h4>
                </div>
                <div className="form-group col-md-12">
                  <input
                    className="mr-2"
                    type="checkbox"
                    name="coupon-range"
                    id="coupon-range"
                    checked={dateRangeLimitCheckbox}
                    onChange={(e) => setDateRangeLimitCheckbox(e.target.checked)}
                  />
                  <label for="coupon-range" style={{ cursor: "pointer" }}>
                    Limit the date range when customers can redeem this coupon
                  </label>
                  {dateRangeLimitCheckbox && (
                    <div className="row align-items-center px-3">
                      <label>Redeem by</label>
                      <div className="col-4">
                        <input onChange={(e) => setCouponExpiryDate(e.target.value)} className="form-control" type="datetime-local" name="" id="" />
                      </div>
                      {/* <div className="col-3">
                        <input onChange={(e) => setCouponExpiryTime(e.target.value)} className="form-control" type="time" name="" id="" />
                      </div> */}
                    </div>
                  )}
                  {/* {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>} */}
                </div>
                <div className="form-group col-md-12">
                  <input
                    className="mr-2"
                    type="checkbox"
                    name="redeem-limit"
                    id="redeem-limit"
                    checked={numberOfTimesCheckbox}
                    onChange={(e) => setNumberOfTimesCheckbox(e.target.checked)}
                  />
                  <label for="redeem-limit" style={{ cursor: "pointer" }}>
                    Limit the total number of times this coupon can be redeemed
                  </label>
                  {numberOfTimesCheckbox && (
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="time"
                          value={couponRedemptionsLimit}
                          onChange={(e) => setCouponRedemptionsLimit(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                  {/* {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>} */}
                </div>
                <div className="form-group col-md-12">
                  <input
                    className="mr-2"
                    type="checkbox"
                    name="onetime-use-only"
                    id="onetime-use-only"
                    checked={oneTimeUseOnly}
                    onChange={(e) => setOneTimeUseOnly(e.target.checked)}
                  />
                  <label for="onetime-use-only" style={{ cursor: "pointer" }}>
                    Limit the user from using this coupon more than one time
                  </label>
                  {/* {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>} */}
                </div>
              </div>

              <div className="row px-3">
                <div className="col-12 my-2">
                  <h4>Codes</h4>
                  <div className="d-flex align-items-center">
                    <label className="switch mt-2">
                      <input type="checkbox" checked={showCodesToggle} onClick={(e) => setShowCodesToggle(e.target.checked)} />
                      <span className="slider round"></span>
                    </label>
                    <label htmlFor="" className="mb-0 ml-2">
                      Use customer-facing coupon codes
                    </label>
                  </div>
                  {/* Promotion Codes  */}
                  {showCodesToggle && (
                    <div>
                      <label>{codeName}</label>
                      <div className="form-group col-md-6 px-0">
                        <label>Code</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter code"
                          value={codeName}
                          onChange={(e) => setCodeName(e.target.value)}
                        />
                        {errors.codeTitle && !codeName && <div className="text-warning font-size-12 mt-2">{errors.codeTitle}</div>}
                      </div>
                      <input
                        className="mr-2"
                        type="checkbox"
                        name="first-time-only"
                        id="first-time-only"
                        checked={firstTimeOrderCheckbox}
                        onChange={(e) => setFirstTimeOrderCheckbox(e.target.checked)}
                      />
                      <label for="first-time-only" style={{ cursor: "pointer" }}>
                        Eligible for first-time orders only
                      </label>
                      <div>
                        <input
                          className="mr-2"
                          type="checkbox"
                          name="specific-customer"
                          id="specific-customer"
                          checked={specficCustomerCheckbox}
                          onChange={(e) => setSpecficCustomerCheckbox(e.target.checked)}
                        />
                        <label for="specific-customer" style={{ cursor: "pointer" }}>
                          Limit to specifice customer
                        </label>
                        {specficCustomerCheckbox && (
                          <div className="col-6">
                            <div className="form-group">
                              <Select
                                name="customers"
                                value={selectedCustomer}
                                onInputChange={handleInputChange}
                                options={customersList}
                                onChange={(selectedOption) => setSelectedCustomer(selectedOption)}
                                className="basic-single form-control"
                                classNamePrefix="select"
                                isLoading={customersListLoading}
                              />

                              {/* {errors.timeType && !timeType && <div className="text-warning font-size-12 mt-2">{errors.timeType}</div>} */}
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        <input
                          className="mr-2"
                          type="checkbox"
                          name="redemption-limit"
                          id="redemption-limit"
                          checked={redeemLimitCheckbox}
                          onChange={(e) => setRedeemLimitCheckbox(e.target.checked)}
                        />
                        <label for="redemption-limit" style={{ cursor: "pointer" }}>
                          Limit the number of times this code can be redeemed
                        </label>
                        {redeemLimitCheckbox && (
                          <div class="input-group mb-3 col-md-6 px-0">
                            <img alt="" srcset="" />
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Number of times"
                              value={redemtionLimitCount}
                              onChange={(e) => setRedemtionLimitCount(e.target.value)}
                            />
                            <span class="input-group-text" id="basic-addon1">
                              time
                            </span>
                          </div>
                        )}
                      </div>
                      <div>
                        <input
                          className="mr-2"
                          type="checkbox"
                          name="expiry-date"
                          id="expiry-date"
                          checked={expiryDateCheckbox}
                          onChange={(e) => setExpiryDateCheckbox(e.target.checked)}
                        />
                        <label for="expiry-date" style={{ cursor: "pointer" }}>
                          Add an expiry date
                        </label>

                        {expiryDateCheckbox && (
                          <div className="row align-items-center px-3 mb-3">
                            <label>Expired at</label>
                            <div className="col-4">
                              <input
                                onChange={(e) => setCodeExpiryDate(e.target.value)}
                                className="form-control"
                                type="datetime-local"
                                name=""
                                id=""
                              />
                            </div>
                            {/* <div className="col-3">
                              <input onChange={(e) => console.log(e.target.value)} className="form-control" type="time" name="" id="" />
                            </div> */}
                          </div>
                        )}
                      </div>
                      <div>
                        <input
                          className="mr-2"
                          type="checkbox"
                          name="minimum-order-value"
                          id="minimum-order-value"
                          checked={minimumOrderValueCheckbox}
                          onChange={(e) => setMinimumOrderValueCheckbox(e.target.checked)}
                        />
                        <label for="minimum-order-value" style={{ cursor: "pointer" }}>
                          Require minimum order value
                        </label>
                        {minimumOrderValueCheckbox && (
                          <div class="input-group mb-3 col-md-6 px-0">
                            <img alt="" srcset="" />
                            <span class="input-group-text" id="basic-addon1">
                              $
                            </span>
                            <input
                              type="number"
                              class="form-control"
                              placeholder="Amount"
                              value={codeMinimumOrderValue}
                              onChange={(e) => setCodeMinimumOrderValue(e.target.value)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="modal-footer border-0 mb-4 ml-auto mt-4">
                <button type="submit" className="btn btn-primary">
                  {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Create
                </button>
              </div>
              {/* </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CouponsList;
