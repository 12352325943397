import React, { useEffect } from "react";
import dayjs from "dayjs";
import "react-image-gallery/styles/css/image-gallery.css";
import ReactPaginate from "react-paginate";
import { getReportedPostDetails, remove_session } from "../../../../../functions/general";
import { useNavigate } from "react-router-dom";

const ReportDetailsModal = ({ postDetails, props }) => {
  const navigate = useNavigate();
  const [from, setFrom] = React.useState(0);
  const [limit, setLimit] = React.useState(12);

  const [reportDetailsList, setReportDetailsList] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);

  const [tableDataLoading, setTableDataLoading] = React.useState(true);

  useEffect(() => {
    fetchData();
  }, [from, limit, postDetails?.post?._id]);

  const fetchData = () => {
    setTableDataLoading(true);
    getReportedPostDetails(postDetails?.post?._id, from, limit).then((res) => {
      if (res && res.status === 200) {
        setReportDetailsList(res?.data?.reportedPosts);
        setTotalCount(res?.data?.count);
      } else if (res && res.status === 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };

  const handlePageChange = (data) => {
    const selectedPage = data.selected;
    setFrom(selectedPage * limit);
  };

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
    setFrom(0); // Reset to the first page
  };

  return (
    <>
      <div className="modal fade" id="reportDetailsModal">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header border-0 px-3 mb-3">
              <h4 className="modal-title">Report Details</h4>
              <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton">
                <span>&times;</span>
              </button>
            </div>
            <div className="row px-3">
              {tableDataLoading ? (
                <div className="col-12 text-center py-5">
                  <div
                    className="spinner-border text-black"
                    style={{ width: "3rem", height: "3rem", borderWidth: "0.35rem" }}
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>
              ) : reportDetailsList?.length > 0 ? (
                reportDetailsList?.map((report, index) => {
                  const { text, userDetails, createdAt } = report;

                  return (
                    <div className="col-12" key={index}>
                      <div className="card" style={{ border: "1px solid #c5c5c5" }}>
                        <div className="d-flex justify-content-between align-items-center p-3">
                          <div className="d-flex align-items-center">
                            <img
                              src={
                                userDetails?.profileImage
                                  ? process.env.REACT_APP_MEDIAPATH + userDetails?.profileImage
                                  : `https://ui-avatars.com/api/?name=${userDetails?.firstName}&bold=true`
                              }
                              alt={userDetails?.firstName}
                              style={{ width: 50, height: 50, objectFit: "cover", borderRadius: "50px", marginRight: 14 }}
                            />
                            <div>
                              <p className="mb-0" style={{ fontWeight: 600, color: "#000" }}>
                                {userDetails?.firstName}
                              </p>
                              <p className="mb-0" style={{ fontSize: 15 }}>
                                {dayjs(createdAt)?.format("DD MMM YYYY")}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card-body px-3 pt-0 pb-2 mb-2">
                          <p className="card-text text-black" title={text}>
                            <span style={{ fontWeight: 600, fontSize: 18 }}>Reason:</span> {text}
                            {/* {text.length > 100 ? `${text.substring(0, 140)}...` : text} */}
                          </p>
                        </div>
                        {/* <div className="card-footer">
                          <div className="d-flex justify-content-between">
                            <span>
                              <i style={{ fontSize: 20 }} className="flaticon-381-like mr-1"></i> {likeCount}
                            </span>
                            <span onClick={() => navigate(`/community-post-comments?id=${post?._id}`)} style={{ cursor: "pointer" }}>
                              <i style={{ fontSize: 20 }} className="mdi mdi-comment-outline mr-1"></i> {totalCommentsCount}
                            </span>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center w-100 my-5">
                  <h4 style={{ fontWeight: 600 }}>No Report Found!</h4>
                </div>
              )}
            </div>

            {/* Pagination and Limit Controls */}
            {totalCount > limit && (
              <div className="d-flex justify-content-between align-items-center mt-4 px-3 pb-4">
                <ReactPaginate
                  previousLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                      />
                    </svg>
                  }
                  nextLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                      />
                    </svg>
                  }
                  breakLabel={"..."}
                  pageCount={Math.ceil(totalCount / limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  disabledClassName={"disabled"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                />
                <span className="d-flex align-items-center">
                  Limit:
                  <select className="form-control ml-2" style={{ width: "auto", height: "42px" }} value={limit} onChange={handleLimitChange}>
                    <option value={12}>12</option>
                    <option value={24}>24</option>
                    <option value={48}>48</option>
                    <option value={96}>96</option>
                  </select>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* {isGalleryOpen && (
        <div className="gallery-modal">
          <div className="gallery-overlay" onClick={() => setIsGalleryOpen(false)}></div>
          <div className="gallery-content">
            <ImageGallery items={galleryItems} showThumbnails={false} showFullscreenButton={false} showPlayButton={false} />
          </div>
        </div>
      )} */}
    </>
  );
};

export default ReportDetailsModal;
