import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  remove_session,
  getAllAdminUsers,
  updateAdminUser,
  addAdminUser,
  deleteAdminUserPermanently,
  restoreAdminUser,
  deleteAdminUser,
  getRolesList,
} from "../../../functions/general";
import { toast } from "react-toastify";
import Media from "../../../components/Media";
import ModalImage from "react-modal-image";

function AdminUsersList(props) {
  const navigate = useNavigate();
  const [adminUsersList, setAdminUsersList] = React.useState([]);
  const [rolesList, setRolesList] = React.useState([]);
  const [category, setCategory] = React.useState();
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [image, setImage] = React.useState("");
  const [programType, setProgramType] = React.useState("");
  const [lectureCategoryId, setLectureCategoryId] = React.useState("");
  const [id, setId] = React.useState("");
  const [isPaid, setIsPaid] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [role, setRole] = React.useState("");
  const [errors, setErrors] = React.useState("");

  let { state } = useLocation();

  useEffect(() => {
    props.setPageTitleAndSubTitle("Admin Users", "Here is the list of Admin Users!");
    fetchData();
  }, []);

  const fetchData = () => {
    getAllAdminUsers().then((res) => {
      if ((res && res.status == 201) || res.status == 200) {
        if (res.data && res.data.length) {
          setAdminUsersList(res?.data);
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
    getRolesList().then((res) => {
      if ((res && res.status == 201) || res.status == 200) {
        if (res.data && res.data.length) {
          setRolesList(res?.data);
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
  };
  const modalHandler = (user) => {
    if (user && user._id) {
      setModalTitle("Update User");
      setModalButtonText("Update");
      setFirstName(user?.firstName);
      setLastName(user?.lastName);
      setEmail(user?.email);
      setPassword("");
      setRole(user?.role);
      setId(user._id);
    } else {
      setModalTitle("Add New User");
      setModalButtonText("Add");
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setRole("");
      setId("");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const userData = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: password,
        role: role,
        userType: 2,
        isSuperAdmin: false,
      };
      if (id) {
        updateAdminUser(id, userData).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("User updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update user.");
          }
          setLoading(false);
        });
      } else {
        addAdminUser(userData).then((res) => {
          if (res && (res.status == 201 || res.status == 200)) {
            fetchData();
            toast.success("User added successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add user.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordLength: "",
      role: "",
    };
    let isValid = true;
    if (!firstName) {
      isValid = false;
      errors.firstName = "Please enter first name";
    }
    if (!lastName) {
      isValid = false;
      errors.lastName = "Please enter last name";
    }
    if (!email) {
      isValid = false;
      errors.email = "Please enter email address";
    }
    if (!password && !id) {
      isValid = false;
      errors.password = "Please enter password";
    }
    if (password?.length < 7 && password) {
      isValid = false;
      errors.passwordLength = "Password must be at least 7 characters";
    }
    if (!role) {
      isValid = false;
      errors.role = "Please select role";
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, program) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete user?") == true) {
      deleteAdminUserPermanently(program?._id).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("User deleted successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete user.");
        }
      });
    }
  };

  const deleteStatusHandler = (e, user) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the user?";
    if (user?.isDeleted) {
      confirmText = "Are you sure you want to active the user?";
    }
    if (window.confirm(confirmText) == true) {
      if (user?.isDeleted) {
        restoreAdminUser(user._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("User activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active user.");
          }
        });
      } else {
        deleteAdminUser(user?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("User deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive user.");
          }
        });
      }
    }
  };

  const setImageHandler = (image) => {
    setImage(image);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4 align-items-center">
          <div className="col-8">
            <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li class="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Admin Users
              </li>
            </ol>
          </div>
          <div className="col-4">
            <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={() => modalHandler()}>
              Add New User
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {adminUsersList?.length > 0 ? (
                        adminUsersList?.map((user, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{user?.firstName + " " + user?.lastName}</td>
                              <td>{user?.role}</td>
                              <td>{user?.email}</td>
                              <td>
                                <label className="switch">
                                  <input type="checkbox" checked={!user?.isDeleted} onClick={(e) => deleteStatusHandler(e, user)} />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                              <td>
                                <div className="d-flex">
                                  <button
                                    data-toggle="modal"
                                    data-target="#add"
                                    onClick={(e) => modalHandler(user)}
                                    className="btn btn-primary shadow btn-sm sharp mr-1"
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </button>
                                  <button onClick={(e) => deleteHandler(e, user)} className="btn btn-danger shadow btn-sm sharp">
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <td>No user found.</td>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="add">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="row px-3">
                  <div className="form-group px-3 col-md-6">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter first name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    {errors.firstName && !firstName && <div className="text-warning font-size-12">{errors.firstName}</div>}
                  </div>
                  <div className="form-group px-3 col-md-6">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    {errors.lastName && !lastName && <div className="text-warning font-size-12">{errors.lastName}</div>}
                  </div>
                  <div className="form-group px-3 col-md-6">
                    <label>Email</label>
                    <input type="email" className="form-control" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    {errors.email && !email && <div className="text-warning font-size-12">{errors.email}</div>}
                  </div>
                  <div className="form-group px-3 col-md-6">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {errors.password && !password && <div className="text-warning font-size-12">{errors.password || errors.passwordLength}</div>}
                    {errors.passwordLength && password?.length < 7 && <div className="text-warning font-size-12">{errors.passwordLength}</div>}
                  </div>
                  {rolesList?.length > 0 && (
                    <div className="form-group px-3 col-md-6">
                      <label>Select Role</label>
                      <select className="form-control" value={role} onChange={(e) => setRole(e.target.value)}>
                        <option value="">Select user role</option>
                        {rolesList?.length > 0 && rolesList?.map((role) => <option value={role?.name}>{role?.name}</option>)}
                      </select>
                      {errors.role && !role && <div className="text-warning font-size-12">{errors.role}</div>}
                    </div>
                  )}
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Media setImageHandler={setImageHandler} />
      </div>
    </>
  );
}

export default AdminUsersList;
