import React, { useEffect } from "react";
import MP3Icon from "../images/MP3_icon.png";
import {
  deleteLectureOverviewPermanently,
  deleteProgramWeekLectureOverviewPermanently,
  deleteWeekDayOverviewPermanently,
  deleteWeekOverviewPermanently,
  deleteWorkoutOverviewPermanently,
  remove_session,
  updateLectureOverview,
  updateProgramWeekLectureOverview,
  updateWeekDayOverview,
  updateWeekOverview,
  updateWorkoutOverview,
} from "../functions/general";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ArrowDown from "../images/ArrowDown.svg";
import Media from "./Media";
import { usePermissions } from "../functions/usePermissions";
import DragHandler from "./DragHandler";

const AudioOverview = ({ item, fetchData, getLoggedInUser, type, index }) => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [isOpen, setIsopen] = React.useState(index === 0 ? true : false);
  const [loading, setLoading] = React.useState(false);
  const [audioLoading, setAudioLoading] = React.useState(true);
  const [audio, setAudio] = React.useState(item?.url ?? "");
  const [fileName, setFileName] = React.useState(item?.title);
  const [fileSize, setFileSize] = React.useState(item?.fileSize);

  const [title, setTitle] = React.useState(item?.title);

  const [showUpdateAudioModal, setShowUpdateAudioModal] = React.useState(false);

  useEffect(() => {
    setAudioLoading(true);
    const loader = setTimeout(() => {
      setAudioLoading(false);
    }, 400);
    return () => {
      clearTimeout(loader);
    };
  }, [isOpen]);

  const handleUpdateOverviewAudio = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (audio) {
      setLoading(true);
      if (type === "week") {
        const audioData = {
          title: title ?? "",
          fileSize: fileSize ?? "",
          url: audio,
          text: "",
          type: "audio",
          week: item?.week,
        };
        updateWeekOverview(audioData, item?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Audio updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update Audio.");
          }
          setLoading(false);
        });
      } else if (type === "day") {
        const audioData = {
          title: title ?? "",
          fileSize: fileSize ?? "",
          url: audio,
          text: "",
          type: "audio",
          weekDay: item?.weekDay,
        };
        updateWeekDayOverview(audioData, item?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Audio updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update Audio.");
          }
          setLoading(false);
        });
      } else if (type === "lecture") {
        const audioData = {
          title: title ?? "",
          fileSize: fileSize ?? "",
          url: audio,
          text: "",
          type: "audio",
          lecture: item?.lecture,
        };
        updateLectureOverview(audioData, item?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Audio updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update Audio.");
          }
          setLoading(false);
        });
      } else if (type === "programWeekLecture") {
        const audioData = {
          title: title ?? "",
          fileSize: fileSize ?? "",
          url: audio,
          text: "",
          type: "audio",
          lecture: item?.lecture,
        };
        updateProgramWeekLectureOverview(audioData, item?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Audio updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update Audio.");
          }
          setLoading(false);
        });
      } else {
        const audioData = {
          title: title ?? "",
          fileSize: fileSize ?? "",
          url: audio,
          text: "",
          type: "audio",
          workout: item?.workout,
        };
        updateWorkoutOverview(audioData, item?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Audio updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update Audio.");
          }
          setLoading(false);
        });
      }
    }
  };

  const deleteHandler = (e, item) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete audio overview permanently?") == true) {
      if (type === "week") {
        deleteWeekOverviewPermanently(item._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Audio overview deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete audio overview.");
          }
        });
      } else if (type === "day") {
        deleteWeekDayOverviewPermanently(item._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Audio overview deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete audio overview.");
          }
        });
      } else if (type === "lecture") {
        deleteLectureOverviewPermanently(item._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Audio overview deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete audio overview.");
          }
        });
      } else if (type === "programWeekLecture") {
        deleteProgramWeekLectureOverviewPermanently(item._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Audio overview deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete audio overview.");
          }
        });
      } else if (type === "day") {
        deleteWorkoutOverviewPermanently(item._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Audio overview deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete audio overview.");
          }
        });
      } else {
        deleteWorkoutOverviewPermanently(item._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Audio overview deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete audio overview.");
          }
        });
      }
    }
  };
  const setImageHandler = (audio) => {
    setAudio(audio);
  };

  return (
    <>
      <div className="col-6 bg-white" style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", borderRadius: "10px" }}>
        <div
          className="py-4 text-black d-flex justify-content-between"
          style={{ fontSize: "18px", fontWeight: "500", cursor: "pointer" }}
          onClick={() => setIsopen(!isOpen)}
        >
          <span className="d-flex align-items-center" style={{ gap: "15px" }}>
            <DragHandler /> Audio {item?.title && `(${item?.title})`}
          </span>
          <img src={ArrowDown} />
        </div>
        {isOpen && (
          <div>
            <form noValidate onSubmit={handleUpdateOverviewAudio}>
              {audioLoading ? (
                <div className="text-center py-5">
                  <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                </div>
              ) : (
                <>
                  <div className="form-group px-3">
                    <label>Title</label>
                    <input type="text" className="form-control" placeholder="Enter title" value={title} onChange={(e) => setTitle(e.target.value)} />
                    {/* {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>} */}
                  </div>
                  <div className="form-group px-3 py-4 text-center">
                    <label>{fileName}</label>
                    <br />
                    {(item?.url || audio) && (
                      <a href={process.env.REACT_APP_MEDIAPATH + audio} target="_blank">
                        <img src={MP3Icon} className="img-fluid img-thumbnail" width="100" />
                        <br />
                      </a>
                    )}
                    <button
                      type="button"
                      onClick={() => setShowUpdateAudioModal(true)}
                      className="btn btn-primary mt-3"
                      data-toggle="modal"
                      data-target="#overViewAudio"
                    >
                      Select Audio
                    </button>
                    {!audio && <div className="text-warning font-size-12 mt-2">Audio is required</div>}
                  </div>
                </>
              )}
              <div className="modal-footer border-0 px-0">
                {((hasPermission("edit-day-overview") && type === "day") ||
                  (hasPermission("edit-workout-overview") && type === "workout") ||
                  (hasPermission("edit-lecture-overview") && type === "lecture") ||
                  (hasPermission("edit-program-week-lecture-overview") && type === "programWeekLecture") ||
                  (hasPermission("edit-week-overview") && type === "week")) && (
                  <button type="submit" className="btn btn-primary btn-sm">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Update
                  </button>
                )}
                {((hasPermission("delete-day-overview") && type === "day") ||
                  (hasPermission("delete-workout-overview") && type === "workout") ||
                  (hasPermission("delete-lecture-overview") && type === "lecture") ||
                  (hasPermission("delete-program-week-lecture-overview") && type === "programWeekLecture") ||
                  (hasPermission("delete-week-overview") && type === "week")) && (
                  <button onClick={(e) => deleteHandler(e, item)} className="btn btn-danger btn-sm">
                    Delete
                  </button>
                )}
              </div>
            </form>
          </div>
        )}
      </div>
      {showUpdateAudioModal && <Media setFileName={setFileName} setFileSize={setFileSize} setImageHandler={setImageHandler} id={"overViewAudio"} />}
    </>
  );
};

export default AudioOverview;
