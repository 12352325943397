import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  remove_session,
  getLectureWarmupPdfs,
  updateLectureWarmupPdf,
  addLectureWarmupPdf,
  deleteLectureWarmupPdfPermanently,
  restoreLectureWarmupPdf,
  deleteLectureWarmupPdf,
  sortProgramWeekLectureWarmupPdfs,
  getProgramWeekLectureWarmupPdfs,
  updateProgramWeekLectureWarmupPdf,
  deleteProgramWeekLectureWarmupPdfPermanently,
  addProgramWeekLectureWarmupPdf,
  restoreProgramWeekLectureWarmupPdf,
  deleteProgramWeekLectureWarmupPdf,
} from "../../../../../../functions/general";
import { toast } from "react-toastify";
import Media from "../../../../../../components/Media";
import PDFIcon from "../../../../../../images/PDF_file_icon.png";
import { usePermissions } from "../../../../../../functions/usePermissions";

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";

function LectureWarmupPdfs(props) {
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();

  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [pdf, setPdf] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [lectureWarmupPdfs, setLectureWarmupPdfs] = React.useState([]);
  const [errors, setErrors] = React.useState("");
  const [fileSize, setFileSize] = React.useState("");
  const [tableDataLoading, setTableDataLoading] = React.useState(true);

  const [fileName, setFileName] = React.useState("");

  useEffect(() => {
    // props.setPageTitleAndSubTitle("Lecture Videos", "Here is the list of Lecture Categories !");
    fetchData();
  }, []);

  console.log("props?.lectureWarmupData",props?.lectureWarmupData);
  const fetchData = (type) => {
    getProgramWeekLectureWarmupPdfs(props?.lectureWarmupData?._id).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        setLectureWarmupPdfs(res?.data);
        if (type === "add") {
          let sortedArray = [];
          res?.data?.map((pdf) => sortedArray.push(pdf?._id));
          sortProgramWeekLectureWarmupPdfs(sortedArray).then((res) => {
            if (res && res.status == 200) {
              // fetchData();
            } else if (res && res.status == 401) {
              remove_session();
              props.getLoggedInUser();
              navigate("/login");
            } else {
              toast.error("Failed to sort the list.");
            }
            setTableDataLoading(false);
          });
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };

  const modalHandler = (pdf) => {
    if (pdf && pdf._id) {
      setModalTitle("Update Warmup Pdf");
      setModalButtonText("Update");
      setTitle(pdf?.title);
      setFileSize(pdf?.fileSize);
      setPdf(pdf?.url);
      setId(pdf._id);
    } else {
      setModalTitle("Add New Warmup Pdf");
      setModalButtonText("Add");
      setTitle("");
      setFileSize("");
      setDescription("");
      setPdf("");
      setId("");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const pdfData = {
        title: title,
        fileSize: fileSize,
        url: pdf,
        text: description,
        lectureWarmup: props?.lectureWarmupData,
      };
      let type = "add";
      if (id) {
        updateProgramWeekLectureWarmupPdf(id, pdfData).then((res) => {
          if (res && (res.status == 200 || res.status == 201)) {
            fetchData();
            toast.success("PDF updated successfully.");
            document.getElementById("programWeekLectureWarmupPdfModal").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update pdf.");
          }
          setLoading(false);
        });
      } else {
        addProgramWeekLectureWarmupPdf(pdfData).then((res) => {
          if (res && res.status == 201) {
            fetchData(type);
            toast.success("PDF added successfully.");
            document.getElementById("programWeekLectureWarmupPdfModal").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add pdf.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      pdf: "",
      description: "",
    };
    let isValid = true;
    // if (!title) {
    //   isValid = false;
    //   errors.title = "Please enter title.";
    // }
    if (!pdf) {
      isValid = false;
      errors.pdf = "Please select pdf.";
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, pdf) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete pdf?") == true) {
      if (window.confirm("Are you sure you want to delete pdf permanently?") == true) {
        deleteProgramWeekLectureWarmupPdfPermanently(pdf._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("PDF deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete PDF.");
          }
        });
      }
    }
  };

  const deleteStatusHandler = (e, pdf) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the PDF?";
    if (pdf.isDeleted) {
      confirmText = "Are you sure you want to active the PDF?";
    }
    if (window.confirm(confirmText) == true) {
      if (pdf.isDeleted) {
        restoreProgramWeekLectureWarmupPdf(pdf._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("PDF activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active PDF.");
          }
        });
      } else {
        deleteProgramWeekLectureWarmupPdf(pdf._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("PDF deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive PDF.");
          }
        });
      }
    }
  };

  const setImageHandler = (pdf) => {
    setPdf(pdf);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    const updatedWarmupPdfs = arrayMove(lectureWarmupPdfs, oldIndex, newIndex);
    let sortedArray = [];
    updatedWarmupPdfs?.map((pdf) => sortedArray.push(pdf?._id));
    sortProgramWeekLectureWarmupPdfs(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setLectureWarmupPdfs(updatedWarmupPdfs);
        // fetchData();
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ pdf, index, serialNo }) => {
    return (
      <tr key={index}>
        <td>{serialNo + 1}</td>
        <td>
          <div className="d-flex align-items-center">
            <img src={PDFIcon} alt="pdf-icon" width={30} />
            <span className="ml-3">{pdf?.title}</span>
          </div>
        </td>
        {hasPermission("edit-workout-warmup-pdfs") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={!pdf.isDeleted} onClick={(e) => deleteStatusHandler(e, pdf)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {(hasPermission("edit-workout-warmup-pdfs") || hasPermission("delete-workout-warmup-pdfs")) && (
          <td>
            <div className="d-flex">
              {hasPermission("edit-workout-warmup-pdfs") && (
                <button
                  data-toggle="modal"
                  data-target="#addProgramWeekLectureWarmupPdfModal"
                  onClick={(e) => modalHandler(pdf)}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {hasPermission("delete-workout-warmup-pdfs") && (
                <button onClick={(e) => deleteHandler(e, pdf)} className="btn btn-danger shadow btn-sm sharp">
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </td>
        )}
        {hasPermission("edit-workout-warmup-pdfs") && (
          <td>
            <DragHandle />
          </td>
        )}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ lectureWarmupPdfs }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>No</th>
            <th>PDF</th>
            {hasPermission("edit-workout-warmup-pdfs") && <th>Status</th>}
            {(hasPermission("edit-workout-warmup-pdfs") || hasPermission("delete-workout-warmup-pdfs")) && <th>Actions</th>}
            {hasPermission("edit-workout-warmup-pdfs") && <th>Sort Order</th>}
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="5" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {lectureWarmupPdfs?.length > 0 ? (
                lectureWarmupPdfs?.map((pdf, index) => {
                  return <SortableTableRow key={index} pdf={pdf} index={index} serialNo={index} />;
                })
              ) : (
                <tr>
                  <td colSpan="5" className="text-center pt-5">
                    No Warmup PDF found !
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-12">
            <button
              className="btn btn-primary"
              onClick={() => {
                props?.setWarmupPdfsShow(false);
              }}
            >
              <i className="fas fa fa-arrow-circle-left mr-3"></i>
              Back to Warmups
            </button>
            {hasPermission("add-workout-warmup-pdfs") && (
              <button
                className="btn btn-primary float-right"
                data-toggle="modal"
                data-target="#addProgramWeekLectureWarmupPdfModal"
                onClick={(e) => modalHandler()}
              >
                Add New PDF
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  {tableDataLoading ? (
                    <div className="text-center py-5">
                      <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                    </div>
                  ) : (
                    <>
                      <SortableTable lectureWarmupPdfs={lectureWarmupPdfs} onSortEnd={onSortEnd} useDragHandle={true} />
                      {/* <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>PDF</th>
                            {hasPermission("edit-workout-warmup-pdfs")&&<th>Status</th>}
                            {(hasPermission("edit-workout-warmup-pdfs") || hasPermission("delete-workout-warmup-pdfs")) &&<th>Actions</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {lectureWarmupPdfs?.length > 0 ? (
                            lectureWarmupPdfs?.map((pdf, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <img src={PDFIcon} alt="pdf-icon" width={30} />
                                      <span className="ml-3">{pdf?.title}</span>
                                    </div>
                                  </td>
                                  {hasPermission("edit-workout-warmup-pdfs")&&<td>
                                    <label className="switch">
                                      <input type="checkbox" checked={!pdf.isDeleted} onClick={(e) => deleteStatusHandler(e, pdf)} />
                                      <span className="slider round"></span>
                                    </label>
                                  </td>}
                                  {(hasPermission("edit-workout-warmup-pdfs") || hasPermission("delete-workout-warmup-pdfs")) &&<td>
                                    <div className="d-flex">
                                      {hasPermission("edit-workout-warmup-pdfs")&&<button
                                        data-toggle="modal"
                                        data-target="#addProgramWeekLectureWarmupPdfModal"
                                        onClick={(e) => modalHandler(pdf)}
                                        className="btn btn-primary shadow btn-sm sharp mr-1"
                                      >
                                        <i className="fa fa-pencil"></i>
                                      </button>}
                                      {hasPermission("delete-workout-warmup-pdfs")&&<button onClick={(e) => deleteHandler(e, pdf)} className="btn btn-danger shadow btn-sm sharp">
                                        <i className="fa fa-trash"></i>
                                      </button>}
                                    </div>
                                  </td>}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="4" className="text-center pt-5">
                                No Warmup PDF found !
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="addProgramWeekLectureWarmupPdfModal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="programWeekLectureWarmupPdfModal">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="form-group px-3">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Pdf Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {/* {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>} */}
                </div>
                {/* <div className="form-group px-3">
                  <label>Description</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter Description"
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  {errors.description && !description && <div className="text-warning font-size-12">{errors.description}</div>}
                </div> */}
                <div className="form-group px-3 py-4 text-center">
                  <label>{title ? title : "Upload PDF"}</label>
                  <br /><br />
                  {pdf && (
                    <>
                      <span><strong>{fileName}</strong></span><br /><br />
                      {pdf?.substr(pdf?.length - 4) === ".pdf" ? (
                        <img src={PDFIcon} className="img-fluid img-thumbnail" width="100" />
                      ) : (
                        <img src={process.env.REACT_APP_MEDIAPATH + pdf} className="img-fluid img-thumbnail" width="150" />
                      )}
                      <br />
                    </>
                  )}
                  <button type="button" className="btn btn-primary mt-3" data-toggle="modal" data-target="#programWeekLectureWarmupPdfMedia">
                    Select PDF
                  </button>
                  {errors.pdf && !pdf && <div className="text-warning font-size-12 mt-2 pl-2">{errors.pdf}</div>}
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Media setFileName={setFileName} setImageHandler={setImageHandler} setFileSize={setFileSize} id={"programWeekLectureWarmupPdfMedia"} />
    </>
  );
}

export default LectureWarmupPdfs;
