import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  remove_session,
  getWorkoutSubcategories,
  updateWorkoutSubcategory,
  addWorkoutSubcategory,
  deleteWorkoutSubcategoryPermanently,
  restoreWorkoutSubcategory,
  deleteWorkoutSubcategory,
  sortWorkoutSubcategories,
} from "../../../../functions/general";
import { toast } from "react-toastify";
import Media from "../../../../components/Media";
import ModalImage from "react-modal-image";
import { usePermissions } from "../../../../functions/usePermissions";

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";

function ListWorkoutSubcategories(props) {
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();

  const [workoutSubcategories, setWorkoutSubcategories] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [image, setImage] = React.useState("");
  const [secondaryImg, setSecondaryImg] = React.useState("");
  const [isSecondaryImg, setIsSecondaryImg] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState("");
  const [workoutCategory, setWorkoutCategory] = React.useState({});
  const [tableDataLoading, setTableDataLoading] = React.useState(true);

  const { state } = useLocation();

  useEffect(() => {
    props.setPageTitleAndSubTitle(state?.category?.title, `Here is the list of ${state?.category?.title ?? workoutCategory?.title} Subcategories !`);
    fetchData();
  }, []);

  const fetchData = (type) => {
    getWorkoutSubcategories(state?.category?._id).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        setWorkoutSubcategories(res?.data);
        if (type === "add") {
          let sortedArray = [];
          res?.data?.map((subCategory) => sortedArray.push(subCategory?._id));
          sortWorkoutSubcategories(sortedArray).then((res) => {
            if (res && res.status == 200) {
              // fetchData();
            } else if (res && res.status == 401) {
              remove_session();
              props.getLoggedInUser();
              navigate("/login");
            } else {
              toast.error("Failed to sort the list.");
            }
            setTableDataLoading(false);
          });
        }
        // setWorkoutCategory(res?.data[0]?.category);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };

  const modalHandler = (subCategory) => {
    if (subCategory && subCategory._id) {
      setModalTitle("Update Workout Subcategory");
      setModalButtonText("Update");
      setTitle(subCategory?.title);
      setImage(subCategory?.image);
      setSecondaryImg(subCategory?.secondaryImage);
      setDescription(subCategory?.text);
      setId(subCategory?._id);
    } else {
      setModalTitle("Add New Workout Subcategory");
      setModalButtonText("Add");
      setTitle("");
      setImage("");
      setSecondaryImg(subCategory?.secondaryImg);
      setDescription("");
      setId("");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const subcategory = {
        title: title,
        image: image,
        secondaryImage : secondaryImg,
        text: description ? description : "",
        category: state?.category,
      };
      let type = "add";
      if (id) {
        updateWorkoutSubcategory(id, subcategory).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Workout subCategory updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update Workout subcategory.");
          }
          setLoading(false);
        });
      } else {
        addWorkoutSubcategory(subcategory).then((res) => {
          if (res && res.status == 201) {
            fetchData(type);
            toast.success("Workout subCategory added successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add Workout subcategory.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      image: "",
      secondaryImg: "",
      // description: "",
    };
    let isValid = true;
    if (!title) {
      isValid = false;
      errors.title = "Please enter title.";
    }
    if (!image) {
      isValid = false;
      errors.image = "Please select rectangle image";
    }
    if (!secondaryImg) {
      isValid = false;
      errors.secondaryImg = "Please select square image";
    }
    // if (!description) {
    //   isValid = false;
    //   errors.description = "Please enter description";
    // }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, subcategory) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete workout subcategory?") == true) {
      if (window.confirm("Are you sure you want to delete workout subcategory permanently?") == true) {
        deleteWorkoutSubcategoryPermanently(subcategory._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Workout subCategory deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete subcategory.");
          }
        });
      }
    }
  };

  const deleteStatusHandler = (e, subcategory) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the workout subcategory?";
    if (subcategory.isDeleted) {
      confirmText = "Are you sure you want to active the workout subcategory?";
    }
    if (window.confirm(confirmText) == true) {
      if (subcategory.isDeleted) {
        restoreWorkoutSubcategory(subcategory._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Workout subCategory activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active subcategory.");
          }
        });
      } else {
        deleteWorkoutSubcategory(subcategory._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Workout subCategory deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive subcategory.");
          }
        });
      }
    }
  };

  const setImageHandler = (image) => {
    if (isSecondaryImg) {
      setSecondaryImg(image);
    } else setImage(image);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    const updatedWorkoutSubcategories = arrayMove(workoutSubcategories, oldIndex, newIndex);
    let sortedArray = [];
    updatedWorkoutSubcategories?.map((subCategory) => sortedArray.push(subCategory?._id));
    sortWorkoutSubcategories(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setWorkoutSubcategories(updatedWorkoutSubcategories);
        // fetchData();
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ subcategory, serialNo }) => {
    return (
      <tr>
        <td>{serialNo + 1}</td>
        <td>
          <div className="maxWidth70px">
            <ModalImage small={process.env.REACT_APP_MEDIAPATH + subcategory.image} large={process.env.REACT_APP_MEDIAPATH + subcategory.image} />
          </div>
        </td>
        <td>{subcategory.title}</td>
        {hasPermission("view-workouts") && (
          <td>
            <Link to={`/additional-workouts`} state={{ subcategory, category: state?.category?.title }} className="btn btn-dark btn-xs">
              {subcategory?.count ? subcategory?.count : 0}
            </Link>
          </td>
        )}
        {hasPermission("edit-workout-subcategory") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={!subcategory.isDeleted} onClick={(e) => deleteStatusHandler(e, subcategory)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {(hasPermission("edit-workout-subcategory") || hasPermission("delete-workout-subcategory")) && (
          <td>
            <div className="d-flex">
              {hasPermission("edit-workout-subcategory") && (
                <button
                  data-toggle="modal"
                  data-target="#add"
                  onClick={(e) => modalHandler(subcategory)}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {hasPermission("delete-workout-subcategory") && (
                <button onClick={(e) => deleteHandler(e, subcategory)} className="btn btn-danger shadow btn-sm sharp">
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </td>
        )}
        {hasPermission("edit-workout-subcategory") && (
          <td>
            <DragHandle />
          </td>
        )}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ workoutSubcategories }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>No</th>
            <th>Image</th>
            <th>Name</th>
            {hasPermission("view-workouts") && <th>Workouts</th>}
            {hasPermission("edit-workout-subcategory") && <th>Status</th>}
            {(hasPermission("edit-workout-subcategory") || hasPermission("delete-workout-subcategory")) && <th>Actions</th>}
            {hasPermission("edit-workout-subcategory") && <th>Sort Order</th>}
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="7" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {workoutSubcategories?.length > 0 ? (
                workoutSubcategories?.map((subcategory, index) => {
                  return <SortableTableRow key={index} subcategory={subcategory} index={index} serialNo={index} />;
                })
              ) : (
                <tr>
                  <td colSpan="7" className="text-center pt-5">
                    No lecture Subcategory found!
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4 align-items-center">
          <div className="col-8">
            <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li class="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li class="breadcrumb-item">
                <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate(-1)}>
                  Workout Categories
                </span>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {state?.category?.title}
              </li>
            </ol>
          </div>
          <div className="col-4">
            {hasPermission("add-workout-subcategory") && (
              <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={(e) => modalHandler()}>
                Add New
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  {tableDataLoading ? (
                    <div className="text-center py-5">
                      <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                    </div>
                  ) : (
                    <>
                      <SortableTable workoutSubcategories={workoutSubcategories} onSortEnd={onSortEnd} useDragHandle={true} />

                      {/* <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>Image</th>
                            <th>Name</th>
                            {hasPermission("view-workouts") && <th>Workouts</th>}
                            {hasPermission("edit-workout-subcategory") && <th>Status</th>}
                            {(hasPermission("edit-workout-subcategory") || hasPermission("delete-workout-subcategory")) && <th>Actions</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {workoutSubcategories?.length > 0 ? (
                            workoutSubcategories?.map((subcategory, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    <div className="maxWidth70px">
                                      <ModalImage
                                        small={process.env.REACT_APP_MEDIAPATH + subcategory.image}
                                        large={process.env.REACT_APP_MEDIAPATH + subcategory.image}
                                      />
                                    </div>
                                  </td>
                                  <td>{subcategory.title}</td>
                                  {hasPermission("view-workouts") && (
                                    <td>
                                      <Link
                                        to={`/additional-workouts`}
                                        state={{ subcategory, category: state?.category?.title }}
                                        className="btn btn-dark btn-xs"
                                      >
                                        {subcategory?.count ? subcategory?.count : 0}
                                      </Link>
                                    </td>
                                  )}
                                  {hasPermission("edit-workout-subcategory") && (
                                    <td>
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          checked={!subcategory.isDeleted}
                                          onClick={(e) => deleteStatusHandler(e, subcategory)}
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </td>
                                  )}
                                  {(hasPermission("edit-workout-subcategory") || hasPermission("delete-workout-subcategory")) && (
                                    <td>
                                      <div className="d-flex">
                                        {hasPermission("edit-workout-subcategory") && (
                                          <button
                                            data-toggle="modal"
                                            data-target="#add"
                                            onClick={(e) => modalHandler(subcategory)}
                                            className="btn btn-primary shadow btn-sm sharp mr-1"
                                          >
                                            <i className="fa fa-pencil"></i>
                                          </button>
                                        )}
                                        {hasPermission("delete-workout-subcategory") && (
                                          <button onClick={(e) => deleteHandler(e, subcategory)} className="btn btn-danger shadow btn-sm sharp">
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center pt-5">
                                No Subcategory found !
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="add">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="form-group px-3">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Workout Subcategory Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>}
                </div>
                <div className="form-group px-3">
                  <label>Description</label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Enter Description"
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  {/* {errors.description && !description && <div className="text-warning font-size-12">{errors.description}</div>} */}
                </div>
                <div className="row mt-4">
                  <div className="col-md-4">
                    <div className="form-group px-3">
                      <label>Rectangle Image</label>
                      <br />
                      {image && (
                        <>
                          <img src={process.env.REACT_APP_MEDIAPATH + image} className="img-fluid img-thumbnail" width="150" />
                          <br />
                        </>
                      )}
                      <button
                        onClick={() => setIsSecondaryImg(false)}
                        type="button"
                        className="btn btn-primary mt-3"
                        data-toggle="modal"
                        data-target="#media"
                      >
                        Select Rectangle Image
                      </button>
                      {errors.image && !image && <div className="text-warning font-size-12 mt-2">{errors.image}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group px-3">
                      <label>Square Image</label>
                      <br />
                      {secondaryImg && (
                        <>
                          <img src={process.env.REACT_APP_MEDIAPATH + secondaryImg} className="img-fluid img-thumbnail" width="150" />
                          <br />
                        </>
                      )}
                      <button
                        onClick={() => setIsSecondaryImg(true)}
                        type="button"
                        className="btn btn-primary mt-3"
                        data-toggle="modal"
                        data-target="#media"
                      >
                        Select Square Image
                      </button>
                      {errors.secondaryImg && !secondaryImg && <div className="text-warning font-size-12 mt-2">{errors.secondaryImg}</div>}
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Media setImageHandler={setImageHandler} />
      </div>
    </>
  );
}

export default ListWorkoutSubcategories;
