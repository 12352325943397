import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  remove_session,
  getWeekDaysList,
  updateDay,
  addDay,
  deleteDayPermanently,
  restoreDay,
  deleteDay,
  cloneWeekDay,
  sortProgramWeekDays,
  getReferenceDaysList,
  getWeekDetails,
} from "../../../functions/general";
import { toast } from "react-toastify";
import Media from "../../../components/Media";
import ModalImage from "react-modal-image";
import Select from "react-select";
import { usePermissions } from "../../../functions/usePermissions";

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";

function ListWeekDays(props) {
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const [weekDaysList, setWeekDaysList] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [image, setImage] = React.useState("");
  const [dayType, setDayType] = React.useState(null);
  const [referenceDayValue, setReferenceDayValue] = React.useState(null);
  const [typeName, setTypeName] = React.useState("");
  const [typeColor, setTypeColor] = React.useState(null);
  const [description, setDescription] = React.useState("");
  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [isPaid, setIsPaid] = React.useState(true);
  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [errors, setErrors] = React.useState("");
  const [allowMarkAsComplete, setAllowMarkAsComplete] = React.useState(false);
  const [referenceDaysList, setReferenceDaysList] = React.useState([]);

  let { state } = useLocation();

  const [weekDetails, setWeekDetails] = React.useState(null);
  const [searchParams] = useSearchParams();
  const weekId = searchParams.get("weekId");

  useEffect(() => {
    getWeekDetails(weekId).then((res) => {
      if (res && res.status == 200) {
        setWeekDetails(res?.data);
        props.setPageTitleAndSubTitle(res?.data?.title, "Here is the list of Days!");
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
    fetchData();
  }, []);

  const fetchData = (type) => {
    // const weekId = searchParams.get("weekId");
    getWeekDaysList(weekId).then((res) => {
      if (res && res.status == 201) {
        if (res.data) {
          setWeekDaysList(res?.data);
          if (type === "add") {
            let sortedArray = [];
            res?.data?.map((day) => sortedArray.push(day?._id));
            sortProgramWeekDays(sortedArray).then((res) => {
              if (res && res.status == 200) {
                // fetchData();
              } else if (res && res.status == 401) {
                remove_session();
                props.getLoggedInUser();
                navigate("/login");
              } else {
                toast.error("Failed to sort the list.");
              }
              setTableDataLoading(false);
            });
          }
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };

  const modalHandler = (day) => {
    getReferenceDaysList(weekDetails?.program?._id).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        setReferenceDaysList(res.data);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
    });
    if (day && day?._id) {
      setModalTitle("Update Day");
      setModalButtonText("Update");
      setTitle(day?.title);
      setDayType(day?.type);
      setTypeName(day?.typeName);
      setTypeColor(day?.typeColor);
      setImage(day?.image);
      setDescription(day?.text);
      setIsPaid(day?.isPaid);
      setId(day?._id);
      setAllowMarkAsComplete(day?.shouldComplete);
      setReferenceDayValue(day?.referenceDay);
    } else {
      setModalTitle("Add New Day");
      setModalButtonText("Add");
      setTitle("");
      setDayType(null);
      setTypeName("");
      setTypeColor(null);
      setImage("");
      setDescription("");
      setId("");
      setIsPaid(true);
      setAllowMarkAsComplete(false);
      setReferenceDayValue(null);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const weekDayData = {
        title: title,
        type: dayType ? dayType : "",
        text: description,
        image: image,
        typeName: typeName,
        isPaid: isPaid,
        typeColor: typeColor ? typeColor : "",
        week: weekDetails,
        shouldComplete: allowMarkAsComplete,
        referenceDay: referenceDayValue,
      };
      let type = "add";
      if (id) {
        updateDay(id, weekDayData).then((res) => {
          if (res && res.status == 200) {
            fetchData(type);
            toast.success("Day updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update day.");
          }
          setLoading(false);
        });
      } else {
        addDay(weekDayData).then((res) => {
          if (res && res.status == 201) {
            fetchData();
            toast.success("Day added successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add day.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      description: "",
      image: "",
      dayType: "",
    };
    let isValid = true;
    if (!title) {
      isValid = false;
      errors.title = "Please select title";
    }
    if (!dayType) {
      isValid = false;
      errors.dayType = "Please select day type";
    }
    if (!image) {
      isValid = false;
      errors.image = "Please select image";
    }
    setErrors(errors);
    return isValid;
  };
  const paymentStatusHandler = (e, day) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to change payment status of day?";
    if (window.confirm(confirmText) == true) {
      const weekDayData = {
        title: day?.title,
        type: day?.type,
        text: day?.text,
        image: day?.image,
        typeName: day?.typeName,
        typeColor: day?.typeColor,
        week: weekDetails,
        shouldComplete: day?.shouldComplete,
        referenceDay: day?.referenceDay,
        isPaid: e.target.checked,
      };
      updateDay(day._id, weekDayData).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("Payment status changed successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to change the payment status of day.");
        }
      });
    }
  };
  const deleteHandler = (e, day) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete day permanently?") == true) {
      deleteDayPermanently(day?._id).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("Day deleted successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete day.");
        }
      });
    }
  };

  const deleteStatusHandler = (e, day) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the day?";
    if (day?.isDeleted) {
      confirmText = "Are you sure you want to active the day?";
    }
    if (window.confirm(confirmText) == true) {
      if (day?.isDeleted) {
        restoreDay(day._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Day activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active day.");
          }
        });
      } else {
        deleteDay(day?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Day deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive day.");
          }
        });
      }
    }
  };

  const setImageHandler = (image) => {
    setImage(image);
  };

  const dayCloneHandler = (e, day) => {
    setTableDataLoading(true);
    e.preventDefault();
    if (window.confirm("Are you sure you want to clone day?") == true) {
      cloneWeekDay(day?._id).then((res) => {
        if (res && (res.status == 200 || res.status == 201)) {
          fetchData();
          toast.success("Day cloned successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to clone day.");
        }
        setTableDataLoading(false);
      });
    } else {
      setTableDataLoading(false);
    }
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    const updatedWeekDays = arrayMove(weekDaysList, oldIndex, newIndex);
    let sortedArray = [];
    updatedWeekDays?.map((day) => sortedArray.push(day?._id));
    sortProgramWeekDays(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setWeekDaysList(updatedWeekDays);
        // fetchData();
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ day, serialNo }) => {
    return (
      <tr>
        <td>{serialNo + 1}</td>
        <td>
          <div className="maxWidth70px">
            <ModalImage small={process.env.REACT_APP_MEDIAPATH + day?.image} large={process.env.REACT_APP_MEDIAPATH + day?.image} />
          </div>
        </td>
        {/* <td>{day?.type === "intro" ? <Link to="/intro-week-details">{day?.title}</Link>:day?.title}</td> */}
        {/* <td>{day?.title}</td> */}
        <td>
          {hasPermission("view-days-overview") ||
          hasPermission("view-days-warmup") ||
          hasPermission("view-days-exercise") ||
          hasPermission("view-days-result") ? (
            <Link
              style={{ color: "blue" }}
              to={`/week-day-details?dayId=${day?._id}`}
              // state={{ day, category: state?.category, program: state?.program, week: state?.week?.title }}
            >
              {day?.title}
            </Link>
          ) : (
            day?.title
          )}
        </td>
        <td>{day?.type}</td>
        <td style={{ color: day?.typeColor }}>{day?.typeName}</td>
        {hasPermission("edit-day") && (
            <td>
              <label className="switch">
                <input type="checkbox" checked={day.isPaid} onClick={(e) => paymentStatusHandler(e, day)} />
                <span className="slider round"></span>
              </label>
            </td>
        )}
        {hasPermission("edit-day") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={!day.isDeleted} onClick={(e) => deleteStatusHandler(e, day)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {(hasPermission("edit-day") || hasPermission("delete-day")) && (
          <td>
            <div className="d-flex">
              {hasPermission("edit-day") && (
                <button
                  data-toggle="modal"
                  data-target="#add"
                  onClick={(e) => modalHandler(day)}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {hasPermission("delete-day") && (
                <button onClick={(e) => deleteHandler(e, day)} className="btn btn-danger shadow btn-sm sharp mr-1">
                  <i className="fa fa-trash"></i>
                </button>
              )}
              {hasPermission("edit-day") && (
                <button onClick={(e) => dayCloneHandler(e, day)} className="btn btn-secondary shadow btn-sm" style={{ padding: "9px 13px" }}>
                  <i className="fa fa-copy" style={{ fontSize: "16px" }}></i>
                </button>
              )}
            </div>
          </td>
        )}
        {hasPermission("edit-day") && (
          <td>
            <DragHandle />
          </td>
        )}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ weekDaysList }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>No</th>
            <th>Image</th>
            <th>Day Name</th>
            <th>Day Type</th>
            <th>Type Name</th>
            {hasPermission("edit-day") && <th>Paid</th>}
            {hasPermission("edit-day") && <th>Status</th>}
            {(hasPermission("edit-day") || hasPermission("delete-day")) && <th>Actions</th>}
            {hasPermission("edit-day") && <th>Sort Order</th>}
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="8" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {weekDaysList?.length > 0 ? (
                weekDaysList?.map((day, index) => {
                  return <SortableTableRow key={index} day={day} index={index} serialNo={index} />;
                })
              ) : (
                <tr>
                  <td colSpan="8" className="text-center pt-5">
                    No week day found!
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  const dayTypeOptions = [
    {
      value: "normal",
      label: "Normal",
    },
    {
      value: "assessment",
      label: "Assessment",
    },
    {
      value: "finalAssessment",
      label: "Final Assessment",
    },
  ];
  const typeColorOptions = [
    {
      value: "#33B469",
      label: "Green",
    },
    {
      value: "#ED3A3A",
      label: "Red",
    },
    {
      value: "#EBBC2E",
      label: "Yellow",
    },
    {
      value: "#F26322",
      label: "Orange",
    },
    {
      value: "#007CFF",
      label: "Blue",
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4 align-items-center">
          <div className="col-8">
            {weekDetails && (
              <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
                <li class="breadcrumb-item">
                  <span onClick={() => navigate("/")}>
                    <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                  </span>
                </li>
                <li class="breadcrumb-item">
                  <span style={{ color: "#132C48", cursor: "pointer" }} onClick={() => navigate("/program-categories")}>
                    Programs
                  </span>
                </li>
                <li class="breadcrumb-item">
                  <span
                    style={{ color: "#132C48", cursor: "pointer" }}
                    onClick={() => navigate(`/program-category-programs?categoryId=${weekDetails?.program?.category?._id}`)}
                  >
                    {weekDetails?.program?.category?.title}
                  </span>
                </li>
                <li class="breadcrumb-item">
                  <span
                    style={{ color: "#132C48", cursor: "pointer" }}
                    onClick={() => navigate(`/program-weeks?programId=${weekDetails?.program?._id}`)}
                  >
                    {weekDetails?.program?.title}
                  </span>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {weekDetails?.title}
                </li>
              </ol>
            )}
          </div>
          <div className="col-4">
            {hasPermission("add-day") && (
              <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={() => modalHandler()}>
                Add New
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <SortableTable weekDaysList={weekDaysList} onSortEnd={onSortEnd} useDragHandle={true} />
                  {/* <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Image</th>
                        <th>Day Name</th>
                        <th>Day Type</th>
                        <th>Type Name</th>
                        {hasPermission("edit-day") && <th>Status</th>}
                        {(hasPermission("edit-day") || hasPermission("delete-day")) && <th>Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {tableDataLoading ? (
                        <tr>
                          <td colSpan="8" className="text-center pt-5">
                            <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {weekDaysList?.length > 0 ? (
                            weekDaysList?.map((day, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    <div className="maxWidth70px">
                                      <ModalImage
                                        small={process.env.REACT_APP_MEDIAPATH + day?.image}
                                        large={process.env.REACT_APP_MEDIAPATH + day?.image}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    {hasPermission("view-days-overview") ||
                                    hasPermission("view-days-warmup") ||
                                    hasPermission("view-days-exercise") ||
                                    hasPermission("view-days-result") ? (
                                      <Link
                                        style={{ color: "blue" }}
                                        to="/week-day-details"
                                        state={{ day, category: state?.category, program: state?.program, week: state?.week?.title }}
                                      >
                                        {day?.title}
                                      </Link>
                                    ) : (
                                      day?.title
                                    )}
                                  </td>
                                  <td>{day?.type}</td>
                                  <td style={{ color: day?.typeColor }}>{day?.typeName}</td>
                                  {hasPermission("edit-day") && (
                                    <td>
                                      <label className="switch">
                                        <input type="checkbox" checked={!day.isDeleted} onClick={(e) => deleteStatusHandler(e, day)} />
                                        <span className="slider round"></span>
                                      </label>
                                    </td>
                                  )}
                                  {(hasPermission("edit-day") || hasPermission("delete-day")) && (
                                    <td>
                                      <div className="d-flex">
                                        {hasPermission("edit-day") && (
                                          <button
                                            data-toggle="modal"
                                            data-target="#add"
                                            onClick={(e) => modalHandler(day)}
                                            className="btn btn-primary shadow btn-sm sharp mr-1"
                                          >
                                            <i className="fa fa-pencil"></i>
                                          </button>
                                        )}
                                        {hasPermission("delete-day") && (
                                          <button onClick={(e) => deleteHandler(e, day)} className="btn btn-danger shadow btn-sm sharp">
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="8" className="text-center pt-5">
                                No week day found!
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <PerfectScrollbar>
          <div className="modal fade" id="add" style={{ overflowY: "auto" }}>
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header border-0 px-3 mb-3">
                  <h5 className="modal-title">{modalTitle}</h5>
                  <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton">
                    <span>&times;</span>
                  </button>
                </div>
                <form noValidate onSubmit={onSubmit}>
                  <div className="row px-3">
                    {/* <div className="form-group px-3 col-md-6">
                      <label>Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Day Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                      {errors.title && !title && <div className="text-warning font-size-12">{errors.title}</div>}
                    </div> */}
                    <div className="form-group px-3 col-md-6">
                      <label>Day Title</label>
                      <Select
                        name="referenceDay"
                        value={
                          referenceDayValue &&
                          referenceDaysList?.map((day) => ({ value: day?._id, label: day?.title }))?.find((obj) => obj?.value === referenceDayValue)
                        }
                        options={referenceDaysList?.map((day) => ({ value: day?._id, label: day?.title }))}
                        placeholder="Select..."
                        onChange={(selected) => {
                          setReferenceDayValue(selected?.value);
                          setTitle(selected?.label);
                        }}
                        className="basic-single form-control"
                        classNamePrefix="select"
                      />
                      {errors.title && !title && <div className="text-warning font-size-12">{errors.title}</div>}
                    </div>
                    <div className="form-group px-3 col-md-6">
                      <label>Day Type</label>
                      <Select
                        name="dayType"
                        value={dayType && dayTypeOptions.find((obj) => obj?.value === dayType)}
                        options={dayTypeOptions}
                        onChange={(selected) => setDayType(selected?.value)}
                        className="basic-single form-control"
                        classNamePrefix="select"
                      />
                      {errors.dayType && !dayType && <div className="text-warning font-size-12">{errors.dayType}</div>}
                    </div>
                    <div className="form-group px-3 col-md-6">
                      <label>Type Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Type Name"
                        value={typeName}
                        onChange={(e) => setTypeName(e.target.value)}
                      />
                      {/* {errors.typeName && !typeName && <div className="text-warning font-size-12">{errors.typeName}</div>} */}
                    </div>
                    <div className="form-group px-3 col-md-6">
                      <label>Type Color</label>
                      <Select
                        name="typeColor"
                        value={typeColor && typeColorOptions.find((obj) => obj?.value === typeColor)}
                        options={typeColorOptions}
                        onChange={(selected) => setTypeColor(selected?.value)}
                        className="basic-single form-control"
                        classNamePrefix="select"
                      />
                      {/* {errors.typeColor && !typeColor && <div className="text-warning font-size-12">{errors.typeColor}</div>} */}
                    </div>
                    {/* <div className="form-group px-3 col-12">
                      <label>Description</label>
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Enter Description"
                        rows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      {errors.description && !description && <div className="text-warning font-size-12">{errors.description}</div>}
                    </div> */}

                    <div className="col-md-12 px-3 mt-2">
                      <div className="d-flex align-items-center mb-3">
                        <input
                          className="mr-2 mb-1"
                          type="checkbox"
                          name="allow-score"
                          id="allow-score"
                          value={allowMarkAsComplete}
                          checked={allowMarkAsComplete}
                          onChange={(e) => setAllowMarkAsComplete(e.target.checked)}
                        />
                        <label className="mb-0" for="allow-score" style={{ cursor: "pointer" }}>
                          Restrict Mark As Complete (Hint: Restrict user to log all Exercises for result.)
                        </label>
                      </div>
                    </div>
                    <div className="form-group px-3">
                      <label>Image</label>
                      <br />
                      {image && (
                        <>
                          <img src={process.env.REACT_APP_MEDIAPATH + image} className="img-fluid img-thumbnail" width="150" />
                          <br />
                        </>
                      )}
                      <button type="button" className="btn btn-primary mt-3" data-toggle="modal" data-target="#media">
                        Select Image
                      </button>
                      {errors.image && !image && <div className="text-warning font-size-12">{errors.image}</div>}
                    </div>
                  </div>
                  <div className="modal-footer border-0">
                    <button type="submit" className="btn btn-primary">
                      {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </PerfectScrollbar>
        <Media setImageHandler={setImageHandler} />
      </div>
    </>
  );
}

export default ListWeekDays;
