import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React from "react";
import {
  deleteLectureOverviewPermanently,
  deleteProgramWeekLectureOverviewPermanently,
  deleteWeekDayOverviewPermanently,
  deleteWeekOverviewPermanently,
  deleteWorkoutOverviewPermanently,
  remove_session,
  updateLectureOverview,
  updateProgramWeekLectureOverview,
  updateWeekDayOverview,
  updateWeekOverview,
  updateWorkoutOverview,
} from "../functions/general";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowDown from "../images/ArrowDown.svg";
import { usePermissions } from "../functions/usePermissions";
import DragHandler from "./DragHandler";

const TextOverview = ({ item, fetchData, getLoggedInUser, type, index }) => {
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const [isOpen, setIsopen] = React.useState(index === 0 ? true : false);
  const [loading, setLoading] = React.useState(false);
  const [overviewText, setOverviewText] = React.useState(item?.text);

  const handleUpdateOverviewText = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (overviewText) {
      setLoading(true);
      if (type === "week") {
        const textData = {
          title: "",
          url: "",
          text: overviewText,
          type: "text",
          fileSize: "",
          week: item?.week,
        };
        updateWeekOverview(textData, item?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Text updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update text.");
          }
          setLoading(false);
        });
      } else if (type === "day") {
        const textData = {
          title: "",
          url: "",
          text: overviewText,
          type: "text",
          fileSize: "",
          weekDay: item?.weekDay,
        };
        updateWeekDayOverview(textData, item?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Text updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update text.");
          }
          setLoading(false);
        });
      } else if (type === "lecture") {
        const textData = {
          title: "",
          url: "",
          text: overviewText,
          type: "text",
          fileSize: "",
          lecture: item?.lecture,
        };
        updateLectureOverview(textData, item?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Text updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update text.");
          }
          setLoading(false);
        });
      } else if (type === "programWeekLecture") {
        const textData = {
          title: "",
          url: "",
          text: overviewText,
          type: "text",
          fileSize: "",
          lecture: item?.lecture,
        };
        updateProgramWeekLectureOverview(textData, item?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Text updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update text.");
          }
          setLoading(false);
        });
      } else {
        const textData = {
          title: "",
          url: "",
          text: overviewText,
          type: "text",
          fileSize: "",
          workout: item?.workout,
        };
        updateWorkoutOverview(textData, item?._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Text updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update text.");
          }
          setLoading(false);
        });
      }
    }
  };
  const deleteHandler = (e, item) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete text overview permanently?") == true) {
      if (type === "week") {
        deleteWeekOverviewPermanently(item._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Text overview deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete text overview.");
          }
        });
      } else if (type === "day") {
        deleteWeekDayOverviewPermanently(item._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Text overview deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete text overview.");
          }
        });
      } else if (type === "lecture") {
        deleteLectureOverviewPermanently(item._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Text overview deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete text overview.");
          }
        });
      } else if (type === "programWeekLecture") {
        deleteProgramWeekLectureOverviewPermanently(item._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Text overview deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete text overview.");
          }
        });
      } else {
        deleteWorkoutOverviewPermanently(item._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Text overview deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete text overview.");
          }
        });
      }
    }
  };

  return (
    <div className="col-6 bg-white" style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", borderRadius: "10px", position: "relative" }}>
      <div
        className="py-4 text-black d-flex justify-content-between"
        onClick={() => setIsopen(!isOpen)}
        style={{ fontSize: "18px", fontWeight: "500", cursor: "pointer" }}
      >
        <span className="d-flex align-items-center" style={{ gap: "15px" }}>
          <DragHandler /> Text
        </span>
        <img src={ArrowDown} />
      </div>
      {isOpen && (
        <div>
          <form noValidate onSubmit={handleUpdateOverviewText}>
            <CKEditor
              editor={ClassicEditor}
              on
              onChange={(event, editor) => {
                const data = editor.getData();
                setOverviewText(data);
              }}
              data={overviewText}
            />
            {!overviewText && <div className="text-warning font-size-12 mt-2">Text is required</div>}
            <div className="modal-footer border-0 px-0">
              {((hasPermission("edit-day-overview") && type === "day") ||
                (hasPermission("edit-workout-overview") && type === "workout") ||
                (hasPermission("edit-lecture-overview") && type === "lecture") ||
                (hasPermission("edit-program-week-lecture-overview") && type === "programWeekLecture") ||
                (hasPermission("edit-week-overview") && type === "week")) && (
                <button type="submit" className="btn btn-primary btn-sm">
                  {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Update
                </button>
              )}
              {((hasPermission("delete-day-overview") && type === "day") ||
                (hasPermission("delete-workout-overview") && type === "workout") ||
                (hasPermission("delete-lecture-overview") && type === "lecture") ||
                (hasPermission("delete-program-week-lecture-overview") && type === "programWeekLecture") ||
                (hasPermission("delete-week-overview") && type === "week")) && (
                <button onClick={(e) => deleteHandler(e, item)} className="btn btn-danger btn-sm">
                  Delete
                </button>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default TextOverview;
