import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  remove_session,
  getWeekLectureCategories,
  updateWeekLectureCategory,
  addWeekLectureCategory,
  restoreWeekLectureCategory,
  deleteWeekLectureCategory,
  deleteWeekLectureCategoryPermanently,
  updateIntroWeekTabs,
  sortWeekLectureCategory,
  getLectureCategories,
} from "../../../../functions/general";
import { toast } from "react-toastify";
import Media from "../../../../components/Media";
import ModalImage from "react-modal-image";

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";
import { usePermissions } from "../../../../functions/usePermissions";

function IntroWeekLecturesCategories(props) {
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const { state } = useLocation();

  const [weekLectureCategories, setWeekLectureCategories] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [image, setImage] = React.useState("");
  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [tabName, setTabName] = React.useState(props?.week?.lectureTabName ? props?.week?.lectureTabName : "Lectures");
  const [screenTitle, setScreenTitle] = React.useState(props?.week?.lectureTitle ? props?.week?.lectureTitle : "");
  const [screenDescription, setScreenDescription] = React.useState(props?.week?.lectureText ? props?.week?.lectureText : "");
  const [tabNameBtnLoading, setTabNameBtnLoading] = React.useState("");
  const [errors, setErrors] = React.useState("");
  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [showAddImgModal, setShowAddImgModal] = React.useState(false);

  const [isPaid, setIsPaid] = React.useState(true);

  useEffect(() => {
    // props.setPageTitleAndSubTitle("Lecture Categories", "Here is the list of Lecture Categories !");
    fetchData();
  }, []);

  const fetchData = (type) => {
    getWeekLectureCategories(props?.week?._id).then((res) => {
      // getLectureCategories(props?.week?._id).then((res) => {
      if (res && (res.status == 200 || res.status == 201)) {
        setWeekLectureCategories(res.data);
        if (type === "add") {
          let sortedArray = [];
          res?.data?.map((category) => sortedArray.push(category?._id));
          sortWeekLectureCategory(sortedArray).then((res) => {
            if (res && res.status == 200) {
              // fetchData();
            } else if (res && res.status == 401) {
              remove_session();
              props.getLoggedInUser();
              navigate("/login");
            } else {
              toast.error("Failed to sort the list.");
            }
            setTableDataLoading(false);
          });
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };

  const modalHandler = (category) => {
    if (category && category._id) {
      setModalTitle("Update Lecture Category");
      setModalButtonText("Update");
      setTitle(category.title);
      setImage(category.image);
      setId(category._id);
      setIsPaid(category?.isPaid);
    } else {
      setModalTitle("Add New Lecture Category");
      setModalButtonText("Add");
      setTitle("");
      setImage("");
      setId("");
      setIsPaid(true);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const category = {
        title: title,
        image: image,
        week: props?.week,
        isPaid,
      };
      let type = "add";
      if (id) {
        updateWeekLectureCategory(id, category).then((res) => {
          if (res && res.status == 200) {
            fetchData(type);
            toast.success("Lecture Category updated successfully.");
            document.getElementById("weekLecturecategoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update lecture category.");
          }
          setLoading(false);
        });
      } else {
        addWeekLectureCategory(category).then((res) => {
          if (res && res.status == 201) {
            fetchData();
            toast.success("Lecture Category added successfully.");
            document.getElementById("weekLecturecategoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add lecture category.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
      image: "",
    };
    let isValid = true;
    if (!title) {
      isValid = false;
      errors.title = "Please enter title.";
    }
    if (!image) {
      isValid = false;
      errors.image = "Please select image.";
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, category) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete lecture category?") == true) {
      if (window.confirm("Are you sure you want to delete lecture category permanently?") == true) {
        deleteWeekLectureCategoryPermanently(category._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Lecture Category deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete category.");
          }
        });
      }
    }
  };

  const deleteStatusHandler = (e, category) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the lecture category?";
    if (category.isDeleted) {
      confirmText = "Are you sure you want to active the lecture category?";
    }
    if (window.confirm(confirmText) == true) {
      if (category.isDeleted) {
        restoreWeekLectureCategory(category._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Lecture Category activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active category.");
          }
        });
      } else {
        deleteWeekLectureCategory(category._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Lecture Category deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive category.");
          }
        });
      }
    }
  };

  const paymentStatusHandler = (e, category) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to change payment status of lecture category?";
    if (window.confirm(confirmText) == true) {
      const categoryData = {
        title: category?.title,
        image: category?.image,
        week: props?.week,
        isPaid: e.target.checked,
      };
      updateWeekLectureCategory(category._id, categoryData).then((res) => {
        if (res && res.status == 200) {
          fetchData();
          toast.success("Payment status changed successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to change the payment status of lecture category.");
        }
      });
    }
  };

  const tabNameHandler = () => {
    setTabNameBtnLoading(true);
    updateIntroWeekTabs(
      { lectureTabName: tabName, lectureTitle: screenTitle, lectureText: screenDescription, isLecture: true },
      props?.week?._id
    ).then((res) => {
      if (res && res.status == 200) {
        toast.success("Tab name updated successfully.");
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to update tab name.");
      }
      setTabNameBtnLoading(false);
    });
  };

  const setImageHandler = (image) => {
    setImage(image);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    const updatedLectureCategories = arrayMove(weekLectureCategories, oldIndex, newIndex);
    let sortedArray = [];
    updatedLectureCategories?.map((category) => sortedArray.push(category?._id));
    sortWeekLectureCategory(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setWeekLectureCategories(updatedLectureCategories);
        // fetchData();
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ category, serialNo }) => {
    return (
      <tr>
        <td>{serialNo + 1}</td>
        <td>
          <div className="maxWidth70px">
            <ModalImage small={process.env.REACT_APP_MEDIAPATH + category.image} large={process.env.REACT_APP_MEDIAPATH + category.image} />
          </div>
        </td>
        <td>{category.title}</td>
        {hasPermission("view-intro-week-lectures-videos") && (
          <td>
            <button
              className="btn btn-dark btn-xs"
              onClick={() => {
                props?.setLectureVideosShow(true);
                // props?.setLectureSubcategoriesShow(true);
                props?.setLectureCategory(category);
              }}
            >
              {category?.count ? category?.count : 0}
            </button>
          </td>
        )}
        {/* {hasPermission("edit-intro-week-lectures-categories") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={category?.isPaid} onChange={(e) => paymentStatusHandler(e, category)} />
              <span className="slider round"></span>
            </label>
          </td>
        )} */}
        {hasPermission("edit-intro-week-lectures-categories") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={!category.isDeleted} onClick={(e) => deleteStatusHandler(e, category)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {(hasPermission("edit-intro-week-lectures-categories") || hasPermission("delete-intro-week-lectures-categories")) && (
          <td>
            <div className="d-flex">
              {hasPermission("edit-intro-week-lectures-categories") && (
                <button
                  data-toggle="modal"
                  data-target="#addWeekLectureCategory"
                  onClick={(e) => modalHandler(category)}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {hasPermission("delete-intro-week-lectures-categories") && (
                <button onClick={(e) => deleteHandler(e, category)} className="btn btn-danger shadow btn-sm sharp">
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </td>
        )}
        {hasPermission("edit-intro-week-lectures-categories") && (
          <td>
            <DragHandle />
          </td>
        )}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ weekLectureCategories }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>No</th>
            <th>Image</th>
            <th>Name</th>
            {hasPermission("view-intro-week-lectures-videos") && <th>Lectures</th>}
            {/* {hasPermission("edit-intro-week-lectures-categories") && <th>Paid</th>} */}
            {hasPermission("edit-intro-week-lectures-categories") && <th>Status</th>}
            {(hasPermission("edit-intro-week-lectures-categories") || hasPermission("delete-intro-week-lectures-categories")) && <th>Actions</th>}
            {hasPermission("edit-intro-week-lectures-categories") && <th>Sort Order</th>}
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="7" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {weekLectureCategories?.length > 0 ? (
                weekLectureCategories?.map((category, index) => {
                  return <SortableTableRow key={index} category={category} index={index} serialNo={index} />;
                })
              ) : (
                <tr>
                  <td colSpan="7" className="text-center pt-5">
                    No data found!
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  return (
    <>
      {/*<div className="container-fluid pt-0">*/}
      <div className="row justify-content-center">
        <div className="form-group col-md-6">
          {hasPermission("edit-intro-week-lectures-categories") && (
            <div className="row">
              <div className="form-group col-md-12">
                <label>Tab Name</label>
                <input type="text" className="form-control" placeholder="Lectures" value={tabName} onChange={(e) => setTabName(e.target.value)} />
                {!tabName && <div className="text-warning font-size-12 mt-2">This field is required.</div>}
              </div>
              <div className="form-group col-md-12">
                <label>Screen Title</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Title"
                  value={screenTitle}
                  onChange={(e) => setScreenTitle(e.target.value)}
                />
                {/* {errors.title && !title && <div className="text-warning font-size-12">{errors.title}</div>} */}
              </div>
              <div className="form-group col-md-12">
                <label>Screen Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Enter Description"
                  rows={4}
                  value={screenDescription}
                  onChange={(e) => setScreenDescription(e.target.value)}
                />
                <button className="btn btn-primary float-right btn-sm mt-4" onClick={tabNameHandler}>
                  {tabNameBtnLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} Update
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row mb-4 pt-4" style={{ borderTop: "1px solid #f0f1f5" }}>
        <div className="col-12">
          {hasPermission("add-intro-week-lectures-categories") && (
            <button className="btn btn-primary float-right" data-toggle="modal" data-target="#addWeekLectureCategory" onClick={(e) => modalHandler()}>
              Add New
            </button>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <SortableTable weekLectureCategories={weekLectureCategories} onSortEnd={onSortEnd} useDragHandle={true} />
                {/* <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Image</th>
                        <th>Lecture Categories</th>
                        <th>Videos</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {weekLectureCategories?.length > 0 ? (
                        weekLectureCategories?.map((category, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>
                                <div className="maxWidth70px">
                                  <ModalImage
                                    small={process.env.REACT_APP_MEDIAPATH + category.image}
                                    large={process.env.REACT_APP_MEDIAPATH + category.image}
                                  />
                                </div>
                              </td>
                              <td>{category.title}</td>
                              <td>
                                <button
                                  className="btn btn-dark btn-xs"
                                  onClick={() => {
                                    props?.setLectureVideosShow(true);
                                    props?.setLectureCategory(category);
                                  }}
                                >
                                  {category?.count ? category?.count : 0}
                                </button>
                              </td>
                              <td>
                                <label className="switch">
                                  <input type="checkbox" checked={!category.isDeleted} onClick={(e) => deleteStatusHandler(e, category)} />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                              <td>
                                <div className="d-flex">
                                  <button
                                    data-toggle="modal"
                                    data-target="#addWeekLectureCategory"
                                    onClick={(e) => modalHandler(category)}
                                    className="btn btn-primary shadow btn-sm sharp mr-1"
                                  >
                                    <i className="fa fa-pencil"></i>
                                  </button>
                                  <button onClick={(e) => deleteHandler(e, category)} className="btn btn-danger shadow btn-sm sharp">
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No Lecture Category found !</td>
                        </tr>
                      )}
                    </tbody>
                  </table> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="addWeekLectureCategory">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header border-0 px-3 mb-3">
              <h5 className="modal-title">{modalTitle}</h5>
              <button type="button" className="close" data-dismiss="modal" id="weekLecturecategoryModalCloseButton">
                <span>&times;</span>
              </button>
            </div>
            <form noValidate onSubmit={onSubmit}>
              <div className="form-group px-3">
                <label>Title</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Lecture Category Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {errors.title && !title && <div className="text-warning font-size-12 mt-2">{errors.title}</div>}
              </div>
              <div className="form-group px-3">
                <label>Image</label>
                <br />
                {image && (
                  <>
                    <img src={process.env.REACT_APP_MEDIAPATH + image} className="img-fluid img-thumbnail" width="150" />
                    <br />
                  </>
                )}
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={() => setShowAddImgModal(true)}
                  data-toggle="modal"
                  data-target="#weekLectureCategoryImg"
                >
                  Select Image
                </button>
                {errors.image && !image && <div className="text-warning font-size-12 mt-2">{errors.image}</div>}
              </div>
              <div className="modal-footer border-0">
                <button type="submit" className="btn btn-primary">
                  {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/*</div>*/}
      {showAddImgModal && <Media setImageHandler={setImageHandler} id={"weekLectureCategoryImg"} />}
    </>
  );
}

export default IntroWeekLecturesCategories;
