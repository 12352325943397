import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCurrencySymbol, getMemberDetails, remove_session } from "../../../../functions/general";
import { useState } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";

const MemberDetails = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [memberDtails, setMemberDetails] = useState(null);

  const memberId = searchParams.get("id");

  useEffect(() => {
    props.setPageTitleAndSubTitle("Member Details", `Here is the details of ${memberDtails && memberDtails?.firstName}!`);
    fetchData();
  }, [memberDtails?.firstName]);

  const fetchData = () => {
    getMemberDetails(memberId).then((res) => {
      if ((res && res.status == 200) || (res && res.status == 200)) {
        setMemberDetails(res?.data);
        setLoading(false);
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        setLoading(false);
      }
    });
  };

  return (
    <div className="container-fluid">
      <div className="row mb-2 align-items-center">
        <div className="col-8">
          <ol className="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
            <li className="breadcrumb-item">
              <span onClick={() => navigate("/")}>
                <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
              </span>
            </li>
            <li className="breadcrumb-item">
              <span style={{ color: "rgb(19, 44, 72)", cursor: "pointer" }} onClick={() => navigate("/members")}>
                Members Listing
              </span>
            </li>
            <li className="breadcrumb-item">Member Details</li>
          </ol>
        </div>
      </div>
      {loading ? (
        <div className="py-5 text-center">
          <div className="spinner-border text-black text-center" role="status" aria-hidden="true"></div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-3 col-12">
            <div className="card p-3" style={{ borderRadius: "10px" }}>
              <div className="d-flex justify-content-center mb-3">
                <div className="text-center">
                  <img
                    className="mb-2"
                    src={
                      memberDtails?.profileImage
                        ? memberDtails?.profileImage
                        : `https://ui-avatars.com/api/?name=${memberDtails?.firstName}&rounded=true&bold=true`
                    }
                    alt=""
                  />
                  <h4>{memberDtails?.firstName}</h4>
                </div>
              </div>
              <div className="d-flex justify-content-between border-bottom py-3">
                <span>Full Name</span>
                <span style={{ fontWeight: 500, color: "black" }}>{memberDtails?.firstName}</span>
              </div>
              <div className="d-flex justify-content-between border-bottom py-3">
                <span>Email</span>
                <span style={{ fontWeight: 500, color: "black" }}>{memberDtails?.email}</span>
              </div>
            </div>
          </div>
          <div className="col-md-9 col-12">
            <div className="card p-3" style={{ borderRadius: "10px" }}>
              <div className="border-bottom pb-2 mb-4">
                <h3>Subscription Details</h3>
              </div>
              {memberDtails?.subscriptions?.map((subscription, index) => {
                const totalAmount = subscription?.transactions?.reduce((accumulator, transaction) => {
                  const transactionTotal = transaction.amount_paid - transaction.refundedAmount;
                  return accumulator + transactionTotal;
                }, 0);
                return (
                  <div key={index} className="card p-3" style={{ borderRadius: "10px", background: "#f7f7f7" }}>
                    <div className="border-bottom pb-1 mb-2 d-flex justify-content-between">
                      <h4 style={{ color: "blue" }}>
                        {subscription?.userSubscriptionName}{" "}
                        <span
                          style={{
                            background: subscription?.status ? "green" : "red",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            color: "white",
                            fontSize: "12px",
                            marginLeft: "6px",
                          }}
                        >
                          {subscription?.status ? "Active" : "InActive"}
                        </span>
                      </h4>
                      <span>
                        <h4 className="text-black">
                          Total Amount Paid:{" "}
                          <span>
                            {subscription?.userSubscriptionCurrency && getCurrencySymbol(subscription?.userSubscriptionCurrency)}
                            {totalAmount}
                          </span>
                        </h4>
                      </span>
                    </div>
                    <span>
                      <span className="text-black" style={{ fontWeight: "500", marginRight: "5px" }}>
                        Amount Paid:
                      </span>
                      {subscription?.userSubscriptionCurrency && getCurrencySymbol(subscription?.userSubscriptionCurrency)}
                      {subscription?.totalAmountPaid}
                    </span>
                    <span>
                      <span className="text-black" style={{ fontWeight: "500", marginRight: "5px" }}>
                        Subscription Date:
                      </span>
                      {dayjs(subscription?.createdAt).format("DD-MM-YYYY hh:mm A")}
                    </span>
                    <span>
                      <span className="text-black" style={{ fontWeight: "500", marginRight: "5px" }}>
                        Subscription Expiry:
                      </span>
                      {dayjs(subscription?.userSubscriptionExpiryDate).format("DD-MM-YYYY hh:mm A")}
                    </span>
                    <span>
                      <span className="text-black" style={{ fontWeight: "500", marginRight: "5px" }}>
                        Subscription Duration:
                      </span>
                      {subscription?.userSubscriptionPeriod}
                    </span>
                    <span>
                      <span className="text-black" style={{ fontWeight: "500", marginRight: "5px" }}>
                        Next Biling Date:
                      </span>
                      {dayjs(subscription?.nextBillingDate).format("DD-MM-YYYY hh:mm A")}
                    </span>
                    <span>
                      <span className="text-black" style={{ fontWeight: "500", marginRight: "5px" }}>
                        Payment Gateway:
                      </span>
                      {subscription?.userSubscriptionGateway}
                    </span>
                    <span>
                      <span className="text-black" style={{ fontWeight: "500", marginRight: "5px" }}>
                        Subscription Type:
                      </span>
                      {subscription?.userSubscriptionType}
                    </span>
                    <span>
                      <span className="text-black" style={{ fontWeight: "500", marginRight: "5px" }}>
                        Plateform:
                      </span>
                      {subscription?.userSubscriptionPlatform}
                    </span>
                    <span>
                      <span className="text-black" style={{ fontWeight: "500", marginRight: "5px" }}>
                        Total Transctions:
                      </span>
                      {subscription?.transactions?.length}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemberDetails;
