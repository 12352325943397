import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  addLectureCategory,
  deleteLectureCategory,
  deleteLectureCategoryPermanently,
  getLectureCategories,
  remove_session,
  updateLectureCategory,
  restoreLectureCategory,
  sortLectureCategories,
} from "../../../functions/general";
import { toast } from "react-toastify";
import Media from "../../../components/Media";
import ModalImage from "react-modal-image";
import { usePermissions } from "../../../functions/usePermissions";

import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";

function ListLectureCategories(props) {
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const [lectureCategories, setLectureCategories] = React.useState([]);
  const [modalTitle, setModalTitle] = React.useState("");
  const [modalButtonText, setModalButtonText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [image, setImage] = React.useState("");
  const [id, setId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [tableDataLoading, setTableDataLoading] = React.useState(true);
  const [errors, setErrors] = React.useState("");
  useEffect(() => {
    props.setPageTitleAndSubTitle("Lecture Categories", "Here is the list of Lecture Categories !");
    fetchData();
  }, []);

  const fetchData = (type) => {
    getLectureCategories().then((res) => {
      if (res && res.status == 200) {
        setLectureCategories(res.data);
        if (type === "add") {
          let sortedArray = [];
          res?.data?.map((category) => sortedArray.push(category?._id));
          sortLectureCategories(sortedArray).then((res) => {
            if (res && res.status == 200) {
              // fetchData();
            } else if (res && res.status == 401) {
              remove_session();
              props.getLoggedInUser();
              navigate("/login");
            } else {
              toast.error("Failed to sort the list.");
            }
            setTableDataLoading(false);
          });
        }
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };

  const modalHandler = (category) => {
    if (category && category._id) {
      setModalTitle("Update Lecture Category");
      setModalButtonText("Update");
      setTitle(category.title);
      setImage(category.image);
      setId(category._id);
    } else {
      setModalTitle("Add New Lecture Category");
      setModalButtonText("Add");
      setTitle("");
      setImage("");
      setId("");
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (loading) return false;
    if (validate()) {
      setLoading(true);
      const category = {
        title: title,
        image: image,
      };
      let type = "add";
      if (id) {
        updateLectureCategory(id, category).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Lecture Category updated successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to update lecture category.");
          }
          setLoading(false);
        });
      } else {
        addLectureCategory(category).then((res) => {
          if (res && res.status == 201) {
            fetchData(type);
            toast.success("Lecture Category added successfully.");
            document.getElementById("categoryModalCloseButton").click();
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to add lecture category.");
          }
          setLoading(false);
        });
      }
    }
  };

  const validate = () => {
    let errors = {
      title: "",
    };
    let isValid = true;
    if (!title) {
      isValid = false;
      errors.title = "Please enter title.";
    }
    setErrors(errors);
    return isValid;
  };

  const deleteHandler = (e, category) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete lecture category?") == true) {
      if (window.confirm("Are you sure you want to delete lecture category permanently?") == true) {
        deleteLectureCategoryPermanently(category._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Lecture Category deleted successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to delete category.");
          }
        });
      }
    }
  };

  const deleteStatusHandler = (e, category) => {
    e.preventDefault();
    let confirmText = "Are you sure you want to in-active the lecture category?";
    if (category.isDeleted) {
      confirmText = "Are you sure you want to active the lecture category?";
    }
    if (window.confirm(confirmText) == true) {
      if (category.isDeleted) {
        restoreLectureCategory(category._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Lecture Category activated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to active category.");
          }
        });
      } else {
        deleteLectureCategory(category._id).then((res) => {
          if (res && res.status == 200) {
            fetchData();
            toast.success("Lecture Category deactivated successfully.");
          } else if (res && res.status == 401) {
            remove_session();
            props.getLoggedInUser();
            navigate("/login");
          } else {
            toast.error("Failed to inactive category.");
          }
        });
      }
    }
  };

  const setImageHandler = (image) => {
    setImage(image);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableDataLoading(true);
    const updatedLectureCategories = arrayMove(lectureCategories, oldIndex, newIndex);
    let sortedArray = [];
    updatedLectureCategories?.map((category) => sortedArray.push(category?._id));
    sortLectureCategories(sortedArray).then((res) => {
      if (res && res.status == 200) {
        setLectureCategories(updatedLectureCategories);
        // fetchData();
      } else if (res && res.status == 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
        toast.error("Failed to sort the list.");
      }
      setTableDataLoading(false);
    });
  };

  const DragHandle = SortableHandle(() => (
    <div
      style={{
        cursor: "move",
        width: "20px",
        height: "20px",
      }}
    >
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="grip-vertical"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill="#6e6e6e"
          d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  ));

  const SortableTableRow = SortableElement(({ category, serialNo }) => {
    return (
      <tr>
        <td>{serialNo + 1}</td>
        <td>
          <div className="maxWidth70px">
            <ModalImage small={process.env.REACT_APP_MEDIAPATH + category.image} large={process.env.REACT_APP_MEDIAPATH + category.image} />
          </div>
        </td>
        <td>{category.title}</td>
        {hasPermission("view-lecture-subcategories") && (
          <td>
            <Link to={`/lecture-category-subcategories?categoryId=${category._id}`} state={{ category }} className="btn btn-dark btn-xs">
              {category.subcategories.length}
            </Link>
          </td>
        )}
        {hasPermission("edit-lecture-category") && (
          <td>
            <label className="switch">
              <input type="checkbox" checked={!category.isDeleted} onClick={(e) => deleteStatusHandler(e, category)} />
              <span className="slider round"></span>
            </label>
          </td>
        )}
        {(hasPermission("edit-lecture-category") || hasPermission("delete-lecture-category")) && (
          <td>
            <div className="d-flex">
              {hasPermission("edit-lecture-category") && (
                <button
                  data-toggle="modal"
                  data-target="#add"
                  onClick={(e) => modalHandler(category)}
                  className="btn btn-primary shadow btn-sm sharp mr-1"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              )}
              {hasPermission("delete-lecture-category") && (
                <button onClick={(e) => deleteHandler(e, category)} className="btn btn-danger shadow btn-sm sharp">
                  <i className="fa fa-trash"></i>
                </button>
              )}
            </div>
          </td>
        )}
        {hasPermission("edit-lecture-category") && (
          <td>
            <DragHandle />
          </td>
        )}
      </tr>
    );
  });

  const SortableTable = SortableContainer(({ lectureCategories }) => {
    return (
      <table id="roles-table" className="display dataTable" style={{ minWidth: "845px" }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Image</th>
            <th>Name</th>
            {hasPermission("view-lecture-subcategories") && <th>Subcategories</th>}
            {hasPermission("edit-lecture-category") && <th>Status</th>}
            {(hasPermission("edit-lecture-category") || hasPermission("delete-lecture-category")) && <th>Actions</th>}
            {hasPermission("edit-lecture-category") && <th>Sort Order</th>}
          </tr>
        </thead>
        <tbody>
          {tableDataLoading ? (
            <tr>
              <td colSpan="7" className="text-center pt-5">
                <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
              </td>
            </tr>
          ) : (
            <>
              {lectureCategories.length > 0 ? (
                lectureCategories.map((category, index) => {
                  return <SortableTableRow key={index} category={category} index={index} serialNo={index} />;
                })
              ) : (
                <tr>
                  <td colSpan="7" className="text-center pt-5">
                    No lecture category found!
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-4">
        <div className="col-8">
            <ol class="breadcrumb bg-transparent mb-0" style={{ fontSize: "18px" }}>
              <li class="breadcrumb-item">
                <span onClick={() => navigate("/")}>
                  <i className="fas fa fa-home" style={{ fontSize: "28px", cursor: "pointer" }}></i>
                </span>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Lecture Categories
              </li>
            </ol>
          </div>
          <div className="col-4">
            {hasPermission("add-lecture-category") && (
              <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={(e) => modalHandler()}>
                Add New
              </button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <SortableTable lectureCategories={lectureCategories} onSortEnd={onSortEnd} useDragHandle={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="add">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header border-0 px-3 mb-3">
                <h5 className="modal-title">{modalTitle}</h5>
                <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton">
                  <span>&times;</span>
                </button>
              </div>
              <form noValidate onSubmit={onSubmit}>
                <div className="form-group px-3">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Lecture Category Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <div className="text-warning font-size-12">{errors.title}</div>
                </div>
                <div className="form-group px-3">
                  <label>Image</label>
                  <br />
                  {image && <img src={process.env.REACT_APP_MEDIAPATH + image} className="img-fluid img-thumbnail" width="150" />}
                  <br />
                  <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#media">
                    Select Image
                  </button>
                  <div className="text-warning font-size-12">{errors.image}</div>
                </div>
                <div className="modal-footer border-0">
                  <button type="submit" className="btn btn-primary">
                    {loading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>} {modalButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Media setImageHandler={setImageHandler} />
      </div>
    </>
  );
}

export default ListLectureCategories;
