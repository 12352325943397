import React, { useEffect } from "react";
import {Link, useNavigate} from "react-router-dom";
import {
    addCategory,
    deleteCategory,
    deleteCategoryPermanently,
    getCategories,
    remove_session,
    restoreCategory, updateCategory
} from "../../../functions/general";
import { toast } from 'react-toastify';

function ListCategories(props) {
    const navigate = useNavigate();
    const [categories, setCategories] = React.useState([]);
    const [modalTitle, setModalTitle] = React.useState("");
    const [modalButtonText, setModalButtonText] = React.useState("");
    const [title, setTitle] = React.useState("");
    const [id, setId] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState("");
    useEffect(() => {
        props.setPageTitleAndSubTitle("Categories", "Here is the list of Categories !");
        fetchData();
    }, [])

    const fetchData = () => {
        getCategories().then(res => {
            if (res && res.status == 200) {
                setCategories(res.data);
            } else if (res && res.status == 401) {
                remove_session();
                props.getLoggedInUser();
                navigate('/login');
            } else {
            }
        })
    }

    const modalHandler = (category) => {
        if(category && category._id){
            setModalTitle("Update Category")
            setModalButtonText("Update")
            setTitle(category.title)
            setId(category._id)
        } else {
            setModalTitle("Add New Category")
            setModalButtonText("Add")
            setTitle("")
            setId("")
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (loading)
            return false;
        if (validate()) {
            setLoading(true);
            const category = {
                title: title
            }
            if(id){
                updateCategory(id, category).then(res => {
                    if (res && res.status == 200) {
                        fetchData();
                        toast.success("Category updated successfully.");
                        document.getElementById('categoryModalCloseButton').click();
                    } else if (res && res.status == 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate('/login');
                    } else {
                        toast.error("Failed to update category.");
                    }
                    setLoading(false)
                })
            } else {
                addCategory(category).then(res => {
                    if (res && res.status == 201) {
                        fetchData();
                        toast.success("Category added successfully.");
                        document.getElementById('categoryModalCloseButton').click();
                    } else if (res && res.status == 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate('/login');
                    } else {
                        toast.error("Failed to add category.");
                    }
                    setLoading(false)
                })
            }
        }
    }

    const validate = () => {
        let errors = {
            title: '',
        };
        let isValid = true;
        if (!title) {
            isValid = false;
            errors.title = "Please enter title.";
        }
        setErrors(errors);
        return isValid;
    }

    const deleteHandler = (e, category) => {
        e.preventDefault()
        if (window.confirm("Are you sure you want to delete category?") == true) {
            if (window.confirm("Are you sure you want to delete category permanently?") == true) {
                deleteCategoryPermanently(category._id).then(res => {
                    if (res && res.status == 200) {
                        fetchData();
                        toast.success("Category deleted successfully.");
                    } else if (res && res.status == 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate('/login');
                    } else {
                        toast.error("Failed to delete category.");
                    }
                })
            }
        }
    }

    const deleteStatusHandler = (e, category) => {
        e.preventDefault()
        let confirmText = "Are you sure you want to in-active the category?"
        if(category.isDeleted){
            confirmText = "Are you sure you want to active the category?"
        }
        if (window.confirm(confirmText) == true) {
            if(category.isDeleted) {
                restoreCategory(category._id).then(res => {
                    if (res && res.status == 200) {
                        fetchData();
                        toast.success("Category activated successfully.");
                    } else if (res && res.status == 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate('/login');
                    } else {
                        toast.error("Failed to active category.");
                    }
                })
            }else{
                deleteCategory(category._id).then(res => {
                    if (res && res.status == 200) {
                        fetchData();
                        toast.success("Category deactivated successfully.");
                    } else if (res && res.status == 401) {
                        remove_session();
                        props.getLoggedInUser();
                        navigate('/login');
                    } else {
                        toast.error("Failed to inactive category.");
                    }
                })
            }
        }
    }

  return (
      <>
          <div className="container-fluid">
              <div className="row mb-4">
                  <div className="col-12">
                      <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add" onClick={e => modalHandler()}>Add New
                      </button>
                  </div>
              </div>
              <div className="row">
                  <div className="col-12">
                      <div className="card">
                          <div className="card-body">
                              <div className="table-responsive">
                                  <table id="roles-table" className="display dataTable" style={{minWidth: "845px"}}>
                                      <thead>
                                      <tr>
                                          <th>ID</th>
                                          <th>Name</th>
                                          <th>Filters</th>
                                          <th>Status</th>
                                          <th>Actions</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      { categories.length > 0 && categories.map((category, index) => {
                                          return (
                                              <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{category.title}</td>
                                                  <td>
                                                      <Link to={`/category-filters?categoryId=${category._id}`} className="btn btn-dark btn-xs">
                                                        {category.filters.length}
                                                      </Link>
                                                  </td>
                                                  <td>
                                                      <label className="switch">
                                                          <input type="checkbox" checked={!category.isDeleted} onClick={e => deleteStatusHandler(e, category)}/>
                                                              <span className="slider round"></span>
                                                      </label>
                                                  </td>
                                                  <td>
                                                      <div className="d-flex">
                                                          <button data-toggle="modal" data-target="#add" onClick={e => modalHandler(category)}
                                                                  className="btn btn-primary shadow btn-sm sharp mr-1"><i
                                                              className="fa fa-pencil"></i></button>
                                                          <button onClick={e => deleteHandler(e, category)} className="btn btn-danger shadow btn-sm sharp"><i
                                                              className="fa fa-trash"></i></button>
                                                      </div>
                                                  </td>
                                              </tr>
                                          );
                                      })}
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="modal fade" id="add">
                  <div className="modal-dialog" role="document">
                      <div className="modal-content">
                          <div className="modal-header border-0 px-3 mb-3">
                              <h5 className="modal-title">{modalTitle}</h5>
                              <button type="button" className="close" data-dismiss="modal" id="categoryModalCloseButton"><span>&times;</span>
                              </button>
                          </div>
                          <form noValidate onSubmit={onSubmit}>
                              <div className="form-group px-3">
                                  <label>Title</label>
                                  <input type="text" className="form-control" placeholder="Category Title"
                                         value={title} onChange={(e) => setTitle(e.target.value)}/>
                                  <div className="text-warning font-size-12">{errors.title}</div>
                              </div>
                              <div className="modal-footer border-0">
                                  <button type="submit" className="btn btn-primary">{modalButtonText}</button>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </>
  );
}

export default ListCategories;
