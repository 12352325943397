import React, { useEffect, useRef, useState } from "react";
import {
  checkPromotionStatus,
  checkSalesStatus,
  getAllMembershipPlansList,
  getCurrencySymbol,
  getCurrentSubscription,
  getPaymenIntent,
  validateCoupon,
} from "../../../functions/general";
import { Link, useSearchParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import checkIcon from "../../../assets/icons/check-icon.png";
// const stripe = loadStripe("pk_test_f6DXZjRRT68TeKsxjtcdKGM600tWEkB0Ud");

const StripeWebview = () => {
  const [subscriptionsList, setSubscriptionsList] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [couponMessageColor, setCouponMessageColor] = useState("");
  const [promoBtnLoading, setPromoBtnLoading] = useState(false);
  const [promoText, setPromoText] = useState("");
  const [couponMessage, setCouponMessage] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponData, setCouponData] = useState(null);
  const [searchParams] = useSearchParams();
  const userToken = searchParams.get("token");
  const origin = searchParams.get("origin");

  const [userCurrentSubscription, setUserCurrentSubscription] = useState("");
  const [isSale, setIsSale] = useState(false);

  useEffect(() => {
    fetchData();
    document.body.style.backgroundColor = "#0F1217";
    localStorage.removeItem("userCurrentSubscription");
  }, []);

  const fetchData = () => {
    let currency = "usd";
    getAllMembershipPlansList(currency, userToken, origin).then((res) => {
      if (res && (res.status == 201 || res.status == 200)) {
        if (res.data && res.data.length) {
          res?.data?.map((item) =>{
            if(item?.name === 'MONTHLY'){
              console.log('item', item)
              setSelectedSubscription(item);
            }
          })
          setSubscriptionsList(res?.data);
          setIsLoading(false);
        }
      } else if (res && res.status == 401) {
        console.log("some error");
        setIsLoading(false);
      } else {
      }
    });
    // getCurrentSubscription(userToken).then((res) => {
    //   if (res && (res.status == 201 || res.status == 200)) {
    //     if (res?.data) {
    //       setUserCurrentSubscription(res?.data);
    //     }
    //   } else if (res && res.status == 401) {
    //     console.log("some error");
    //     setIsLoading(false);
    //   } else {
    //   }
    // });
    checkSalesStatus().then((res) => {
      if (res && (res.status == 201 || res.status == 200)) {
        if (res.data) {
          // setIsSale(res?.data?.status);
        }
      } else if (res && res.status == 401) {
        console.log("some error");
      } else {
      }
    });
  };

  useEffect(() => {
    getCurrentSubscription(userToken).then((res) => {
      if (res && (res.status == 201 || res.status == 200)) {
        if (res?.data) {
          setUserCurrentSubscription(res?.data);
          const monthlySubscription = selectedSubscription?.name === "MONTHLY";
          if (res?.data?.nextBillingDate) {
            checkPromotionStatus().then((promotion) => {
              if (promotion?.data?.status && monthlySubscription) {
                if (
                  (res?.data?.isCanceled && res?.data?.userSubscriptionName === "MONTHLY") ||
                  ((res?.data?.isCanceled || !res?.data?.isCanceled) && res?.data?.userSubscriptionName === "YEARLY")
                ) {
                  setPromoText(promotion?.data?.couponCode);
                  setIsSale(promotion?.data?.status);
                  handleCoupon(promotion?.data?.couponCode);
                }
              }
            });
          } else {
            checkPromotionStatus().then((promotion) => {
              if (promotion?.data?.status && monthlySubscription) {
                setPromoText(promotion?.data?.couponCode);
                  setIsSale(promotion?.data?.status);
                handleCoupon(promotion?.data?.couponCode);
              }
            });
          }
        }
      } else if (res && res.status == 401) {
        console.log("some error");
        setIsLoading(false);
      } else {
      }
    });
  }, [selectedSubscription]);
  // const paymentHandler = () => {
  //   setBtnLoading(true);
  //   const payload = {
  //     productId: selectedSubscription?.id,
  //     amount: selectedSubscription?.price?.unit_amount,
  //     currency: selectedSubscription?.price?.currency,
  //     origin: "academy",
  //     couponCode,
  //   };
  //   getPaymenIntent(payload, userToken).then((res) => {
  //     if (res && (res.status == 201 || res.status == 200)) {
  //       if (res.data) {
  //         setBtnLoading(false);
  //         navigate("/stripe-form", {
  //           state: { clientSecret: res?.data?.client_secret, userToken, selectedSubscription, discountedAmount: res?.data?.amount },
  //         });
  //       }
  //     } else if (res && res.status == 401) {
  //       console.log("some error");
  //       setBtnLoading(false);
  //     } else {
  //     }
  //     setBtnLoading(false);
  //   });
  // };

  const getDevicePlateform = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return "android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "apple";
    } else {
      return "web";
    }
  };

  const paymentHandler = async () => {
    setBtnLoading(true);
    const payload = {
      productId: selectedSubscription?.id,
      amount: selectedSubscription?.price?.unit_amount,
      currency: selectedSubscription?.price?.currency,
      origin: origin,
      priceId: selectedSubscription?.price?.id,
      baseUrl: window.location.origin + "/",
      token: userToken,
      couponCode: couponCode,
      platform: getDevicePlateform(),
    };

    const stripeKey = process.env.REACT_APP_STRIPEKEY;

    const stripePromise = loadStripe(stripeKey);
    const stripe = await stripePromise;

    await getPaymenIntent(payload, userToken).then((res) => {
      if (res && (res.status == 201 || res.status == 200)) {
        if (res.data) {
          setBtnLoading(false);
          if (stripe) {
            stripe
              .redirectToCheckout({ sessionId: res?.data?.id })
              .then((result) => {
                // Handle success or error from the redirect
                if (result.error) {
                  // Show an error message to the user
                  setErrorMessage(result.error.message);
                }
              })
              .catch((error) => {
                // Handle any other errors
                console.error(error);
                setErrorMessage("An error occurred during the payment process. Please try again.");
              });
          }
        }
      } else if (res && (res.status == 401 || res.status == 400)) {
        setErrorMessage(res?.data?.message);
        setBtnLoading(false);
      }
      setBtnLoading(false);
    });
  };

  // const paymentHandler = async () => {
  //   setBtnLoading(true);
  //   const payload = {
  //     productId: selectedSubscription?.id,
  //     amount: selectedSubscription?.price?.unit_amount,
  //     currency: selectedSubscription?.price?.currency,
  //     origin: "academy",
  //     priceId: selectedSubscription?.price?.id,
  //     baseUrl: "http://localhost:3000",
  //     token: userToken,
  //   };

  //   // Retrieve your Stripe publishable key
  //   const stripePromise = loadStripe("pk_test_f6DXZjRRT68TeKsxjtcdKGM600tWEkB0Ud");

  //   // Wait for Stripe to load and initialize
  //   const stripe = await stripePromise;
  //   await getPaymenIntent(payload, userToken).then((res) => {
  //     if (res && (res.status == 201 || res.status == 200)) {
  //       if (res.data) {
  //         setBtnLoading(false);
  //         console.log(res.data, "res.data");

  //         if (stripe) {
  //           stripe.redirectToCheckout({ sessionId: res?.data?.id });
  //         }

  //         // navigate("/stripe-form", {
  //         //   state: { clientSecret: res?.data?.client_secret, userToken, selectedSubscription, discountedAmount: res?.data?.amount },
  //         // });
  //       }
  //     } else if (res && res.status == 401) {
  //       console.log("some error");
  //       setBtnLoading(false);
  //     } else {
  //     }
  //     setBtnLoading(false);
  //   });
  // };

  // const handleFocus = () => {
  //   inputRef.current.scrollIntoView({
  //     behavior: "smooth",
  //     block: "center",
  //   });
  // };

  const trialDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" });

  const handleCoupon = (codeFromApi) => {
    setPromoBtnLoading(true);
    const payload = {
      productId: selectedSubscription?.id,
      couponCode: codeFromApi || promoText,
      amount: selectedSubscription?.price?.unit_amount,
    };
    validateCoupon(payload, userToken).then((res) => {
      if (res && (res.status == 201 || res.status == 200)) {
        setPromoBtnLoading(false);
        setCouponData(res?.data?.coupon);
        if (res?.data?.valid) {
          setCouponMessage("Coupon Applied Successfully!");
          setCouponMessageColor("green");
          setCouponCode(codeFromApi || promoText);
          // navigate("/stripe-form", { state: { clientSecret: res?.data?.client_secret, userToken, selectedSubscription } });
        } else {
          setCouponMessage(res?.data?.error);
          setCouponMessageColor("red");
        }
      } else if (res && res.status == 401) {
        console.log("some error");
        setPromoBtnLoading(false);
      } else {
      }
      // setPromoText("");
      setPromoBtnLoading(false);
    });
  };

  const handlePromoInput = (e) => {
    setPromoText(e.target.value);
    if (couponMessage && (!e.target.value || !promoText)) {
      setCouponMessage("");
    }
  };

  const handleRemoveCoupon = () => {
    setCouponCode("");
    setCouponMessage("");
    setPromoText("");
    setCouponData(null);
  };

  // const handleDiscount = () => {
  //   if (couponData?.percent_off) {
  //     // return getCurrencySymbol(selectedSubscription?.price?.currency) + (selectedSubscription?.price?.unit_amount * couponData?.percent_off) / 10000;
  //     return "a"
  //   } else {
  //     return getCurrencySymbol(couponData?.currency) + couponData?.amount_off;
  //   }
  // };

  const handleDiscount = () => {
    if (couponData?.percent_off) {
      return (
        "-" +
        getCurrencySymbol(selectedSubscription?.price?.currency) +
        ((selectedSubscription?.price?.unit_amount * couponData?.percent_off) / 10000).toFixed(2)
      );
    } else {
      return "-" + getCurrencySymbol(couponData?.currency) + (couponData?.amount_off / 100).toFixed(2);
    }
  };
  const handleTotalAfterDiscount = () => {
    if (couponData?.percent_off) {
      return ((selectedSubscription?.price?.unit_amount * couponData?.percent_off) / 10000).toFixed(2);
    } else {
      return (couponData?.amount_off / 100).toFixed(2);
    }
  };

  const parsedUserCurrentSubscription = JSON.parse(localStorage.getItem("userCurrentSubscription"));

  return (
    <>
      <div
        className="subscriptions-list-wrapper py-5"
        style={{ background: "#0F1217", minHeight: "125vh", fontFamily: "Poppins", padding: "24px", height: "100%" }}
      >
        <div className="row justify-content-center">
          <div className="col-md-4 col-12">
            <div className="header-text text-center">
              <h1 style={{ color: "white", fontSize: "22px", fontWeight: "600", marginBottom: 0, letterSpacing: 3 }}>UNLOCK THE FULL EXPERIENCE</h1>
              <div style={{ fontSize: "18px", color: "white", lineHeight: "34px", color: "#7E7E7E" }}>
                Discover why 20,000+ students trust our programs.
                {/* <h1 style={{ color: "white", fontSize: "28px", marginBottom: "15px", fontWeight: "600" }}>CHOOSE YOUR PLAN</h1>
              <div style={{ fontSize: "18px", color: "white", lineHeight: "34px" }}>
                When your free trial ends on <b>{trialDate}</b>, your account will be automatically charged for the selected plan. You can
                <b> change or cancel </b>
                your plan anytime. */}
              </div>
            </div>
            <div className="d-flex flex-column my-5" style={{ gap: "14px" }}>
              <span className="d-flex fw-medium text-white" style={{ gap: "15px", fontSize: "18px" }}>
                <img width={22} height={22} src={checkIcon} alt="" />
                Access all current and future programs.
              </span>
              <span className="d-flex fw-medium text-white" style={{ gap: "15px", fontSize: "18px" }}>
                <img width={22} height={22} src={checkIcon} alt="" />
                Cancel any time.
              </span>
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center mt-5">
                <span
                  className="spinner-border text-white"
                  style={{ color: "#007CFF", width: "2rem", height: "2rem" }}
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            ) : (
              <>
                <div className="membership-plans-list-wrapper mt-4 position-relative">
                  {subscriptionsList?.map((subscription, index) => (
                    <div
                      key={index}
                      className={`d-flex mb-4 position-relative ${
                        selectedSubscription === subscription||(!selectedSubscription && userCurrentSubscription && !userCurrentSubscription?.status &&subscription?.name === "MONTHLY")
                          ? // || (userCurrentSubscription?.stripeProductId === subscription?.id &&
                            //   userCurrentSubscription?.status &&
                            //   (userCurrentSubscription?.paymentStatus === "paid" ||
                            //     userCurrentSubscription?.paymentStatus === "complete" ||
                            //     userCurrentSubscription?.paymentStatus === "succeeded"))
                            "membership-plans-list-item-selected"
                          : "membership-plans-list-item "
                      }`}
                    >
                      <input
                        className="membership-plan-radio"
                        type="radio"
                        id={subscription?.id}
                        name={subscription?.name}
                        style={{ opacity: 0, width: 0 }}
                        disabled={promoBtnLoading}
                        checked={
                          selectedSubscription === subscription
                          // || (userCurrentSubscription?.stripeProductId === subscription?.id &&
                          //   userCurrentSubscription?.status &&
                          //   (userCurrentSubscription?.paymentStatus === "paid" ||
                          //     userCurrentSubscription?.paymentStatus === "complete" ||
                          //     userCurrentSubscription?.paymentStatus === "succeeded"))
                        }
                        onChange={() => {
                          if (errorMessage) setErrorMessage(null);
                          if (userCurrentSubscription) {
                            if (couponData) handleRemoveCoupon();
                            setSelectedSubscription(subscription);
                            localStorage.setItem("userCurrentSubscription", JSON.stringify(userCurrentSubscription));
                            setUserCurrentSubscription("");
                          } else if (couponData) {
                            handleRemoveCoupon();
                          } else setSelectedSubscription(subscription);
                        }}
                      />
                      {/* <label
                        style={{ gap: "10px", padding: "22px" }}
                        for={subscription?.id}
                        className="d-flex align-items-center justify-content-between w-100 membership-plan-radio-label mb-0"
                      >
                        <div style={{ flexBasis: "100%" }}>
                          <p className="mb-2">
                            <span style={{ fontSize: "16px", fontWeight: "600", color: "#007CFF" }}>{subscription?.name}</span>
                          </p>
                          <span style={{ fontSize: "16px", fontWeight: "600", color: "white", textTransform: "capitalize" }}>
                            {getCurrencySymbol(subscription?.price?.currency)}
                            {subscription?.price?.unit_amount / 100} / {subscription?.price?.recurring && subscription?.price?.recurring?.interval}{" "}
                            {subscription?.price?.nickname && subscription?.price?.nickname}
                          </span>
                        </div>
                      </label> */}
                      <label
                        style={{ gap: "10px" }}
                        for={subscription?.id}
                        className="d-flex align-items-center justify-content-between w-100 membership-plan-radio-label mb-0"
                      >
                        <div style={{ flexBasis: "40%", position: "relative", overflow: "hidden", padding: "25px 0 25px 40px" }}>
                          {/* {subscription?.name === "YEARLY" && isSale && <span className="discount-batch">-52%</span>} */}
                          {subscription?.name === "MONTHLY" && isSale && <span className="discount-batch">-50%</span>}

                          <p className="mb-0">
                            <span className="fs-small-screen" style={{ fontSize: "24px", fontWeight: "700", color: "#007CFF" }}>
                              {subscription?.name}
                            </span>
                          </p>
                        </div>
                        <div style={{ flexBasis: "60%", textAlign: "end", paddingRight: "22px" }}>
                          {/* {subscription?.name === "YEARLY" && isSale && <span className="text-white mr-3 fs-6 fw-bolder strikethrough">$334.90</span>} */}
                          {subscription?.name === "MONTHLY" && isSale && <span className="text-white mr-3 fs-6 fw-bolder strikethrough">$33.49</span>}
                          <span
                            className="fs-small-screen"
                            style={{ fontSize: "26px", fontWeight: "700", color: "white", textTransform: "capitalize" }}
                          >
                            {getCurrencySymbol(subscription?.price?.currency)}
                            {/* {subscription?.price?.unit_amount / 100} */}
                            {subscription?.name === "MONTHLY" && isSale ? "16.75" : subscription?.price?.unit_amount / 100}
                            <span style={{ fontSize: "14px", fontWeight: 500 }}>
                              {" "}
                              / {subscription?.price?.recurring && subscription?.price?.recurring?.interval}
                            </span>
                          </span>
                          {/* <p className="mb-2">
                            <span style={{ fontSize: "14px", fontWeight: "400", color: "#fff" }}>{subscription?.description}</span>
                          </p> */}
                          {/* <span style={{ background: "#007CFF", color: "white", fontSize: "12px", padding: "4px 10px", borderRadius: "20px" }}>
                            save 20%
                          </span> */}
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
                <label className="mt-4" style={{ fontSize: "18px", color: "white" }}>
                  Have a promo code?
                </label>
                <div className="d-flex mb-4 align-center" style={{ gap: "15px" }}>
                  <div className="form-group w-75">
                    <input
                      // onFocus={handleFocus}
                      type="text"
                      className="form-control bg-transparent"
                      placeholder="Enter your promo code"
                      value={promoText}
                      style={{ borderRadius: "5px", minHeight: "56px" }}
                      onChange={(e) => handlePromoInput(e)}
                    />
                    {couponMessage && (
                      <div className={couponMessageColor === "red" ? "text-danger font-size-12 mt-2" : "text-success font-size-12 mt-2"}>
                        {couponMessage}
                      </div>
                    )}
                  </div>
                  <span className="w-25">
                    {couponMessage === "Coupon Applied Successfully!" ? (
                      <button className="btn btn-danger w-100 mb-3 py-3 fs-16" onClick={handleRemoveCoupon}>
                        {promoBtnLoading && <span className="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>}Remove
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary w-100 mb-3 py-3 fs-16"
                        style={{ background: "#007CFF" }}
                        disabled={!promoText || promoBtnLoading || !selectedSubscription}
                        onClick={() => handleCoupon()}
                      >
                        {promoBtnLoading ? <span className="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span> : "Apply"}
                      </button>
                    )}
                  </span>
                </div>
                {couponData && (
                  <div class="OrderDetailsFooter-subtotalItems flex-container direction-column">
                    <div class="DiscountLine">
                      <div
                        className="d-flex justify-content-between fs-16"
                        style={{ fontWeight: 600, paddingBottom: "20px", marginBottom: "20px", borderBottom: "1px solid white" }}
                      >
                        <span>Subtotal</span>
                        <span>
                          {getCurrencySymbol(selectedSubscription?.price?.currency)}
                          {(selectedSubscription?.price?.unit_amount / 100).toFixed(2)}
                        </span>
                      </div>
                      <div
                        className="d-flex justify-content-between fs-16"
                        style={{ fontWeight: 600, paddingBottom: "20px", marginBottom: "20px", borderBottom: "1px solid white" }}
                      >
                        {/* <span>{"Discount" + couponData?.percent_off ? `${couponData?.percent_off}%` : ""}</span> */}
                        <span>
                          {"Discount" +
                            (couponData?.percent_off
                              ? ` (${couponData.percent_off}%)`
                              : `(${getCurrencySymbol(couponData?.currency)}${(couponData?.amount_off / 100).toFixed(2)})`)}
                        </span>

                        <span>{couponData && handleDiscount()}</span>
                      </div>
                      <div
                        className="d-flex justify-content-between fs-16"
                        style={{ fontWeight: 600, paddingBottom: "20px", marginBottom: "20px", borderBottom: "1px solid hsla(0,0%,10%,.1)" }}
                      >
                        <span>Total due</span>
                        <span>
                          {getCurrencySymbol(selectedSubscription?.price?.currency)}
                          {(selectedSubscription?.price?.unit_amount / 100 - handleTotalAfterDiscount()).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <p className="mb-4">
                  By clicking "Continue," you agree to our{" "}
                  <a
                    href="https://saturnomovement.com/terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                    id="terms"
                    style={{ fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }}
                  >
                    Terms of Service,
                  </a>{" "}
                  <a
                    href="https://saturnomovement.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    id="privacy_policy"
                    style={{ fontWeight: "bold", textDecoration: "underline", cursor: "pointer" }}
                  >
                    Privacy Policy
                  </a>
                  , and to receive our email updates.
                </p>

                {errorMessage && <div className="text-danger font-size-12">{errorMessage}</div>}
                <button
                  onClick={paymentHandler}
                  className="btn btn-custom w-100 my-5 py-3 d-flex justify-content-center align-items-center"
                  disabled={
                  selectedSubscription?.id === userCurrentSubscription?.stripeProductId ||
                    !selectedSubscription ||
                    btnLoading ||
                    promoBtnLoading ||
                    selectedSubscription === userCurrentSubscription ||
                    (selectedSubscription?.id === parsedUserCurrentSubscription?.stripeProductId &&
                      !parsedUserCurrentSubscription?.isCanceled &&
                      (parsedUserCurrentSubscription?.paymentStatus === "paid" ||
                        parsedUserCurrentSubscription?.paymentStatus === "complete" ||
                        parsedUserCurrentSubscription?.paymentStatus === "succeeded"))
                  }
                >
                  {btnLoading && <span className="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>} Continue
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StripeWebview;
