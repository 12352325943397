import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { getAppSummaryStats, remove_session } from "../../../functions/general";
import { useNavigate } from "react-router-dom";
import { DateRange } from "react-date-range";

const AppSummarySection = ({ dashboardStats, getLoggedInUser }) => {
  const navigate = useNavigate();

  const [stats, setStats] = useState(dashboardStats);
  const [duration, setDuration] = useState("yearly");
  const [dateRange, setDateRange] = useState({ value: "", show: false });
  const [loading, setLoading] = useState(false);

  const [showCustomDuration, setShowCustomDuration] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const handleDateRange = (value) => {
    setState([value.selection]);
    if (value.selection.endDate.toString() !== "Invalid Date") {
      setDuration(`${dayjs(value.selection.startDate).format("YYYY-MM-DD")}-${dayjs(value.selection.endDate).format("YYYY-MM-DD")}`);
      setDateRange({
        value: `${dayjs(value.selection.startDate).format("MM/DD/YYYY")}-${dayjs(value.selection.endDate).format("MM/DD/YYYY")}`,
        show: false,
      });
    }
  };

  useEffect(() => {
    if (duration === "yearly") {
      setStats(dashboardStats);
      setLoading(false);
    } else {
      getAppSummaryStats(duration).then((res) => {
        if ((res && res.status == 200) || res.status == 201) {
          setStats(res?.data);
          setLoading(false);
        } else if (res && res.status == 401) {
          remove_session();
          getLoggedInUser();
          navigate("/login");
        } else {
        }
        setLoading(false);
      });
    }
  }, [duration, dashboardStats]);

  return (
    <>
      <div className="card">
        <div className="card-header border-0 pb-0 d-sm-flex d-block">
          <div>
            <h4 className="card-title mb-1">App Summary</h4>
          </div>
          <span className="d-flex gap-2">
            {showCustomDuration && (
              <span style={{ position: "absolute", zIndex: 1, right: "270px" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    <strong>{dateRange?.value}</strong>
                  </span>
                  <button
                    onClick={() => {
                      setDateRange({ show: true });
                      setState([
                        {
                          startDate: new Date(),
                          endDate: null,
                          key: "selection",
                        },
                      ]);
                    }}
                    className="btn btn-link"
                  >
                    Change
                  </button>
                </div>
                {dateRange.show && (
                  <DateRange
                    maxDate={new Date()}
                    editableDateInputs={true}
                    onChange={(value) => handleDateRange(value)}
                    ranges={state}
                    moveRangeOnFirstSelection={false}
                    retainEndDateOnFirstSelection={true}
                  />
                )}
              </span>
              // <input
              //   className="form-control"
              //   value={duration}
              //   style={{ width: "250px", height: "67.5px", marginRight: "15px" }}
              //   type="date"
              //   max={dayjs().format("YYYY-MM-DD")}
              //   onChange={(e) => {
              //     setLoading(true);
              //     setDuration(dayjs(e.target.value).format("YYYY-MM-DD"));
              //   }}
              // />
            )}
            <div className="dropdown custom-dropdown">
              <div className="btn btn-sm btn-primary light d-flex align-items-center svg-btn px-4" data-toggle="dropdown">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <path
                      d="M22.4281 2.856H21.8681V1.428C21.8681 0.56 21.2801 0 20.4401 0C19.6001 0 19.0121 0.56 19.0121 1.428V2.856H9.71606V1.428C9.71606 0.56 9.15606 0 8.28806 0C7.42006 0 6.86006 0.56 6.86006 1.428V2.856H5.57206C2.85606 2.856 0.560059 5.152 0.560059 7.868V23.016C0.560059 25.732 2.85606 28.028 5.57206 28.028H22.4281C25.1441 28.028 27.4401 25.732 27.4401 23.016V7.868C27.4401 5.152 25.1441 2.856 22.4281 2.856ZM5.57206 5.712H22.4281C23.5761 5.712 24.5841 6.72 24.5841 7.868V9.856H3.41606V7.868C3.41606 6.72 4.42406 5.712 5.57206 5.712ZM22.4281 25.144H5.57206C4.42406 25.144 3.41606 24.136 3.41606 22.988V12.712H24.5561V22.988C24.5841 24.136 23.5761 25.144 22.4281 25.144Z"
                      fill="#2F4CDD"
                    ></path>
                  </g>
                </svg>
                <div className="text-left ml-3">
                  <span className="d-block fs-16">Select Duration</span>
                  <small className="d-block fs-14 text-capitalize">{showCustomDuration ? "Custom" : duration}</small>
                </div>
                <i className="fa fa-angle-down scale5 ml-3"></i>
              </div>
              <div className="dropdown-menu dropdown-menu-right">
                <span
                  onClick={() => {
                    if (duration !== "yearly") {
                      setLoading(true);
                      setDuration("yearly");
                      setShowCustomDuration(false);
                    }
                  }}
                  className={`dropdown-item ${duration === "yearly" && !showCustomDuration ? "disabled" : ""}`}
                >
                  Yearly
                </span>
                <span
                  onClick={() => {
                    if (duration !== "monthly") {
                      setLoading(true);
                      setDuration("monthly");
                      setShowCustomDuration(false);
                    }
                  }}
                  className={`dropdown-item ${duration === "monthly" && !showCustomDuration ? "disabled" : ""}`}
                >
                  Monthly
                </span>
                <span
                  onClick={() => {
                    if (duration !== "weekly") {
                      setLoading(true);
                      setDuration("weekly");
                      setShowCustomDuration(false);
                    }
                  }}
                  className={`dropdown-item ${duration === "weekly" && !showCustomDuration ? "disabled" : ""}`}
                >
                  Weekly
                </span>
                <span
                  onClick={() => {
                    setShowCustomDuration(true);
                    setDateRange({ show: true });
                    setState([
                      {
                        startDate: new Date(),
                        endDate: null,
                        key: "selection",
                      },
                    ]);
                  }}
                  className={`dropdown-item ${showCustomDuration ? "disabled" : ""}`}
                >
                  Custom
                </span>
              </div>
            </div>
          </span>
        </div>
        <div className="card-body orders-summary">
          {loading ? (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: "200px" }}>
              <div className="spinner-border text-black" role="status" aria-hidden="true"></div>
            </div>
          ) : (
            <div className="row">
              <div className="col-sm-4 mb-4">
                <div className="border px-3 py-3 rounded-xl">
                  <h2 className="fs-32 font-w600 counter">{stats?.totalMembers ?? 0}</h2>
                  <p className="fs-16 mb-0">Total Members</p>
                </div>
              </div>
              <div className="col-sm-4 mb-4">
                <div className="border px-3 py-3 rounded-xl">
                  <h2 className="fs-32 font-w600 counter">{stats?.newMembers ?? 0}</h2>
                  <p className="fs-16 mb-0">New Members</p>
                </div>
              </div>
              <div className="col-sm-4 mb-4">
                <div className="border px-3 py-3 rounded-xl">
                  {/* <h2 className="fs-32 font-w600 counter">
                        {(stats?.totalMembers && stats?.totalMembers) -
                          (stats?.academyMembers && stats?.academyMembers) ?? 0}
                      </h2> */}
                  <h2 className="fs-32 font-w600 counter">{stats?.wpMembers ?? 0}</h2>
                  <p className="fs-16 mb-0">WP Members</p>
                </div>
              </div>
              <div className="col-sm-4 mb-4">
                <div className="border px-3 py-3 rounded-xl">
                  <h2 className="fs-32 font-w600 counter">{stats?.androidMembers ?? 0}</h2>
                  <p className="fs-16 mb-0">Android Members</p>
                </div>
              </div>
              <div className="col-sm-4 mb-4">
                <div className="border px-3 py-3 rounded-xl">
                  <h2 className="fs-32 font-w600 counter">{stats?.appleMembers ?? 0}</h2>
                  <p className="fs-16 mb-0">Apple Members</p>
                </div>
              </div>
              <div className="col-sm-4 mb-4">
                <div className="border px-3 py-3 rounded-xl">
                  <h2 className="fs-32 font-w600 counter">{stats?.webMembers ?? 0}</h2>
                  <p className="fs-16 mb-0">Web Members</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AppSummarySection;
