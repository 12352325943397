import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ReactPaginate from "react-paginate";
import { deleteSMCommunityPost, getSMCommunityPosts, remove_session } from "../../../../../functions/general";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CommunityPosts = (props) => {
  const navigate = useNavigate();

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryItems, setGalleryItems] = useState([]);

  const [dropdownStates, setDropdownStates] = useState({});

  const [from, setFrom] = React.useState(0);
  const [limit, setLimit] = React.useState(12);

  const [communityPosts, setCommunityPosts] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);

  const [tableDataLoading, setTableDataLoading] = React.useState(true);

  const [searchNameQuery, setSearchNameQuery] = React.useState("");
  const [searchQuery, setSearchQuery] = React.useState("");

  const [dateFrom, setDateFrom] = React.useState("");
  const [dateTo, setDateTo] = React.useState("");

  // const [resetPagination, setResetPagination] = React.useState(false);

  useEffect(() => {
    fetchData({ username: searchNameQuery, search: searchQuery, dateFrom, dateTo, from, limit });
  }, [from, limit]);

  const fetchData = (filters) => {
    setTableDataLoading(true);
    getSMCommunityPosts(filters).then((res) => {
      if (res && res.status === 200) {
        setCommunityPosts(res?.data?.posts);
        setTotalCount(res?.data?.count);
      } else if (res && res.status === 401) {
        remove_session();
        props.getLoggedInUser();
        navigate("/login");
      } else {
      }
      setTableDataLoading(false);
    });
  };

  const handleCardClick = (attachments) => {
    const items = attachments.map((attachment) => ({
      original: attachment.url,
      thumbnail: attachment.url,
      renderItem: () =>
        attachment.fileType === "image" ? (
          <img src={attachment.url} alt={attachment.fileName} className="image-gallery-image" />
        ) : (
          <video controls className="image-gallery-video">
            <source src={attachment.url} type={`video/${attachment.fileExtension}`} />
            Your browser does not support the video tag.
          </video>
        ),
    }));

    setGalleryItems(items);
    setIsGalleryOpen(true);
  };

  const handlePageChange = (data) => {
    const selectedPage = data.selected;
    setFrom(selectedPage * limit);
  };

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
    setFrom(0); // Reset to the first page
  };

  const toggleDropdown = (index) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown")) {
      setDropdownStates({});
    }
  };

  React.useEffect(() => {
    if (Object.values(dropdownStates).some((state) => state)) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownStates]);

  const deleteHandler = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this post?") === true) {
      deleteSMCommunityPost(id).then((res) => {
        if (res && (res.status === 200 || res.status === 201)) {
          fetchData();
          toast.success("Post deleted successfully.");
        } else if (res && res.status == 401) {
          remove_session();
          props.getLoggedInUser();
          navigate("/login");
        } else {
          toast.error("Failed to delete post.");
        }
      });
    }
  };

  const handleFilterButtonClick = () => {
    setFrom(0);
    // setResetPagination(true);

    const filtersObj = {
      username: searchNameQuery,
      search: searchQuery,
      dateFrom,
      dateTo,
      from,
      limit,
    };
    fetchData(filtersObj);
    setTableDataLoading(true);
  };

  const handleResetFilters = () => {
    setSearchNameQuery("");
    setSearchQuery("");
    setDateFrom("");
    setDateTo("");
    fetchData({ from, limit });
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row align-align-items-center mb-4">
            <div className="col-3">
              <div className="form-group">
                <label>Search by Username</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Username"
                  value={searchNameQuery}
                  onChange={(e) => setSearchNameQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Search</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label>Date From</label>
                <input type="date" className="form-control" placeholder="Date from" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} />
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label>Date To</label>
                <input type="date" className="form-control" placeholder="Date to" value={dateTo} onChange={(e) => setDateTo(e.target.value)} />
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <p className="m-0">&nbsp;</p>
                <button className="btn btn-primary float-right btn-md ml-2" onClick={handleFilterButtonClick}>
                  Apply
                </button>
                <button className="btn btn-primary float-right btn-md" onClick={handleResetFilters}>
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
        {tableDataLoading ? (
          <div className="col-12 text-center py-5">
            <div
              className="spinner-border text-black"
              style={{ width: "3rem", height: "3rem", borderWidth: "0.35rem" }}
              role="status"
              aria-hidden="true"
            ></div>
          </div>
        ) : communityPosts?.length > 0 ? (
          communityPosts?.map((post, index) => {
            const { _id, attachments, text, likeCount, totalCommentsCount, userDetails, createdAt } = post;
            const firstAttachment = attachments.length > 0 ? attachments[0] : null;

            return (
              <div className="col-3" key={index}>
                <div className="card">
                  <div className="d-flex justify-content-between align-items-center p-3">
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          userDetails?.profileImage
                            ? process.env.REACT_APP_MEDIAPATH + userDetails?.profileImage
                            : `https://ui-avatars.com/api/?name=${userDetails?.firstName}&bold=true`
                        }
                        alt={userDetails?.firstName}
                        style={{ width: 45, height: 45, objectFit: "cover", borderRadius: "50px", marginRight: 14 }}
                      />
                      <div>
                        <p className="mb-0" style={{ fontWeight: 600, color: "#000" }}>
                          {userDetails?.firstName}
                        </p>
                        <p className="mb-0" style={{ fontSize: 15 }}>
                          {dayjs(createdAt)?.format("DD MMM YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn btn-light btn-sm p-0"
                        style={{ borderRadius: "30px", width: 32, height: 32, background: "transparent", border: "none" }}
                        onClick={() => toggleDropdown(index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#000"
                          className="bi bi-three-dots-vertical"
                          viewBox="0 0 16 16"
                        >
                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                        </svg>
                      </button>
                      {dropdownStates[index] && (
                        <div className="dropdown-menu show" aria-labelledby="dropdownMenuButton">
                          <button onClick={(e) => deleteHandler(e, _id)} className="dropdown-item" type="button">
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {firstAttachment ? (
                    <div className="card-img-top w-100 text-center" onClick={() => handleCardClick(attachments)}>
                      <img
                        src={firstAttachment?.url}
                        style={{ height: 220, minHeight: 220, objectFit: "contain", cursor: "pointer" }}
                        alt={firstAttachment?.fileName}
                      />
                    </div>
                  ) : (
                    <div
                      className="card-img-top d-flex align-items-center justify-content-center"
                      style={{ height: "220px", backgroundColor: "#f0f0f0" }}
                    >
                      <p className="text-muted">No Attachments</p>
                    </div>
                  )}
                  <div className="card-body p-4">
                    <p className="card-text text-black" title={text}>
                      {text.length > 100 ? `${text.substring(0, 140)}...` : text}
                    </p>
                  </div>
                  <div className="card-footer">
                    <div className="d-flex justify-content-between">
                      <span>
                        <i style={{ fontSize: 20 }} className="flaticon-381-like mr-1"></i> {likeCount}
                      </span>
                      <Link to={`/community-post-comments?id=${post?._id}&comments_count=${totalCommentsCount}`} style={{ cursor: "pointer" }}>
                        <span style={{ cursor: "pointer" }}>
                          <i style={{ fontSize: 20 }} className="mdi mdi-comment-outline mr-1"></i> {totalCommentsCount}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center w-100 my-5">
            <h3 style={{ fontWeight: 600 }}>No Post Found!</h3>
          </div>
        )}
      </div>

      {/* Pagination and Limit Controls */}
      {totalCount > limit && (
        <div className="d-flex justify-content-between align-items-center mt-4">
          <ReactPaginate
            previousLabel={
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                />
              </svg>
            }
            nextLabel={
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                <path
                  fillRule="evenodd"
                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                />
              </svg>
            }
            breakLabel={"..."}
            pageCount={Math.ceil(totalCount / limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            disabledClassName={"disabled"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
          />
          <span className="d-flex align-items-center">
            Limit:
            <select className="form-control ml-2" style={{ width: "auto", height: "42px" }} value={limit} onChange={handleLimitChange}>
              <option value={12}>12</option>
              <option value={24}>24</option>
              <option value={48}>48</option>
              <option value={96}>96</option>
            </select>
          </span>
        </div>
      )}

      {isGalleryOpen && (
        <div className="gallery-modal">
          <div className="gallery-overlay" onClick={() => setIsGalleryOpen(false)}></div>
          <div className="gallery-content">
            <ImageGallery items={galleryItems} showThumbnails={false} showFullscreenButton={false} showPlayButton={false} />
          </div>
        </div>
      )}
    </>
  );
};

export default CommunityPosts;
