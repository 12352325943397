import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import CheckoutForm from "./CheckoutForm";
import { useLocation } from "react-router-dom";

const stripePromise = loadStripe("pk_test_f6DXZjRRT68TeKsxjtcdKGM600tWEkB0Ud");

const CheckoutFormWrapper = () => {
  const { state } = useLocation();
  const clientSecret = state?.clientSecret;

  const appearance = {
    theme: "night",
    labels: "floating",
  };
  return (
    <div className="row subscriptions-list-wrapper justify-content-center" style={{ background: "#0F1217", minHeight: "125vh", fontFamily: "Poppins", padding: "24px" }}>
      <div className="col-lg-4 col-md-6 col-12">
        {clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
            <CheckoutForm data={state} />
          </Elements>
        )}
      </div>
    </div>
  );
};

export default CheckoutFormWrapper;
